import React, { useEffect } from "react";
import TabAmateurs from "../component/CompteAmateurPage/TabAmateur";
import { useUser } from "../contexts/userService";
import { useNavigate } from "react-router";
import Social from "../component/CompteAmateurPage/remodal/social";
import { redirect } from "react-router-dom";

/**
 * This component display the User Page
 * @param {object} user - user object from api
 * @param {object} setUser - user setter
 * @param {boolean} isLogged - test if the user is logged
 * @param {boolean} setIsLogged - isLogged setter
 *  @param {number} isActive - tab selection
 *  @param {number} setIsActive - isActive setter
 */

export default function CompteAmateurPage({
  setIsLogged,
  isLogged,
  isActive,
  setIsActive,
  user,
  setUser,
}) {
  const auth = useUser();
  const navigate = useNavigate();

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  useEffect(async () => {
    // await timeout(5000);
    while (auth.data === false) {
      await timeout(10);
    }
    if (auth.data) return;
    {
      navigate("../../view/LogIn.js");
    }
  }, []);

  return <div>{auth.data ? <Social /> : navigate("../../view/LogIn.js")}</div>;
}
