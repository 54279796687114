import React, {useEffect, useState} from 'react'
import soc1 from "../../webapp/temp/soc_1.jpg";
import del from "../../webapp/img/icons/ico_user_delete_2.svg";
import acc from "../../webapp/img/icons/ico_user_delete_2.svg";
import send from "../../webapp/img/icons/ico_chatbox_send.svg";
import {URLDayl} from "../../services/constantes";
import axios from "axios";
import {enumTypeModalNetwork} from "../CompteAmateurPage/ModalNetwork";
import {useUser} from "../../contexts/userService";
import {Button, colors, Typography} from "@mui/material";
import {toast} from "react-toastify";
import {friendshipStatus} from "../CompteAmateurPage/remodal/ProfileTab";

export default function ({user, friendId, typeFriendModal, refreshKey, setRefreshKey}) {

    const [friend, setFriend] = useState()
    const auth = useUser()
    const [mutual, setMutual] = useState(0)

    useEffect(() => {
        axios.get(URLDayl + `users/${friendId}`).then((res) => {
            setFriend(res.data)
        })
        axios.get(URLDayl + "friends/friendInCommon", {params: {userId: auth.data.id, friendId: friendId}}).then((res) => {
            setMutual(res.data)
        })
        if (friendId)
            axios
                .get(URLDayl + `friends/listOfFollowers?userId=${friendId}&requested=true`)
                .then((friendList) => {

                    const friendshipExist = friendList.data.find(item => item.friendId === auth.data.id)

                    console.log(friendshipExist)



                    if (friendshipExist === undefined) {

                        setFriendship(friendshipStatus.follow)
                        return;
                    }



                    switch (friendshipExist.status) {
                        case "ACCEPTED":
                            setFriendship(friendshipStatus.following)

                            break;
                        case "REQUESTED":
                            setFriendship(friendshipStatus.requested)

                            break
                        default:
                            setFriendship(friendshipStatus.follow)

                            break
                    }

                });
    }, [friendId, refreshKey])

    const removeFriend = () => {
        switch (typeFriendModal){
            case enumTypeModalNetwork.Following: {
                axios.get(URLDayl + `friends/delete/${user.id}/${friendId}`).then((res) => {
                    setRefreshKey(refreshKey+1)
                })
            }
            case enumTypeModalNetwork.Requests: {
                axios.get(URLDayl + `friends/delete/${friendId}/${user.id}`).then((res) => {
                    setRefreshKey(refreshKey+1)
                })
            }
        }

    }

    const [frienship, setFriendship] = useState(0)

    const followUser = () => {
        axios.get(URLDayl + `friends/add/${auth.data.id}/${friendId}`).then((res) => {
            toast.success("Demande d'ajoute envoyée", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setFriendship(friendshipStatus.requested)
        })
    }

    const acceptFriend = () => {
        axios.put(URLDayl + `friends/accept/${friendId}`, null, {
            headers: {
                'Authorization': `Bearer ${auth.data.jwt}`
            }}).then((res) => {
            setRefreshKey(refreshKey+1)
        })
    }

    return (
        friend ? <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", flex:1,}}>

            <div style={{display: "flex", flexDirection: "row", gap: 20, alignItems: "center"}} >
                <div style={{backgroundSize: "cover",borderRadius:50,backgroundImage: `url(${URLDayl + `images/userIconImage/${friend.id}`})`, height: 50, width: 50, marginLeft: 20}}>

                </div>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "space-evenly"}}>
                <Typography >{friend.firstName} {friend.lastName}</Typography>
                <Typography style={{color: "grey"}}>{mutual} membres en commun</Typography>
                </div>
            </div>


            {/*<div className="userFacts">*/}
            {/*    <a style={{backgroundImage: `url(${URLDayl + `images/userIconImage/${friend.id}`})`}}></a>*/}
            {/*    <a className="userName">{friend.firstName + " " + friend.lastName}</a>*/}
            {/*    <p className="userPlace">{friend.city} {friend.country}</p>*/}
            {/*    <p className="userCorel">17 membres en commun</p>*/}
            {/*</div>*/}

            <div style={{display: "flex", flexDirection: "row", gap: 15}}>
                {auth.data.id === user.id && typeFriendModal !== enumTypeModalNetwork.Followers &&<Button
                    variant="outlined"
                    color={"primary"}
                    style={{  border: 'solid', height: '40px' }}
                    onClick={() => {removeFriend()}}
                >
                    Supprimer
                </Button>}
                {auth.data.id === user.id && typeFriendModal === enumTypeModalNetwork.Requests &&<Button
                    variant="outlined"
                    color={"primary"}
                    style={{  border: 'solid', height: '40px' }}
                    onClick={() => {acceptFriend()}}
                >
                    Accepter
                </Button>}
                {auth.data.id !== user.id && <Button
                    variant="outlined"
                    color={"primary"}
                    style={{  border: 'solid', height: '40px' }}
                    onClick={() => {followUser()}}
                >
                    {frienship === friendshipStatus.loading ? "Loading" : frienship === friendshipStatus.follow ? "Follow" : frienship === friendshipStatus.following ? "Following" : "Requested"}
                </Button>}
            </div>

            {/*<div className="userSubBtn">*/}
            {/*    {typeFriendModal !== enumTypeModalNetwork.Followers && <a className="acceptFriend" onClick={() => {*/}
            {/*        removeFriend()*/}
            {/*    }}*/}
            {/*        >*/}
            {/*        <img src={del}/>Retirer*/}
            {/*    </a>*/}
            {/*    }*/}
            {/*    {typeFriendModal === enumTypeModalNetwork.Requests &&*/}
            {/*        <a className="acceptFriend" onClick={() => {*/}
            {/*            acceptFriend()*/}
            {/*        }}*/}
            {/*           >*/}
            {/*            <img src={acc}/>Accepter*/}
            {/*        </a>*/}
            {/*    }*/}

            {/*</div>*/}
        </div> : <></>
    )
}