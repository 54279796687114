import React, { useState, useRef, useEffect } from "react";
import i18n from "../../i18n";
import globe from "../../webapp/img/icons/globe.svg";
import enFlag from "../../";

import '../../webapp/css/output.css'

export const changeLanguage = (selectedLang) => {
  i18n.changeLanguage(selectedLang);
  localStorage.setItem("userSelectedLanguage", selectedLang);
};

const LanguageSelector = () => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const buttonRef = useRef(null);

  const useOutsideClick = (ref, callback) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          !buttonRef.current.contains(event.target)
        ) {
          callback();
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, callback]);
  };

  const dropdownRef = useRef(null);
  useOutsideClick(dropdownRef, () => setIsDropdownVisible(false));

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  return (
    <div
      style={{ display: "flex", position: "relative", alignItems: "center" }}
    >
      <button
        ref={buttonRef}
        onClick={toggleDropdown}
        className="focus:outline-none h-8 w-8 text-black"
      >
        <img src={globe} alt="Globe icon" />
      </button>

      {isDropdownVisible && (
        <div
          style={{
            position: "absolute",
            top: "2.5rem",
            zIndex: 20,
            borderRadius: "0.5rem",
            background:"white",
            border:"2px solid black",
            padding:"0.5rem"
          }}
          className="rounded-lg bg-dark-300 absolute top-10 z-20 w-20"
          ref={dropdownRef}
        >
          {["fr", "en"].map((lang) => (
            <div
              key={lang}
              className="py-2 text-black hover:bg-dark-500 w-full cursor-pointer z-20 rounded-lg flex flex-row items-center gap-2"
              onClick={() => {
                changeLanguage(lang);
                toggleDropdown();
              }}
            >
              <div className="flex w-1/2">{lang.toUpperCase()}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
