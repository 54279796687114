import React, { useEffect, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import axios from "axios";
import { URLDayl } from "../../services/constantes";
import { toast } from "react-toastify";
import { useCompteExpert } from "../../contexts/CompteExpertService";

export const ContenuWikipedia = ({ user, artist }) => {
  const [count, setCount] = useState(0);
  const [value, setValue] = useState(artist ? artist.wikipedia_summary_en : "");
  const [image, setImage] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [artistDetail, setArtistDetail] = useState();
  const compteExpert = useCompteExpert();

  useEffect(() => {
    axios.get(URLDayl + `artist/${artist.id}`).then((res) => {
      setArtistDetail(res.data);
    });
  }, [artist]);

  const [newImages, setNewImages] = useState([]);

  const changeHandler = (e) => {
    setImage(e.target.files);
  };

  useEffect(() => {
    setValue(
      artistDetail
        ? compteExpert.language === "ENGLISH"
          ? artistDetail.wikipedia_summary_en
          : compteExpert.language === "FRENCH"
          ? artistDetail.wikipedia_summary_fr
          : artistDetail.wikipedia_summary_es
        : ""
    );
  }, [artistDetail, artist]);

  const updateContenuWikipedia = () => {
    axios
      .put(URLDayl + `artist/contenuWikipedia/update`, {
        artistId: artistDetail.id,
        contenu: value,
        language: compteExpert.language,
      })
      .then((res) => {
        setRefreshKey(refreshKey + 1);
        toast.success("Updated wikipedia content", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        toast.error("Could not update wikipedia content", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  useEffect(() => {
    if (!artist)
      toast.error("Please select an artist", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
  }, [artistDetail, artist]);

  return (
    <>
      <h3 className="mt-c" style={{fontFamily:"Raleway"}}>Contenu wikipedia</h3>
      <div style={{ minWidth: "70%" }}>
        <div style={{ width: "60%", margin: "auto" }}>
          <TextareaAutosize
            style={{ paddingBottom: 10, backgroundColor: "transparent",border: "2px solid black" ,fontFamily:"Raleway",lineHeight:"25px"}}
            minRows={5}
            placeholder={artistDetail ? artistDetail.id : ""}
            value={value}
            onChange={(e) => {
              setCount(e.target.value.length);
              setValue(e.target.value);
            }}
          />
          {/* <div style={{ width: "4%" }} className="charCount">
            {count}/255
          </div> */}
        </div>
      </div>

      {/*<input type="file" name="file" onChange={changeHandler} multiple={true}/>*/}

      {/*<div style={{display: "flex", flexDirection: 'row', gap : 20, justifyContent: 'center'}}>*/}
      {/*{image && [...image].map((file)=>(*/}
      {/*    <img style={{width: `${100/image.length}%`, borderRadius: 20, gap: 20, maxWidth: '30%'}} src={URL.createObjectURL(file)} alt={artistDetail && artistDetail.artist_name_tot} />*/}
      {/*))}*/}
      {/*</div>*/}
      <div style={{width:"100%",display:"flex",justifyContent:"center"}}>
        <input
          type="submit"
          className="saveDraftBtn mt-a"
          value="Poster"
          onClick={() => {
            // console.log("CLICK")
            updateContenuWikipedia();
          }}
        />
      </div>
    </>
  );
};
