import React, { useState, useEffect } from "react";
import axios from "axios";
import sha1 from "sha1";
import { SALT, URLDayl } from "../services/constantes";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import vis from "../webapp/img/icons/password_display_1.svg";
import unvis from "../webapp/img/icons/password_hide_1.svg";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useUser } from "../contexts/userService";

export default function LogIn({ user, setUser, setIsLogged }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [randImg, setRandImg] = useState([]);
  const [boolVisibility, setBoolVisibility] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const auth = useUser();

  const navigate = useNavigate();

  //Connexion au compte via l'api//
  const onSubmit = (data, event) => {
    event.preventDefault();
    let log = false;

    if (forgotPassword) {
      // Handle forgotten password
      axios
        .post(
          URLDayl + "users/forgottenPassword",
          {
            email: data.trueEmail,
            language: "FR",
          },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          toast.success("Email de récupération envoyé! Vérifiez vos spams !", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setForgotPassword(false);
        })
        .catch((e) => {
          console.log(e);
          toast.warning("Erreur lors de l'envoi de l'email!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } else {
      // Handle login
      axios
        .post(
          URLDayl + "users/login",
          {
            email: data.trueEmail,
            password: sha1(data.truePassword + SALT),
          },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        )
        .then(async (response) => {
          await auth.setData(response.data);

          setUser({ ...user, ...response.data });
          if (response.data) {
            setIsLogged(true);
            log = true;
            localStorage.setItem("id", response.data.id);
            localStorage.setItem("jwt", response.data.jwt);

            navigate(`../view/CompteAmateurPage.js`, { state: response.data });
          }

          toast.success("Vous êtes connecté!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .catch((e) => {
          console.log(e);
          toast.warning("Identifiants ou mot de passe incorrect!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };

  useEffect(() => {
    axios
      .get(URLDayl + "paintingEntity/randomPaintings30", {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setRandImg(response.data);
      });
  }, []);

  function handleVisibility() {
    setBoolVisibility(!boolVisibility);
  }

  return (
    <div className="LogIn">
      <div className="accountFront formCompact">
        <div className="splitLeft darkBgGradient-2 center-tex">
          <h1>Mon compte</h1>
          <form id={"loginInput"} onSubmit={handleSubmit(onSubmit)}>
            <input
              {...register("trueEmail")}
              type="email"
              className="email"
              size="35"
              placeholder="Email"
              required
            />
            {!forgotPassword && (
              <>
                <img
                  className="img-left"
                  src={boolVisibility === true ? unvis : vis}
                  alt="Fermer"
                  onClick={handleVisibility}
                  style={{
                    width: "20px",
                    height: "20px",
                    position: "absolute",
                    zIndex: "99999",
                    top: "72px",
                    right: "10px",
                  }}
                />
                <input
                  {...register("truePassword")}
                  type={boolVisibility ? "text" : "password"}
                  className="password"
                  placeholder="Mot de passe"
                  minLength="8"
                  required
                />
              </>
            )}
            <input
              type="submit"
              className="submit mt-a mb-a"
              value={forgotPassword ? "Récupérer" : "Connexion"}
            />
          </form>
          {!forgotPassword ? (
            <div
              className="cursor-pointer text-base underline mt-4 text-blue-500 hover:text-blue-400"
              onClick={() => setForgotPassword(true)}
            >
              J'ai oublié mon mot de passe
            </div>
          ) : (
            <div
              className="text-base mt-4 text-white cursor-pointer"
              onClick={() => setForgotPassword(false)}
            >
              Retour
            </div>
          )}
          {!forgotPassword && (
            <>
              <p className="mt-b">Je n'ai pas de compte</p>
              <Link to="../view/SignIn.js" className="addAccount">
                Inscription rapide
              </Link>
            </>
          )}
        </div>
        <div
          className="splitRight"
          style={{
            backgroundImage: `url(${URLDayl}images/paintingPic/${
              randImg.length > 0 && randImg[0].id
            })`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover",
          }}
        >
          <div>
            <p className="itemArtist">
              {randImg.length > 0 && randImg[0].artisteName}
            </p>
            <p className="itemName">
              {randImg.length > 0 && randImg[0].name} (
              {randImg.length > 0 && randImg[0].year})
            </p>
            <p className="itemYear"></p>
          </div>
        </div>
      </div>
    </div>
  );
}
