import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import { Link } from "react-router-dom";
import { URLDayl, URLDaylImage } from "../../services/constantes";
import "react-multi-carousel/lib/styles.css";
import Fav from "../general/Fav";
import Box from "@mui/material/Box";
import { Slider } from "@mui/material";
import { withStyles } from "@mui/styles";
import HSLSliders from "../MatchingColor/HSLSliders";
import { useTranslation } from "react-i18next";
import { SearchOutlined } from "@mui/icons-material";
import SmallGrid from "../general/ImageGrid/SmallGrid";
import { colorToRgba } from "@react-spring/shared";
import ColorPaletteItem from "../MatchingColor/ColorPaletteItem";
import "../../webapp/css/output.css";
import ImageGrid, { enumTypeGrid } from "../general/ImageGrid/ImageGrid";
import { use } from "i18next";

/**
 * This component display a color Picker and allow you to find paitings with the selected color
 * @param {object} user - user object from api
 * @param {object} setUser - user setter
 *  @param {boolean} isLogged - test if the user is logged
 * @emits - users/${user.id} : update user informations
 * @emits - paintingSearch/findByColor : get list of paintings by selected color


 */

const styles = {
  root: {
    borderRadius: 20,
    padding: 10,
    // boxShadow: "rgba(0, 0, 0, 0.36) 0px 3px 6px, rgba(0, 0, 0, 0.33) 0px 3px 6px"
    paddingRight: 0,
    paddingLeft: 0,
    marginBottom: 10,
    // width: "100%",
    // backgroundImage: 'linear-gradient( to right, rgb(255, 0, 0), rgb(255, 255, 0), rgb(0, 255, 0), rgb(0, 255, 255), rgb(0, 0, 255), rgb(255, 0, 255), rgb(255, 0, 0) )',
  },
  rail: {
    color: "transparent",
    backgroundColor: "transparent",
  },
  track: {
    color: "transparent",
    backgroundColor: "transparent",
  },
  thumb: {
    backgroundColor: "transparent",
    border: "3px solid white",
    borderRadius: "50%",
    width: 30,
    height: 30,
  },
};

export function calculateHSL(hsv) {
  // determine the lightness in the range [0,100]
  var l = ((2 - hsv.s / 100) * hsv.v) / 2;

  // store the HSL components
  const hsl = {
    h: hsv.h,
    s: (hsv.s * hsv.v) / (l < 50 ? l * 2 : 200 - l * 2),
    l: l,
  };

  // correct a division-by-zero error
  if (isNaN(hsl.s)) hsl.s = 0;
  if (isNaN(hsl.l)) hsl.l = 0;
  return hsl;
}

const hsb2hsl = (hue, saturation, brightness) => {
  saturation /= 100;
  brightness /= 100;
  let ll = (2 - saturation) * brightness;
  let ss = saturation * brightness;
  ss /= ll <= 1 ? (ll !== 0 ? ll : 1) : 2 - ll !== 0 ? 2 - ll : 1;
  ll /= 2;
  return [hue, Math.round(ss * 100), Math.round(ll * 100)];
};

export const linearGradientCompute = (hsb) => {
  const [h0, s0, l0] = hsb2hsl(hsb.h, hsb.s, 0);
  const [h1, s1, l1] = hsb2hsl(hsb.h, hsb.s, 100);
  const g0 = `hsl(${h0}, ${s0}%, ${l0}%)`;
  const g1 = `hsl(${h1}, ${s1}%, ${l1}%)`;
  // const gradient = (
  //     <linearGradient id="Brightness" x1="0" x2="0" y1="0" y2="1">
  //         <stop offset="0%" stopColor={g0} />
  //         <stop offset="100%" stopColor={g1} />
  //     </linearGradient>
  // );

  return `linear-gradient( to right, ${g0}, ${g1})`;
};

export const linearGradientCompute2 = (hsb) => {
  const [h0, s0, l0] = hsb2hsl(hsb.h, 0, hsb.v);
  const [h1, s1, l1] = hsb2hsl(hsb.h, 100, hsb.v);
  const g0 = `hsl(${h0}, ${s0}%, ${l0}%)`;
  const g1 = `hsl(${h1}, ${s1}%, ${l1}%)`;
  // const gradient = (
  //     <linearGradient id="Brightness" x1="0" x2="0" y1="0" y2="1">
  //         <stop offset="0%" stopColor={g0} />
  //         <stop offset="100%" stopColor={g1} />
  //     </linearGradient>
  // );

  return `linear-gradient( to right, ${g0}, ${g1})`;
};

export function calculateHSV(newHsl) {
  var h = newHsl.h;
  var s = newHsl.s / 100;
  var l = newHsl.l / 100;

  var v = l + s * Math.min(l, 1 - l);

  var hsv = {
    h: h,
    s: v === 0 ? 0 : 2 * (1 - l / v) * 100,
    v: v * 100,
  };

  // correct a division-by-zero error
  if (isNaN(hsv.s)) hsv.s = 0;
  return hsv;
}

export const HueSlider = withStyles(styles)(Slider);

export default function NewColorPicker({ user, setUser, isLogged }) {
  const [paintingByColor, setPaintingByColor] = useState([]);
  const [refreshKey] = useState(0);
  const [maxNumPainting, setMaxNumPainting] = useState(21);

  const [color, setColor] = useColor(
    "hex",
    "#" + Math.floor(Math.random() * 16777215).toString(16)
  );
  const [hsl, setHSL] = useState(calculateHSL(color.hsv));

  useEffect(() => {
    if (user)
      axios
        .get(URLDayl + `users/${user.id}`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setUser(response.data);
        });
  }, [refreshKey]);

  const handleChange = (color) => {
    const hsl = calculateHSL(color.hsv);
    setHSL(hsl);
    setColor(color);
  };

  useEffect(() => {
    setMaxNumPainting(24);
  }, [color]);

  useEffect(() => {
    axios
      .post(
        URLDayl + `paintingSearch/findByColorOptimised`,
        {
          colorCode: color.hex,
          sortOrder: "ASC",
          maxNumPainting: maxNumPainting,
          minNumPainting: 1,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setPaintingByColor(response.data);

        const boolArray = response.data.map((el) => {
          return { ...el, bool: false };
        });

        setPaintingByColor(boolArray);
      });
  }, [color, maxNumPainting]);

  const { t } = useTranslation();

  return (
    <div className="w-full">
      <div className="text-3xl  p-6 pb-0  text-black font-raleway m-5 font-bold">
        A VOUS DE JOUER{" "}
      </div>
      <div className="text-2xl px-6  py-3  text-black font-raleway m-5 ">
        CHOISISSEZ UNE COULEUR ET DECOUVREZ LES PEINTURES CORRESPONDANTES
      </div>
      <div className="bg-black flex overflow-hidden items-center pl-10 justify-between">
        <div className="flex flex-col justify-center items-center align-middle  " style={{paddingTop:"30px"}}>
          <div>
            <Box>
              <ColorPicker
                width={320}
                height={320}
                color={color}
                onChange={handleChange}
                hideHSV
                hideHEX
                hideRGB
              />
              <div>
                <HSLSliders
                  color={color}
                  setColor={setColor}
                  hsl={hsl}
                  setHSL={setHSL}
                />
              </div>
            </Box>

            <div
              style={{ backgroundColor: color.hex }}
              className=" flex border rounded px-8 py-6 w-fit m-auto mt-5 text-white text-sm"
            >
              {color.hex}
            </div>
          </div>
        </div>
        <div className="w-3/4">
          <ImageGrid
            user={user}
            isLogged={isLogged}
            paintings={paintingByColor}
            showTools={true}
            selectionMode={false}
            maxNumPainting={maxNumPainting}
            setMaxNumPainting={setMaxNumPainting}
            totalMaxNum={100}
            fixed={true}
            typeGrid={enumTypeGrid.ArtistPaintingsGrid}
            customW={54}
            header={false}
          />
        </div>
      </div>

      {/* <!-- carousel - fin --> */}

      {/* <!-- SECTION CHOISISSEZ VOTRE THÉMATIQUE COULEUR - fin --> */}
    </div>
  );
}
