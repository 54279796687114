import {URLDayl} from "../../services/constantes";
import ListModal from "./ListModal";
import {Link} from "react-router-dom";
import React, {useEffect} from "react";
import {useUser} from "../../contexts/userService";
import ListModal2 from "./ListModal2";

export default function PaintingSquareComponent ({paint, typeMatching }) {

    const auth = useUser()

    return (

            <div className="accountArtists">
        <div className="sliderOne owl-loaded " >
            <div
                className="item"
                style={{
                    backgroundImage: `url(${URLDayl}images/paintingPic/${paint.id})`,
                }}
            >
                    <ListModal2
                        user={auth.data}
                        isLogged={auth.data !== undefined}
                        listPaint={paint}
                        tooltip={false}
                        styleButton={{color:"white",zIndex:"3",position:"absolute",left:"10px",top:"210px"}}
                    />
                <div className="bt-wrapper" style={{top:"-0px"}}>
                    {/* <div className="img-wrapper"> */}

                    <div className="titre">
                        <h3
                            style={{
                                fontStyle: "oblique",
                                fontWeight: "600",
                                fontSize: "14px",
                                color: "white",
                                marginBottom: "15px",
                            }}
                        >
                            {paint.artist_name_tot}
                        </h3>
                        {paint.aw_title_origin}
                        <div className="date">{paint.aw_year}</div>
                    </div>
                    <div className="container-action">
                        <Link
                            className="bt-action voir"
                            to={typeMatching ? `../../view/MatchingPage.js/${paint.id}` : `../../view/OpusPage.js/${paint.id}`}
                            state={typeMatching ? typeMatching : paint.id}
                        >
                            voir
                        </Link>
                        {!typeMatching && <Link
                            className="bt-action match"
                            to={`../../view/MatchingPage.js/${paint.id}`}
                            state={paint.id}
                        >
                            match
                        </Link>}
                    </div>
                </div>
            </div>
        </div>
        </div>


    )
}