// FindByStylePaintings.js
import React, { useEffect, useState, useRef } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import ImageGrid from "../component/general/ImageGrid/ImageGrid";
import { useUser } from "../contexts/userService";
import { URLDayl } from "../services/constantes";
import axios from "axios";
import ArtistCarousel from "../component/ArtistDiscoverPage/ArtistCarousel";
import MovementContext from "../component/MovementPage/MovementContext";
import SingleSlideCarousel from "../component/general/SingleSlideCarousel";

export default function FindByStylePaintings({ style, selectedMovement }) {
  const auth = useUser();
  const [paintings, setPaintings] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [year, setYear] = useState({ startYear: "", endYear: "" });
  const [maxNumPainting, setMaxNumPainting] = useState(48);
  const [sort, setSort] = useState("");
  const [movementDetail, setMovementDetail] = useState();
  const [mouvementArtist, setMouvementArtists] = useState([]);
  const [topPaintings, setTopPaintings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const movementDetailRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const res = await axios.get(URLDayl + `movement?movement=${style}`);
        setMovementDetail(res.data);

        const topPaintingsRes = await axios.get(
          URLDayl + "top_paintings_movement/paintingsByMovement",
          {
            params: { movementId: res.data.id },
          }
        );
        setTopPaintings(
          topPaintingsRes.data.map((el) => ({
            id: el.painting.id,
            artistId: el.painting.artist.id,
            artisteName: el.painting.artist.artist_name_tot,
            year: el.painting.aw_year,
            name: el.painting.aw_title_origin,
          }))
        );

        const paintingsRes = await axios.get(
          URLDayl +
            `paintingSearch/findPaintingsByStyle?style=${style}&numberOfPaintings=${maxNumPainting}&sort=${sort}`
        );
        setPaintings(paintingsRes.data);

        const artistsRes = await axios.post(
          URLDayl + `artistSearch/freeSearch`,
          { mouvement: style }
        );
        setMouvementArtists(artistsRes.data);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [style, maxNumPainting, sort]);

  useEffect(() => {
    if (selectedMovement && movementDetailRef.current && !isLoading) {
      const offsetTop = movementDetailRef.current.getBoundingClientRect().top + window.scrollY - 50;
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
  }, [selectedMovement, isLoading]);
  
  



  return (
    <div  ref={movementDetailRef} style={{ marginTop: 20, paddingBottom: 100 }}>
      {!isLoading && (
        <>
          <div  style={{ display: "flex" }}>
            <div className="text-3xl  p-6  text-black m-5 font-raleway  font-bold">{`MOUVEMENT - ${style}`}</div>
          </div>

          {topPaintings && topPaintings.length > 0 && (
            <div className={"darkBgGradient"} style={{}} color={"white"}>
              <div
                className="bg-black"
                style={{
                  width: "100%",
                }}
              >
                <SingleSlideCarousel
                  paintings={topPaintings}
                  artistName={false}
                />
              </div>
            </div>
          )}
          {movementDetail.quote && (
            <div
              className="bg-gray-300 w-full flex flex-col  text-xl"
              style={{ fontFamily: "cinzel", padding: "40px 100px" }}
            >
              <div className="italic">
                {movementDetail.quote}
                <br />
              </div>
              {movementDetail.quote_author}
            </div>
          )}

          {movementDetail && (
            <div className="mt-10">
              <MovementContext movementDetail={movementDetail} />{" "}
            </div>
          )}

          <div className="text-3xl  p-6  text-black m-5 font-raleway  font-bold">{`PEINTURES TAGGEES -  ${style}`}</div>

          <ImageGrid
            user={auth.data}
            isLogged={auth.data !== undefined}
            paintings={paintings}
            showTools={true}
            selectionMode={false}
            orderDate={sort}
            setOrderDate={setSort}
            totalMaxNum={100}
            maxNumPainting={maxNumPainting}
            setMaxNumPainting={setMaxNumPainting}
            refreshKey={refreshKey}
            setRefreshKey={setRefreshKey}
          />

          <div className="text-3xl  p-6  text-black m-5 font-raleway  font-bold">{`ARTISTES ASSOCIES AU STYLE -  ${style}`}</div>

          <div className={""} color={"white"}>
            <ArtistCarousel color={"white"} artists={mouvementArtist} />
          </div>
        </>
      )}
    </div>
  );
}
