import React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PaintingToMatch from "../component/MatchingResult/PaintingToMatch";
import ResultMatch from "../component/MatchingResult/ResultMatch";
import { useParams } from "react-router";

/**
 * This component display a Matching Page with a painting from our db
 * @param {object} user - user object from api
 * @param {object} setUser - user setter
 * @param {boolean} isLogged - test if the user is logged
 *  @param {number} paintId - paint id
 */

export default function MatchingPage({ user, setUser, isLogged }) {
  const location = useLocation();

  const { paintId } = useParams();



  const [matchingType, setMatchingType] = useState(() => {
    const initialState = location.state ? 
      { value: location.state, text: location.state } : 
      { value: "STYLE", text: "Style" };
    return initialState;
  });

  useEffect(() => {
    console.log("Current location state:", location.state);
    console.log("Current matchingType state:", matchingType);
  }, [location.state, matchingType]); 

  return (
    <div className="MatchingPage">
      <PaintingToMatch
        user={user}
        matchingType={matchingType}
        setMatchingType={setMatchingType}
        paintId={paintId}
        setUser={setUser}
        isLogged={isLogged}
      />
      <ResultMatch
        user={user}
        matchingType={matchingType}
        setMatchingType={setMatchingType}
        paintId={paintId}
        isLogged={isLogged}
      />
    </div>
  );
}
