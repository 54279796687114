import React from "react";
import Slider from "react-slick";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Link } from "react-router-dom";
import { URLDaylImage } from "../../services/constantes";
import { useUser } from "../../contexts/userService";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function CustomNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ChevronRightIcon
      className={`${className} carousel-painting-arrows-custom-next`}
      style={{
        ...style,
        color: "black",
        fontSize: 60,
        position: "absolute",
        top: "50%",
        right: "-30px",
        transform: "translateY(-50%)",
      }}
      onClick={onClick}
    />
  );
}

function CustomPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ChevronLeftIcon
      className={`${className} carousel-painting-arrows-custom-prev`}
      style={{
        ...style,
        color: "black",
        fontSize: 60,
        position: "absolute",
        top: "50%",
        left: "-30px",
        transform: "translateY(-50%)",
      }}
      onClick={onClick}
    />
  );
}

export default function ArtistCarousel({ artists, title, color }) {
  const auth = useUser();
  const slidesToShow = Math.max(artists.length, 1);

  var settings = {
    infinite: true,
    speed: 500,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 750,
        settings: {
          slidesToShow: Math.min(slidesToShow, 1),
          slidesToScroll: Math.min(slidesToShow, 1),
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: Math.min(slidesToShow, 2),
          slidesToScroll: Math.min(slidesToShow, 2),
        },
      },
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: Math.min(slidesToShow, 3),
          slidesToScroll: Math.min(slidesToShow, 3),
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: Math.min(slidesToShow, 4),
          slidesToScroll: Math.min(slidesToShow, 4),
        },
      },
      {
        breakpoint: 1750,
        settings: {
          slidesToShow: Math.min(slidesToShow, 5),
          slidesToScroll: Math.min(slidesToShow, 5),
        },
      },
      {
        breakpoint: 9999,
        settings: {
          slidesToShow: Math.min(slidesToShow, 6),
          slidesToScroll: Math.min(slidesToShow, 6),
        },
      },
    ],
  };

  if (artists.length === 0) {
    return (
      <div className="text-center py-10">
        <h2 className="text-2xl font-bold text-gray-700">No Results Found</h2>
        <p className="text-gray-500 mt-2">Try adjusting your search criteria</p>
      </div>
    );
  }

  return (
    <div>
      <h1
        style={{
          marginLeft: 50,
          fontWeight: " ",
          color: color,
          textTransform: "uppercase",
          fontFamily: "cinzel",
          width: "85%",
          margin: "auto",
        }}
      >
        {title}
      </h1>
      <div className="MostMatched">
        <section className="center-col  cont-sty-1">
          <div className="carousel-container">
            <Slider {...settings}>
              {artists.map((matchPaint, index) => (
                <div className="sliderOne" key={index}>
                  <div
                    className="item"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Link
                      to={`../../view/ArtistDetailPage.js/${matchPaint.id}`}
                      state={matchPaint.id}
                    >
                      <div className="img-wrapper">
                        <div className="bt-wrapper">
                          <div className="titre">
                            {matchPaint.name}
                            <div className="date">{matchPaint.year}</div>
                          </div>
                        </div>
                        <img
                          className="owl-lazy"
                          src={
                            URLDaylImage + `images/artistPic/${matchPaint.id}`
                          }
                          alt="Titre de l'oeuvre"
                        />
                      </div>
                    </Link>
                    <div className="name" style={{ textAlign: "start" }}>
                      <Link
                        to={`../../view/ArtistDetailPage.js/${matchPaint.id}`}
                        style={{ color: "black" }}
                        state={matchPaint.id}
                      >
                        {matchPaint.artist_name_tot}
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </section>
      </div>
    </div>
  );
}
