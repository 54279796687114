import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { URLDayl } from "../../services/constantes";
import close from "../../webapp/img/icons/ico_close_2.svg";
import { useUser } from "../../contexts/userService";
import { toast } from "react-toastify";

/**
 * This component is used to save a matching
 * @todo en attente de changement api
 * @param {object} user - user object from api
 *  @param {boolean} isLogged - test if the user is logged
 * @param {object} matchPaint - paint object to add in match
 * @emits - matching/save : save a matching
 */

export default function SaveMatch({
  user,
  isLogged,
  matchPaint,
  matchingType,
}) {
  const [refreshKey, setRefreshKey] = useState(0);
  const [boolMatch, setBoolMatch] = useState(false);

  const auth = useUser();
  //Test Match saved//
  useEffect(() => {
    if (auth.data && auth.data.matchingLiked) {
      for (let i = 0; i < auth.data.matchingLiked.length; i++) {
        if (auth.data.matchingLiked[i] === matchPaint.id) {
          setBoolMatch(true);
        }
      }
    }
  }, [matchPaint]);

  async function handleClick(e) {
    console.log(matchingType.value);

    e.preventDefault();

    if (auth.data) {
      if (boolMatch === false)
        await axios
          .post(URLDayl + "users/updateMatchingPainting", {
            userId: auth.data.id,
            favoriteId: matchPaint.id,
            operation: "add",
            typeMatching: matchingType.value,
          })
          .then((response) => {
            setBoolMatch(true);
            axios.get(URLDayl + `users/${auth.data.id}`).then((response2) => {
              auth.setData(response2.data);
            });
          });
      else
        await axios
          .post(URLDayl + "users/updateMatchingPainting", {
            userId: auth.data.id,
            favoriteId: matchPaint.id,
            operation: "remove",
          })
          .then((response) => {
            setBoolMatch(false);
            axios.get(URLDayl + `users/${auth.data.id}`).then((response2) => {
              auth.setData(response2.data);
            });
          });
    } else {
      toast.error("Please log in.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  return (
    <div className="SaveMatch">
      <div
        className="mt-5 border border-solid w-fit p-3 text-lg"
        onClick={handleClick}
      >
        {boolMatch ? "Matching enregistré" : "Enregistrer ce matching"}
      </div>
    </div>
  );
}
