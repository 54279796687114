import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import { URLDayl } from "../services/constantes";
import Social from "../component/CompteAmateurPage/remodal/social";
import { useUser } from "../contexts/userService";

/**
 * This component display the User Page
 * @param {object} user - user object from api
 * @param {object} setUser - user setter
 * @param {boolean} isLogged - test if the user is logged
 * @param {boolean} setIsLogged - isLogged setter
 *  @param {number} isActive - tab selection
 *  @param {number} setIsActive - isActive setter
 */

export default function ProfileAmateur({
  setIsLogged,
  isLogged,
  isActive,
  setIsActive,
  user,
  setUser,
}) {
  const { friendId } = useParams();

  const [friend, setFriend] = useState();
  const auth = useUser();

  useEffect(() => {
    axios.get(URLDayl + `users/${friendId}`).then((res) => {
      setFriend(res.data);
    });
  }, [friendId]);

  const navigate = useNavigate();

  useEffect(() => {
    if (auth.data && auth.data.id.toString() === friendId.toString())
      navigate("../../view/compteAmateurPage.js");
  }, [friendId, auth.data, navigate]);

  return <div>{friend && <Social friend={friend} />}</div>;
}
