import React, { useEffect, useState } from "react";
import axios from "axios";
import { URLDayl } from "../../services/constantes";
import close from "../../webapp/img/icons/ico_close_2.svg";
import { toast } from "react-toastify";

import { ArtistStatementCompteExpert } from "./ArtistStatement/ArtistStatementCompteExpert";
import { useCompteExpert } from "../../contexts/CompteExpertService";
import { ElementsBiographies } from "./ElementsBiographies";
import { ContenuWikipedia } from "./ContenuWikipedia";
import { AvisExperts } from "./AvisExperts/AvisExperts";
import AutoCompleteStyles from "../PaintingSearch/AutoCompleteStyles";

/**
 * This component is a modal used to manage your network (add/delete friends,...)
 * @param {object} user - user object from api

 *  @param {boolean} boolModal - test if the network modal is open
 *  @param {boolean} setBoolModal - boolModal setter
 */

export default function ModalEditArtist({
  user,
  boolModal,
  setBoolModal,
  artistId,
}) {
  //  init Variables global
  const [isActive, setIsActive] = useState(1);

  const [artist, setArtist] = useState();
  const [refreshKey, setRefreshKey] = useState(0);
  const compteExpert = useCompteExpert();
  const [stylesOptions, setStylesOptions] = useState([]);

  const [contenuEdition, setContenuEdition] = useState("0");

  useEffect(() => {
    compteExpert.setLanguage("ENGLISH");
    compteExpert.setArtistPageMode(true);
  }, []);

  useEffect(() => {
    axios.get(URLDayl + `paintingFront/listStyles`).then((res) => {
      setStylesOptions(
        res.data.map((el) => {
          return {
            name: el.name,
          };
        })
      );
    });
  }, [artistId]);

  useEffect(() => {
    let overlay = document.createElement("div");

    Object.assign(overlay.style, {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      zIndex: 1,
    });
    document.body.appendChild(overlay);
    document.body.style.overflow = "hidden";
    return () => {
      document.body.removeChild(overlay);
      document.body.style.overflow = "unset";
    };
  }, []);

  const onSelectStyle = (e, value) => {
    setArtist((previous) => ({
      ...previous,
      artist_style: value,
    }));
  };

  const change = () => {
    if (artist) {
      switch (contenuEdition) {
        case "artisteStatement":
          return <ArtistStatementCompteExpert user={user} artist={artist} />;

        case "elementBiographie":
          return (
            <>
              <ElementsBiographies user={user} artist={artist} />
            </>
          );
        case "contenuWikipedia":
          return <ContenuWikipedia user={user} artist={artist} />;
        case "avisExperts":
          return <AvisExperts user={user} artist={artist} />;
        default:
          return <></>;
      }
    }
  };

  useEffect(() => {
    axios.get(URLDayl + `artist/${artistId}`).then((res) => {
      setArtist(res.data);
    });
  }, [artistId, refreshKey]);

  useEffect(() => {
    setContenuEdition("");
  }, [compteExpert.language]);

  const updateArtist = async () => {
    await axios
      .post(URLDayl + "artist/update", {
        id: artistId,
        artist_origin: artist.artist_origin,
        artist_birthyear: artist.artist_birthyear,
        artist_deathyear: artist.artist_deathyear,
        artist_name_tot: artist.artist_name_tot,
        artist_sex: artist.artist_sex,
        artist_birthplace_x: artist.artist_birthplace_x,
        artist_deathplace_x: artist.artist_deathplace_x,
        artist_auteur_fun_fact: artist.artist_auteur_fun_fact,
        artist_nationality: artist.artist_nationality,
        hideArtist: artist.hideArtist,
        artist_style: artist.artist_style,
        artist_copyright: artist.artist_copyright,
        artist_dayl_mark: artist.artist_dayl_mark,
      })
      .then(() => {
        toast.success("Artist modified", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // addKeywords()
        // removeKeywords()
      })
      .catch(() => {
        toast.error("Could not change artist", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <div
      className="smart-modal-content-l scrollAuto"
      style={{
        transform: "translate(-50%,-50%)",
        display: "inline-flex",
        flexWrap: "wrap",
        zIndex: 5,
        alignContent: "flex-start",
      }}
    >
      <h3>Modifier l'artiste #{artist && artist.id}</h3>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 20,
          position: "absolute",
          right: 80,
          top: 10,
        }}
      >
        <div
          onClick={() => {
            compteExpert.setLanguage("");
            compteExpert.setLanguage("ENGLISH");
          }}
        >
          <h4>EN</h4>
        </div>
        <div
          onClick={() => {
            compteExpert.setLanguage("");
            compteExpert.setLanguage("FRENCH");
          }}
        >
          <h4>FR</h4>
        </div>
        <div
          onClick={() => {
            compteExpert.setLanguage("");
            compteExpert.setLanguage("SPANISH");
          }}
        >
          <h4>ES</h4>
        </div>
      </div>
      <a
        className="smart-modal-close"
        onClick={(e) => setBoolModal(!boolModal)}
      >
        <img className="img-left" src={close} alt="Fermer" />
      </a>
      <div className="layoutArtiste">
        {/*                     {artist && <div style={{justifyContent: "center", display: "flex", width: '100%',}}>
                        <img style={{width: 'auto', height: 350, overflow: 'hidden', borderRadius: 10,}}
                             src={URLDaylImage + `images/artistPic/${artistId}`} alt={artistId}/>
                    </div>
                    } */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "",
            gap: 200,
            paddingTop: 20,
          }}
        >
          <div>
            <input
              className="input-field"
              type="text"
              value={artist ? artist.artist_name_tot : ""}
              onChange={(value) => {
                setArtist((previous) => ({
                  ...previous,
                  artist_name_tot: value.target.value,
                }));
              }}
              placeholder="Titre"
              required
            />
          </div>

          <div style={{}}>
            <input
              className="input-field"
              type="text"
              value={artist ? artist.artist_origin : ""}
              onChange={(value) => {
                setArtist((previous) => ({
                  ...previous,
                  artist_origin: value.target.value,
                }));
              }}
              placeholder="Pays d'origine"
              required
            />
          </div>
        </div>

        <div>
          <input
            className="input-field"
            type="number"
            value={artist ? artist.artist_dayl_mark : ""}
            onChange={(value) => {
              setArtist((previous) => ({
                ...previous,
                artist_dayl_mark: value.target.value,
              }));
            }}
            placeholder="dayl mark"
            required
          />
        </div>
        <h4>année de naissance / année du décès</h4>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "",
            gap: 200,
          }}
        >
          <div>
            <input
              className="input-field"
              type="number"
              value={artist ? artist.artist_birthyear : ""}
              onChange={(value) => {
                setArtist((previous) => ({
                  ...previous,
                  artist_birthyear: value.target.value,
                }));
              }}
              placeholder="année de naissance"
              required
            />
          </div>

          <div style={{}}>
            <input
              className="input-field"
              type="number"
              value={artist ? artist.artist_deathyear : ""}
              onChange={(value) => {
                setArtist((previous) => ({
                  ...previous,
                  artist_deathyear: value.target.value,
                }));
              }}
              placeholder="année du décès"
              required
            />
          </div>
        </div>

        <h4>lieu de naissance / lieu du décès</h4>
        <div
          style={{
            display: "flex",
            flexDirection: "row",

            gap: 200,
          }}
        >
          <div>
            <input
              className="input-field"
              type="text"
              value={artist ? artist.artist_birthplace_x : ""}
              onChange={(value) => {
                setArtist((previous) => ({
                  ...previous,
                  artist_birthplace_x: value.target.value,
                }));
              }}
              placeholder="lieu de naissance"
              required
            />
          </div>

          <div style={{ width: "100%" }}>
            <input
              className="input-field"
              type="text"
              value={artist ? artist.artist_deathplace_x : ""}
              onChange={(value) => {
                setArtist((previous) => ({
                  ...previous,
                  artist_deathplace_x: value.target.value,
                }));
              }}
              placeholder="lieu du décès"
              required
            />
            {/*<span className="required">*</span>*/}
          </div>
        </div>
        <h4>Nationalité</h4>
        <div
          style={{
            display: "flex",
            flexDirection: "row",

            gap: 30,
            paddingTop: 0,
          }}
        >
          <div style={{ width: "100%" }}>
            <input
              className="input-field"
              type="text"
              value={artist ? artist.artist_nationality : ""}
              onChange={(value) => {
                setArtist((previous) => ({
                  ...previous,
                  artist_nationality: value.target.value,
                }));
              }}
              placeholder="Nationalité"
              required
            />
            {/*<span className="required">*</span>*/}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 200,
            justifyContent: "center",
          }}
        >
          <div>
            <h4>Sexe</h4>
            <select
              className="searchFilterDarkBg mb-a"
              id="mostMatchedByMonth"
              name="mostMatchedByMonth"
              value={artist ? artist.artist_sex : ""}
              onChange={(e) => {
                setArtist((previous) => ({
                  ...previous,
                  artist_sex: e.target.value,
                }));
              }}
            >
              <option value="female">Femme</option>
              <option value="male" selected>
                Homme
              </option>
              <option value="other" selected>
                Autre
              </option>
            </select>
          </div>
          <div>
            <h4>Hide artist</h4>
            <select
              className="searchFilterDarkBg mb-a"
              id="mostMatchedByMonth"
              name="mostMatchedByMonth"
              value={artist ? artist.hideArtist : ""}
              onChange={(e) => {
                setArtist((previous) => ({
                  ...previous,
                  hideArtist: e.target.value,
                }));
              }}
            >
              <option value="true">True</option>
              <option value="false" selected>
                False
              </option>
            </select>
          </div>
          <div>
            <h4>Copyright</h4>
            <input
              className="input-field"
              type="text"
              value={artist ? artist.artist_copyright : ""}
              onChange={(value) => {
                setArtist((previous) => ({
                  ...previous,
                  artist_copyright: value.target.value,
                }));
              }}
              placeholder="Copyright"
              required
            />
          </div>
        </div>
        <h4>Styles</h4>
        <div
          style={{
            backgroundColor: "transparent",
            width: "50%",
            margin: "auto",
          }}
        >
          {artist && (
            <AutoCompleteStyles
              options={stylesOptions}
              onChange={() => {}}
              onSelect={onSelectStyle}
              placeholder={"Styles"}
              defaultValues={artist.artist_style
                .filter((el) => el !== "")
                .map((el) => {
                  return { name: el };
                })}
            />
          )}
        </div>

        {artist && compteExpert.language && compteExpert.language !== "" && (
          <>
            <div
              className="editMain redac center-tex pb-b"
              style={{ display: "block" }}
            >
              <h2 className="mt-a">Contenu rédactionnel sur l'artiste</h2>

              <select
                name="année"
                className="mt-a mb-b"
                onChange={(event) => setContenuEdition(event.target.value)}
                value={contenuEdition}
              >
                <option value="0">
                  Choisissez un contenu rédactionnel à modifier
                </option>
                <option value="avisExperts">Avis d'expert</option>
                <option value="elementBiographie">Element biographie</option>
                <option value="2">Analyse chromatique</option>
                <option value="artisteStatement">Artiste statement</option>
                <option value="5">Actualités</option>
                <option value="contenuWikipedia">Contenu wikipedia</option>
              </select>

              {change()}
            </div>
          </>
        )}

        <div style={{ display: "flex", justifyContent: "center" }}>
          <input
            type="submit"
            className="saveDraftBtn mt-a"
            value="Enregistrer"
            onClick={async () => {
              await updateArtist();
              setBoolModal(false);
            }}
          />
        </div>
      </div>
    </div>
  );
}
