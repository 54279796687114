import React, { useEffect, useState } from "react";
import axios from "axios";
import { URLDayl } from "../../services/constantes";
import ModalEditMouvement from "./ModalEditMouvementContext";
import AutoCompleteCustom from "../PaintingSearch/AutoCompleteCustom";
import numberOneSvg from "../../webapp/img/icons/number-one.svg";
import numberTwoSvg from "../../webapp/img/icons/number-two.svg";
import { useUser } from "../../contexts/userService";
import { useCompteExpert } from "../../contexts/CompteExpertService";
import ArtworkModalTag from "./ArtworkModalTag/ArtworkModalTag";
import ChoosePaintings from "./ChoosePaintings";
import CompteExpertTopPaintings from "./CompteExpertTopPaintings";

export default function MouvementCompteExpert({}) {
  const [mouvementDetails, setMouvementDetails] = useState();
  const [editModal, setEditModal] = useState(false);
  const [selectedStyle, setSelectedStyle] = useState("");
  const [selectedStyleId, setSelectedStyleId] = useState(0);
  const [type, setType] = useState("");
  const [listStyles, setListStyles] = useState([]);
  const compteExpert = useCompteExpert();

  useEffect(async () => {
    await axios
      .get(URLDayl + `movement?movement=${selectedStyle}`)
      .then((res) => {
        setMouvementDetails(res.data);
      });
  }, [editModal, selectedStyle]);

  const onSelectStyle = (e, item) => {
    console.log(item);

    setSelectedStyle(item.name);
    setSelectedStyleId(item.id);
  };

  useEffect(async () => {
    await axios.get(URLDayl + "movement/getMovements").then((res) => {
      setListStyles(res.data.sort((a, b) => -b.name.localeCompare(a.name)));
    });
  }, []);

  return (
    <div style={{ width: "100%" }}>
      {/*<div className={"darkBgGradient"} style={{marginTop: 20}}>*/}
      {/*            <AutoCompleteCustom onChange={() => {*/}
      {/*            }} placeholder={"Style"} onSelect={onSelectStyle} options={listStyles}/>*/}
      {/*</div>*/}

      <div className="editMain center-tex">
        <h1>Modifier une page Mouvement</h1>
        <p className="how">
          Entrez le nom du mouvement dont vous souhaitez modifier les
          informations puis sélectionnez la langue d'édition afin de faire
          apparaître le menu d'édition.
        </p>
      </div>

      <div className="editMain mt-b pb-a">
        <div className="inline">
          <form className="subOpt" style={{ marginTop: "30px" }}>
            <img src={numberOneSvg} />
            <p>Style</p>

            {/*<input type="text" id="artistName" name="artistName"/>*/}
            <AutoCompleteCustom
              onChange={() => {}}
              placeholder={"Style"}
              onSelect={onSelectStyle}
              options={listStyles}
            />
          </form>

          <div className="subOpt" style={{ marginTop: "30px" }}>
            <img src={numberTwoSvg} />
            <p>Langue d'édition</p>
            <ul className="langEdit">
              <li
                className={
                  compteExpert.language === "FRENCH" ? "fr active" : "fr"
                }
                onClick={() => compteExpert.setLanguage("FRENCH")}
              >
                Français
              </li>
              <li
                className={
                  compteExpert.language === "ENGLISH" ? "en active" : "en"
                }
                onClick={() => compteExpert.setLanguage("ENGLISH")}
              >
                Anglais
              </li>
              <li
                className={
                  compteExpert.language === "SPANISH" ? "es active" : "es"
                }
                onClick={() => compteExpert.setLanguage("SPANISH")}
              >
                Espagnol
              </li>
            </ul>
          </div>
        </div>
      </div>

      {mouvementDetails && compteExpert.language !== "" && (
        <div style={{ width: "70%", margin: "auto" }}>
          <h1 style={{ fontFamily: "Raleway, sans-serif" }}>Presentation</h1>
          <div className="text-wrapper">
            <span
              style={{
                fontFamily: "Raleway, sans-serif",
                whiteSpace: "pre-line",
                display: "block",
              }}
            >
              {compteExpert.language === "FRENCH"
                ? mouvementDetails.presentation_fr
                : mouvementDetails.presentation}
            </span>
          </div>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <input
              type="reset"
              className="saveDraftBtn mt-a"
              value="Modifer"
              style={{}}
              onClick={() => {
                setEditModal(!editModal);
                setType("PRES");
              }}
            />
          </div>
          <h1 style={{ fontFamily: "Raleway, sans-serif" }}>
            Characteristiques
          </h1>
          <div className="text-wrapper">
            <span
              style={{
                fontFamily: "Raleway, sans-serif",
                whiteSpace: "pre-line",
                display: "block",
              }}
            >
              {compteExpert.language === "FRENCH"
                ? mouvementDetails.characteristiques_fr
                : mouvementDetails.characteristiques}
            </span>
          </div>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <input
              type="reset"
              className="saveDraftBtn mt-a"
              value="Modifer"
              style={{}}
              onClick={() => {
                setType("CHAR");
                setEditModal(!editModal);
              }}
            />
          </div>
        </div>
      )}
      {selectedStyle && <div style={{}}>
        <h1 style={{width: "70%", margin: "auto"}}>Top peintures</h1>
        <CompteExpertTopPaintings
            style={selectedStyle}
            styleId={selectedStyleId}
        />
      </div>}
      {editModal && mouvementDetails && (
        <ModalEditMouvement
          setModal={setEditModal}
          placeholder={
            type === "PRES"
              ? compteExpert.language === "FRENCH"
                ? mouvementDetails.presentation_fr
                : mouvementDetails.presentation
              : compteExpert.language === "FRENCH"
              ? mouvementDetails.characteristiques_fr
              : mouvementDetails.characteristiques
          }
          id={mouvementDetails.id}
          type={type}
        />
      )}
    </div>
  );
}
