import {Skeleton, Stack} from "@mui/material";
import React from "react";



export default function SkeletonPaintings ({num = 4}) {



    return (
        <Stack spacing={1}>
            <div style={{display: "flex", flexDirection: "row", gap: 20}}>
                {Array.from(Array(num).keys()).map((number) => (
                    <Skeleton
                        variant="rectangular"
                        width={250}
                        height={250}
                        style={{borderRadius: 10}}
                    />
                ))}

            </div>
        </Stack>
    )
}