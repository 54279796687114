import { URLDayl } from "../../services/constantes";
import ListModal from "./ListModal";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import { useUser } from "../../contexts/userService";
import { useTranslation } from "react-i18next";

export default function PaintingComponentMinimalist({ paint }) {
  const { t } = useTranslation();
  return (
    <div className="accountArtists">
      <div className="sliderOne owl-loaded ">
        <div
          className="item"
          style={{
            backgroundImage: `url(${URLDayl}images/paintingPic/mini/${paint.id})`,
          }}
        >
          <div className="bt-wrapper">
            {/* <div className="img-wrapper"> */}

            <div className="">
              <h3
                style={{
                  fontStyle: "oblique",
                  fontWeight: "600",
                  fontSize: "14px",
                  color: "var(--color-8)",
                  marginBottom: "6px",
                }}
              >
                <Link
                  className={"onHoverTextUnderline onHoverOpacity white"}
                  to={`../../view/ArtistDetailPage.js/${paint.artistId}`}
                  state={paint.artistId}
                >
                  {paint.artisteName}
                </Link>
              </h3>
              <Link to={`../../view/OpusPage.js/${paint.id}`} state={paint.id}>
                <p style={{ marginBottom: "0px", fontSize: "16px" }}>
                  {paint.name}
                </p>{" "}
              </Link>
              <div className="date" style={{ fontSize: "14px" }}>
                {paint.year}
              </div>
            </div>
            <div className="container-action">
              <Link
                className="bt-action voir"
                to={`../../view/OpusPage.js/${paint.id}`}
                state={paint.id}
              >
                {t('See')}
              </Link>
              <Link
                className="bt-action match"
                to={`../../view/MatchingPage.js/${paint.id}`}
              >
                match
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
