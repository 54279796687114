import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { URLDayl } from "../../services/constantes"
import { ToastContainer, toast } from "react-toastify"
import {useUser} from "../../contexts/userService";


/**
 * This component is used to like a painting
 * @param {object} user - user object from api
 *  @param {boolean} isLogged - test if the user is logged
 * @param {object} favPaint - paint object to like
 * @emits - users/updateFavoritePainting : like/unlike painting

 */


export default function Fav({ user, isLogged, favPaint }) {

  const [boolFav, setBoolFav] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const auth = useUser()


  /**
   * test si les peintures sont deja like
   */
  useEffect(() => {
    if (auth.data) {

      auth.data.paintingLiked.length > 0 && auth.data.paintingLiked.map((paintUser) => (

          favPaint.id === paintUser && (setBoolFav(true))
      ))
    }




  }, []);





  function handleClick() {
    console.log(isLogged)
    if (isLogged === true) {

      //on supprime
      if (boolFav === true) {
        setBoolFav(false)
        FavRemove()
      }
      //sinon on ajoute
      else {
        setBoolFav(true)
        FavAdd()

      }


    }
    else {
      toast.warn("Connectez vous pour ajouter aux favoris!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }


  }

  /**
   * Add paint to likes
   */
  function FavAdd() {


    //add a favorite painting
    axios.post(URLDayl + "users/updateFavoritePainting", { userId: user.id, favoriteId: favPaint.id, operation: "add" },
      { headers: { "Access-Control-Allow-Origin": "*", "Content-Type": "application/json", "USER_PAINT": 0 } }
    )
      .then((response) => {


        toast.success("Ajoutée aux favoris!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

      });
  }
  function FavRemove() {

    //delete a favorite painting
    axios.post(URLDayl + "users/updateFavoritePainting", { userId: user.id, favoriteId: favPaint.id, operation: "remove" },
      { headers: { "Access-Control-Allow-Origin": "*", "Content-Type": "application/json", "USER_PAINT": 0 } }
    )
      .then((response) => {

        toast.success("Supprimée des favoris!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });


      });

  }

  return (


    <div className="Fav">

      <button className={boolFav ? "favo yes" : "favo not"} type="button" onClick={handleClick}></button>

    </div>
  );

}





















export function FavAdd(user, paintId) {


  //add a favorite painting
  axios.post(URLDayl + "users/updateFavoritePainting", { userId: user.id, favoriteId: paintId, operation: "add" },
    { headers: { "Access-Control-Allow-Origin": "*", "Content-Type": "application/json", "USER_PAINT": 0 } }
  )
    .then((response) => {


      console.log(response.data + " add")

    });
}
export function FavRemove(user, paintId) {

  //delete a favorite painting
  axios.post(URLDayl + "users/updateFavoritePainting", { userId: user.id, favoriteId: paintId, operation: "remove" },
    { headers: { "Access-Control-Allow-Origin": "*", "Content-Type": "application/json", "USER_PAINT": 0 } }
  )
    .then((response) => {


      console.log(response.data + " remove")

    });

}

export function TestFav(user, paintId, index, paintings) {



  if (user) {


    //Inversion bool pour la classe du boutton
    if (paintings[index].bool === false) {
      paintings[index].bool = true;
      FavAdd(user, paintId)
    }

    else {
      paintings[index].bool = false;
      FavRemove(user, paintId);
    }




  }


}

export function getFav(user, paintings) {


  paintings.length > 0 && paintings.map((paint, index) => (
    user.paintingLiked.length > 0 && user.paintingLiked.map((paintUser) => (

      paint.id === paintUser && (paintings[index].bool = true)
    ))
  ))

}
export function getFavM(user, paintings) {


  paintings.length > 0 && paintings.map((paint, index) => (
    user.paintingLiked.length > 0 && user.paintingLiked.map((paintUser) => (
      paint.painting.id === paintUser && (paintings[index].bool = true)
    ))
  ))

}