import React from "react";
import FooterMenu from "./FooterMenu";
import Logo from "../../webapp/img/logos/daylfonce.svg";
import { Link } from "react-router-dom";
import news from "../../webapp/img/icons/newsletter.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import ContactForm from "../ContactFrom/ContactForm";
const listItems = [
  {
    title: "DÉCOUVRIR",
    items: [
      {
        item: "Oeuvres",
        link: "/view/PaintingSearch.js",
      },
      {
        item: "Artistes",
        link: "/view/ArtistDiscoverPage.js",
      },
      {
        item: "Mouvements",
        link: "/view/discoverMovements.js",
      },
      {
        item: "Matchez vos œuvres",
        link: "/view/discoverMovements.js",
      },
    ],
  },
  {
    title: "MON ESPACE",
    items: [
      {
        item: "Mon Profile",
        link: "/view/CompteAmateurPage.js",
        state: "Profile",
      },
      {
        item: "Mon fil",
        link: "/view/CompteAmateurPage.js",
        state: "Feed",
      },
      {
        item: "Mes artistes",
        link: "/view/CompteAmateurPage.js",
        state: "Favorites",
      },
    ],
  },
  {
    title: "AUTRES",
    items: [
      {
        item: "Partenaires",
        link: "",
      },
      {
        item: "Mentions légales",
        link: "",
      },
      {
        item: "Données personnelles",
        link: "",
      },
    ],
  },
];
export default function CustomFooter() {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <div style={{ zIndex: 99 }}>
      <div
        style={{ height: "30vh", marginTop: "50px" }}
        className="text-black border-t-2 border-black bg-zinc-50"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Link to="/">
                <img style={{ overflow: "auto" }} src={Logo} alt="logo" />
              </Link>
            </div>

            <div className="appstore-container">
              <a
                href="https://www.facebook.com/profile.php?id=61560812530272"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon style={{ fontSize: 40, color: "black" }} />
              </a>
              <a
                href="https://www.instagram.com/discoverartyoulove/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon style={{ fontSize: 40, color: "black" }} />
              </a>
              <a
                href="https://x.com/DiscoverArtYouL"
                target="_blank"
                rel="noopener noreferrer"
              >
                <XIcon style={{ fontSize: 40, color: "black" }} />
              </a>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 10,
            }}
          >
            {listItems &&
              listItems.map((el, index) => {
                return <FooterMenu key={index} listItems={listItems[index]} />;
              })}
          </div>
          <div className="newsletter-container" style={{ display: "flex" }}>
            <img className="bg-black p-1 rounded-lg" src={news} alt="news" />
            <div
              className=" cursor-pointer text-lg font-raleway rounded-full bg-black hover:bg-blue-900 text-white px-3 py-1"
              onClick={() => setIsOpen(true)}
            >
              Contactez nous !{" "}
            </div>
          </div>
          <ContactForm isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
      </div>
    </div>
  );
}
