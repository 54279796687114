import { Button, Popover, Stack, Switch } from "@mui/material";
import {
  Cancel as CancelIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import axios from "axios";
import CustomTextField from "./CustomTextField";
import AutoCompleteCustom from "../../PaintingSearch/AutoCompleteCustom";
import { URLDayl } from "../../../services/constantes";
import CustomSelectField from "./CustomSelectField";
import HdIcon from "@mui/icons-material/Hd";
import ColorPaletteItem from "../../MatchingColor/ColorPaletteItem";
import { useColor } from "react-color-palette";

const styles = {
  header: {
    justifyContent: "space-between",
    paddingTop: 30,
    paddingBottom: 20,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
    paddingLeft: 50,
    paddingRight: 50,
  },
  browse: {
    // paddingLeft: 50,
    fontWeight: "bold",
    fontSize: 20,

    textDecoration: "underline",
    textUnderlineOffset: 3,
  },
  padding: {
    padding: 50,
    paddingTop: 0,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    gap: 20,
  },
  title: {
    padding: 10,
    fontWeight: "bold",
    fontSize: 16,
  },
};

function SearchBarPaintingsPopover({
  anchorEl,
  handleClose,
  searchDTO,
  setSearchDTO,
  onSubmit,
  onClearRef,
}) {
  const [listGenres, setListGenres] = useState([]);

  const [listNationality, setListNationality] = useState([]);
  const [artworkTextOptions, setArtworkTextOptions] = useState([]);
  const [artistTextOptions, setArtistTextOptions] = useState([]);
  const [color, setColor] = useState();
  const [hd, setHD] = useState(searchDTO.hd);

  useEffect(() => {
    getListGenres();
    getListNationality();
  }, []);

  const getData = async (
    apiPath,
    setData,
    nameKey,
    idKey,
    type = "",
    searchedtext = ""
  ) => {
    if (searchedtext) {
      const response = await axios.get(URLDayl + apiPath);
      const data = response.data.map((item) => ({
        name: item[nameKey],
        type,
        id: item[idKey],
      }));
      setData(data);
    }
  };

  const onSelectNationality = (e, item) => {
    if (item !== null)
      setSearchDTO((previous) => ({ ...previous, nationality: item.name }));
    else {
      setSearchDTO((previous) => ({ ...previous, nationality: null }));
    }
  };

  const onSelectGenre = (e, item) => {
    if (item !== null)
      setSearchDTO((previous) => ({ ...previous, genre: item.name }));
    else {
      setSearchDTO((previous) => ({ ...previous, genre: null }));
    }
  };

  const getListGenres = async () => {
    const { data } = await axios.get(`${URLDayl}paintingFront/listGenres`);
    setListGenres(
      data
        .map(({ name }) => ({ name }))
        .sort((a, b) => -b.name.localeCompare(a.name))
    );
  };

  // const getListStyles = async () => {
  //     const {data} = await axios.get(`${URLDayl}movement/getMovements`);
  //     setListStyles(data.map(({name}) => ({name})).sort((a, b) => -b.name.localeCompare(a.name)));
  // };

  const onInputChangeArtwork = async (e, searchedtext) => {
    setArtworkTextOptions([]);
    getData(
      `paintingSearch/findPaintingByPrefix?prefix=${searchedtext}`,
      setArtworkTextOptions,
      "name",
      "id",
      "Painting",
      searchedtext
    );
  };

  const getListNationality = async () => {
    const { data } = await axios.get(
      `${URLDayl}paintingFront/listOriginsAndNationalities`
    );
    setListNationality(data.sort().map((el) => ({ name: el })));
  };

  const handleSelect = (field) => (e) => {
    if (e !== null) {
      const selectedValue = e.target.value;
      setSearchDTO((previous) => ({
        ...previous,
        [field]: selectedValue || null,
      }));
    }
  };

  const onSelectStyle = (e, item) => {
    if (item != null)
      setSearchDTO((previous) => ({ ...previous, style: item.name }));
    else {
      setSearchDTO((previous) => ({ ...previous, style: null }));
    }
  };

  const onSelectArtwork = (e, item) => {
    if (item != null)
      setSearchDTO((previous) => ({ ...previous, aw_name: item.name }));
    else {
      setSearchDTO((previous) => ({ ...previous, aw_name: null }));
    }
  };

  const id = anchorEl ? "simple-popover" : undefined;

  return (
    <Popover
      id={id}
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      PaperProps={{ style: { backgroundColor: "rgba(255,255,255,1)" } }}
    >
      <div>
        <div style={styles.header}>
          <div style={styles.browse}>Browse paintings</div>
          {/* <div style={{
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 10
                }}>

                    <HdIcon/>

                    <Switch checked={hd} onChange={(e) => {setHD(!hd) ;                             setSearchDTO((previous) => ({
                        ...previous, hd: e.target.value === 'on',
                    }))}}>

                    </Switch>
                </div> */}
        </div>
        <div style={styles.padding}>
          <div>
            <div style={styles.title}>Period</div>
            <div style={styles.flexRow}>
              <CustomTextField
                defaultValue={searchDTO.periodStart}
                onChange={(e) => {
                  setSearchDTO((previous) => ({
                    ...previous,
                    periodStart: e.target.value,
                  }));
                }}
                type="number"
                placeholder="From"
              />
              <CustomTextField
                defaultValue={searchDTO.periodEnd}
                onChange={(e) => {
                  setSearchDTO((previous) => ({
                    ...previous,
                    periodEnd: e.target.value,
                  }));
                }}
                type="number"
                placeholder="to"
              />
            </div>
          </div>

          <div>
            <AutoCompleteCustom
              ref={onClearRef}
              defaultValue={
                searchDTO.aw_name ? { name: searchDTO.aw_name } : null
              }
              title="Artwork name"
              custom
              customWidth={410}
              onChange={onInputChangeArtwork}
              placeholder="Artwork name"
              onSelect={onSelectArtwork}
              options={artworkTextOptions}
            />

            {/*<AutoCompleteCustom defaultValue={searchDTO.style ? {name: searchDTO.style} : null} title="Style" custom customWidth={410} onChange={handleSelect('style')}*/}
            {/*                    placeholder="Style" onSelect={onSelectStyle} options={listStyles}/>*/}

            <AutoCompleteCustom
              ref={onClearRef}
              onChange={() => {}}
              defaultValue={searchDTO.genre ? { name: searchDTO.genre } : null}
              title="Genre"
              custom
              customWidth={410}
              placeholder="Genre"
              onSelect={onSelectGenre}
              options={listGenres}
            />

            <div style={styles.flexRow}>
              <AutoCompleteCustom
                ref={onClearRef}
                title="Nationality"
                defaultValue={
                  searchDTO.nationality ? { name: searchDTO.nationality } : null
                }
                customWidth={250}
                custom
                onChange={() => {}}
                placeholder="Nationality"
                onSelect={onSelectNationality}
                options={listNationality}
              />

              <CustomSelectField
                defaultValue={searchDTO.sexe}
                onChange={(e) => {
                  setSearchDTO((previous) => ({
                    ...previous,
                    sexe: e.target.value,
                  }));
                }}
                width={140}
                title={"Sexe"}
                placeholder={""}
              />
            </div>

            <Divider />

            <div style={styles.flexRow}>
              <ColorPaletteItem
                color={"green"}
                master_color={"VERT"}
                notHex
                setColor={setSearchDTO}
                selectedColor={color}
              />
              <ColorPaletteItem
                color={"brown"}
                master_color={"BRUN"}
                notHex
                setColor={setSearchDTO}
                selectedColor={color}
              />
              <ColorPaletteItem
                color={"orange"}
                master_color={"ORANGE"}
                notHex
                setColor={setSearchDTO}
                selectedColor={color}
              />
              <ColorPaletteItem
                color={"yellow"}
                master_color={"JAUNE"}
                notHex
                setColor={setSearchDTO}
                selectedColor={color}
              />
              <ColorPaletteItem
                color={"purple"}
                master_color={"ROSE et VIOLET"}
                notHex
                setColor={setSearchDTO}
                selectedColor={color}
              />
              <ColorPaletteItem
                color={"red"}
                master_color={"ROUGE"}
                notHex
                setColor={setSearchDTO}
                selectedColor={color}
              />
              <ColorPaletteItem
                color={"blue"}
                master_color={"BLEU"}
                notHex
                setColor={setSearchDTO}
                selectedColor={color}
              />
              <ColorPaletteItem
                color={"black"}
                master_color={"GRIS ET NOIR"}
                notHex
                setColor={setSearchDTO}
                selectedColor={color}
              />
              <ColorPaletteItem
                color={"white"}
                master_color={"BLANC et COULEURS PALES"}
                notHex
                setColor={setSearchDTO}
                selectedColor={color}
              />
            </div>

            <Divider />
            <Stack
              direction="row"
              spacing={5}
              mt={1}
              justifyContent="center"
              alignItems="center"
            >
              <Button
                variant="outlined"
                onClick={() => {
                  handleClose();
                }}
                startIcon={<CancelIcon />}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  onSubmit();

                  handleClose();
                }}
                variant="contained"
                endIcon={<SearchIcon />}
              >
                Search
              </Button>
            </Stack>
          </div>
        </div>
      </div>
    </Popover>
  );
}

export default SearchBarPaintingsPopover;
