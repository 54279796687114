export const stringSplitting = (limit, string) => {
    let chunks = [];
    let sentences = string.split(/(\. |\n\n\n)/);

    let currentChunk = '';
    let needPeriod = false;  

    for (let sentence of sentences) {
        if (sentence === '. ' || sentence === '\n\n\n') {
            needPeriod = (sentence === '. ');
            continue;
        }

        if (currentChunk.length + sentence.length + (needPeriod ? 2 : 0) > limit) {
            if (currentChunk.trim() !== '') {
                chunks.push(currentChunk.trim());
            }
            currentChunk = sentence + (needPeriod ? '. ' : '');
        } else {
            currentChunk += (needPeriod ? '. ' : '') + sentence;
        }
        needPeriod = false;  

        if (sentence.endsWith('\n\n\n')) {
            if (currentChunk.trim() !== '') {
                chunks.push(currentChunk.trim());
            }
            currentChunk = '';
        }
    }

    if (currentChunk.trim() !== '') {
        chunks.push(currentChunk.trim());
    }

    return chunks;
}



export function reformatDate(dateString) {
    const date = new Date(dateString);
    const now = new Date();
    const timeDiff = now.getTime() - date.getTime();
    const oneDayInMs = 24 * 60 * 60 * 1000;

    if (timeDiff < oneDayInMs) {
        const options = {
            hour: 'numeric',
            minute: 'numeric',
            hour12: false
        };
        return date.toLocaleString('en-US', options);
    } else if (timeDiff < 2 * oneDayInMs) {
        return '1 day ago';
    } else if (timeDiff < 3 * oneDayInMs) {
        return '2 days ago';
    } else if (timeDiff < 4 * oneDayInMs) {
        return '3 days ago';
    } else {
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour12: false
        };
        return date.toLocaleString('en-US', options);
    }
}



