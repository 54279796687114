import React, { useState, useEffect } from "react";
import OurDaylNews from "./OurDaylNews";
import { useTranslation } from "react-i18next";

// Enum-like object for tabs
const TABS = {
  NETWORK: "Network",
  PUBLIC: "Public",
  DAYL_NEWS: "Dayl news",
};

function FeedTab() {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(TABS.NETWORK);

  const handleMenuClick = (tabKey) => {
    setActiveTab(tabKey);
  };

  const menuItems = [
    // { tabKey: TABS.NETWORK, tabName: t("network"), icon: null },
    // { tabKey: TABS.PUBLIC, tabName: t("public"), icon: null },
    { tabKey: TABS.DAYL_NEWS, tabName: t("dayl_news"), icon: null },
  ];

  const renderTabContent = () => {
    switch (activeTab) {
      // case TABS.NETWORK:
      //   return <Network />;
      // case TABS.PUBLIC:
      //   return <Public />;
      case TABS.DAYL_NEWS:
        return <OurDaylNews />;
      default:
        return <OurDaylNews />;
    }
  };

  return (
    <div className="profileContainer shadowBox" style={{ minHeight: 900 }}>
      <p className="menuTitle">{t("whats_new")}</p>
      <div className="mainMenu">
        {menuItems.map((menuItem, index) => (
          <React.Fragment key={menuItem.tabKey}>
            <div
              className={`profileMenuItem ${
                activeTab === menuItem.tabKey ? "active" : ""
              }`}
              onClick={() => handleMenuClick(menuItem.tabKey)}
            >
              {menuItem.icon && <img src={menuItem.icon} alt="Logo" />}
              <p>{menuItem.tabName}</p>
            </div>
            {index !== menuItems.length - 1 && (
              <div className="menuSeparator" />
            )}
          </React.Fragment>
        ))}
      </div>
      <div className="profileTab">{renderTabContent()}</div>
    </div>
  );
}

export default FeedTab;
