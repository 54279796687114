import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { URLDayl } from "../../services/constantes";
import close from "../../webapp/img/icons/ico_close_2.svg";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import lockB from "../../webapp/img/icons/content_locked_1-2.svg";
import unlockB from "../../webapp/img/icons/content_unlocked_1-2.svg";
import { IconButton } from "@mui/material";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import { Tooltip } from "@material-ui/core";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import { useUser } from "../../contexts/userService";

/**
 * This component is used to add your paint to a list
 * @param {object} user - user object from api
 *  @param {boolean} isLogged - test if the user is logged
 * @param {object} listPaint - paint object to add in list
 * @emits - userLists/save : create new list
 * @emits - userLists/byUserId : get user lists
 */

export default function ListModal2({
  user,
  isLogged,
  listPaint,
  styleButton,
  tooltip = true,
  hide = false,
  onClose,
}) {
  const [boolList, setBoolList] = useState(hide);
  const [refreshKey, setRefreshKey] = useState(0);
  let tempTitle = "";
  const [listList, setListList] = useState([]);
  const [publicList, setPublicList] = useState(true);
  const [locked, setLocked] = useState(false);
  const handleClose = () => {
    // Perform any necessary cleanup or actions before closing the modal
    onClose(); // Call the onClose prop passed from ResultMatchingColor
  };

  const handleTitleChange = (e) => {
    tempTitle = e.target.value; // Update the temporary variable
  };
  //Open List Modal//
  function handleList(paintId) {
    if (isLogged === true) {
      if (boolList === true) setBoolList(false);
      else setBoolList(true);
    } else {
      toast.warning("Connectez vous pour ajouter à une liste!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }
  const auth = useUser();

  useEffect(() => {
    isLogged &&
      axios
        .get(URLDayl + "userLists/byUserId", {
          params: { userId: user.id },
          headers: { Authorization: `Bearer ${auth.data.jwt}` },
        })
        .then(async (res) => {
          const boolArray = res.data;
          testList(boolArray);
        });
  }, [boolList, refreshKey]);

  const testList = (tempListList) => {
    if (boolList) {
      let updatedLists = tempListList.map((list) => ({
        ...list,
        boolAdded: false,
      }));
      const promises = updatedLists.map((list, index) =>
        axios
          .get(URLDayl + "userLists/detailByListId", {
            params: { listId: list.id },
          })
          .then((response) => {
            const isAdded = response.data.listPaintings.some(
              (painting) => painting.id === listPaint.id
            );
            return { ...list, boolAdded: isAdded };
          })
      );
      Promise.all(promises).then((updatedListsWithBoolAdded) => {
        setListList(updatedListsWithBoolAdded);
      });
    }
  };

  const handleSubmit = (e) => {
    if (isLogged) {
      e.preventDefault();
      axios
        .post(
          URLDayl + "userLists/save",
          {
            listName: tempTitle,
            rank: 0,
            userId: user.id,
            id: 0,
            status: !locked ? "PUBLIC" : "PRIVATE",
            detail: "",
            imageUrl: "",
          },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          setRefreshKey((oldKey) => oldKey + 1);

          toast.success("Nouvelle liste ajoutée!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } else {
      toast.warn("Connectez vous pour ajouter aux favoris!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  useEffect(() => {
    document.body.style.overflow = boolList ? "hidden" : "auto";
  }, [boolList]);

  const [createNewList, setCreateNewList] = useState(false);

  const addRemovePaintToList = (list, paintId, index) => {
    let tempList = [...listList];
    if (isLogged) {
      if (list.boolAdded === false)
        axios
          .get(URLDayl + "userLists/addPaintingToList", {
            params: { listId: list.id, paintingId: paintId },
          })
          .then((response) => {
            tempList[index].boolAdded = true;
            tempList[index].countItems = tempList[index].countItems + 1;
            setListList(tempList);

            toast.success("Oeuvre ajoutée à la liste!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      else
        axios
          .get(URLDayl + "userLists/removePaintingFromList", {
            params: { listId: list.id, paintingId: paintId },
          })
          .then((response) => {
            tempList[index].boolAdded = false;
            tempList[index].countItems = tempList[index].countItems - 1;
            setListList(tempList);

            toast.success("Oeuvre retirée de la liste!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
    } else {
      toast.warning("Connectez vous pour ajouter à une liste!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <div className="ListModal2">
      {tooltip ? (
        <Tooltip title="Ajouter cette oeuvre à une ou plusieurs liste(s)">
          <IconButton onClick={() => handleList()}>
            <CreateNewFolderOutlinedIcon
              style={{ color: "white", zIndex: "3" }}
            />
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton onClick={() => handleList()}>
          <CreateNewFolderOutlinedIcon style={styleButton} />
        </IconButton>
      )}

      {boolList === true && (
        <div>
          <div
            className="smart-modal-overlay"
            style={{
              display: "block",
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.5)",
              zIndex: 1000,
            }}
          ></div>
          <div
            id="1777"
            className="smart-modal-content scrollAuto listModal"
            style={{
              display: "block",
              transform: "translate(-50%,-50%)",
              top: "50%",
              left: "50%",
              minWidth: "0",
              zIndex: 2000,
            }}
          >
            <h3>Ajouter à une liste</h3>
            <a className="smart-modal-close">
              <img
                className="img-left"
                onClick={() => handleList(listPaint.id)}
                src={close}
                alt="Trier"
                style={{ height: "20px" }}
              />
            </a>
            <div className="addToList">
              <div
                style={{
                  maxHeight: "40vh",
                  width: "400px",
                  overflowY: "auto",
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                {listList.length > 0 &&
                  listList.map((list, index) => {
                    return (
                      <div
                        key={index}
                        className="listDetails"
                        style={{ display: "flex" }}
                      >
                        <div
                          className="listElement"
                          onClick={() =>
                            addRemovePaintToList(list, listPaint.id, index)
                          }
                          style={{
                            left: "50px",
                            top: "3px",
                            fontSize: "18px",
                          }}
                        >
                          {list.listName}
                        </div>
                        <button
                          type="button"
                          onClick={() =>
                            addRemovePaintToList(list, listPaint.id, index)
                          }
                          className={
                            list.boolAdded ? "addedToThisList" : "addToThisList"
                          }
                        >
                          {list.boolAdded ? (
                            <CreateNewFolderIcon
                              style={{ color: "black", zIndex: "3" }}
                            />
                          ) : (
                            <CreateNewFolderOutlinedIcon
                              style={{ color: "black", zIndex: "3" }}
                            />
                          )}
                        </button>
                      </div>
                    );
                  })}
              </div>
              <div
                className="createNewList"
                style={{ display: "flex", marginTop: "20px" }}
              >
                <ControlPointOutlinedIcon
                  style={{ marginLeft: "18px", color: "black" }}
                />
                <button
                  className="listElement"
                  onClick={() => setCreateNewList(true)}
                  style={{
                    fontSize: "18px",
                    left: "22px",
                  }}
                >
                  Créer une nouvelle liste
                </button>
              </div>

              {createNewList && (
                <form className="newListForm" action="" onSubmit={handleSubmit}>
                  <div className="addListForm">
                    <input
                      onChange={handleTitleChange}
                      type="text"
                      placeholder="Le nom de votre liste"
                      maxLength="25"
                    />
                    {locked ? (
                      <LockOutlinedIcon
                        onClick={() => setLocked(!locked)}
                        style={{
                          top: "8px",
                          left: "235px",
                          position: "absolute",
                          cursor: "pointer",
                          color: "black",
                        }}
                      />
                    ) : (
                      <LockOpenOutlinedIcon
                        onClick={() => setLocked(!locked)}
                        style={{
                          top: "8px",
                          left: "235px",
                          position: "absolute",
                          cursor: "pointer",
                          color: "black",
                        }}
                      />
                    )}
                  </div>

                  <div className="ListButtons" style={{ display: "flex" }}>
                    <button className="listSubmit" type="submit">
                      Créer
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      )}

      {/* <!-- Modal "ajouter à une liste" - fin --> */}
    </div>
  );
}
