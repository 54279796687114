
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import React, { useState } from 'react';
import { AutoAwesome as AutoAwesomeIcon, MoreVert as MoreVertIcon } from '@mui/icons-material';
import { Menu, MenuItem, TextField } from '@mui/material';

const YourComponent = ({ sessionsChat }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editLabel, setEditLabel] = useState('');

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setActiveIndex(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setEditLabel(sessionsChat[activeIndex]?.label || '');
    handleClose();
  };

  const handleDelete = () => {
    // Perform delete action here
    handleClose();
  };

  const handleShare = () => {
    // Perform share action here
    handleClose();
  };

  const handleLabelChange = (event) => {
    setEditLabel(event.target.value);
  };

  const handleLabelUpdate = () => {
    // Perform label update action here
    handleClose();
  };

  return (
    <div className="sidebar-section">
      {sessionsChat?.map((elem, index) => (
        <div className={`elementsSides elementsSides-${activeIndex === index ? 'active' : ''}`} key={index}>
          <div onClick={(e) => {
            setActiveIndex(index);
          }}>
            <AutoAwesomeIcon style={{
              color: "#1f1fe7",
              fontSize: "18px",
              marginRight: "10px"
            }} />
            {activeIndex === index ? (
              <TextField
                value={editLabel}
                onChange={handleLabelChange}
                onBlur={handleLabelUpdate}
                autoFocus
              />
            ) : (
              <span>
                {elem?.label.length > 20 ? `${elem.label.substring(0, 20)}...` : elem.label}
              </span>
            )}
          </div>
          <div>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleEdit}>Modifier</MenuItem>
              <MenuItem onClick={handleDelete}>Supprimer</MenuItem>
              <MenuItem onClick={handleShare}>Partager</MenuItem>
            </Menu>
            <DragIndicatorIcon onClick={(event) => handleClick(event, index)} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default YourComponent;
