import React, { useEffect, useState } from "react";
import { MenuItem } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { toColor } from "react-color-palette";

export default function RectanglePaletteItem({
  color,
  master_color,
  setColor,
  notHex,
  selectedColor,
  isOpen,
  onOpen,
  setColorName,
  childColors,
}) {
  const [selectedChildColor, setSelectedChildColor] = useState(null);

  const containerWidth = isOpen ? 40 * childColors.length + 90 : 0;

  useEffect(() => {
    if (isOpen && childColors.length > 0 && !selectedColor) {
      const randomChildColorIndex = Math.floor(
        Math.random() * childColors.length
      );
      const randomChildColor = childColors[randomChildColorIndex];
      setColor(toColor("hex", randomChildColor.hex));
      setColorName(randomChildColor.color_name);
      setSelectedChildColor(randomChildColor.id || randomChildColor.hex);
    }
  }, [isOpen, childColors, setColor, setColorName, selectedColor]);

  return (
    <div className="flex p-2 items-center">
      <div
        onClick={onOpen}
        style={{
          backgroundColor: color,
          width: "40px",
          height: "150px",
          cursor: "pointer",
          border: "1px solid black",
        }}
      />
      <div
        className="flex overflow-hidden transition-[width] duration-1000 ease-in-out"
        style={{ width: `${containerWidth}px`, transition: "width 0.3s ease" }}
      >
        {childColors.map((el, index) => (
          <Tooltip
            title={el.color_name}
            arrow
            placement="top"
            key={el.id || index}
          >
            <MenuItem
              onClick={() => {
                notHex
                  ? setColor((previous) => ({
                      ...previous,
                      color: el.color_name,
                    }))
                  : setColor(toColor("hex", el.hex));
                setSelectedChildColor(el.id || el.hex);
                setColorName(el.color_name);
              }}
              style={{
                backgroundColor: el.hex,
                width:
                  selectedColor && selectedColor.hex === el.hex
                    ? "90px"
                    : "40px",
                height: "150px",
                border:
                  selectedColor && selectedColor.hex === el.hex
                    ? "2px solid black"
                    : "",
                marginLeft:
                  selectedColor && selectedColor.hex === el.hex ? "5px" : "",
                marginRight:
                  selectedColor && selectedColor.hex === el.hex ? "5px" : "",
              }}
            />
          </Tooltip>
        ))}
      </div>
    </div>
  );
}
