import newtempprofile from "../../../webapp/img/icons/newtempprofile.svg";
import newheart from "../../../webapp/img/icons/newheart.svg";
import newcomment from "../../../webapp/img/icons/newcomment.svg";
import newshare from "../../../webapp/img/icons/newshare.svg";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { URLDayl } from "../../../services/constantes";
import { reformatDate } from "../../../services/Utils";
import SocialNetworkPostHeader from "./SocialNetworkPostHeader";
import { Skeleton } from "@mui/material";
import SocialNetworkPostFooter from "./SocialNetworkPostFooter";
import SocialNetworkPostComment from "./SocialNetworkPostComment";
import PaintingSquareComponent from "../../general/PaintingSquareComponent";
import { useNavigate } from "react-router";
export const renderTextWithHashtags = (text, onClick) => {
  const words = text.split(" ");
  const styledWords = words.map((word, index) => {
    if (word.startsWith("#")) {
      const hashtag = word.substring(1);
      return (
        <span
          key={index}
          style={{ color: "blue", cursor: "pointer" }}
          onClick={() => onClick(hashtag)}
          className="hastag"
        >
          {word}{" "}
        </span>
      );
    }
    return (
      <span key={index} style={{ fontFamily: "raleway" }}>
        {word}{" "}
      </span>
    );
  });

  return styledWords;
};

export default function SocialNetworkPost({ id }) {
  const [post, setPost] = useState();
  const [comments, setComments] = useState([]);
  const [boolComments, setBoolComments] = useState(false);

  const [taggedPainting, setTaggedPainting] = useState();

  useEffect(() => {
    axios.get(URLDayl + `publication/byId?id=${id}`).then((res) => {
      setPost(res.data);
      if (res.data.taggedPainting)
        axios
          .get(URLDayl + `paintingEntity/${res.data.taggedPainting}`)
          .then((resTagged) => setTaggedPainting(resTagged.data));
    });
    axios
      .get(URLDayl + `publication/commentPubli/ByPublicationId?id=${id}`)
      .then((res) => {
        setComments(res.data);
      });
  }, [id]);

  const navigate = useNavigate();

  const onClickHashtag = (hashtag) => {
    navigate(`../../view/SearchByHashtag.js/${hashtag}`);
  };

  return post ? (
    <div className="post">
      <SocialNetworkPostHeader post={post} />

      {post.pathImage ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <img
            className="postPicture"
            alt="postPicture"
            src={
              URLDayl + `images/contentPublication/${post.id}/${post.pathImage}`
            }
          />
          <div className="caption">
            {renderTextWithHashtags(post.content, onClickHashtag)}
          </div>
        </div>
      ) : taggedPainting ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <PaintingSquareComponent
            paint={taggedPainting}
            typeMatching={post.matchingType}
          />
          <div className=" flex-col gap-3 font-raleway border-2 bg-white border-black p-3 rounded-lg text-base">
            {renderTextWithHashtags(post.content, onClickHashtag)}
          </div>
        </div>
      ) : (
        <div className=" flex-col gap-3 font-raleway border-2 bg-white border-black p-3 rounded-lg">
          {renderTextWithHashtags(post.content, onClickHashtag)}
        </div>
      )}

      <SocialNetworkPostFooter
        publication={post}
        setCommentsBool={setBoolComments}
      />
      {boolComments &&
        comments.map((el) => {
          return <SocialNetworkPostComment comment={el} post={post} />;
        })}
    </div>
  ) : (
    <Skeleton
      variant="rounded"
      width={"80%"}
      style={{ alignSelf: "center" }}
      height={300}
    />
  );
}
