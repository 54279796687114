import React from "react";
import MenuAtelier from "../component/Atelier/MenuAtelier"
import Accueil from "../component/Atelier/Accueil";
import Biography from "../component/Atelier/Biography";


/**
 * This component display workshop Page
 * @param {object} user - user object from api
 * @param {object} setUser - user setter
 *  @param {boolean} isLogged - test if the user is logged
 * @param {boolean} setIsLogged - isLogged setter
 */

export default function Atelier({user,setIsLogged, isLogged,setUser}) {
  return (


    <div>
       
      <MenuAtelier user = {user} setIsLogged={setIsLogged} isLogged={isLogged} setUser={setUser}/>
      <Biography user = {user} setIsLogged={setIsLogged} isLogged={isLogged} setUser={setUser}/>

      
     
     
      {/* <Draft  user = {user} setIsLogged={setIsLogged} isLogged={isLogged} setUser={setUser}/>
      <ListPaintAtelier  user = {user} setIsLogged={setIsLogged} isLogged={isLogged} setUser={setUser}/>*/}
      
    </div> 
  );
}
