import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import sha1 from "sha1";
import { SALT, URLDayl } from "../services/constantes";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import vis from "../webapp/img/icons/password_display_1.svg";
import unvis from "../webapp/img/icons/password_hide_1.svg";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";

/**
 * This component display the Sign in Page
 * @param {object} user - user object from api
 * @param {object} setUser - user setter
 * @param {boolean} setIsLogged - isLogged setter
 * @emits - users/save : save a new user
 * @emits - paintingEntity/randomPaintings30 : get a random painting
 */

export default function SignIn({ user, setUser, setIsLogged }) {
  const { register, handleSubmit } = useForm();
  const [randImg, setRandImg] = useState([]);
  const [boolVisibility, setBoolVisibility] = useState(false);

  const navigate = useNavigate();

  //Inscription dans l'api
  const onSubmit = (data, event) => {
    event.preventDefault();
    if (true) {
      if (data.truePassword === data.truePasswordConfirm) {
        axios
          .post(
            URLDayl + "users/save",
            {
              firstName: data.trueFirstName,
              lastName: data.trueLastName,
              email: data.trueEmail,
              password: sha1(data.truePassword + SALT),
              userType: "amateur",
              pseudo: data.pseudo,
              myOwnDescription: "",
              hasArtisticInterests: true,
              hashtagsArtisticInterestList: [""],
              hasArtisticSkill: true,
              hashtagsArtisticSkillsList: [""],
              interestedInParticipating: true,
            },
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            console.log(response.data);
            setUser({ ...user, ...response.data });
            setIsLogged(true);
            localStorage.setItem("id", response.data.id);
            localStorage.setItem("jwt", response.data.jwt);

            navigate(`../view/Login.js`, { state: response.data });

            toast.success("Vous avez créé votre compte!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      } else {
        toast.warning("Mots de passe différents!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.warning("Veuillez lire les CGU pour pouvoir vous inscrire", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // get 30 random Paint
  useEffect(() => {
    axios
      .get(
        URLDayl + "paintingEntity/randomPaintings30",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        setRandImg(response.data);
      });
  }, []);

  //montrer/cacher le mdp
  function handleVisibility() {
    setBoolVisibility(!boolVisibility);
  }

  return (
    <div className="accountFront">
      {/* <!-- Rangée de gauche (formulaire) - début --> */}
      <div className="splitLeft darkBgGradient-2 center-tex">
        <h1>Créer un compte</h1>
        <form
          className="formCompact"
          id={"loginInput"}
          onSubmit={handleSubmit(onSubmit)}
        >
          <input
            type="text"
            className="searchFilterDarkBg"
            size="35"
            {...register("trueLastName")}
            placeholder="Nom"
            required
          />
          <input
            type="text"
            className="searchFilterDarkBg"
            size="35"
            {...register("trueFirstName")}
            placeholder="Prénom"
            required
          />
          <input
            type="text"
            className="searchFilterDarkBg"
            size="35"
            {...register("pseudo")}
            placeholder="Pseudo"
            required
          />
          <input
            type="email"
            className="email"
            {...register("trueEmail")}
            size="35"
            placeholder="Adresse e-mail"
            required
          />
          <div className="relative">
            <input
              type={boolVisibility === true ? "text" : "password"}
              className="mt-a password"
              {...register("truePassword")}
              placeholder="Mot de passe"
              minLength="8"
              required
            />
            <img
              src={boolVisibility === true ? unvis : vis}
              alt="Toggle visibility"
              onClick={handleVisibility}
              className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
              style={{ width: "20px", height: "20px" }}
            />
          </div>
          <div className="relative mt-4">
            <input
              type={boolVisibility === true ? "text" : "password"}
              className="password"
              {...register("truePasswordConfirm")}
              placeholder="Confirmez le mot de passe"
              minLength="8"
              required
            />
            <img
              src={boolVisibility === true ? unvis : vis}
              alt="Toggle visibility"
              onClick={handleVisibility}
              className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
              style={{ width: "20px", height: "20px" }}
            />
          </div>

          <br />
          <input type="submit" className="submit mt-4" value="Inscription" />
        </form>
        <p className="mt-c">J'ai déjà un compte</p>
        <Link to="../view/LogIn.js" className="accountGo">
          Connexion
        </Link>
      </div>
      {/* <!-- Rangée de gauche (formulaire) - fin --> */}

      {/* <!-- Rangée de droite (arrière-plan dynamique) - début --> */}
      <div
        className="splitRight"
        style={{
          backgroundImage: `url(${URLDayl}images/paintingPic/${
            randImg.length > 0 && randImg[0].id
          })`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        <div>
          <p className="itemArtist">
            {randImg.length > 0 && randImg[0].artisteName}
          </p>
          <p className="itemName">
            {randImg.length > 0 && randImg[0].name} (
            {randImg.length > 0 && randImg[0].year})
          </p>
        </div>
      </div>
      {/* <!-- Rangée de droite (arrière-plan dynamique) - début --> */}
    </div>
  );
}
