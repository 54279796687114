import React, { useState } from "react";
import ResultMatchingColor from "../component/MatchingColor/ResultMatchingColor";
import { useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import ColorHeader from "../component/MatchingColor/ColorHeader";

/**
 * This component display the Matching Color Page
 * @param {object} user - user object from api
 * @param {object} setUser - user setter
 * @param {boolean} isLogged - test if the user is logged
 */

export default function DiscoverColors({ user, setUser, isLogged }) {
  const [color, setColor] = useState(null);

  return (
    <>
      <ColorHeader color={color} setColor={setColor} />

      {color !== null && (
        <div className="MatchingColor">
          <ResultMatchingColor
            color={color}
            user={user}
            setUser={setUser}
            isLogged={isLogged}
          />
        </div>
      )}
    </>
  );
}
