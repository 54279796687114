import React from "react";

const DeviceModal = ({ open }) => {
  if (!open) return null;
  return (
    <div className=" block fixed top-0 left-0 w-full h-full bg-black  items-center justify-center p-4">
      <div className=" top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 max-w-sm">
        <h2 className="text-xl font-bold mb-4">Désolé pour le désagrément</h2>
        <p className="mb-4">
          L'équipe travaille actuellement pour rendre cette expérience
          accessible à tous les écrans. Pour le moment, veuillez visiter ce site
          sur votre ordinateur portable pour une meilleure expérience.
        </p>
      </div>
    </div>
  );
};

export default DeviceModal;
