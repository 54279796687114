import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Link } from "react-router-dom";
import PaintingComponentMinimalist from "./PaintingComponentMinimalist";

function CustomNextArrow(props) {
  const { className, style, onClick } = props;

  return (
    <ChevronRightIcon
      className={`${className} carousel-painting-arrows-custom-next`}
      style={{ ...style, color: "white", fontSize: 60 }}
      onClick={onClick}
    />
  );
}

function CustomPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ChevronLeftIcon
      className={`${className} carousel-painting-arrows-custom-prev`}
      style={{ ...style, color: "white", fontSize: 60 }}
      onClick={onClick}
    />
  );
}

export default function CarouselColometrie({ paintings, custom }) {
  const settings = {
    infinite: true,
    speed: 500,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    slidesToScroll: 4,
    responsive: [
      { breakpoint: 750, settings: { slidesToShow: 0.9, slidesToScroll: 4 } },
      { breakpoint: 1000, settings: { slidesToShow: 1.9, slidesToScroll: 4 } },
      { breakpoint: 1250, settings: { slidesToShow: 2.9, slidesToScroll: 4 } },
      { breakpoint: 1500, settings: { slidesToShow: 4, slidesToScroll: 4 } },
      { breakpoint: 1750, settings: { slidesToShow: 4, slidesToScroll: 4 } },
      { breakpoint: 9999, settings: { slidesToShow: 4, slidesToScroll: 4 } },
    ],
  };
  
  return (
    <div style={{ width: "70%", margin: "auto" }}>
      <Slider {...settings}>
        {paintings.map((painting, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
            }}
          >
            <PaintingComponentMinimalist paint={painting} />
            {custom ? (
              (painting.id === 6653 || painting.id === 6712) && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Link
                    to={`/view/ColorimetrieAnalyse/${painting.id}`}
                    state={painting.id}
                  >
                    <button
                      style={{
                        width: "fit-content",
                        fontFamily: "cinzel",
                        padding: "6px",
                        backgroundColor: "black",
                       
                        borderRadius: "10px",
                      }}
                    >
                      GO
                    </button>
                  </Link>
                </div>
              )
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Link
                  to={`/view/customRequests/${painting.id}`}
                  state={painting.id}
                >
                  <button
                    style={{
                      width: "fit-content",
                      fontFamily: "cinzel",
                      padding: "6px",
                      backgroundColor: "black",
                      borderRadius: "10px",
                      color: "white",
                    }}
                  >
                    GO
                  </button>
                </Link>
              </div>
            )}
          </div>
        ))}
      </Slider>
    </div>
  );
}
