import ArtistCarousel from "./ArtistCarousel";
import { useEffect, useState } from "react";
import axios from "axios";
import { URLDayl } from "../../services/constantes";
import AutoCompleteCustom from "../PaintingSearch/AutoCompleteCustom";
import { SearchOutlined, Clear as ClearIcon } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";

export default function SearchArtistBar() {
  const [artist, setArtists] = useState([]);
  const [listNationality, setListNationality] = useState([]);
  const [listStyles, setListStyle] = useState([]);

  const [data, setData] = useState({
    artistName: "",
    nationality: "",
    periodStart: null,
    periodEnd: null,
    location: "",
    mouvement: "",
    isDaylMark: true,
  });

  useEffect(() => {
    const savedData = localStorage.getItem("artistSearchData");
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setData(parsedData);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("artistSearchData", JSON.stringify(data));
  }, [data]);

  const onSelectStyle = (e, item) => {
    setData((previous) => ({
      ...previous,
      mouvement: item ? item.name : null,
    }));
  };

  const onSelectNationality = (e, item) => {
    setData((previous) => ({
      ...previous,
      nationality: item ? item.name : null,
    }));
  };

  useEffect(() => {
    axios.get(URLDayl + "paintingFront/listStyles").then((res) => {
      const sortedStyles = res.data.sort(
        (a, b) => -b.name.localeCompare(a.name)
      );
      setListStyle(sortedStyles);
    });
  }, []);

  useEffect(() => {
    axios
      .get(URLDayl + `paintingFront/listOriginsAndNationalities`)
      .then((res) => {
        const sortedNationalities = res.data.sort().map((el) => ({ name: el }));
        setListNationality(sortedNationalities);
      });
  }, []);

  const hasSearchCriteria = () => {
    return (
      data.artistName ||
      data.nationality ||
      data.periodStart ||
      data.periodEnd ||
      data.location ||
      data.mouvement ||
      !data.isDaylMark
    );
  };

  useEffect(() => {
    const fetchInitialArtists = async () => {
      try {
        const res = await axios.get(URLDayl + `artist/randomTopArtists`);
        setArtists(res.data);
      } catch (error) {
        console.error("Error fetching initial artists:", error);
      }
    };

    fetchInitialArtists();
  }, []);

  const handleSearch = async () => {
    try {
      if (!hasSearchCriteria()) {
        const res = await axios.get(URLDayl + `artist/randomTopArtists`);
        setArtists(res.data);
      } else {
        const res = await axios.post(URLDayl + `artistSearch/freeSearch`, data);
        if (res.data.length > 0) {
          setArtists(res.data);
        } else {
          setArtists([]);
        }
      }
    } catch (error) {
      console.error("Error during search:", error);
      setArtists([]);
    }
  };

  const clearSearchData = () => {
    localStorage.removeItem("artistSearchData");
    setData({
      artistName: "",
      nationality: "",
      periodStart: null,
      periodEnd: null,
      location: "",
      mouvement: "",
      isDaylMark: true,
    });
  };

  return (
    <>
      <form
        id={"formDiscoverArtistSearchBar"}
        style={{
          display: "flex",
          height: 50,
          flexDirection: "row",
          width: "auto",
          gap: 50,
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ marginTop: "0", marginRight: "10px" }}>
          <select
            className="searchFilterWhiteBg"
            id="style"
            name="Artists"
            value={data.isDaylMark ? "major" : "all"}
            onChange={(e) =>
              setData((previous) => ({
                ...previous,
                isDaylMark: e.target.value === "major",
              }))
            }
          >
            <option value="major">Major Artists</option>
            <option value="all">All Artists</option>
          </select>
        </div>

        <div style={{ marginTop: "0" }}>
          <select
            className="searchFilterWhiteBg"
            id="style"
            value={data.periodStart || ""}
            name="period"
            onChange={(e) =>
              setData((previous) => ({
                ...previous,
                periodStart: e.target.value,
                periodEnd:
                  e.target.value !== ""
                    ? parseInt(e.target.value) >= 1700
                      ? parseInt(e.target.value) + 50
                      : parseInt(e.target.value) + 99
                    : "",
              }))
            }
          >
            <option value="">Birth Year</option>
            <option value={2000}>21ème siècle</option>
            <option value={1951}>20ème siècle (1951 - 1999)</option>
            <option value={1900}>20ème siècle (1900 - 1950)</option>
            <option value={1851}>19ème siècle (1851 - 1899)</option>
            <option value={1800}>19ème siècle (1800 - 1850)</option>
            <option value={1751}>18ème siècle (1751 - 1799)</option>
            <option value={1700}>18ème siècle (1700 - 1750)</option>
            <option value={1600}>17ème siècle (1600 - 1699)</option>
            <option value={1500}>16ème siècle (1500 - 1599)</option>
            <option value={1400}>15ème siècle (1400 - 1499)</option>
          </select>
        </div>

        <AutoCompleteCustom
          onChange={(e, value) =>
            setData((prev) => ({ ...prev, mouvement: value }))
          }
          placeholder={"Style"}
          onSelect={onSelectStyle}
          options={listStyles}
          className="blackAutoComplete"
          value={data.mouvement ? { name: data.mouvement } : null}
          isOptionEqualToValue={(option, value) => option.name === value?.name}
        />

        <AutoCompleteCustom
          onChange={(e, value) =>
            setData((prev) => ({ ...prev, nationality: value }))
          }
          placeholder={"Nationality"}
          onSelect={onSelectNationality}
          options={listNationality}
          className="blackAutoComplete"
          value={data.nationality ? { name: data.nationality } : null}
          isOptionEqualToValue={(option, value) => option.name === value?.name}
        />

        <div
          className="h-fit flex items-center text-2xl"
          data-tooltip="Lancer la recherche"
        >
          <SearchOutlined
            className="cursor-pointer h-fit"
            onClick={handleSearch}
          />
        </div>

        <Tooltip title="Clear Search">
          <ClearIcon className="cursor-pointer" onClick={clearSearchData} />
        </Tooltip>
      </form>
      <ArtistCarousel title={""} artists={artist} color={"white"} />
    </>
  );
}
