import ImageGrid, {enumTypeGrid} from "../component/general/ImageGrid/ImageGrid";
import {useUser} from "../contexts/userService";
import {useEffect, useState} from "react";
import {URLDayl} from "../services/constantes";
import axios from "axios";
import {useParams} from "react-router";


export default function FindByAwMediumPaintings () {

    const auth = useUser()
    const {aw_medium} = useParams()
    const [paintings, setPaintings] = useState([])
    const [refreshKey, setRefreshKey] = useState(0)
    const [maxNumPainting, setMaxNumPainting] = useState(48)
    const [sort, setSort] = useState("")
    const [totalNumberPainting, setTotalNumberPainting] = useState(0)

    useEffect(async () => {
        await axios.get(URLDayl + `paintingSearch/findPaintingsByAwMedium?aw_medium=${aw_medium}&numberOfPaintings=${maxNumPainting}`).then((res) => {
            setPaintings(res.data.payload)
            setTotalNumberPainting(res.data.numberMax)
        })
    },[aw_medium, maxNumPainting])

    return (
        <div style={{marginTop: 100}}>
            {/*<h1 style={{textAlign: "center", marginBottom: 40}}>{aw_medium}</h1>*/}
            <h1 style={{textAlign: "center", marginBottom: 40}}>AWMEDIUM</h1>
            <ImageGrid user={auth.data} isLogged={auth.data !== undefined} paintings={paintings} showTools={true} selectionMode={false} orderDate={sort} setOrderDate={setSort}
                       maxNumPainting={maxNumPainting} setMaxNumPainting={setMaxNumPainting} refreshKey={refreshKey} setRefreshKey={setRefreshKey} totalMaxNum={totalNumberPainting}/>
        </div>
    )
}