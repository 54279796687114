import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { URLDayl } from "../../services/constantes"
import "../../webapp/css/atelier.css"



/**
 * Page to update your artist Account
 * @param {object} user - user object from api
 * @param {object} setUser - user setter
 *  @param {boolean} isLogged - test if the user is logged
 */
export default function Accueil({ user, setUser, isLogged }) {





    // Call Jquery script
    useEffect(() => {
        const loadFile = async () => {
            const { stat } = await import("../../webapp/js/stats.min.js");


            stat();
        };
        loadFile();
    }, []);


    return (

        <div className="Accueil">
            <main className="workShopMain">

                <h2 className="workShopWelcome">Bonjour ,</h2>
                <p>Bienvenue dans votre atelier Dayl.art. Dans cet espace vous pourrez … Grâce aux technologie de reconnaissance d’image les plus avancées vous pourrez rapprochez vos peintures …</p>

                {/* <!-- SECTION PARTAGER UNE PENSEE - début --> */}
                <section className="mt-c">
                    <h3 className="mt-a">Une pensée à partager ?</h3>
                </section>
                {/* <!-- SECTION PARTAGER UNE PENSEE - fin --> */}

                {/* <!-- SECTION STATISTIQUES RAPIDES ATELIER - début --> */}
                <section className="mt-c">
                    <h3>Ce mois-ci, dans votre atelier :</h3>
                    <div className="itemCounters">
                        <article>
                            <div className="itemCounter" data-target="12"></div>
                            <h4>Image ajoutées</h4>
                        </article>
                        <article>
                            <div className="itemCounter" data-target="3"></div>
                            <h4>Brouillons</h4>
                        </article>
                        <article>
                            <div className="itemCounter" data-target="6"></div>
                            <h4>Peintures</h4>
                        </article>
                        <article>
                            <div className="itemCounter" data-target="17"></div>
                            <h4>j’aimes</h4>
                        </article>
                    </div>
                </section>
                {/* <!-- SECTION STATISTIQUES RAPIDES ATELIER - fin --> */}

                {/* <!-- SECTION DERNIERES IMAGES AJOUTEES - début --> */}
                <section className="mt-c">
                    <h3>Dernières images ajoutées</h3>
                    <p>Vous trouverez ci-dessous les dernières images que vous avez ajoutées. Vous pouvez commencer à renseigner ces nouvelles images, en ajouter ou en supprimer. Créez les descriptifs de vos peintures , publiez et partagez les en ligne.</p>
                    {/* <!-- item grid type 2 - début --> */}
                    <div className="itemGridType-2">
                        {/* <!-- item - début --> */}
                        <div className="itemBox" style={{ backgroundImage: "url('temp/leonora_more_1.jpg')", backgroundPosition: "50% center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                            <div className="dataModify"><img src="img/icons/atelier_side_edit_1.svg" alt="Modifier" /></div>
                            <input type="button" className="unfollow" value="" /* onclick="openCloseNextDiv(this);" */ />
                            <div className="deleteConfirm">
                                <span className="deleteText">Supprimer?</span>
                                <button type="button" className="yes closeTwoParentsNow" value="">Oui</button>
                                <button type="button" className="no closeParentNow" value="">Non</button>
                            </div>
                        </div>
                        {/* <!-- item - fin --> */}
                        {/* <!-- item - début --> */}
                        <div className="itemBox" style={{ backgroundImage: "url('temp/leonora_more_1.jpg')", backgroundPosition: "50% center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                            <div className="dataModify"><img src="img/icons/atelier_side_edit_1.svg" alt="Modifier" /></div>
                            <input type="button" className="unfollow" value="" /* onclick="openCloseNextDiv(this);" */ />
                            <div className="deleteConfirm">
                                <span className="deleteText">Supprimer?</span>
                                <button type="button" className="yes closeTwoParentsNow" value="">Oui</button>
                                <button type="button" className="no closeParentNow" value="">Non</button>
                            </div>
                        </div>
                        {/* <!-- item - fin --> */}
                        {/* <!-- item - début --> */}
                        <div className="itemBox" style={{ backgroundImage: "url('temp/leonora_more_1.jpg')", backgroundPosition: "50% center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                            <div className="dataModify"><img src="img/icons/atelier_side_edit_1.svg" alt="Modifier" /></div>
                            <input type="button" className="unfollow" value="" /* onclick="openCloseNextDiv(this);" */ />
                            <div class="deleteConfirm">
                                <span class="deleteText">Supprimer?</span>
                                <button type="button" class="yes closeTwoParentsNow" value="">Oui</button>
                                <button type="button" class="no closeParentNow" value="">Non</button>
                            </div>
                        </div>
                        {/* <!-- item - fin --> */}
                    </div>
                </section>
                {/* <!-- SECTION DERNIERES IMAGES AJOUTEES - fin --> */}

                {/* <!-- SECTION AJOUTER DES IMAGES - début --> */}
                <section class="mt-c">
                    <h3>Ajouter de nouvelles images</h3>
                    <p>Vous trouverez ci-dessous les dernières images que vous avez ajoutées. Vous pouvez commencer à renseigner ces nouvelles images, en ajouter ou en supprimer. Créez les descriptifs de vos peintures , publiez et partagez les en ligne.</p>
                </section>
                {/* <!-- SECTION AJOUTER DES IMAGES - fin --> */}

                {/* <!-- SECTION DERNIERES OEUVRES AJOUTE - début --> */}
                <section class="mt-c">
                    <h3>Mes dernières peintures en ligne</h3>
                    <p class="pb-a">Vous trouverez ci-dessous les dernières images que vous avez ajoutées. Vous pouvez commencer à renseigner ces nouvelles images, en ajouter ou en supprimer. Créez les descriptifs de vos peintures , publiez et partagez les en ligne.</p>

                    {/* <!-- item grid type 1 - début --> */}
                    <div class="itemGridType-1">
                        {/* <!-- item - début --> */}
                        <div class="itemBox">
                            <div class="dataLikes"><img src="img/icons/atelier_main_likes_1.svg" alt="Cliques j'aime" />12</div>
                            <div class="dataLists"><img src="img/icons/atelier_main_listes_1.svg" alt="Ajouts dans des listes" />3</div>
                            <div class="ItemEdit"><a href="#"><img src="img/icons/ico_pen_1.svg" alt="Modifier" /></a></div>
                            <div class="ItemImg" style={{ backgroundImage: "url('temp/leonora_more_1.jpg')", backgroundPosition: "50% center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}></div>
                            <p class="ItemName">Titre de la peinture</p>
                            <p class="ItemDesc">Description courte</p>
                            <p class="ItemSize">L x H x P Cm</p>
                        </div>
                        {/* <!-- item - fin --> */}
                        {/* <!-- item - début --> */}
                        <div class="itemBox">
                            <div class="dataLikes"><img src="img/icons/atelier_main_likes_1.svg" alt="Cliques j'aime" />12</div>
                            <div class="dataLists"><img src="img/icons/atelier_main_listes_1.svg" alt="Ajouts dans des listes" />3</div>
                            <div class="ItemEdit"><a href="#"><img src="img/icons/ico_pen_1.svg" alt="Modifier" /></a></div>
                            <div class="ItemImg" style={{ backgroundImage: "url('temp/leonora_more_1.jpg')", backgroundPosition: "50% center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}></div>
                            <p class="ItemName">Titre de la peinture</p>
                            <p class="ItemDesc">Description courte</p>
                            <p class="ItemSize">L x H x P Cm</p>
                        </div>
                        {/* <!-- item - fin --> */}
                        {/* <!-- item - début --> */}
                        <div class="itemBox">
                            <div class="dataLikes"><img src="img/icons/atelier_main_likes_1.svg" alt="Cliques j'aime" />12</div>
                            <div class="dataLists"><img src="img/icons/atelier_main_listes_1.svg" alt="Ajouts dans des listes" />3</div>
                            <div class="ItemEdit"><a href="#"><img src="img/icons/ico_pen_1.svg" alt="Modifier" /></a></div>
                            <div class="ItemImg" style={{ backgroundImage: "url('temp/leonora_more_1.jpg')", backgroundPosition: "50% center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}></div>
                            <p class="ItemName">Titre de la peinture</p>
                            <p class="ItemDesc">Description courte</p>
                            <p class="ItemSize">L x H x P Cm</p>
                        </div>
                        {/* <!-- item - fin --> */}
                    </div>
                    {/* <!-- item grid type 1 - fin --> */}

                    <div class="center-tex pb-c pt-b">
                        <a class="btn-1" href="#">Toutes mes peintures</a>
                    </div>
                </section>
                {/* <!-- SECTION DERNIERES OEUVRES AJOUTE - fin --> */}
            </main>
        </div>
    );
}