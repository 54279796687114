import React, { useState } from "react";
import { Modal, Box } from "@mui/material";
import Typography from "@mui/material/Typography";

export default function CommentsModal({ open, handleClose, comments }) {
  const style = {
    position: "absolute",
    right: "0",
    bottom: "0",
    bgcolor: "#a3a2a2",
    border: "2px solid #000",
    boxShadow: 24,
    boderRadius: 16,
    p: 4,
    width: "35vw",
    maxHeight: "70vh",
    overflowY: "scroll",
  };
  

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="flex flex-col gap-3 ">
          {comments.map((comment) => {
            return (
              <div key={comment.id} className=" flex-col gap-3 font-raleway border-2 bg-white border-black p-3 rounded-lg">
                <div className="text-lg"> {comment.content} </div>
                <div className="flex justify-end italic text-base"> {comment.userName}</div>
              </div>
            );
          })}
        </div>
      </Box>
    </Modal>
  );
}
