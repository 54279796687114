import {withStyles} from "@mui/styles";
import TextField from "@mui/material/TextField";
import * as React from "react";

export const stylesCustomTextField = {
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: 'none',
            },
            '&:hover fieldset': {
                border: 'none',
            },
            '&.Mui-focused fieldset': {
                border: 'none',
            },
            padding: 'none',
        },

        backgroundColor: "rgba(213,213,213,0.37)",
        borderRadius: 10,
        marginBottom: 10,
    },
};

const CustomTextFieldTemp = withStyles(stylesCustomTextField)(TextField);

export default function CustomTextField({width = 165, placeholder, defaultValue,title,ref ,...params}) {
    const {classes, ...other} = params;


    return (
        title !== undefined ? <div style={{display: "flex", flexDirection: "column"}}>
                <div style={{padding: 10, fontWeight: "bold", fontSize: 16,}}>
                    {title}
                </div>
                <CustomTextFieldTemp {...other} inputRef={ref} defaultValue={defaultValue} placeholder={placeholder}/></div> :
            <CustomTextFieldTemp {...other} inputRef={ref} defaultValue={defaultValue} placeholder={placeholder}/>
    )
}