// import {useState} from "react";
//
//
//
// export default function Lightbox({image, alt, }) {
//
//     const [showImage, setShowImage] = useState(false)
//
//     return (
//         <>
//             <div className={"lightboxButton"} onClick={() => setShowImage(!showImage)}></div>
//             {showImage && <div className={"lightboxContainer"} style={{position: "absolute"}}>
//                 <img className={"imageLighbox"} alt={alt} src={"https://geo.img.pmdstatic.net/fit/http.3A.2F.2Fprd2-bone-image.2Es3-website-eu-west-1.2Eamazonaws.2Ecom.2FGEO.2Fvar.2Fgeo.2Fstorage.2Fimages.2Fmedia.2Fimages.2Fles-nympheas-1899-claude-monet.2F2687572-1-fre-FR.2Fles-nympheas-1899-claude-monet.2Ejpg/1280x720/background-color/ffffff/quality/10/dix-choses-que-vous-ne-saviez-pas-sur-monet.jpg"}/>
//             </div>}
//         </>
//
//     )
// }

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { URLDayl } from "../../../services/constantes";
import ListModal from "../ListModal";
import PrismaZoom from "react-prismazoom";
import { useNavigate } from "react-router";
import ListModal2 from "../ListModal2";
// import {Close as ReactComponent} from "../../../webapp/img/icons/preview-lightbox_close-active.svg"
// import * as url from "url";

export default function Lightbox({
  user,
  isLogged,
  title,
  index,
  setIndex,
  matchList,
  hideTools,
  url = false,
  ...props
}) {
  const [showImage, setShowImage] = useState(false);

  const [isLiked, setIsLiked] = useState(false);

  // useEffect(() => {
  //     setIndex(index)
  // }, [matchList])

  const navigation = useNavigate();

  useEffect(() => {
    if (isLogged && index) {
      axios
        .get(URLDayl + "users/" + user.id)
        .then((response) => {
          // console.log(response.data.paintingLiked.includes(painting.id))
          setIsLiked(response.data.paintingLiked.includes(matchList[index].id));
        })
        .catch((err) => {
          console.log(err);
          console.log(URLDayl + "users/" + user.id);
        });
    }
  }, [matchList]);

  function handleLike() {
    if (isLogged === true) {
      if (isLiked) {
        axios
          .post(URLDayl + "users/updateFavoritePainting", {
            userId: user.id,
            favoriteId: matchList[index].id,
            operation: "remove",
          })
          .then(() => {
            toast.warn("Peinture supprimée!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setIsLiked(false);
          });
      } else {
        axios
          .post(URLDayl + "users/updateFavoritePainting", {
            userId: user.id,
            favoriteId: matchList[index].id,
            operation: "add",
          })
          .then(() => {
            toast.warn("Peinture ajoutée!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setIsLiked(true);
          });
      }
    } else {
      toast.warn("Connectez vous pour ajouter aux favoris!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }
  return index !== undefined ? (
    <>
      <div className="modal-overlay">
        <div className="modal-wrapper">
          {matchList.length > 1 && (
            <div
              className={"leftArrow"}
              style={{
                position: "absolute",
                left: 100,
              }}
              onClick={() => {
                if (index > 0) setIndex(index - 1);
              }}
            ></div>
          )}
          <div className="modal">
            <div
              className="modal-header"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <PrismaZoom allowPan={true} allowZoom={true}>
                <img
                  id="resizeMe"
                  style={{
                    maxHeight: "80vh",
                    height: "auto",
                    borderRadius: 10,
                    width: "auto",
                  }}
                  src={
                    url
                      ? matchList[index]
                      : URLDayl +
                        `images/paintingPic/full/${matchList[index].id}`
                  }
                  draggable="true"
                  alt="Nom de l'oeuvre"
                />
              </PrismaZoom>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 20,
                  height: "100%",
                  gap: 20,
                  alignSelf: "center",
                  marginTop: 10,
                }}
              >
                <div
                  className={"closeIcon"}
                  style={{
                    width: 32,
                    height: 32,
                    // backgroundColor: "transparent",
                    borderRadius: 100,
                  }}
                  onClick={() => setIndex(undefined)}
                ></div>

                <div
                  className={"exploreIcon"}
                  style={{ width: 32, height: 32, borderRadius: 100 }}
                  onClick={() =>
                    navigation(
                      `../../view/OpusPage.js/${matchList[index].id}`,
                      { state: matchList[index].id }
                    )
                  }
                >
                  <span></span>
                </div>
                {
                  <div
                    className={"likeIcon"}
                    style={{
                      width: 32,
                      height: 32,
                      // backgroundColor: "white",
                      // backgroundColor: isLiked ? "white" : "red",
                      borderRadius: 100,
                    }}
                    onClick={() => handleLike()}
                  ></div>
                }

                <div
                  id="lightBoxListModal"
                  style={{
                    width: 35,
                    height: 45,
                    // backgroundColor: "transparent",
                    borderRadius: 100,
                    right: 10,
                    top: -10,
                  }}
                >
                  <ListModal2
                    user={user}
                    isLogged={isLogged}
                    listPaint={matchList[index]}
                    tooltip={false}
                    styleButton={{ fontSize: "25", color: "white" }}
                  />
                </div>
              </div>
            </div>

            <div className="modal-body">{props.children}</div>
          </div>
          {matchList.length > 1 && (
            <div
              className={"rightArrow"}
              style={{
                width: 62,
                height: 62,
                position: "absolute",
                right: 100,
              }}
              onClick={() => {
                if (index < matchList.length - 1) setIndex(index + 1);
              }}
            >
              <span></span>
            </div>
          )}
        </div>
      </div>
    </>
  ) : null;
}
