import React, { useState } from "react";
import "./Card.css";

const FlipImage = React.forwardRef(
  ({ imageSrc, title, flipSubtitle }, ref) => {
    const [isFlipped, setIsFlipped] = useState(false);

    const handleFlip = () => {
      setIsFlipped(!isFlipped);
    };

    return (
      <div
        className="flip-image-container m-5"
        onMouseEnter={handleFlip}
        onMouseLeave={handleFlip}
        ref={ref}
      >
        <div className={`flip-image ${isFlipped ? "flipped" : ""}`}>
          <div className="flip-image-front">
            <img src={imageSrc} alt={title} />
            <div className="flip-image-text">
              <h4
                style={{
                  fontSize: 28,
                  color: "white",
                  textTransform: "uppercase",
                  fontFamily: "Cinzel",
                }}
              >
                {title}
              </h4>
            </div>
          </div>
          <div className="flip-image-back">
            <img src={imageSrc} alt={title} />
            <div className="flip-image-text flip-image-text-dark">
              <p>{flipSubtitle}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default FlipImage;
