import React, { useEffect, useState } from "react";
import { URLDayl } from "../../../services/constantes";
import ListModal from "../../general/ListModal";
import { Link } from "react-router-dom";

import axios from "axios";
import {
  Chip,
  OutlinedInput,
  Pagination,
  Select,
  Skeleton,
  Stack,
} from "@mui/material";
import { useUser } from "../../../contexts/userService";
import NoPostYet from "../../SocialMedia/NoPostYet";
import SkeletonPaintings from "../../general/SkeletonPaintings";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";

const NUM_PER_PAGE = 24;

const MatchingItem = ({
  paint,
  index,
  painting,
  setPainting,
  friend,
  matchType,
}) => {
  const deleteLike = (paintId) => {
    axios
      .post(URLDayl + "users/updateMatchingPainting", {
        userId: auth.data.id,
        favoriteId: paint.id,
        operation: "remove",
      })
      .then((response) => {
        axios
          .get(URLDayl + `users/${auth.data.id}`, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          })
          .then((response2) => {
            auth.setData(response2.data);
          });
      });
  };

  const handleBoolListFalse = (index) => {
    const tempBool = [...painting];

    tempBool[index].bool = false;
    setPainting(tempBool);
  };
  const handleBoolListTrue = (index) => {
    const tempBool = [...painting];

    tempBool[index].bool = true;
    setPainting(tempBool);
  };

  const auth = useUser();

  return (
    <div className="sliderOne owl-loaded ">
      <div
        className="item"
        style={{
          backgroundImage: `url(${URLDayl}images/paintingPic/${paint.id})`,
        }}
      >
        <div style={{ top: "193px", right: "150px" }}>
          <ListModal
            user={auth.data}
            isLogged={auth.data !== undefined}
            listPaint={paint}
          />
        </div>
        <div className="bt-wrapper">
          {/* <div className="img-wrapper"> */}

          <div className="titre">
            <h3
              style={{
                fontStyle: "oblique",
                fontWeight: "600",
                fontSize: "14px",
                color: "white",
                marginBottom: "15px",
              }}
            >
              {paint.artist_name_tot}
            </h3>
            {paint.aw_title_origin}
            <div className="date">{paint.aw_year}</div>
          </div>
          <div className="container-action ">
            <Link
              className="bt-action voir onHoverOpacity"
              to={`../../view/MatchingPage.js/${paint.id}`}
              state={matchType}
            >
              voir
            </Link>
            <div
              className="bt-action match onHoverOpacity"
              onClick={() => {
                handleBoolListTrue(index);
              }}
            >
              supprimer
            </div>
          </div>
        </div>
        <div className="name"></div>

        {paint.bool === true && !friend && (
          <div className="deleteConfirm">
            <span className="deleteText">Supprimer?</span>
            <button
              type="button"
              className="yes closeTwoParentsNow"
              onClick={() => deleteLike(paint.id)}
              value=""
            >
              Oui
            </button>
            <button
              type="button"
              className="no closeParentNow"
              onClick={() => handleBoolListFalse(index)}
              value=""
            >
              Non
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

function Matchings({ friend }) {
  const [painting, setPainting] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const auth = useUser();
  const [page, setPage] = useState(1);
  const [selectedArtists, setSelectedArtists] = useState([]);
  const [availableArtists, setAvailableArtists] = useState([]);
  const [totalPainting, setTotalPainting] = useState(0);
  const [matchingDetails, setMatchingDetails] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    setPainting([]);
    var userTemp = auth.data;
    if (friend) {
        await axios
            .get(URLDayl + `users/${friend}`)
            .then((res) => (userTemp = res.data));
    }
    const chunkedArray = Array.from(
      { length: Math.ceil(userTemp.matchingLiked.length / NUM_PER_PAGE) },
      (_, index) =>
        userTemp.matchingLiked.slice(
          index * NUM_PER_PAGE,
          index * NUM_PER_PAGE + NUM_PER_PAGE
        )
    );

    setTotalPainting(userTemp.matchingLiked.length);
    setLoading(true);

    for (
      let i = 0;
      chunkedArray.length > 0 && i < chunkedArray[page - 1].length;
      i++
    ) {
      try {
        await axios
          .get(URLDayl + `paintingEntity/${chunkedArray[page - 1][i]}`)
          .then((response) => {
            let boolArray = response.data;
            boolArray.bool = false;
            setPainting((painting) => [...painting, boolArray]);
              setAvailableArtists((artist) => [...artist, response.data.artist]);
            const temp = availableArtists;

            if (
              !temp.some(
                (el) =>
                  el.artistIdtfIntern === response.data.artist.artistIdtfIntern
              )
            ) {
              temp.push(response.data.artist);
            }
          });
        setLoading(false);
      } catch (e) {}
    }


    setLoading(false);
  }, [refreshKey, friend, page]);

  useEffect(() => {
    axios
      .get(URLDayl + "users/getMatchingLikedPageable", {
        params: { userId: friend ? friend : auth.data.id, start: 0, end: 25 },
      })
      .then((res) => {
        setMatchingDetails(res.data);
      });
  }, []);


  //afficher modale suppression//

  const handleChange = (event, value) => {
    setPage(value);
    window.scrollTo(0, 60);
  };

  //cacher modale suppression//

  const handleChangeSelect = (event) => {
    const {
      target: { value },
    } = event;

    const temp = typeof value === "string" ? value.split(",") : value;
    setSelectedArtists(temp);
  };

  const useStyles = makeStyles({
    checkbox: {
      color: "#101e39", // Here is your custom color
      "&$checked": {
        color: "#101e39",
      },
    },
    checked: {},
  });
  const classes = useStyles();
  return (
    <div className="Likes">
      <div style={{ justifyContent: "end", display: "flex",marginRight:"100px" }}>
        <Select
          multiple
          style={{ minWidth: 200 }}
          displayEmpty
          value={selectedArtists}
          labelId="demo-multiple-checkbox-label"
          onChange={handleChangeSelect}
          input={<OutlinedInput label="" placeholder="Select Artists" />}
          renderValue={(selected) =>
            selected.length > 0 ? (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 0.5,
                  maxWidth: "500px",
                }}
              >
                {selected.map((value) => (

                  <Chip key={value} label={value} />
                ))}
              </Box>
            ) : (
              <em>Select Artists</em>
            )
          }
          // MenuProps={MenuProps}
        >
          {availableArtists
            // .sort((a, b) => a.artist_name_tot.localeCompare(b.artist_name_tot))
            .map((name) => (
              <MenuItem key={name.id} value={name.artist_name_tot}>
                <Checkbox
                  className={classes.checkbox}
                  classes={{ checked: classes.checked }}
                  checked={selectedArtists.indexOf(name.artist_name_tot) > -1}
                />
                <ListItemText
                  style={{ marginRight: "20px", fontFamily: "" }}
                  primary={name.artist_name_tot}
                />
              </MenuItem>
            ))}
        </Select>
      </div>
      <div className="accountArtists">
        {loading && <SkeletonPaintings />}
        {!loading && painting.length === 0 && (
          <NoPostYet title={"No saved matchings"} />
        )}
        {!loading &&
          painting.length > 0 &&
          painting
            .slice(0 + 24 * (page - 1), 24 + 24 * (page - 1))
            .map((paint, index) => {
              return (
                (selectedArtists.length < 1 ||
                  selectedArtists.includes(paint.artist.artist_name_tot)) && (
                  <MatchingItem
                    paint={paint}
                    index={index}
                    painting={painting}
                    setPainting={setPainting}
                    friend={friend}
                    matchType={
                      matchingDetails[index]
                        ? matchingDetails[index].typeMatching
                        : ""
                    }
                  />
                )
              );
            })}
      </div>

      {painting.length / 24 > 1 && (
        <Pagination
          count={Math.ceil(painting.length / 24)} // Calculate the total number of pages
          page={page}
          style={{
            alignSelf: "center",
            padding: "20px",
            justifyContent: "center",
          }}
          sx={{ justifyContent: "center" }}
          variant="outlined"
          className="paginationCentered"
          onChange={handleChange} // Add the event handler for page change
        />
      )}
    </div>
  );
}
export default Matchings;
