/**
 * Constant used in the whole website
 */

export const SALT = "PAINTING_NOVAGEN_ETC";
// export const URLDayl = "http://162.19.52.131:8088/"
// export const URLDayl = "http://192.168.92.14:8081"
// <<<<<<< HEAD
//  export const URLDayl = "http://162.19.54.198:8081/"
// //export const URLDayl = "http://localhost:8081/"
// =======
// // <<<<<<< HEAD
// // export const URLDayl = "http://162.19.54.198:8081/"
// // // export const URLDayl = "http://localhost:8081/"
// // =======
//export const URLDayl = "http://162.19.54.198:8081/"
export const URLDayl = process.env.REACT_APP_API_URL;

// export const URLDayl = "http://localhost:8081/api/"
export const URLDaylLocale = "http://localhost:8081/";

//export const URLDaylImage = "http://162.19.54.198:8081/"
export const URLDaylImage = process.env.REACT_APP_API_URL_IMG;

//export const URLDaylImage = "https://demo.dayl.art  http://127.0.0.1:5000"
export const URLDaylIAgent = process.env.REACT_APP_API_URL_AGENT;

// // >>>>>>> 6df6d3877f496e2be6414e619c40391e67098d7b
//
//  export const URLDayl = "http://162.19.54.198:8081/"
// >>>>>>> origin/nouvelPagePeinture
