import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { URLDaylImage } from "../../services/constantes";
import { useUser } from "../../contexts/userService";
import { URLDayl } from "../../services/constantes";
import axios from "axios";
import ReplayIcon from "@mui/icons-material/Replay";

export default function ArtistGrid({}) {
  const auth = useUser();
  const [refreshkey, setRefreshKey] = useState(0);

  const [displayedArtists, setDisplayedArtists] = useState([]);

  useEffect(() => {
    axios.get(URLDayl + "artist/randomTopArtists?limit=6").then((response) => {
      const limitedArtists = response.data.slice(0, 6);
      setDisplayedArtists(limitedArtists);
    });
  }, [refreshkey]);

  return (
    <div className="flex w-full text-white items-center justify-center bg-amber-50">
      <div className="MostMatched" style={{ margin: "20px 50px" }}>
        <div
          className="grid-container"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: "20px",
            margin: "auto",
          }}
        >
          {displayedArtists.map((artists, index) => (
            <div
              className="grid-item"
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Link
                to={`../../view/ArtistDetailPage.js/${artists.id}`}
                state={artists.id}
              >
                <div className="img-wrapper">
                  <img
                    src={`${URLDayl}images/artistPic/${artists.id}`}
                    alt={`${URLDayl}'s artwork`}
                    style={{
                      width: "220px",

                      height: "220px",
                      objectFit: "cover",
                    }}
                  />
                  <div
                    className="artist-info"
                    style={{ textAlign: "center", marginTop: "10px" }}
                  >
                    <div className="artist-name text-black">{artists.artist_name_tot}</div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
