import { useEffect } from "react";
import { useLocation } from "react-router-dom";
  

/**
 * This function is used to scroll to the top of the page when we render a new page

 */


export default function GoToTop() {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  }
  useEffect(() => {
    onTop()
  }, [routePath]);
  
  return null;
}