import React, { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import BiographieElement from "./BiographieElement";
import { stringSplitting } from "../../services/Utils";
import { URLDayl, URLDaylImage } from "../../services/constantes";
import axios from "axios";
import Lightbox from "../general/Lightbox/Lightbox";
import { useUser } from "../../contexts/userService";

/**
 * Artist Biography in ArtistPage
 * @param {string} bg - random image from this artist
 * @param {object} artist - artist informations from api
 */

export default function Biography({ bg, artist }) {
  const [paintingFromArtist, setPaintingFromArtist] = useState([]);
  const [publications, setPublications] = useState([]);
  const [images, setImages] = useState([]);
  const [index, setIndex] = useState();
  const [language, setLanguage] = useState("FRENCH");
  const auth = useUser();


  useEffect(() => {}, [artist]);

  useEffect(() => {
    axios
      .get(URLDayl + `paintingSearch/findPaintingByArtistIdtfn`, {
        params: {
          artistId: artist.id,
          number: 10,
          minNumPainting: 0,
        },
      })
      .then((res) => {
        res.data.payload.map((el) => {
          setPaintingFromArtist((paintingFromArtist) => [
            ...paintingFromArtist,
            URLDaylImage + `images/paintingPic/full/${el.id}`,
          ]);
        });
      });
  }, [artist]);

  useEffect(() => {
    setPublications([]);
    axios
      .get(
        URLDayl +
          `artist/elementsBiographies/artist?artistId=${artist.id}&language=${language}&hidden=true`
      )
      .then((res) => {
        const doubleTempImages = [];

        for (let i = 0; i < res.data.length; i++) {
          const tempImages = [];
          for (let j = 0; j < res.data[i].images.length; j++) {
            if (res.data[i].images[j] !== "")
              tempImages.push(
                URLDaylImage +
                  `images/biography/${artist.id}/${res.data[i].id}?name=${res.data[i].images[j]}`
              );
          }
          for (let k = 0; k < res.data[i].taggedArtworks.length; k++)
            tempImages.push(
              URLDaylImage +
                `images/paintingPic/${res.data[i].taggedArtworks[k]}`
            );
          doubleTempImages.push(tempImages);
        }
        setImages(doubleTempImages);
        setPublications(res.data);
      })
      .catch((err) => {
        console.log("ERROR");
        console.log(err);
      });
  }, [artist.id, language]);

  return (
    <div className="Biography">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 20,
          marginLeft: 20,
        }}
      >
        <div
          onClick={() => {
            setLanguage("ENGLISH");
            console.log("ENGLISH");
          }}
        >
          <div>EN</div>
        </div>
        <div
          onClick={() => {
            setLanguage("FRENCH");
          }}
        >
          <div>FR</div>
        </div>
        <div
          onClick={() => {
            setLanguage("SPANISH");
          }}
        >
          <div>ES</div>
        </div>
      </div>
      <section className="center-col mb-d">
        <div className="RandomQuickFactBox cont-l-w">
          <div>
            {publications &&
              publications.length > 0 &&
              publications.map((el, index) => {
                return (
                  <>
                    <BiographieElement
                      artist={"artist"}
                      bg={"bg"}
                      text={el ? stringSplitting(750, el.content) : ""}
                      direction={index % 2 !== 0 ? "left" : "right"}
                      title={el.title}
                      imageList={
                        el.userId === null
                          ? paintingFromArtist.slice(5, 10)
                          : images[index]
                      }
                    />
                  </>
                );
              })}

            {artist && artist.wikipedia_summary_fr && (
              <BiographieElement
                artist={artist}
                bg={bg}
                text={
                  artist
                    ? stringSplitting(750, artist.wikipedia_summary_fr)
                    : ""
                }
                direction={"left"}
                title={"Contenu wikipedia"}
                imageList={paintingFromArtist.slice(0, 5)}
              />
            )}
          </div>
        </div>
      </section>
      
      {/* <!-- Biographie - fin --> */}
    </div>
  );
}
