import React, {useEffect, useState} from "react";
import newtempprofile from "../../../webapp/img/icons/newtempprofile.svg";
import {reformatDate} from "../../../services/Utils";
import "../../../webapp/css/blocks.css"
import {URLDayl, URLDaylImage} from "../../../services/constantes";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {useUser} from "../../../contexts/userService";
import axios from "axios";
import {useNavigate} from "react-router";

export default function SocialNetworkPostHeader({post}) {

    const [user, setUser] = useState(() => {
        axios.get(URLDayl + `users/${post.userId}`).then((res) => {
            setUser(res.data)
        })
    },[post])

    const navigation = useNavigate()

    return (
        <div className="postInfo">
            {/* <img style={{borderRadius: 50, height: 50, width: 50, objectFit: "cover"}} src={URLDaylImage + `images/userIconImage?id=${post.userId}`}/> */}
            <p className={"onHoverTextUnderline"} onClick={() => {navigation(`../../view/ProfileAmateur.js/${user.id}`)}}>{post.userName}</p>
            <div></div>
            <p>{reformatDate(post.creation)}</p>
        </div>
    )
}