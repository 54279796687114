import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { URLDayl } from "../../services/constantes"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import home from "../../webapp/img/icons/atelier_side_home_1.svg"
import edit from "../../webapp/img/icons/atelier_side_edit_1.svg"
import add from "../../webapp/img/icons/atelier_side_images-ajoutees_1.svg"
import galerie from "../../webapp/img/icons/atelier_side_galerie_1.svg"
import reco from "../../webapp/img/icons/atelier_side_reco_1.svg"
import expo from "../../webapp/img/icons/atelier_side_expo_1.svg"
import a from "../../webapp/img/icons/atelier_side_tableau_1.svg"
import Accueil from "./Accueil";
import Biography from "./Biography";

/**
 * Workshop side menu
 * @param {object} user - user object from api
 * @param {object} setUser - user setter
 *  @param {boolean} isLogged - test if the user is logged 
 *  @param {boolean} setIsLogged - isLogged setter
 */

export default function MenuAtelier({ user, setUser, isLogged, setIsLogged }) {

    //init Variables global
    const [isActive, setActive] = useState(1);


    //Active class a
    const toggleClass = (val) => {
        setActive(val);
    };

    return (
        <div className="MenuAtelier">
            <Tabs>
                <div className="workShopSideNav">
                    {/* <!-- BLOC NAVIGATION FIXE GAUCHE - début --> */}

                    <TabList>
                        <div className="workShopArtistTop">
                            <h1>Arthur de Frayssinet</h1>
                            <Link className="bt-action voir" to="../view/Biography.js">
                                Ma biographie<span><img src={edit} alt="Modifier ma biographie" /></span>
                            </Link>

                        </div>

                        <nav className="workShopNav mt-b">
                            <ul>
                                <Tab className="mb-b react-tabs__tab">
                                    <a className={isActive === 2 ? 'active' : null} onClick={() => toggleClass(2)} ><img src={home} alt="Retour à l'accueil" /><span className="title">Accueil de l'atelier</span></a>
                                </Tab>
                                <Tab className="react-tabs__tab">
                                    <a ><img src={add} alt="Images ajoutées" /><span className="title">Images ajoutées</span></a>
                                </Tab>
                                <Tab className="react-tabs__tab">
                                    <a ><img src={edit} alt="Brouillons à terminer" /><span className="title">Brouillons à terminer</span><span className="pill">4</span></a>
                                </Tab>
                                <Tab className="mb-b react-tabs__tab">
                                    <a ><img src={galerie} alt="Galerie de peintures" /><span className="title">Galerie de peintures</span></a>
                                </Tab>
                                <Tab className="mb-b react-tabs__tab">
                                    <a ><img src={reco} alt="Recommandations" /><span className="title">Recommandations</span></a>
                                </Tab >
                                <Tab className="mb-b react-tabs__tab">
                                    <a ><img src={expo} alt="Expositions" /><span className="title">Expositions</span></a>
                                    <ul>
                                        <Tab className="react-tabs__tab">
                                            <a>Ajouter une exposition</a>
                                        </Tab>
                                        <Tab className="react-tabs__tab">
                                            <a >Toutes mes expositions</a>
                                        </Tab>
                                    </ul>
                                </Tab>
                                <Tab className="react-tabs__tab">
                                    <a ><img src={a} alt="Tableau de bord" /><span className="title">Tableau de bord</span></a>
                                </Tab>
                            </ul>
                        </nav>
                        {/* <!-- BLOC NAVIGATION FIXE GAUCHE - fin --> */}

                    </TabList>

                </div>
                <TabPanel>
                    <Accueil user={user} setIsLogged={setIsLogged} isLogged={isLogged} setUser={setUser} />
                </TabPanel>
            </Tabs>
        </div>

    );
}