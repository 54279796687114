import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import axios from "axios";
import {URLDayl, URLDaylImage} from "../../../services/constantes";

export default function SocialNetworkPostComment ({comment,post}) {
    const [user, setUser] = useState(() => {
        axios.get(URLDayl + `users/${post.userId}`).then((res) => {
            setUser(res.data)
        })
    },[post])

    const navigation = useNavigate()
    return (
        <div>
            <div className="comment">
                {
                    comment.content
                }
            </div>
            <div className="commentUser">
            <p className={"onHoverTextUnderline"} onClick={() => {navigation(`../../view/ProfileAmateur.js/${comment.userId}`)}}>{comment.userName}</p>
            </div>
        </div>
    )
}