import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./MovementContext.css";
import { URLDayl, URLDaylImage } from "../../services/constantes";
import BiographieElement from "../ArtistDetailPage/BiographieElement";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { stringSplitting } from "../../services/Utils";

function paragraphSplitting(text) {
  let paragraphs = text.split("\n\n\n");
  paragraphs = paragraphs.map((paragraph) => paragraph.trim());
  paragraphs = paragraphs.filter((paragraph) => paragraph !== "");
  return paragraphs;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ movementDetail }) {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSelect = (index) => {
    setActiveIndex(index);
  };
  const navBarStyle = {
    backgroundColor: activeIndex === 1 ? "white" : "#101E39",
  };

  const [topPaintings, setTopPaintings] = useState([]);
  const [topImages, setTopImages] = useState([]);

  useEffect(() => {
    if (movementDetail) {
      axios
        .get(URLDayl + "top_paintings_movement/paintingsByMovement", {
          params: { movementId: movementDetail.id },
        })
        .then((res) => {
          setTopPaintings(res.data);
          var tempTopPaintings = [];
          res.data.map((el) => {
            tempTopPaintings.push(
              URLDaylImage + "images/paintingPic/" + el.painting.id
            );
          });
          setTopImages(tempTopPaintings);
        });
    }
  }, [movementDetail]);
  const { t } = useTranslation();
  return (
    <div>
      {movementDetail && (
        <div style={{ paddingLeft: 50, marginRight: 50 }}>
          <BiographieElement
            text={stringSplitting(750, movementDetail.presentation_fr || "")}
            direction={"left"}
            imageList={topImages.slice(0, topImages.length / 2)}
            title={"PRESENTATION"}
            titleSize={"text-2xl"}

          />
          <BiographieElement
            text={stringSplitting(
              750,
              movementDetail.characteristiques_fr || ""
            )}
            direction={"right"}
            imageList={topImages.slice(topImages.length / 2)}
            title={"CARACTERISTIQUES"}
            titleSize={"text-2xl"}
          />
        </div>
      )}
    </div>
  );
}
