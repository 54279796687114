import React from "react";
import Tooltip from "@mui/material/Tooltip";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";

export default function ColorSelection({
  colors,
  selectedColors,
  primaryColorsSelected,
  secondaryColorsSelected,
  handleColorSelection,
  handleSubmit,
  loading,
  luminosity,
  setLuminosity,
  saturation,
  setSaturation,
  luminosityScale,
  saturationScale,
  setSelectedColors,
}) {
  const handleResetColors = () => {
    setSelectedColors([]);
  };
  const handleSelectAllColors = () => {
    setSelectedColors(colors.map((color) => color.name));
  };

  const createArc = (startAngle, endAngle, color) => {
    const isSelected = selectedColors.some((c) => c === color.name);
    const r = isSelected ? 42 : 35;
    const r2 = 20;
    const x1 = 50 + r * Math.cos(startAngle);
    const y1 = 50 + r * Math.sin(startAngle);
    const x2 = 50 + r * Math.cos(endAngle);
    const y2 = 50 + r * Math.sin(endAngle);
    const x3 = 50 + r2 * Math.cos(endAngle);
    const y3 = 50 + r2 * Math.sin(endAngle);
    const x4 = 50 + r2 * Math.cos(startAngle);
    const y4 = 50 + r2 * Math.sin(startAngle);

    return (
      <g key={color.name}>
        <path
          d={`M ${x1} ${y1} A ${r} ${r} 0 0 1 ${x2} ${y2} L ${x3} ${y3} A ${r2} ${r2} 0 0 0 ${x4} ${y4} Z`}
          fill={color.hex}
          stroke="white"
          strokeWidth="1"
          onClick={() => handleColorSelection(color.name)}
          className={`cursor-pointer transition-all duration-200 ${isSelected ? "opacity-100" : "opacity-70 hover:opacity-90"}`}
        />
        {isSelected && (
          <path
            d={`M ${x1} ${y1} A ${r} ${r} 0 0 1 ${x2} ${y2} L ${x3} ${y3} A ${r2} ${r2} 0 0 0 ${x4} ${y4} Z`}
            fill="none"
            stroke="white"
            strokeWidth="1"
            className="pointer-events-none"
          />
        )}
      </g>
    );
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "16px",
        gap: "15px",
        width: "20%",
      }}
    >
      <div className="flex flex-col justify-center gap-4 items-center rounded-2xl border-2 border-black p-2 shadow-lg font-raleway">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "15px",
          }}
        >
          <button
            onClick={handleSelectAllColors}
            style={{ cursor: "pointer", textDecoration: "underline" }}
          >
            Select All Colors
          </button>
          <button
            onClick={handleResetColors}
            style={{ cursor: "pointer", textDecoration: "underline" }}
          >
            Reset Selection
          </button>
        </div>
        <svg
          width="200"
          height="200"
          viewBox="0 0 100 100"
          aria-label="Color wheel"
        >
          {colors.slice(0, -6).map((color, index) => {
            const startAngle =
              (index / (colors.length - 6)) * 2 * Math.PI - Math.PI / 2;
            const endAngle =
              ((index + 1) / (colors.length - 6)) * 2 * Math.PI - Math.PI / 2;
            return createArc(startAngle, endAngle, color);
          })}
          <circle cx="50" cy="50" r="15" fill="white" />
        </svg>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          {colors.slice(-6).map((color, idx) => (
            <button
              key={idx + 6}
              style={{
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                backgroundColor: color.hex,
                border: selectedColors.includes(color.name)
                  ? "2px solid black"
                  : "1px solid #323F4F",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => handleColorSelection(color.name)}
            />
          ))}
        </div>
        <div className="flex flex-col font-cinzel items-center">
          <div className="flex flex-col">
            {" "}
            <label style={{ color: "black", fontSize: "16px" }}>
              <input
                type="checkbox"
                checked={primaryColorsSelected}
                onChange={() => handleColorSelection("Rouge", true)}
                style={{ marginRight: "10px", height: "15px", width: "15px" }}
              />
              Couleurs Primaires
            </label>
            <label style={{ color: "black", fontSize: "16px" }}>
              <input
                type="checkbox"
                checked={secondaryColorsSelected}
                onChange={() => handleColorSelection("Vert", true)}
                style={{ marginRight: "10px", height: "15px", width: "15px" }}
              />
              Couleurs secondaires
            </label>
          </div>

          <FormControl variant="standard" sx={{ m: 1, width: 210 }}>
            <InputLabel id="luminosity-label">Luminosité</InputLabel>
            <Select
              labelId="luminosity-label"
              id="luminosity"
              multiple
              value={luminosity}
              onChange={(e) => setLuminosity(e.target.value)}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return "Toutes luminosités";
                }
                return selected.join(", ");
              }}
            >
              {Object.keys(luminosityScale).map((lumiKey) => (
                <MenuItem key={lumiKey} value={luminosityScale[lumiKey]}>
                  {lumiKey}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="standard" sx={{ m: 1, width: 210 }}>
            <InputLabel id="demo-simple-select-standard-label">
              Saturation
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              multiple
              value={saturation}
              onChange={(e) => setSaturation(e.target.value)}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return "Toutes saturations";
                }
                return selected.join(", ");
              }}
            >
              {Object.keys(saturationScale).map((satKey) => (
                <MenuItem key={satKey} value={saturationScale[satKey]}>
                  {satKey}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>

      <button
        onClick={handleSubmit}
        className="cursor-pointer text-lg rounded p-1 px-4 border-2 border-black h-fit"
      >
        {loading ? <CircularProgress size={20} color="inherit" /> : "Go"}
      </button>
    </div>
  );
}
