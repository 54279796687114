import React, { useEffect, useState } from "react";
import axios from "axios";
import { URLDayl, URLDaylImage } from "../services/constantes";
import SearchArtistBar from "../component/ArtistDiscoverPage/SearchArtistBar";
import AutoCompleteCustom from "../component/PaintingSearch/AutoCompleteCustom";
import ReplayIcon from "@mui/icons-material/Replay";
import { IconButton } from "@mui/material";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import BiographieElement from "../component/ArtistDetailPage/BiographieElement";
import { stringSplitting } from "../services/Utils";
import SmallGrid from "../component/general/ImageGrid/SmallGrid";
import { useNavigate } from "react-router";

function FontAwesomeSvgIcon(props) {
  return null;
}

FontAwesomeSvgIcon.propTypes = { icon: PropTypes.any };

export default function DiscoverArtist() {
  const [refreshKeyElementBiographie, setRefreshKeyElementBiographie] =
    useState(0);
  const [randomBio, setRandomBio] = useState();
  const [artistBio, setArtistBio] = useState();
  const [displayedArtist, setDisplayedArtist] = useState();
  const [displayedArtistPaintings, setDisplayedArtistPaintings] = useState([]);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSelect = (e, value) => {
    if (value) {
      setIsLoading(true);
      axios
        .get(URLDayl + `artist/${value.id}`)
        .then((response) => {
          setDisplayedArtist(response.data);
        })
        .catch((error) => {
          console.error("Error fetching artist data:", error);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const handleClick = (path, state = null) => {
    navigate(path, { state });
  };

  const handleInputChange = async (e, value) => {
    if (value.length > 3) {
      try {
        const res = await fetch(
          URLDayl + `artistSearch/findByPrefix?occurence=${value}`
        );
        const data = await res.json();
        const renamedData = data.map((item) => ({
          ...item,
          name: item.artist_name_tot,
        }));
        setOptions(renamedData);
      } catch (error) {
        console.error("Error in artist search:", error);
      }
    } else {
      setOptions([]);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(URLDayl + "artist/randomTopArtists?limit=6")
      .then((response) => {
        setDisplayedArtist(response.data[0]);
      })
      .catch((error) => {
        console.error("Error fetching random top artists:", error);
      })
      .finally(() => setIsLoading(false));
  }, [refreshKeyElementBiographie]);

  useEffect(() => {
    if (displayedArtist) {
      setIsLoading(true);
      axios
        .post(
          `${URLDayl}paintingSearch/findPaintingBySearchDtoOptimised?num=10`,
          { artistIdtf: displayedArtist.artistIdtfIntern, style: null }
        )
        .then((responseImages) => {
          setDisplayedArtistPaintings(responseImages.data.payload);
        })
        .catch((error) => {
          console.error("Error fetching artist paintings:", error);
        })
        .finally(() => setIsLoading(false));
    }
  }, [displayedArtist]);

  useEffect(() => {
    if (displayedArtist && displayedArtist.id) {
      setIsLoading(true);
      axios
        .get(`${URLDayl}artist/elementsBiographies/artist`, {
          params: {
            artistId: displayedArtist.id,
            language: "FRENCH",
          },
        })
        .then((res) => {
          setRandomBio(res.data[0]);
          return axios.get(URLDayl + `artist/${res.data[0].artistId}`);
        })
        .then((resArtist) => {
          setArtistBio(resArtist.data);
        })
        .catch((error) => {
          console.error("Error fetching artist biography:", error);
        })
        .finally(() => setIsLoading(false));
    }
  }, [displayedArtist]);

  return (
    <div className="" style={{}}>
      <h1
        style={{
          margin: "auto",
          textAlign: "center",
          marginBottom: 20,
          marginTop: 50,
          textTransform: "uppercase",
          fontWeight: "",
          fontSize: 30,
          color: "black",
          fontFamily: "Cinzel",
        }}
      >
        DES ARTISTES « REFERENCE » - SELECT YOUR SEARCH CRITERIAS{" "}
      </h1>

      <div className={""} style={{ marginBottom: 0 }} color={"black"}>
        <SearchArtistBar />
      </div>
      <div className="flex gap-5 text-2xl ml-10 align-middle items-center mb-5 font-raleway font-bold">
        <div>RECHERCHEZ UN ARTISTE</div>
        <AutoCompleteCustom
          className={"thisisMyClass"}
          options={options}
          defaultValue={null}
          onSelect={handleSelect}
          onChange={handleInputChange}
          placeholder="Nom de l'artiste"
        />
      </div>

      {isLoading && <div>Loading...</div>}

      {displayedArtist && (
        <div className="flex w-full bg-black text-white text-3xl justify-evenly p-10 mt-5">
          <div className="flex flex-col justify-center align-middle items-center gap-5">
            <div className="flex items-center">
              <div
                className="underlinedButtonW text-white cursor-pointer"
                onClick={() =>
                  handleClick(
                    `/view/ArtistDetailPage.js/${displayedArtist.id}`,
                    displayedArtist.id
                  )
                }
              >
                {displayedArtist.artist_name_tot}{" "}
              </div>
              <IconButton
                aria-label="Example"
                onClick={() =>
                  setRefreshKeyElementBiographie(
                    refreshKeyElementBiographie + 1
                  )
                }
              >
                <ReplayIcon style={{ color: "white", fontSize: 30 }} />
              </IconButton>
            </div>
            <img
              src={`${URLDayl}images/artistPic/${displayedArtist.id}`}
              alt={`${URLDayl}'s artwork`}
              className="max-h-[70vh] max-w-[45vw] w-fit"
            />
          </div>
          <div>
            <SmallGrid
              paintingsList={displayedArtistPaintings}
              customSize={"23vh"}
            />
          </div>
        </div>
      )}
      <div
        className={"w-11/12 my-auto mb-10"}
        style={{ paddingTop: 20, paddingBottom: 20 }}
      >
        <div className="flex items-center gap-5 w-full ml-10 mt-10 mb-10">
          <div className="text-2xl font-raleway  font-bold uppercase">
            {t("Elements de biographie")} :
          </div>
          <div className="ml-10 text-2xl font-heavy">
            {artistBio && artistBio.artist_name_tot}
          </div>
        </div>
        {randomBio && (
          <BiographieElement
            text={stringSplitting(950, randomBio.content)}
            title={randomBio.title}
            imageList={randomBio.taggedArtworks.map(
              (el) => URLDaylImage + `images/paintingPic/${el}`
            )}
          />
        )}
      </div>
    </div>
  );
}
