import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { URLDayl } from "../../services/constantes"
import MenuAtelier from "./MenuAtelier";


/**
 * Page to update your Biography
 * @param {object} user - user object from api
 * @param {object} setUser - user setter
 *  @param {boolean} isLogged - test if the user is logged
 */
export default function Biography({ user, setUser, isLogged }) {


    return (

        <div className="Biography">
            <MenuAtelier user={user} aaaaaaaaaa isLogged={isLogged} setUser={setUser} />

            <main className="workShopMain">

                <h2>Ma biographie</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis accumsan vehicula odio, tempus convallis eros euismod sed. Donec ultricies quam vulputate commodo suscipit.</p>

                {/* <!-- SECTION A PROPOS DE MOI - début --> */}
                <section className="mt-c">
                    <form className="formCompact" method="post" action="">
                        <h3 className="mt-a">A propos de moi</h3>
                        <div className="inputInline">
                            <input type="text" size="55" placeholder="Nom" required />
                            <input type="text" size="55" placeholder="Prénom" required />
                        </div>
                        <input type="text" size="55" placeholder="Nom d'artiste" required />
                        <div className="inputInline">
                            <input type="text" size="55" placeholder="Ville d'origine" required />
                            <input type="text" size="55" placeholder="Pays d'origine" required />
                        </div>
                        <div className="inputInline">
                            <input type="radio" name="radio" value="radio" />
                            <label for="radio">Femme</label>
                            <input type="radio" name="radio" value="radio" />
                            <label for="radio">Homme</label>
                        </div>
                        <h3 className="mt-c">Présentation de mon parcours</h3>
                        <textarea name="message" rows="5" cols="33" placeholder="Votre message"></textarea>
                        <h3 className="mt-c">Descriptif de mon travail</h3>
                        <textarea name="message" rows="5" cols="33" placeholder="Votre message"></textarea>
                        <h3 className="mt-c">Ajouter des mots clés ( séparés par des virgules)</h3>
                        <input type="text" size="55" placeholder="Ex : paysage, lune, …" required />
                        <div className="tagList toLeft">
                            <a href="#">Mot-clé</a>
                            <a href="#">Mot-clé</a>
                            <a href="#">Mot-clé</a>
                            <a href="#">Mot-clé</a>
                            <a href="#">Mot-clé</a>
                            <a href="#">Mot-clé</a>
                            <a href="#">Mot-clé</a>
                            <a href="#">Mot-clé</a>
                        </div>
                        <h3 className="mt-c">Ma bannière pro</h3>
                        <div className="proBanner" style={{ backgroundImage: "url('temp/leonora_more_1.jpg')", backgroundPosition: "50% center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                            {/* <!-- ///////////////////////////////////
					Modal configuration de l'arrière-plan - début
					//////////////////////////////////// --> */}
                            <a className="openModalNow topAccountBg" data-target="topAccountBg" rel="nofollow">
                                <img src="img/icons/ico_pen_1.svg" alt="Modifier l'arrière-plan" />
                            </a>

                            <div id="topAccountBg" className="smart-modal-content scrollAuto" style={{ width: "340px" }}>
                                <h3>Modifier l'arrière-plan</h3>
                                <a class="smart-modal-close"><img class="img-left" src="img/icons/ico_close_2.svg" alt="Fermer" /></a>

                                <p class="fileRestriction mt-b mb-a">Image au format .jpg ou .png<br />2Mo maximum</p>

                                <div class="fileUploadInput" data-text="Sélectionnez un fichier">
                                    <input name="fileUploadField" type="file" class="fileUploadField" value="" />
                                </div>

                            </div>
                            {/* <!-- ///////////////////////////////////
					Modal configuration de l'arrière-plan - fin
					//////////////////////////////////// --> */}
                        </div>
                        <h3 class="mt-c">Mes réseaux sociaux pro</h3>
                        <div class="inputInline">
                            <input type="text" size="55" placeholder="Site internet" required />
                            <input type="text" size="55" placeholder="Page Facebook" required />
                        </div>
                        <div class="inputInline">
                            <input type="text" size="55" placeholder="Page Instagram" required />
                            <input type="text" size="55" placeholder="Page Pinterest" required />
                        </div>
                        <div class="inputInline">
                            <input type="text" size="55" placeholder="Page Twitter" required />
                            <input type="text" size="55" placeholder="Page LinkedIn" required />
                        </div>
                        <div class="inputInline">
                            <div>
                                <input type="reset" class="saveDraftBtn mt-a" value="Sauvegarder comme brouillon" />
                            </div>
                            <div>
                                <input type="submit" class="submitFormBtn mt-a" value="Enregistrer" />
                            </div>
                        </div>

                    </form>


                </section>
                {/* <!-- SECTION A PROPOS DE MOI - fin --> */}
            </main>
        </div>
    );
}