import React, { useRef } from "react";
import Slider from "react-slick";
import { IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SearchOutlined from "@mui/icons-material/SearchOutlined";
import MatchingPreview from "./MatchingPreview";
import SearchColor from "./SearchColor";
import SimpleRequests from "../Colorimetrie/pocketAnalyse";
import { URLDaylImage } from "../../services/constantes";

export default function HeroCarousel({
  user,
  isLogged,
  setUser,
  paintIdMatch,
  handleClick,
}) {
  const sliderRef = useRef(null);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 7000,
  };
  return (
    <div className="w-full flex flex-col">
      <div className="w-full flex justify-evenly items-center p-4 font-raleway bg-gray-200">
        <IconButton onClick={() => sliderRef.current.slickPrev()}>
          <ArrowBackIosIcon />
        </IconButton>
        <p className="text-3xl">Jouez avec nos outils</p>
        <IconButton onClick={() => sliderRef.current.slickNext()}>
          <ArrowForwardIosIcon />
        </IconButton>
      </div>

      <Slider {...settings} ref={sliderRef}>
        <section className="w-full flex slide" data-aos="fade-right">
          <div
            className="flex flex-col justify-center items-left w-1/4 gap-3 p-14 cursor-pointer hover-effect-div-left h-full"
            onClick={() =>
              handleClick("view/MatchingColor.js", {
                hex: "#000000",
                rgb: {
                  r: 0,
                  g: 0,
                  b: 0,
                },
                hsv: {
                  h: 0.6593406593406593,
                  s: 0,
                  v: 0,
                },
              })
            }
          >
            <div className="flex flex-col">
              <div className="text-4xl">COLOR SEARCH</div>
              <p className="text-2xl">1728 ESPACES COLORIMETRIQUES</p>{" "}
              <p className="text-lg">
                Déplacez la palette
                <br />
                Explorez des centaines de peintures proches
              </p>
              <div className="flex flex-row items-center gap-2">
                <SearchOutlined /> <p className="text-2xl">SEE MORE</p>
              </div>
            </div>
          </div>
          <div className="w-3/4 h-full">
            <SearchColor user={user} isLogged={isLogged} setUser={setUser} />
          </div>
        </section>
        <section className="w-full flex slide" data-aos="fade-right">
          <div
            className="flex flex-col justify-center items-left w-1/3 gap-3 p-14 hover-effect-div-left h-full"
            onClick={() => handleClick("view/CustomRequests/15816", 15816)}
          >
            <div className="text-4xl">DAYL-CHROMA</div>
            <p className="text-lg">
              <br />
              UN OUTIL UNIQUE <br />
              Découvrez l'agencement des teintes, saturations et luminosités sur
              une œuvre
            </p>
            <p className="text-2xl cursor-pointer mt-8">
              <SearchOutlined />
              GO TO DAYL-CHROMA
            </p>
          </div>
          <div className="w-2/3 bg-black h-full flex justify-center">
            <SimpleRequests />
          </div>
        </section>
        <section className="w-full flex slide" data-aos="fade-left">
          <div className="w-3/4 flex justify-center align-middle items-center bg-black h-full">
            <MatchingPreview paintId={paintIdMatch} />

            <div>
              <img
                src={`${URLDaylImage}images/paintingPic/${paintIdMatch}`}
                alt="homeImagge"
                className="max-h-96 w-auto pr-5"
              />
            </div>
          </div>
          <div
            className="flex flex-col justify-center items-left gap-3 p-14 w-1/3 hover-effect-div h-full"
            onClick={() =>
              handleClick(`../../view/MatchingPage.js/${paintIdMatch}`)
            }
          >
            <div className="text-4xl">MATCHEZ UNE PEINTURE</div>
            <div className="text-lg">
              Lancez un match sur sur toutes les peintures et décourvrez des
              centaines de peintures proches <br />{" "}
              Style/Formes/Couleurs/Techniques
            </div>
            <p className="text-2xl cursor-pointer ">
              <SearchOutlined />
              GO MATCH
            </p>
          </div>
        </section>
      </Slider>
    </div>
  );
}
