import React, { useEffect, useState } from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { Tooltip } from "@material-ui/core";
import ReviewsIcon from "@mui/icons-material/Reviews";
import axios from "axios";
import { URLDayl } from "../../services/constantes";
import { Link } from "react-router-dom";
import { accordion } from "../../webapp/js/js_source/accordion";
import ListModal2 from "../general/ListModal2";
import { toast } from "react-toastify";
import modaledit from "../../webapp/img/icons/modaledit.svg";
import right from "../../webapp/img/icons/ico_viewItemPage_2.svg";
import { useNavigate } from "react-router";
import ModalCommentaire from "./ModalCommentaire";
import { useUser } from "../../contexts/userService";
import ModalEditPainting from "../CompteExpert/ModalEditPainting";
import Lightbox from "../general/Lightbox/Lightbox";
import { Button, IconButton, Typography } from "@mui/material";
import OutlinedFlagIcon from "@mui/icons-material/OutlinedFlag";
import SignalModal from "../general/SignalModal";
import { Download } from "@mui/icons-material";
import ReplayIcon from "@mui/icons-material/Replay";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ListModal from "../general/ListModal";
import BoxCloud from "../ArtistDetailPage/BoxCloud";
import AgentGPTHeader from "../../component/general/AgentGpt/AgentGPTHeader";
import {
  GeneralQuestionsAboutArt,
  PrefixQuestionsAboutPeinture,
} from "../../services/QuestionsTemplate";
import getRandomElements, {
  PrefixQuestionsPeintureArtiste,
} from "../general/AgentGpt/utils";
import CommentsModal from "./CommentsModal";
import { useTranslation } from "react-i18next";

{
  /** For the agent gpt */
}

/**
 * This component display informations about a specific painting
 * @param {object} user - user object from api
 * @param {object} setUser - user setter
 * @param {boolean} isLogged - test if the user is logged
 * @param {number} paintId - selected paint Id
 * @emits - paintingEntity/${paintId} : get painting informations
 * @emits - paintingEntity/numberFavorite/${paintId} : get number of likes
 * @emits - api/painting_comment/save : post new comment
 * @emits - users/updateFavoritePainting : like/unlike painting
 */

export default function Painting({ user, setUser, paintId, isLogged }) {
  const [isLoading, setIsLoading] = useState(false);
  const [Painting, setPainting] = useState();
  const [zoomBool, setZoomBool] = useState(false);
  const [boolFav, setBoolFav] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [showKeyword, setShowKeyword] = useState(false);
  const [nLike, setNLike] = useState(0);
  const [boolAccordion, setBoolAccordion] = useState(false);
  const [keywordsSplit, setKeywordsSplit] = useState([]);
  const navigate = useNavigate();
  const [editModal, setEditModal] = useState(false);
  const [index, setIndex] = useState();
  const [signalModal, setSignalModal] = useState(false);
  const [hdAvailable, setHdAvailable] = useState(false);
  const [openComments, setOpenComments] = useState(false);
  const [paintingComments, setPaintingComments] = useState([]);
  const { i18n } = useTranslation();

  const auth = useUser();

  useEffect(() => {
    // Painting && setKeywordsSplit(Painting.aw_keyw_import.split(','))
  }, []);

  accordion();

  const postComment = () => {
    if (auth.data) {
      axios
        .post(URLDayl + "publication/save", {
          content: commentText,
          userId: auth.data.id,
          publicationStatus: "PRIVATE",
          rePublicationId: 0,
          taggedPainting: paintId,
          publicationType: "PAINTING",
        })
        .then((res) => {
          setCommentText("");
          toast.success("Posted", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } else {
      toast.error("Please log in.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  function handleAccordion() {
    if (boolAccordion === true) {
      setBoolAccordion(false);
    } else {
      setBoolAccordion(true);
    }
  }

  useEffect(() => {}, [Painting]);

  useEffect(() => {
    axios
      .get(URLDayl + `images/paintingPic/existsHD/${paintId}`)
      .then((res) => {
        setHdAvailable(res.data);
      });
  }, [paintId]);

  function removeDuplicates(arr) {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  }

  // const onClickDownload = () => {
  //     axios.get(URLDayl + `images/paintingPic/downloadHD/${paintId}`, {responseType: "stream"}).then((res) => {
  //         toast.success("Download has started", {
  //             position: "top-right",
  //             autoClose: 3000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //         });
  //     })
  // }

  const [boxes, setBoxes] = useState([]);
  //get Painting info//
  useEffect(() => {
    axios
      .get(URLDayl + `paintingEntity/${paintId}`)
      .then((response) => {
        setIsLoading(true);
        setPainting(response.data);
        setBoxes(
          response.data.aw_keyw_import.split(",").filter((el) => el !== "")
        );

        const tempResponse = response.data;
        //retrive keywords from string to array

        tempResponse.boolList = false;
        // tempResponse.aw_keyw_ai = tempResponse.aw_keyw_ai.slice(1, -1)

        // (response.data.aw_keyw_ai || response.data.aw_keyw_import) && setKeywordsSplit(response.data.aw_keyw_ai.split(",") + response.data.aw_keyw_import.split(","))
        if (response.data.aw_keyw_import) {
          setKeywordsSplit(response.data.aw_keyw_import.split(","));
        }
        if (response.data.aw_keyw_ai)
          setKeywordsSplit(keywordsSplit + response.data.aw_keyw_ai.split(","));
        // setKeywordsSplit(removeDuplicates(keywordsSplit))

        setPainting(tempResponse);
        setShowKeyword(true);
        //Get number like
        axios
          .get(URLDayl + `paintingEntity/numberFavorite/${paintId}`)
          .then((response2) => {
            setNLike(response2.data);
          });
      })
      .catch((err) => {
        console.log(err);
        console.log("PAINTING");
      });

    //Test painting liké par l'utilisateur
    if (user)
      user.paintingLiked.map((like) => {
        paintId === like && setBoolFav(true);
      });
  }, [paintId]);

  useEffect(() => {
    axios
      .get(URLDayl + `publication/byTaggedPainting`, {
        params: {
          taggedPainting: paintId,
        },
      })
      .then((response) => {
        setPaintingComments(response.data);
      });
  }, [paintId]);

  useEffect(() => {
    setBoolAccordion(false);
    setKeywordsSplit([]);
  }, [paintId]);

  ////////Add fav////////
  function handleClick() {
    const tempUser = user;
    if (auth.data) {
      if (boolFav === true) {
        setBoolFav(false);
        //delete a favorite painting
        axios
          .post(
            URLDayl + "users/updateFavoritePainting",
            {
              userId: user.id,
              favoriteId: paintId,
              operation: "remove",
            },
            {
              headers: {
                "Access- Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                USER_PAINT: 0,
              },
            }
          )
          .then((response) => {
            const removeIndex = tempUser.paintingLiked.indexOf(paintId);
            tempUser.paintingLiked.splice(removeIndex, 1);
            setUser(tempUser);
            updateLike();
            toast.success("Supprimée des favoris!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      } else {
        setBoolFav(true);
        //add a favorite painting
        axios
          .post(
            URLDayl + "users/updateFavoritePainting",
            {
              userId: user.id,
              favoriteId: paintId,
              operation: "add",
            },
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                USER_PAINT: 0,
              },
            }
          )
          .then((response) => {
            tempUser.paintingLiked.push(paintId);
            setUser(tempUser);
            updateLike();
            toast.success("Ajoutée aux favoris!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      }
    } else {
      toast.error("Please log in!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  function updateLike() {
    //update Nombres de like//
    axios
      .get(URLDayl + `paintingEntity/numberFavorite/${paintId}`)
      .then((response) => {
        setNLike(response.data);
      });
  }

  // Call Jquery script
  useEffect(() => {
    const loadFile = async () => {
      // const { modal } = await import("../../webapp/js/modalBox.min.js");
      // modal();
    };
    loadFile();
  }, []);

  //afficher image zoom//

  //post comment//

  const PrefixQuestions = (questions, artiste) => {
    const data = questions.map((question, index) => {
      return question.replace(/{{artiste}}/g, "'" + artiste + "'");
    });
    return data;
  };

  return Painting ? (
    <div>
      <div className="Painting">
        {/* <!-- ******************************************************
			SECTION PRÉSENTATION DE L'OEUVRE - début
			****************************************************** --> */}

        <section
          className="center-col cont-sty-1"
          style={{ paddingBottom: "0px" }}
        >
          <ol className="topBc" style={{ marginTop: "20px" }}>
            <li>
              <Link to="../../" style={{ color: "black" }}>
                Accueil
              </Link>
            </li>
            <li className="#">
              <Link
                to={`../../view/ArtistDetailPage.js/${Painting.artist.id}`}
                state={Painting.artist.id}
                href="artiste.html"
                style={{ color: "black" }}
              >
                {Painting.artist.artist_name_tot}
              </Link>
            </li>
            <li className="#">{Painting.aw_title_origin}</li>
          </ol>

          <div className="bg-black w-full">
            <div className="flex gap-10 items-center w-full">
              <div className=" flex justify-center w-2/3">
                <img
                  src={`${URLDayl}images/paintingPic/${paintId}`}
                  alt="painting"
                  className={`max-h-[55vh] max-w-[50vw] w-auto cursor-pointer ${
                    Painting.artist.hideArtist ? "blur-lg" : ""
                  }`}
                  onClick={() => {
                    setIndex(0);
                    setZoomBool(!zoomBool);
                  }}
                ></img>

                {zoomBool === true && !Painting.artist.hideArtist && (
                  <Lightbox
                    user={user}
                    isLogged={isLogged}
                    hideTools={true}
                    matchList={[Painting]}
                    index={index}
                    setIndex={setIndex}
                    title={""}
                  />
                )}
              </div>

              {/* H1 - H2 - BTN - début */}

              <div className="flex flex-wrap text-white w-1/3">
                <div>
                  <div className="text-3xl">
                    {i18n.language === "fr" && Painting.aw_title_fr
                      ? Painting.aw_title_fr
                      : Painting.aw_title_origin}{" "}
                    - {Painting.aw_year}
                  </div>
                  <div className="text-2xl">
                    <Link
                      to={`../../view/ArtistDetailPage.js/${Painting.artist.id}`}
                      state={Painting.artist.id}
                      href="artiste.html"
                    >
                      {Painting.artist.artist_name_tot !== ""
                        ? Painting.artist.artist_name_tot
                        : "ARTIST"}
                    </Link>
                  </div>
                  <ul style={{ marginTop: "30px" }}>
                    <li className="selectLine" style={{ marginTop: "8px" }}>
                      <IconButton onClick={() => handleClick()} value={nLike}>
                        {boolFav ? (
                          <FavoriteIcon style={{ color: "white" }} />
                        ) : (
                          <FavoriteBorderIcon style={{ color: "white" }} />
                        )}
                      </IconButton>
                      {user && (
                        <ListModal2
                          user={user}
                          isLogged={isLogged}
                          listPaint={Painting}
                          tooltip={false}
                          styleButton={{
                            color: "white",
                            zIndex: "3",
                            fontSize: "30px",
                          }}
                        />
                      )}

                      {/* Commenter cette oeuvre */}

                      <Tooltip title="Signaler l'oeuvre">
                        <IconButton
                          aria-label="Example"
                          onClick={() => setSignalModal(!signalModal)}
                        >
                          <OutlinedFlagIcon
                            style={{ color: "white", fontSize: 24 }}
                          />
                        </IconButton>
                      </Tooltip>

                      {auth.data && auth.data.userType === "expert" && (
                        <Tooltip title="Modifier l'oeuvre">
                          <IconButton
                            aria-label="Edit artwork"
                            onClick={() => setEditModal(!editModal)}
                          >
                            <EditOutlinedIcon
                              style={{ color: "white", fontSize: 24 }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}

                      <Tooltip title="Source de l'oeuvre">
                        <IconButton
                          aria-label="External link"
                          component="a"
                          href={Painting.aw_link_ext}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <OpenInNewOutlinedIcon
                            style={{ color: "white", fontSize: 22 }}
                          />
                        </IconButton>
                      </Tooltip>

                      {hdAvailable && (
                        <a
                          href={
                            URLDayl + `images/paintingPic/downloadHD/${paintId}`
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <IconButton aria-label="Example">
                            <FileDownloadOutlinedIcon
                              style={{ color: "white", fontSize: 24 }}
                            ></FileDownloadOutlinedIcon>
                          </IconButton>
                        </a>
                      )}

                      {auth && auth.data && (
                        <AgentGPTHeader
                          data={
                            "Sur la peinture : " +
                            Painting.aw_title_origin +
                            " et le peintre " +
                            Painting.artist.artist_name_tot +
                            ", "
                          }
                          //questions={GeneralQuestionsAboutArt}
                          questions={PrefixQuestionsPeintureArtiste(
                            PrefixQuestionsAboutPeinture,
                            Painting.aw_title_origin,
                            Painting?.artist?.artist_name_tot
                          )}
                          nb={5}
                          text={true}
                        />
                      )}
                    </li>

                    <div className="flex gap-5 items-center">
                      <textarea
                        onChange={(e) => {
                          setCommentText(e.target.value);
                        }}
                        className="bg-black text-white border-2 border-solid  rounded-lg resize-none w-96 h-30 p-3 mt-4"
                        placeholder="Laissez un commentaire que vous retrouverez sur votre Profil via un POST. Publiez le pour partager votre commentaire.
"
                      ></textarea>

                      <div className="flex flex-col gap-3">
                        {commentText.length > 0 && (
                          <button
                            className="px-4 py-2 bg-slate-800 text-white rounded-md hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-slate-600 focus:ring-opacity-50 transition duration-150 ease-in-out"
                            onClick={() => {
                              postComment();
                            }}
                          >
                            Post
                          </button>
                        )}
                        {paintingComments.length > 0 && (
                          <button
                            className="px-4 py-2 bg-slate-800 text-white rounded-md hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-slate-600 focus:ring-opacity-50 transition duration-150 ease-in-out"
                            onClick={() => {
                              setOpenComments(true);
                            }}
                          >
                            View
                          </button>
                        )}
                      </div>
                    </div>

                    <Link
                      className="w-fit mx-auto"
                      to={`../../view/MatchingPage.js/${paintId}`}
                    >
                      <div className="mt-5 rounded-lg  w-fit py-2 px-4 text-base font-bold bg-red-600 hover:bg-red-500">
                        Match
                      </div>
                    </Link>
                  </ul>
                </div>
              </div>

              {/* H1 - H2 - BTN - fin */}

              {/*<<<<<<< HEAD*/}
              {/* <!-- Lightbox image avec fonction agrandir/réduire - fin --> */}
            </div>
          </div>

          {/* Oeuvre (image - titre - artiste - btn match - btn commentaire - btn like - btn listes) - fin */}
        </section>
        {/* <!-- SECTION PRÉSENTATION DE L'OEUVRE - fin --> */}

        <div>
          {/*
					<!-- ******************************************************
					SECTION DONNÉES TECHNIQUES DE L'OEUVRE ET MOTS-CLES - début
					****************************************************** --> */}

          {/* <!-- SECTION PRÉSENTATION DE L'OEUVRE - fin --> */}

          {/* {Painting.length > 0 &&
							Painting.keywords.map((keyword) => (<a href="/#">{keyword}</a>))} */}
        </div>

        {/* {Painting.length > 0 &&
							Painting.keywords.map((keyword) => (<a href="/#">{keyword}</a>))} */}
      </div>
      {paintingComments.length > 0 && (
        <CommentsModal
          open={openComments}
          handleClose={() => setOpenComments(false)}
          comments={paintingComments}
        />
      )}{" "}
      {editModal && (
        <ModalEditPainting
          paintId={paintId}
          user={user}
          boolModal={editModal}
          setBoolModal={setEditModal}
        />
      )}
      {signalModal && (
        <SignalModal
          user={user}
          boolModal={signalModal}
          setBoolModal={setSignalModal}
          paintId={Painting.aw_picture_idtf}
        />
      )}
    </div>
  ) : (
    <></>
  );
}
