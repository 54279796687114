import React, { useEffect, useState } from "react";
import ProfileTab from "./ProfileTab";
import FeedTab from "./FeedTab";
import FavoritesTab from "./FavoritesTab";

import newprofile from "../../../webapp/img/icons/newprofile.svg";
import newfeed from "../../../webapp/img/icons/newfeed.svg";
import newheart from "../../../webapp/img/icons/newheart.svg";
import newlists from "../../../webapp/img/icons/newlists.svg";
import Messages from "./Messages";
import newmessages from "../../../webapp/img/icons/newmessages.svg";
import { Affix } from "antd";
import Lists from "../Lists";
import { useUser } from "../../../contexts/userService";
import { useLocation, useNavigate } from "react-router-dom";
import AutoCompleteCustom from "../../PaintingSearch/AutoCompleteCustom";
import axios from "axios";
import { URLDayl } from "../../../services/constantes";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import useWindowScroll from "@react-hook/window-scroll";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  userSearchBar: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#EFF3F4",
      borderRadius: 100,
      border: "none",
      width: "210px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "5px 12px",
      border: "none",
      width: "200px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiOutlinedInput-notchedOutline::focus": {
      border: "1px",
    },
    "& .Mui-focused": {
      backgroundColor: "white",
      border: "1px solid #d9d9d9",
    },
  },
});

function Social({ friend }) {
  const { t } = useTranslation();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(
    location.state === null
      ? !friend
        ? t("Social_Feed")
        : t("Social_Feed")
      : location.state
  );
  const navigate = useNavigate();
  const [userSearch, setUserSearch] = useState([]);
  const [hashtagSearch, setHashtagSearch] = useState([]);
  const [searchedTextHashtag, setSearchedTextHashtag] = useState("");
  const auth = useUser();

  const handleClick = (path) => {
    navigate(path);
  };

  useEffect(() => {
    const a = sessionStorage.getItem("tabName");

    if (a) setActiveTab(a);
  }, []);

  const handleMenuClick = (tabName) => {
    setActiveTab(tabName);
    sessionStorage.setItem("tabName", tabName);
    window.scrollTo(0, 0);
  };
  const TAB_KEYS = {
    PROFILE: "Profile",
    FEED: "Feed",
    FAVORITES: "Favorites",
    LISTS: "Lists",
    MESSAGES: "Messages",
  };

  const getTranslatedTabNames = (t) => ({
    [TAB_KEYS.PROFILE]: t("Social_Profil"),
    [TAB_KEYS.FAVORITES]: t("Social_Favourites"),
    [TAB_KEYS.LISTS]: t("Social_Lists"),
    [TAB_KEYS.FEED]: t("Social_Feed"),
    [TAB_KEYS.MESSAGES]: t("Social_Messages"),
  });

  const translatedTabNames = getTranslatedTabNames(t);

  let menuItems = [
    {
      tabKey: TAB_KEYS.PROFILE,
      icon: newprofile,
      tabName: translatedTabNames[TAB_KEYS.PROFILE],
    },
    {
      tabKey: TAB_KEYS.FAVORITES,
      icon: newheart,
      tabName: translatedTabNames[TAB_KEYS.FAVORITES],
    },
    {
      tabKey: TAB_KEYS.LISTS,
      icon: newlists,
      tabName: translatedTabNames[TAB_KEYS.LISTS],
    },
  ];

  if (!friend) {
    menuItems = [
      ...menuItems,
      {
        tabKey: TAB_KEYS.FEED,
        icon: newfeed,
        tabName: translatedTabNames[TAB_KEYS.FEED],
      },
    ];
  }

  const renderTabContent = () => {
    const tabComponents = {
      [TAB_KEYS.PROFILE]: <ProfileTab friend={friend} />,
      [TAB_KEYS.FEED]: <FeedTab />,
      [TAB_KEYS.FAVORITES]: <FavoritesTab friend={friend} />,
      [TAB_KEYS.LISTS]: <Lists user={friend || auth.data} friend={friend} />,
    };

    return tabComponents[activeTab] || <ProfileTab />;
  };

  const onChangeUserInput = async (e, searchedtext) => {
    setUserSearch([]);
    if (searchedtext) {
      const response = await axios.post(URLDayl + "users/searchUsersByText", {
        freeText: searchedtext,
      });
      const data = response.data.map((item) => ({
        name: item.firstName + " " + item.lastName,
        id: item.id,
      }));
      setUserSearch(data);
    }
  };

  const onChangeHashtagInput = async (e, searchedtext) => {
    setHashtagSearch([]);
    if (searchedtext) {
      const response = await axios.get(
        URLDayl +
          `publication/searchPrefixByHashtag?prefix=%23${searchedTextHashtag}`
      );
      const data = response.data.map((item, index) => ({
        name: item,
        id: index,
      }));
      setHashtagSearch(data);
    }
  };

  const onSelectUserInput = (e, item) => {
    navigate(`../../view/ProfileAmateur.js/${item.id}`);
  };

  const onSelectHashtagInput = (e, item) => {
    navigate(`../../view/SearchByHashtag.js/${item.name.substring(1)}`);
  };

  const classes = useStyles();

  return (
    <div>
      <div style={{ zIndex: 9 }} className="socialLayout">
        <Affix offsetTop={60}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              marginTop: "50px",
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <AutoCompleteCustom
                label={false}
                className={classes.userSearchBar}
                onSelect={onSelectUserInput}
                onChange={onChangeUserInput}
                options={userSearch}
                placeholder="Search users"
              />
              {/* <AutoCompleteCustom
                label={false}
                className={classes.userSearchBar}
                onSelect={onSelectHashtagInput}
                onChange={onChangeHashtagInput}
                options={hashtagSearch}
                placeholder="Search hashtags"
              /> */}
            </div>
            {friend && (
              <Button
                variant=""
                color={"primary"}
                sx={{
                  border: "none",
                  borderBottom: "2px solid",
                  borderColor: "primary.main",
                  borderRadius: "0px",
                }}
                onClick={() => {
                  navigate("../../view/compteAmateurPage.js");
                }}
              >
                Revenir sur mon compte
              </Button>
            )}
            <div className="socialMenu">
              {menuItems.map((menuItem) => (
                <div
                  key={menuItem.tabKey}
                  className={`menuItem ${
                    activeTab === menuItem.tabKey ? "active" : ""
                  }`}
                  onClick={() => handleMenuClick(menuItem.tabKey)}
                >
                  {menuItem.icon && <img src={menuItem.icon} alt="Logo" />}
                  <p>{menuItem.tabName}</p>
                </div>
              ))}
            </div>

            {!friend && auth.data && auth.data.userType === "expert" && (
              <button
                className="ExpertButton"
                onClick={() => handleClick("/view/CompteExpertPage.js/1")}
              >
                Compte Expert
              </button>
            )}
          </div>
        </Affix>
        <div className="socialTabs">{renderTabContent()}</div>
      </div>
    </div>
  );
}

export default Social;
