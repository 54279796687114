import React, {useCallback, useEffect, useRef, useState} from "react";
import axios from "axios";
import {URLDayl} from "../../services/constantes"
import "@mickyballadelli/react-tenor17/dist/styles.css"
import SockJsClient from 'react-stomp'
import {TalkBox} from "react-talk";






/**
 * Chat modal : used for chatting with someone
 * @param {object} user - user object from api
 * @param {object} setUser - user setter
 *  @param {boolean} isLogged - test if the user is logged
 *
 */

export default function ChatModal({user, setUser, isLogged, friend}) {
    const ref = useRef()
    const [boolDisplay, setBoolDisplay] = useState(false);
    const [boolEmoji, setBoolEmoji] = useState(false);
    const [txtArea, setTxtArea] = useState([]);
    const clientRef = useRef()
    const [jwt, setJwt] = useState(localStorage.getItem('jwt'))
    const [friendData, setFriendData] = useState()
    const [messages, setMessages] = useState([])

    useEffect(() => {
        axios.get(URLDayl + `users/${friend}`).then((response) => {
            setFriendData(response.data)
        })
    }, [friend])

    function handleVisible() {
        if (boolDisplay === false) {
            setBoolDisplay(true);
        } else {
            setBoolDisplay(false);
        }
        console.log(boolDisplay)

    }

    function handleVisibleEmoji() {
        if (boolEmoji === false) {
            setBoolEmoji(true);
        } else {
            setBoolEmoji(false);
        }
        console.log(boolEmoji)

    }

    const sendMessage = (msg, selfMsg) => {
        // selfMsg is the message object constructed from the message typed by the current user
        // NOTE: selfMsg doesn't include timestamp and needs to be added by the user of the module
        // in client or server side as required
        console.log(friend)
        selfMsg["timestamp"] = Date.now().toString();
        setMessages(messages => ([...messages, selfMsg]));
        clientRef.current.sendMessage('/app/directMessage', JSON.stringify({
                "recipient": friend,
                "content": msg,
                "emoji": ":-))",
                "priority": "HIGH"
            }
        ))
        return true


    }

    const latestMessages = useCallback(() => {
        clientRef.current.sendMessage('/app/lastestMessageWithUser', JSON.stringify({"recipientId": friend}))
    }, [friend])



    return (
        friend && <div className="ChatModal">
            <SockJsClient url={URLDayl + 'chat'}
                          onMessage={(message, topic) => {
                              if (topic === "/users/queue/conversation") {
                                  const temp = message.map((mess) => {
                                      console.log(mess)
                                      return {
                                          authorId: mess.author.toString(),
                                          author: ' ',
                                          message: mess.content,
                                          timestamp: mess.creation,

                                      }
                                  })
                                  setMessages(temp.length > 0 ? temp : [])
                                  console.log(temp)

                              } else {
                                  setMessages(messages => [...messages, {

                                      authorId: message.author.toString(),
                                      author: message.author === user.id ? user.firstName + ' ' + user.lastName : friendData.firstName + ' ' + friendData.lastName,
                                      message: message.content,
                                      timestamp: message.creation,

                                      // _id: message.uuid,
                                      // createdAt: message.creation,
                                      // text: message.content,
                                      // user: {
                                      //     _id: userId,
                                      //     avatar: URL + `images/contentUser/${user.id}/${user.iconImage}`,
                                      //     name: user.firstName + ' ' + user.lastName
                                      // }
                                  }])
                              }
                          }}

                          topics={['/users/queue/notification', '/users/queue/conversation']}
                          ref={(client) => {
                              clientRef.current = client
                          }}

                          onConnect={() => {
                              console.log("CONNECTED")
                              latestMessages()
                          }}

                          headers={
                              {
                                  Bearer: jwt,
                              }
                          }
                          debug/>


            <div className="chatBox">
                {friendData && <TalkBox style={{width: '100%',}} topic={friendData ? friendData.firstName +
                    ' ' + friendData.lastName : "UNKNOWN"} currentUserId={user.id.toString()}
                                        currentUser={user.firstName +
                                            ' ' + user.lastName}
                                        messages={messages} onSendMessage={sendMessage}/>}
                {/*    <header>*/}
                {/*        <a href="">*/}
                {/*            <img src={photo} alt="Messagerie" />*/}
                {/*            <p>Arthur De Frayssinet</p>*/}
                {/*        </a>*/}
                {/*        <button className="plus"></button>*/}
                {/*        <button className="settings"></button>*/}
                {/*        <button className="close" ></button>*/}
                {/*    </header>*/}


                {/*<section>*/}

                {/*    <time>13 Juin 2022</time>*/}

                {/*    <div className="msgLeft">*/}
                {/*        <img className="pic" src={photo} alt="Messagerie" />*/}
                {/*        <p>Lorem Ipsum is simply dummy text of the printing ??</p>*/}
                {/*    </div>*/}

                {/*    <div className="msgRight">*/}
                {/*        <p>Is simply dummy text of the printing !</p>*/}
                {/*    </div>*/}

                {/*    <div className="msgLeft">*/}
                {/*        <img className="pic" src={photo} alt="Messagerie" />*/}
                {/*        <p>Simply dummy text of the printing ??</p>*/}
                {/*    </div>*/}

                {/*    <div className="msgLeft">*/}
                {/*        <p>Lorem Ipsum is simply</p>*/}
                {/*    </div>*/}

                {/*    <div className="msgRight">*/}
                {/*        <p>Text of the printing !</p>*/}
                {/*    </div>*/}

                {/*</section>*/}

                {/*<footer>*/}
                {/*    <textarea onChange={(e) => setTxtArea(e.target.value)} value={txtArea} />*/}
                {/*    <button className="send"></button>*/}
                {/*    <button className="image"></button>*/}
                {/*    <button className="gif"></button>*/}
                {/*    /!* <GifPicker style={{bottom: "300px"}}/> *!/*/}
                {/*    <button className="emojis" onClick={handleVisibleEmoji}></button>*/}
                {/*</footer>*/}
                {/*{boolEmoji === true && <EmojiPicker txtArea={txtArea} setTxtArea={setTxtArea} />}*/}

            </div>
        </div>
    );
}


