import React, {useEffect, useState} from "react";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import axios from "axios";
import {URLDayl, URLDaylImage} from "../../services/constantes";
import {useUser} from "../../contexts/userService";
import { Modal} from "@mui/material";
import Box from "@mui/material/Box";



/**
 * This component is a modal used to manage your network (add/delete friends,...)
 * @param {object} user - user object from api
 * @param {object} setUser - user setter
 *  @param {boolean} boolNetWork - test if the network modal is open
 *  @param {boolean} setBoolNetWork - boolNetWork setter
 */

export const enumTypeModalNetwork =
    {
        Followers: 1,
        Following: 2,
        Requests: 0
    }

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: "70%",
    borderRadius: 3,
    padding: 5,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,

};

export default function ModalNetwork({
                                         user,
                                         setUser,
                                         boolNetWork,
                                         setBoolNetWork,
                                         typeModalNetwork,
                                         refreshKey,
                                         setRefreshKey
                                     }) {

    //  init Variables global
    const [isActive, setIsActive] = useState(typeModalNetwork);
    const [data, setData] = useState([])
    const auth = useUser()
    const [stats, setStats] = useState()


    useEffect(() => {

        if (auth.data) {
            switch (isActive) {
                case enumTypeModalNetwork.Followers:
                    axios.get(URLDayl + `friends/listOfFollowers`, {params: {userId: user.id}}).then((res) => {
                        setData(res.data)
                    })
                    break;
                case enumTypeModalNetwork.Following:
                    axios.get(URLDayl + `friends/listOfFollowing`, {params: {userId: user.id}}).then((res) => {
                        setData(res.data)
                    })
                    break
                case enumTypeModalNetwork.Requests:
                    axios.get(URLDayl + `friends/listRequests`, {headers: {"Authorization": `Bearer ${auth.data && localStorage.getItem("jwt")}`}}).then((res) => {
                        setData(res.data)
                    })
            }

            axios.get(URLDayl + `friends/statistics/${auth.data && auth.data.id}`).then((res) => {
                setStats(res.data)
            })
        }

    }, [typeModalNetwork, isActive, refreshKey])


    //Active class Tab
    const toggleClass = (val) => {
        setIsActive(val);
    };


    const handleOpen = () => setBoolNetWork(true);
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <Modal
            open={boolNetWork}
            onClose={() => {
                setBoolNetWork(false)
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    flexGrow: 1,
                    bgcolor: 'background.paper',
                    display: 'flex',
                    height: "55%",
                    marginTop: 50,
                    borderRadius: 3,
                    overflow: "auto"
                }}
            >
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{borderRight: 1, borderColor: 'divider', minWidth: 200}}
                >
                    <Tab label="Search painting" {...a11yProps(0)} />
                    <Tab label="Liked" {...a11yProps(1)} />
                    {/*<Tab label="Lists" {...a11yProps(2)} />*/}
                    <Tab label="Upload file" {...a11yProps(2)}/>
                </Tabs>

                <TabPanel value={value} index={0}>
                    Item One
                </TabPanel>
                <TabPanel value={value} index={1}>
                    Item Two
                </TabPanel>
                <TabPanel value={value} index={2}>
                    Item Three
                </TabPanel>



            </Box>

                {/*<Tabs defaultIndex={isActive - 1}>*/}
                {/*    <TabList>*/}
                {/*        <nav className="accountTabs">*/}
                {/*            <ul>*/}
                {/*                <Tab className="react-tabs__tab"><a className={isActive === 1 ? 'active' : null}*/}
                {/*                                                    onClick={() => toggleClass(2)}>Demandes*/}
                {/*                    d'ajout<span*/}
                {/*                        className="pillR">{stats && stats.numberOfRequests}</span></a></Tab>*/}
                {/*                /!*<Tab className="react-tabs__tab"><a className={isActive === 2 ? 'active' : null}*!/*/}
                {/*                /!*                                    onClick={() => toggleClass(2)}>Mon*!/*/}
                {/*                /!*    réseau</a></Tab>*!/*/}
                {/*                <Tab className="react-tabs__tab"><a className={isActive === 3 ? 'active' : null}*/}
                {/*                                                    onClick={() => toggleClass(3)}>Mes*/}
                {/*                    abonnés</a></Tab>*/}
                {/*                <Tab className="react-tabs__tab"> <a className={isActive === 4 ? 'active' : null}*/}
                {/*                                                     onClick={() => toggleClass(4)}>Mes*/}
                {/*                    abonnements</a></Tab>*/}
                {/*            </ul>*/}
                {/*        </nav>*/}
                {/*    </TabList>*/}

                {/*    <TabPanel>*/}
                {/*        <h4 className="mb-b">Vos demandes d'ajout</h4>*/}

                {/*        {data && data.length > 0 && data.map((el) => {*/}
                {/*            return <FriendRequest user={user} friendId={el.userId} key={el.userId}*/}
                {/*                                  typeFriendModal={enumTypeModalNetwork.Requests}*/}
                {/*                                  refreshKey={refreshKey} setRefreshKey={setRefreshKey}/>*/}
                {/*        })}*/}
                {/*        /!* <!-- DÉTAILS CONTACT DU RESEAU - fin --> *!/*/}
                {/*    </TabPanel>*/}
                {/*    <TabPanel>*/}
                {/*        <h4 className="mb-b">Vos Abonnés ({data && data.length})</h4>*/}
                {/*        {data && data.length > 0 && data.map((el) => {*/}
                {/*            return <FriendRequest user={user} friendId={el.friendId} key={el.friendId}*/}
                {/*                                  typeFriendModal={enumTypeModalNetwork.Followers}*/}
                {/*                                  refreshKey={refreshKey} setRefreshKey={setRefreshKey}/>*/}
                {/*        })}*/}

                {/*    </TabPanel>*/}
                {/*    <TabPanel>*/}
                {/*        <h4 className="mb-b">Vos Abonnements ({data && data.length})</h4>*/}
                {/*        /!* <!-- DÉTAILS CONTACT DU RESEAU - début --> *!/*/}
                {/*        {data && data.length > 0 && data.map((el) => {*/}
                {/*            return <FriendRequest user={user} friendId={el.friendId} key={el.friendId}*/}
                {/*                                  typeFriendModal={enumTypeModalNetwork.Following}*/}
                {/*                                  refreshKey={refreshKey} setRefreshKey={setRefreshKey}/>*/}
                {/*        })}*/}
                {/*        /!* <!-- DÉTAILS CONTACT DU RESEAU - fin --> *!/*/}
                {/*    </TabPanel>*/}
                {/*</Tabs>*/}

        </Modal>

        //         </div>
        //     </div>
        //
        // </div>
    );
}