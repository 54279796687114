import React, { useState } from "react";
import Lightbox from "../../general/Lightbox/Lightbox";
// Import Swiper React components
// import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import "./CustomGaleryCarousel.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs } from "swiper/modules";
import { useUser } from "../../../contexts/userService";

// import required modules
// import { Navigation, Thumbs } from "swiper";

export default function CustomGaleryCarousel({ imageList, canZoom }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [index, setIndex] = useState();
  const auth = useUser();

  const transformedImageList = imageList.map((url) => {
    const parts = url.split("/");
    const id = parts[parts.length - 1];
    return { url, id };
  });

  return (
    <>
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        spaceBetween={10}
        navigation={false}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[Navigation, Thumbs]}
        className="mySwiper2"
      >
        {imageList &&
          imageList.length > 0 &&
          imageList.map((el, index) => {
            return (
              <SwiperSlide key={index}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={el}
                    alt={"elementsBiographie"}
                    onClick={() => setIndex(index)}
                    className="cursor-pointer"
                  />
                </div>
              </SwiperSlide>
            );
          })}
      </Swiper>
      {imageList.length > 1 && (
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={10}
          slidesPerView={4}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[Navigation, Thumbs]}
          className="mySwiper"
        >
          {imageList &&
            imageList.length > 0 &&
            imageList.map((el, index) => {
              return (
                <SwiperSlide key={index}>
                  <img src={el} alt={"elementsBiographie"} />
                </SwiperSlide>
              );
            })}
        </Swiper>
      )}
      {canZoom && (
        <Lightbox
          title={"yes"}
          isLogged={auth.data ? true : false}
          user={auth.data}
          index={index}
          setIndex={setIndex}
          matchList={transformedImageList}
          url={false}
        />
      )}
    </>
  );
}
