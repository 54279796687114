import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import close from "../../webapp/img/icons/ico_close_2.svg";
import pen from "../../webapp/img/icons/ico_pen_1.svg";
import pen2 from "../../webapp/img/icons/ico_pen_2.svg";
import { URLDayl, URLDaylImage } from "../../services/constantes";
import defaultBg from "../../webapp/img/bg/bg_default.jpg";
import defaultLogo from "../../webapp/img/icons/ico_mod_profile_1.svg";
import { toast } from "react-toastify";
import axios from "axios";
import { Button } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useUser } from "../../contexts/userService";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";

/**
 * This component is used to update your informations
 * @param {object} user - user object from api
 * @param {object} setUser - user setter
 * @emits - users/update : edit user information
 * @emits - images/uploadUserIcon?userId=${user.id} :edit profil picture
 * @emits - users/${user.id} : update user informations
 */

export default function AmateurInfo({ user, setUser }) {
  const [boolExpert, setBoolExpert] = useState(false);
  const auth = useUser();
  const navigate = useNavigate();
  const [imageSrc, setImageSrc] = useState(null);

  const [imageError, setImageError] = useState(false);

  const [boolCrea, setBoolCrea] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();
  const [fileName, setFileName] = useState("Glissez/déposez votre fichier ici");
  const [title, setTitle] = useState("Glissez/déposez votre fichier ici");
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);
  const [listNationality, setListNationality] = useState([]);
  useEffect(() => {
    getListNationality();
  }, []);

  const makeExpertAccount = () => {
    fetch(`${URLDayl}users/makeExpertAccount/${user.id}`, {
      method: "PUT",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => console.log(data))
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const getListNationality = async () => {
    const { data } = await axios.get(
      `${URLDayl}paintingFront/listOriginsAndNationalities`
    );
    setListNationality(data.sort().map((el) => ({ name: el })));
  };

  const postUserInfo = async (data) => {
    axios
      .post(URLDayl + "users/update", {
        id: user.id,
        firstName: data.firstName,
        lastName: data.lastName,
        biography: data.biography,
        linkedinAccount: data.linkedinAccount,
        instagramAccount: data.instagramAccount,
        facebookAccount: data.facebookAccount,
        pinterestAccount: data.pinterestAccount,
        twitterAccount: data.twitterAccount,
        city: data.city,
        country: data.country,
        dateOfBirth: data.yearOfBirth,
        sexe: data.sexe,
        pseudo: data.pseudo,
      })
      .then((response) => {
        toast.success("Nouvelles informations enregistrées!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        updateUser();
        navigate("/view/CompteAmateurPage.js");
      });
  };

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await axios.get(URLDayl + `images/userIconImage`, {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${auth.data.jwt}`,
          },
        });
        const imageBlob = response.data;
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setImageSrc(imageObjectURL);
      } catch (error) {
        setImageError(true);
      }
    };

    if (user) {
      fetchImage();
    }
  }, []);

  const postProfilPicture = async (data) => {
    try {
      const file = data.file[0];
      if (!file) return;

      const formdata = new FormData();
      formdata.append("image", file);

      const response = await axios.post(
        `${URLDayl}images/uploadUserIcon`,
        formdata,
        {
          headers: {
            Authorization: `Bearer ${auth.data.jwt}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Nouvelle photo de profile enregistrée!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.warning(
          "Un problème est survenu dans l'enregistrement de votre image!",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      updateUser();
    } catch (error) {
      toast.warning(
        "Un problème est survenu dans l'enregistrement de votre image!",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  const onSubmitBg = async (data) => {
    var file = await data.fileBg[0];
    var formdata = new FormData();
    formdata.append("image", file);
    console.log(file);

    var xhr = new XMLHttpRequest();
    xhr.open(
      "POST",
      URLDayl + `images/uploadUserBackgroundIcon?userId=${user.id}`
    );
    //xhr.setRequestHeader('Content-Type', 'multipart/form-data');
    xhr.send(formdata);

    xhr.onreadystatechange = function () {
      if (xhr.readyState != 4) return;
      if (xhr.status != 200) {
        toast.warning(
          "Un problème est survenu dans l'enregistrement de votre bannière!",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      } else {
        updateUser();
        toast.success("Nouvelle bannière enregistrée!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };
  };

  //update User
  const updateUser = async () => {
    axios
      .get(URLDayl + `users/${user.id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setUser(response.data);
      });
  };

  const [selectedFile, setSelectedFile] = useState();
  const fileInputRef = useRef(null);
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  useEffect(() => {
    if (selectedFile) {
      var file = selectedFile;
      var formdata = new FormData();
      formdata.append("image", file);
      console.log(file);

      var xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        URLDayl + `images/uploadUserBackgroundIcon?userId=${user.id}`
      );
      //xhr.setRequestHeader('Content-Type', 'multipart/form-data');
      xhr.send(formdata);

      xhr.onreadystatechange = function () {
        if (xhr.readyState != 4) return;
        if (xhr.status != 200) {
          toast.warning(
            "Un problème est survenu dans l'enregistrement de votre bannière!",
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        } else {
          updateUser();
          toast.success("Nouvelle bannière enregistrée!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      };
    }
  }, [selectedFile]);

  // Call Jquery script
  useEffect(() => {
    const loadFile = async () => {
      const { modal } = await import("../../webapp/js/js_source/modalBox");

      modal();
    };
    loadFile();
  }, []);

  return (
    <div className="AmateurInfo">
      {user && (
        <>
          <div className="enthusiastHeader">
            {/* <!-- BLOC AVATAR TOP - début --> */}
            <div className="enthusiastTop">
              <div
                className="userTop pb-b"
                style={{
                  backgroundImage: `url(${URLDaylImage}images/userBackgroundImage/${user?.id})`,
                  backgroundSize: "cover",
                  height: "230px",
                }}
              >
                <form onChange={handleSubmit(postProfilPicture)}>
                  <div className="userImg userAvatar">
                    {imageError ? (
                      <AccountCircleIcon
                        className="profilePic"
                        style={{ color: "black" }}
                      />
                    ) : (
                      <img
                        className="profilePic"
                        style={{ objectFit: "cover" }}
                        src={imageSrc}
                        alt="profile pic"
                        onError={() => setImageError(true)}
                      />
                    )}
                    <div
                      className="uploadBtn absBR"
                      style={{ width: "36px", height: "36px" }}
                    >
                      <button
                        style={{
                          width: "36px",
                          height: "36px",
                          padding: "0",
                          cursor: "pointer",
                        }}
                        className="btn"
                      >
                        {" "}
                        <FileUploadOutlinedIcon />
                      </button>
                      <input
                        style={{ width: "36px", height: "36px" }}
                        name="fileUploadField"
                        type="file"
                        className="fileUploadField"
                        id="formIcon"
                        {...register("file")}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* <!-- BLOC AVATAR TOP - fin --> */}
          </div>
          <div className="editBanner">
            <Button
              variant="outlined"
              style={{ color: "black", border: "none", fontSize: "12px" }}
              onClick={handleButtonClick}
            >
              Changez votre bannière
            </Button>
            <input
              type="file"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={changeHandler}
            />
          </div>
        </>
      )}

      {/* <!-- **************************************************
		BLOC CONTENU DE LA PAGE - début
		**************************************************** --> */}
      {user && (
        <main className="enthusiastMain">
          {/* <!-- SECTION A PROPOS DE MOI - début --> */}
          <section>
            <form className="formCompact" onSubmit={handleSubmit(postUserInfo)}>
              <h3>Mes informations personnelles</h3>

              <div className="infoInput">
                <label htmlFor="lastName">Nom :</label>
                <input
                  id="lastName"
                  type="text"
                  {...register("lastName", { value: user.lastName })}
                  required
                />
                <span>*</span>
              </div>
              <div className="infoInput">
                <label htmlFor="firstName">Prénom :</label>
                <input
                  id="firstName"
                  type="text"
                  {...register("firstName", { value: user.firstName })}
                  required
                />
                <span>*</span>
              </div>
              <div className="infoInput">
                <label htmlFor="pseudo">Pseudo :</label>
                <input
                  id="pseudo"
                  type="text"
                  {...register("pseudo", { value: user.pseudo })}
                  required
                />
                <span>*</span>
              </div>
              <div className="infoInput">
                <div htmlFor="country">Origine, nationalité :</div>
                <span>*</span>
                <select
                  {...register("country")}
                  defaultValue={auth.data.country || ""}
                  required
                >
                  {listNationality.map((option) => (
                    <option key={option.name} value={option.name}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="infoInput">
                <label htmlFor="lastName">Ville :</label>
                <input
                  type="text"
                  {...register("city", { value: user.city })}
                />
              </div>
              <div className="infoInput">
                <label htmlFor="lastName">Sexe :</label>
                <select
                  {...register("sexe", { value: user.sexe })}
                  defaultValue={user.sexe}
                  style={{ width: "200px " }}
                >
                  <option value="MAN">MAN</option>
                  <option value="WOMAN">WOMAN</option>
                  <option value="UNKNOWN">OTHER</option>
                </select>
              </div>
              <div className="infoInput">
                <label htmlFor="yearOfBirth">Année de naissance :</label>
                <select
                  {...register("yearOfBirth", { value: user.yearOfBirth })}
                  style={{ width: "200px " }}
                >
                  {Array.from({ length: 101 }, (_, index) => {
                    const year = new Date().getFullYear() - index;
                    return (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    );
                  })}
                </select>
              </div>

              <h3 className="mt-c">Ma présentation</h3>

              <div style={{ width: "70%", marginLeft: "50px" }}>
                <p className="legend">
                  Présentation synthétique qui apparaitra sur votre profil et
                  sera visible par chaque membre de la communauté Dayl.
                </p>
                <textarea
                  name="message"
                  style={{ height: "fit-content", resize: "none" }}
                  {...register("biography", {
                    value: user.biography,
                    onChange: (e) => setCount1(e.target.value.length),
                  })}
                  defaultValue={user.biography}
                ></textarea>
              </div>

              {/* <h3 className="mt-c">Mes réseaux sociaux</h3>
                <div className="inputInline userSN ">
                  <input
                    type="text"
                    className="userFacebook"
                    size="55"
                    placeholder="Page Facebook"
                    {...register("facebookAccount", {
                      value: user.facebookAccount,
                    })}
                  />
                  <input
                    type="text"
                    className="userLinkedin"
                    size="55"
                    placeholder="Page LinkedIn"
                    {...register("linkedinAccount", {
                      value: user.linkedinAccount,
                    })}
                  />
                </div>
                <div className="inputInline userSN mt-a   ">
                  <input
                    type="text"
                    className="userInstagram"
                    size="55"
                    placeholder="Page Instagram"
                    {...register("instagramAccount", {
                      value: user.instagramAccount,
                    })}
                  />
                  <input
                    type="text"
                    className="userPinterest"
                    size="55"
                    placeholder="Page Pinterest"
                    {...register("pinterestAccount", {
                      value: user.pinterestAccount,
                    })}
                  />
                </div>
                <div className="inputInline userSN mt-a ">
                  <input
                    type="text"
                    className="userTwitter"
                    size="55"
                    placeholder="Page Twitter"
                    {...register("twitterAccount", {
                      value: user.twitterAccount,
                    })}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  {!user.userType === "expert" && (
                    <div>
                      <h4 className="mt-c">
                        Voulez vous faire une demande pour avoir acces a un
                        compte expert ?
                      </h4>
                      <div className="inputInline">
                        <div>
                          <button
                            onClick={makeExpertAccount}
                            className="resquestButton mt-a"
                          >
                            Request
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  <div>
                    <h4 className="mt-c">
                      Restez informé des mises à jour et nouveautés Dayl :
                    </h4>
                    <div className="inputInline">
                      <div>
                        <button
                          onClick={() => {}}
                          className="resquestButton mt-a"
                        >
                          Subscribe
                        </button>
                      </div>
                    </div>
                  
                  </div> 
                </div>*/}
              <div className="inputInline mt-c">
                <div>
                  <input
                    type="submit"
                    className="submitFormBtn mt-a"
                    value="Enregistrer"
                  />
                </div>
              </div>
            </form>
          </section>
          {/* <!-- SECTION A PROPOS DE MOI - fin --> */}
        </main>
      )}
      {/* <!-- **************************************************
		BLOC CONTENU DE LA PAGE - fin
		**************************************************** --> */}
    </div>
  );
}
