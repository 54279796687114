import React from "react";
import AmateurInfo from "../component/UserInfo/AmateurInfo";

/**
 * This component display the User update Page
 * @param {boolean} isLogged - test if the user is logged
 * @param {object} user - user object from api
 * @param {object} setUser - user setter

 */


export default function UserInfo({ isLogged,user, setUser}) {

    // const { state } = useLocation();
    // const [user, setUser] = useState(state);



    return (
        <div className="UserInfo">

            <AmateurInfo user={user} setUser={setUser} isLogged={isLogged}/>

        </div>

    );
}


