import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { URLDayl } from "../../services/constantes";
import ListModal2 from "../general/ListModal2";
import { useTranslation } from "react-i18next";
import ImageGrid, { enumTypeGrid } from "../general/ImageGrid/ImageGrid.js";

/**
 * This component display the matched painting (by color)
 * @param {object} user - user object from api
 * @param {object} setUser - user setter
 * @param {boolean} isLogged - test if the user is logged
 * @param {string} color - contain the hex code of the chosed color
 *  @emits - paintingSearch/findByColor : get list of paintings by selected color
 */

export default function ResultMatchingColor({
  color,
  setUser,
  user,
  isLogged,
}) {
  const [listMatch, setListMatch] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [maxNumPainting, setMaxNumPainting] = useState(24);
 



 

  useEffect(() => {
    if (color.hex !== "#121212")
    //à changer, comportement très bizzare
    axios
      .post(URLDayl + `paintingSearch/findByColorOptimised`, {
        colorCode: color.hex,
        sortOrder: "ASC",
        maxNumPainting: maxNumPainting,
        minNumPainting: 0,
      })
      .then((response) => {
        setListMatch(response.data);
        const boolArray = response.data.map((el) => {
          return { ...el, bool: false, boolList: false };
        });

        setListMatch(boolArray);
      });
  }, [color, maxNumPainting]);

  //update user
  useEffect(() => {
    if (user)
      axios.get(URLDayl + `users/${user.id}`).then((response) => {
        setUser(response.data);
      });
  }, [refreshKey, user, setUser]);

  // Call Jquery script
  useEffect(() => {
    const loadFile = async () => {
      const { modal } = await import("../../webapp/js/js_source/modalBox");

      const { grid } = await import("../../webapp/js/gridActions.min.js");

      modal();
      grid();
    };
    loadFile();
  }, []);
  const { t } = useTranslation();

  return (
    <div className="ResultMatchingColor">
      <ImageGrid
        user={user}
        isLogged={isLogged}
        paintings={listMatch}
        showTools={true}
        selectionMode={false}
        maxNumPainting={maxNumPainting}
        setMaxNumPainting={setMaxNumPainting}
        totalMaxNum={100}
        typeGrid={enumTypeGrid.ArtistPaintingsGrid}
        refreshKey={refreshKey}
        setRefreshKey={setRefreshKey}
      />

      
    </div>
  );
}
