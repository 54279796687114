import React, {useEffect, useState} from "react";
import newtempprofile from "../../../webapp/img/icons/newtempprofile.svg";
import newcomment from "../../../webapp/img/icons/newcomment.svg";
import newshare from "../../../webapp/img/icons/newshare.svg";
import newheart from "../../../webapp/img/icons/newheart.svg";
import SocialNetworkPost from "./SocialNetworkPost";
import axios from "axios";
import {URLDayl} from "../../../services/constantes";
import NoPostYet from "../../SocialMedia/NoPostYet";
function SocialNetworkPostList({posts}) {






  return (
    <div className="MypostsLayout">

      {
          posts.length <= 0 && <NoPostYet/>
      }

      {posts.map((el) =>
          (<SocialNetworkPost id={el.id}/>)
      )}



      {/*<div className="post">*/}
      {/*  <div className="postInfo">*/}
      {/*    <img src={newtempprofile} alt="profile pic" />*/}
      {/*    <p></p>*/}
      {/*    <div></div>*/}
      {/*    <p>18h ago</p>*/}
      {/*  </div>*/}
      {/*  <div className="caption">*/}
      {/*    <p>*/}
      {/*      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do*/}
      {/*      eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim*/}
      {/*      ad minim veniam, quis nostrud exercitation{" "}*/}
      {/*    </p>*/}
      {/*  </div>*/}
      {/*  <div className="postPicture"></div>*/}
      {/*  <div className="interactions">*/}
      {/*    <div style={{ display: "flex", gap: "10px" }}>*/}
      {/*      <img src={newheart} alt="profile pic" />*/}
      {/*      <img src={newcomment} alt="profile pic" />*/}
      {/*    </div>*/}
      {/*    <img src={newshare} alt="profile pic" />*/}
      {/*  </div>*/}
      {/*  <div>*/}
      {/*    <div className="comment">*/}
      {/*      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do*/}
      {/*      eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim*/}
      {/*      ad minim*/}
      {/*    </div>*/}
      {/*    <div className="commentUser">*/}
      {/*      <p>From @User</p>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
}

export default SocialNetworkPostList;
