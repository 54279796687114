import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { URLDaylImage, URLDayl } from "../../services/constantes";
import axios from "axios";
import { stringSplitting } from "../../services/Utils";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

const MovementDetail = ({ movementId, onBack }) => {
  const [movement, setMovement] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const componentRef = useRef(null);
  const [paintingInfo, SetPaintingInfo] = useState(null);

  useEffect(() => {
    fetchMovement();
  }, [movementId]);

  useEffect(() => {
    if (componentRef.current) {
      componentRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [movement]);

  const fetchMovement = async () => {
    setLoading(true);
    try {
      const movementResponse = await axios.get(
        URLDayl + "editorial-contents/" + movementId
      );
      const movementData = movementResponse.data;
      setMovement(movementData);

      if (movementData.paintingId) {
        const paintingResponse = await axios.get(
          URLDayl + "paintingEntity/" + movementData.paintingId
        );
        SetPaintingInfo(paintingResponse.data);
      }
      console.log(paintingInfo);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to load movement details. Please try again later.");
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 font-bold text-xl text-center">{error}</div>
    );
  }

  if (!movement) {
    return null;
  }

  return (
    <div ref={componentRef} className="">
      <div className="flex justify-between">
        <Link
          to="/view/DaylContent"
          className="mb-4 text-black text-xl font-bold hover:underline py-2 px-4 rounded inline-block"
          onClick={onBack}
        >
          Retour grille
        </Link>
        <Link
          className="mb-4 text-black text-xl font-bold hover:underline py-2 px-4 rounded inline-block"
          to={`/FriseMouvementArtistique`}
        >
          Retour Frise
        </Link>
      </div>
      <div className="flex flex-col lg:flex-row gap-8 items-center justify-center">
        <div className="lg:w-1/2">
          <div className="flex items-center gap-4 mb-6">
            <h2 className="text-4xl font-bold text-gray-800 uppercase">
              {movement.movement}
            </h2>
            <p className="text-gray-600 italic text-2xl">
              {movement.dateStart}
            </p>
          </div>
          <div>
            <h3 className="text-2xl font-semibold mb-3">Présentation </h3>
            <p>{movement.summary}</p>
          </div>
          <h3 className="text-2xl font-semibold mb-3 mt-5">Contenu </h3>

          <Swiper modules={[Navigation]} navigation={true} className="mb-6">
            {stringSplitting(950, movement?.content).map((el, index, array) => {
              const isSingleElement = array.length === 1;
              if (isSingleElement)
                return (
                  <p
                    key={index}
                    className="flex items-center text-gray-700 px-16 whitespace-pre-wrap font-raleway"
                  >
                    {movement.content}
                  </p>
                );
              else
                return (
                  <SwiperSlide key={index}>
                    <p className="px-16 text-gray-700 whitespace-pre-wrap font-raleway">
                      {el}
                    </p>
                  </SwiperSlide>
                );
            })}
          </Swiper>
          {movement.children.length > 0 && (
            <>
              {" "}
              <h3 className="text-2xl font-semibold mb-3">Mouvements Fils</h3>
              <div className="flex flex-wrap gap-2">
                {movement.children.map((child) => (
                  <Link
                    key={child.id}
                    to={`/view/DaylContent/${child.id}`}
                    className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold py-2 px-4 rounded-full transition duration-300"
                  >
                    {child.movement}
                  </Link>
                ))}
              </div>
            </>
          )}
        </div>
        {movement.paintingId && (
          <div className="lg:w-1/2 flex justify-center items-center flex-col  ">
            <img
              src={`${URLDaylImage}images/paintingPic/${movement.paintingId}`}
              alt={`Artwork ${movement.paintingId}`}
              className="w-full h-auto max-h-[50vh] object-contain shadow-lg"
            />
            <div className="flex justify-center flex-col">
              <p className="text-xl font-semibold mt-4 italic">
                {paintingInfo?.aw_title_origin} - {paintingInfo?.aw_year}
              </p>
              <p className="text-gray-600 text-lg mt-0 italic w-fit m-auto">
                {paintingInfo?.artist.artist_name_tot}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MovementDetail;
