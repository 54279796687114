import React, {useState,useEffect} from 'react'
import close from "../../webapp/img/icons/ico_close_2.svg";
import TextareaAutosize from "react-textarea-autosize";
import axios from "axios";
import {URLDayl} from "../../services/constantes";
import {toast} from "react-toastify";
import {useAsync} from "react-select/async";
import {useUser} from "../../contexts/userService";

export default function SignalModal({user, boolModal, setBoolModal, paintId}) {

    const [comment, setComment] = useState("")

    const handleSubmit = (e) => {

        e.preventDefault();

            axios.post(URLDayl + "paintingFront/signalPainting",
                {
                    paintingIdtf: paintId,
                    comment: comment
                },
                {headers: {"Access-Control-Allow-Origin": "*", "Content-Type": "application/json"}}
            )
                .then((response) => {
                    setBoolModal(false)
                    toast.success("Signaled", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }).catch((err) => {
                setBoolModal(false)
                toast.error("erreur d'ajout, veuillez réessayer", {

                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
        }
        useEffect(() => {
            let overlay = document.createElement("div");
        
            Object.assign(overlay.style, {
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              zIndex: 1,
            });
            document.body.appendChild(overlay);
            document.body.style.overflow = "hidden";
            return () => {
              document.body.removeChild(overlay);
              document.body.style.overflow = "unset";
            };
          }, []);


    return (
        <div className="ModalNetwork">

            {/* <!-- MODAL GERER MON RESEAU - DÉBUT --> */}
            <div className="smart-modal-overlay" style={{display: "block"}}></div>

            <div className="smart-modal-content-l scrollAuto" style={{
                transform: "translate(-50%,-50%)",
                display: "inline-flex",
                flexWrap: "wrap",
                alignContent: "flex-start",
                minHeight: "40%",
            }}>
                <h3>Signaler cette oeuvre</h3>
                <a className="smart-modal-close" onClick={(e) => {

                    setBoolModal(false)

                }}><img className="img-left"
                        src={close}
                        alt="Trier"/></a>
                <div className="showSubs">

                    <TextareaAutosize minRows={15}
                                      placeholder={"saisie du texte"}
                                      value={comment}
                                      onChange={e => {
                                          setComment(e.target.value)
                                      }}
                    />

                    <div>
                        <input type="submit" className="submitFormBtn mt-a" value="Ajouter ce commentaire" onClick={(e) => {
                            handleSubmit(e)
                        }} />
                    </div>

                </div>
            </div>

        </div>
    )
}