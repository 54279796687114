import React, {useEffect, useState} from "react";
import axios from "axios";
import {URLDayl} from "../../services/constantes";
import {Link} from "react-router-dom";
import {Chip, OutlinedInput, Pagination, Select} from "@mui/material";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import SkeletonPaintings from "../general/SkeletonPaintings";
import {useUser} from "../../contexts/userService";
import NoPostYet from "../SocialMedia/NoPostYet";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import {makeStyles} from "@material-ui/core/styles";
import ListModal2 from "../general/ListModal2";

/**
 * This component contain your list of likes
 * @param {object} user - user object from api
 * @param {object} setUser - user setter
 * @emits - paintingEntity/{id} : get paint informations
 * @emits - users/updateFavoritePainting : delete paint
 */

const NUM_PER_PAGE = 24;

export default function Likes({
                                  user,
                                  setUser,
                                  friend,
                                  selectionMode = false,
                                  setSelected,
                                  selected,
                                  multiple = false
                              }) {
    const [painting, setPainting] = useState([]);
    const [refreshKey, setRefreshKey] = useState(0);
    const [isLogged] = useState(true);
    const [totalPaintings, setTotalPainting] = useState(0);
    const [page, setPage] = useState(sessionStorage.getItem("pagination") ? parseInt(sessionStorage.getItem("pagination")) : 1);
    const [selectedArtists, setSelectedArtists] = useState([]);
    const [availableArtists, setAvailableArtists] = useState([]);

    const [filteredPaintings, setFilteredPaintings] = useState([])

    const [loading, setLoading] = useState(false);
    const auth = useUser();

    useEffect(() => {
        sessionStorage.removeItem("pagination")
        setPage(1)
    },[user, friend])


    useEffect(async () => {
        setPainting([]);
        var userTemp = auth.data;
        if (friend)
            await axios
                .get(URLDayl + `users/${friend}`)
                .then((res) => (userTemp = res.data));
        const chunkedArray = Array.from(
            {length: Math.ceil(userTemp.paintingLiked.length / NUM_PER_PAGE)},
            (_, index) =>
                userTemp.paintingLiked.slice(
                    index * NUM_PER_PAGE,
                    index * NUM_PER_PAGE + NUM_PER_PAGE
                )
        );

        setTotalPainting(userTemp.paintingLiked.length);
        setLoading(userTemp.paintingLiked.length > 0);
        for (let i = 0; i < chunkedArray[page - 1].length; i++) {
            try {
                await axios
                    .get(URLDayl + `paintingEntity/${chunkedArray[page - 1][i]}`)
                    .then((response) => {
                        let boolArray = response.data;
                        boolArray.bool = false;
                        setPainting((painting) => [...painting, boolArray]);
                        const temp = availableArtists;
                        setAvailableArtists((artist) => [...artist, response.data.artist]);
                        if (
                            !temp.some(
                                (el) =>
                                    el.artistIdtfIntern === response.data.artist.artistIdtfIntern
                            )
                        ) {
                            temp.push(response.data.artist);
                        }
                    });
                setLoading(false);
            } catch (e) {
            }
        }
        setFilteredPaintings(painting)
        setLoading(false);
    }, [refreshKey, friend, page]);

    const deleteLike = (paintId) => {
        axios
            .post(URLDayl + "users/updateFavoritePainting", {
                userId: user.id,
                favoriteId: paintId,
                operation: "remove",
            })
            .then((response) => {
                console.log("remove");
                //update user
                axios
                    .get(URLDayl + `users/${user.id}`)
                    .then((response2) => {
                        setUser(response2.data);
                        setRefreshKey((oldKey) => oldKey + 1);
                    })
                    .catch((err) => {
                        console.log("ERROR LIKES");
                    });
            });
    };

    //afficher modale suppression//
    const handleBoolListTrue = (index) => {
        const tempBool = [...painting];

        tempBool[index].bool = true;
        setPainting(tempBool);
    };

    const handleChange = (event, value) => {
        sessionStorage.setItem("pagination", value)
        setPage(value);
        window.scrollTo(0, 60);

    };

    //cacher modale suppression//
    const handleBoolListFalse = (index) => {
        const tempBool = [...painting];

        tempBool[index].bool = false;
        setPainting(tempBool);
    };

    const handleChangeSelect = (event) => {
        const {
            target: {value},
        } = event;

        const temp = typeof value === "string" ? value.split(",") : value;
        setSelectedArtists(temp);
    };
    const useStyles = makeStyles({
        checkbox: {
            color: "#101e39",
            "&$checked": {
                color: "#101e39",
            },
        },
        checked: {},
    });
    const classes = useStyles();
    return (
        <div className="Likes">
            <div
                style={{justifyContent: "end", display: "flex", marginRight: "100px"}}
            >
                <Select
                    multiple
                    style={{minWidth: 200}}
                    displayEmpty
                    value={selectedArtists}
                    labelId="demo-multiple-checkbox-label"
                    onChange={handleChangeSelect}
                    input={<OutlinedInput label="" placeholder="Select Artists"/>}
                    renderValue={(selected) =>
                        selected.length > 0 ? (
                            <Box
                                sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: 0.5,
                                    maxWidth: "1040px",
                                }}
                            >
                                {selected.map((value) => (
                                    <Chip key={value} label={value}/>
                                ))}
                            </Box>
                        ) : (
                            <em>Select Artists</em>
                        )
                    }
                    // MenuProps={MenuProps}
                >
                    {availableArtists
                        .sort((a, b) => a.artist_name_tot.localeCompare(b.artist_name_tot))
                        .map((name) => (
                            <MenuItem key={name.id} value={name.artist_name_tot}>
                                <Checkbox
                                    className={classes.checkbox}
                                    classes={{checked: classes.checked}}
                                    checked={selectedArtists.indexOf(name.artist_name_tot) > -1}
                                />
                                <ListItemText
                                    style={{marginRight: "20px", fontFamily: ""}}
                                    primary={name.artist_name_tot}
                                />
                            </MenuItem>
                        ))}
                </Select>
            </div>
            <div className="accountArtists">
                {loading && <SkeletonPaintings num={selectionMode ? 5 : 4}/>}
                {!loading && painting.length < 1 && (
                    <NoPostYet title={"No liked artworks"}/>
                )}
                {painting.length > 0 &&
                    painting.map((paint, index) => {
                        return (
                            (selectedArtists.length < 1 ||
                                selectedArtists.includes(paint.artist.artist_name_tot)) && (
                                <div
                                    className="sliderOne owl-loaded "
                                    onClick={() => {
                                        selectionMode && (multiple ? setSelected((previous) => [...previous, paint.id]) : setSelected([paint.id]));
                                    }}
                                    style={{
                                        opacity:
                                            selectionMode && selected && selected.includes(paint.id)
                                                ? 0.5
                                                : 1,
                                    }}
                                >
                                    <div
                                        className="item"
                                        style={{
                                            backgroundImage: `url(${URLDayl}images/paintingPic/${paint.id})`,
                                        }}
                                    >
                                        {!selectionMode && (
                                            <div style={{top: "193px", left: "10px"}}>
                                                <ListModal2
                                                    user={user}
                                                    isLogged={isLogged}
                                                    listPaint={paint}
                                                />
                                            </div>
                                        )}
                                        <div className="bt-wrapper" style={{top: "0px"}}>
                                            {/* <div className="img-wrapper"> */}

                                            <div className="titre">
                                                <h3
                                                    style={{
                                                        fontStyle: "oblique",
                                                        fontWeight: "600",
                                                        fontSize: "14px",
                                                        color: "white",
                                                        marginBottom: "15px",
                                                    }}
                                                >
                                                    {paint.artist_name_tot}
                                                </h3>
                                                {paint.aw_title_origin}
                                                <div className="date">{paint.aw_year}</div>
                                            </div>
                                            {!selectionMode && (
                                                <div className="container-action">
                                                    <Link
                                                        className="bt-action voir"
                                                        to={`../../view/OpusPage.js/${paint.id}`}
                                                        state={paint.id}
                                                    >
                                                        voir
                                                    </Link>
                                                    <Link
                                                        className="bt-action match"
                                                        to={`../../view/MatchingPage.js/${paint.id}`}
                                                    >
                                                        match
                                                    </Link>
                                                </div>
                                            )}
                                        </div>
                                        <div className="name"></div>

                                        {paint.userId === user.id && (
                                            <input
                                                type="button"
                                                className="unfollow"
                                                onClick={() => handleBoolListTrue(index)}
                                            />
                                        )}

                                        {paint.bool === true && paint.userId === user.id && (
                                            <div className="deleteConfirm">
                                                <span className="deleteText">Supprimer?</span>
                                                <button
                                                    type="button"
                                                    className="yes closeTwoParentsNow"
                                                    onClick={() => deleteLike(paint.id)}
                                                    value=""
                                                >
                                                    Oui
                                                </button>
                                                <button
                                                    type="button"
                                                    className="no closeParentNow"
                                                    onClick={() => handleBoolListFalse(index)}
                                                    value=""
                                                >
                                                    Non
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )
                        );
                    })}
            </div>

            {totalPaintings / NUM_PER_PAGE > 1 && (
                <Pagination
                    count={Math.ceil(totalPaintings / NUM_PER_PAGE)} // Calculate the total number of pages
                    page={page}
                    style={{
                        alignSelf: "center",
                        padding: "20px",
                        justifyContent: "center",
                    }}
                    sx={{justifyContent: "center"}}
                    variant="outlined"
                    className="paginationCentered"
                    onChange={handleChange} // Add the event handler for page change
                />
            )}
        </div>
    );
}
