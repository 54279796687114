import React, { useState, useRef, useEffect } from "react";
import { useUser } from "../../../contexts/userService"; 
import { URLDaylIAgent } from "../../../services/constantes";
import { useNavigate } from "react-router";
import axios from "axios";
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RefreshIcon from '@mui/icons-material/Refresh';
import BarChartIcon from '@mui/icons-material/BarChart';
import Button from '@mui/material/Button';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import "../../../webapp/css/gptAgent.css"
import Logo from "../../../webapp/img/logos/logo_1.svg";
import AgentGPTPlayground from "./AgentGPTPlayground";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import FileOpen from '@mui/icons-material/FileOpen';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PhoneIcon from '@mui/icons-material/Phone';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box } from "@mui/material";
import TuneIcon from '@mui/icons-material/Tune';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { GeneralQuestionsAboutArt } from "../../../services/QuestionsTemplate";
import getRandomElements from "./utils";
import AddCircleIcon from '@mui/icons-material/AddCircle';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SendIcon from '@mui/icons-material/Send';
import './styles.css'
import { Divider, message } from "antd";
import SettingsIcon from '@mui/icons-material/Settings';
import { BoltRounded } from "@mui/icons-material";
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
 
import Stack from '@mui/material/Stack';
import { art_innovation } from "../../../services/QuestionsTemplate";
 
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';

import UploadContainer from "./UploadContainer"
import ChatSession from "./ChatSession"

const TypedText = ({ text }) => {
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    let index = 0;

    const intervalId = setInterval(() => {
      setDisplayedText((prevText) => prevText + text[index]);

      index += 1;
      if (index === text.length) {
        clearInterval(intervalId);
      }
    }, 10);

    return () => clearInterval(intervalId);
  }, [text]);

  return <span>{displayedText}</span>;
};


/**
 * This component display the User Page
 * @param {object} user - user object from api
 * @param {object} setUser - user setter
 * @param {boolean} isLogged - test if the user is logged
 * @param {boolean} setIsLogged - isLogged setter
 *  @param {number} isActive - tab selection
 *  @param {number} setIsActive - isActive setter
 */

export default function GptConfiguration(props) {
  // const { state } = useLocation();
  // const [user, setUser] = useState(state);

  const auth = useUser();
  const navigate = useNavigate();


  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
  }

  useEffect(async () => {
    // await timeout(5000);
    while (auth.data === false){
      await timeout(10);
    }
    if (auth.data) return;{
      navigate("../../view/LogIn.js")
    }

  },[])



  const [selectedFile, setSelectedFile] = useState(null);
  const [filesList, setFilesList] = useState([]);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    try {
      const formData = new FormData();
      formData.append('file', selectedFile);

      axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
        "jwt"
      )}`; 
      const response = await axios.post( URLDaylIAgent + '/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log("response.data");
      console.log(response.data);
      fetchFilesList()
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const fetchFilesList = async () => {
    try {
      axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
        "jwt"
      )}`; 
      const response = await axios.get(URLDaylIAgent + '/upload');
      setFilesList(response.data.files);
    } catch (error) {
      console.error('Error fetching files list:', error);
    }
  };


  useEffect(() => {
    fetchFilesList();
  }, []); // Empty dependency array to run the effect only once when the component mounts

 


  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const [Modele, setModele] = React.useState('/llm_agent_gpt');

  const handleChangeModel = (event) => {
    setModele(event.target.value);
  };
 

  const handleIconClick = (iconType) => {
    // Gérer l'affichage du drop-up en fonction du type d'icône cliqué
    console.log(`Clic sur l'icône ${iconType}`);
  };
  const [sessionsChat, setsessionsChat] = useState(art_innovation);
  
  const [settingsAnchor, setSettingsAnchor] = useState(null);

  const handleSettingsClick = (event) => {
    setSettingsAnchor(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setSettingsAnchor(null);
  };

  const [isActiveUi, setIsActiveUi] = useState(true); // set to true for the first element, false for the second
  const [inputText, setInputText] = useState('');
  const [attachedImage, setAttachedImage] = useState(null);





  const [open, setOpen] = useState(false);
  const [messagesIdChat, setMessagesIdChat] = useState(sessionsChat[0].ID);
  const [messages, setMessages] = useState([]);
  const [userMessage, setUserMessage] = useState('');
  const [loading, setLoading] = useState(false); // Added loading state
  const messagesEndRef = useRef(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  function systemPromptingBuilder(data){
    if (data){
      return data;
    } 
    return "Tu es un agent expert dans le domaine de l'art."
  }

  const updateSessionChat = async (sessionId, newMessages, userInstruction, assistantInstuctions) => {

    // Checking if the first session's messages length equals 2
    const firstSession = sessionsChat[messagesIdChat]; // assuming sessionsChat is defined
    // If session doesn't exist, create a new one
    const newSession = {
      ID: sessionId,
      label: "Nouveau chat",
      user: "demo",
      messages: [],
    };
    const response = {}
    if (firstSession && firstSession.messages.length <= 2) {

          var params = {
            title: userInstruction.message
          }
          axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
            "jwt"
          )}`; 
          const response = await axios.post(URLDaylIAgent + "/SessionChatId", 
          params, 
          { withCredentials: false });

          console.log("response : ", response.data)

          userInstruction["rank"] = 0
          userInstruction["evaluation"] = 0
          userInstruction["sessionId"] = response.data?.data?.id ? response.data?.data?.id : sessionId

          axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
            "jwt"
          )}`; 
          params = userInstruction
          const responseUser = await axios.post(URLDaylIAgent + "/SessionChatMessageId", 
          params, 
          { withCredentials: false });
          console.log("response : ", responseUser.data?.data)


          assistantInstuctions["rank"] = 0
          assistantInstuctions["evaluation"] = 0
          assistantInstuctions["sessionId"] = response.data?.data.id ? response.data?.data.id : sessionId

          params = assistantInstuctions
          axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
            "jwt"
          )}`; 
          const responseAssitant = await axios.post(URLDaylIAgent + "/SessionChatMessageId", 
          params, 
          { withCredentials: false });
          console.log("response : ", responseAssitant.data)


        setsessionsChat(prevSessionsChat => [newSession, ...prevSessionsChat]);

        setsessionsChat((prevSessionsChat) => {
          return prevSessionsChat.map((session) => {
            if (session.ID === sessionId) {
              // Update the messages for the matching session
              return {
                ...session,
                label:session.label === "Nouveau chat" ? "Ajouter un titre du chat..." : session.label,
                id: response.data?.data.id,
                messages: newMessages,
              };
            } else {
              return session;
            }
          });
        });
    
    } 
    else {

    setsessionsChat((prevSessionsChat) => {
      return prevSessionsChat.map((session) => {
        if (session.ID === sessionId) {
          // Update the messages for the matching session
          return {
            ...session,
            label:session.label === "Nouveau chat" ? "Ajouter un titre du chat..." : session.label,
            id: sessionId,
            messages: newMessages,
          };
        } else {
          return session;
        }
      });
    });

    userInstruction["rank"] = 0
    userInstruction["evaluation"] = 0
    userInstruction["sessionId"] = sessionId

    axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
      "jwt"
    )}`; 
    params = userInstruction
    const responseUser = await axios.post(URLDaylIAgent + "/SessionChatMessageId", 
    params, 
    { withCredentials: false });
    console.log("response : ", responseUser.data)


    assistantInstuctions["rank"] = 0
    assistantInstuctions["evaluation"] = 0
    assistantInstuctions["sessionId"] = sessionId

    params = assistantInstuctions
    axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
      "jwt"
    )}`; 
    const responseAssitant = await axios.post(URLDaylIAgent + "/SessionChatMessageId", 
    params, 
    { withCredentials: false });
    console.log("response : ", responseAssitant.data)
    }

  };
  

  // Inside your handleSendMessage function in React
  const handleSendMessage = async (message) => {
    if (userMessage.trim() !== '' || message.trim() !== '') {
      // Append user message to the chat
      setMessages([...messages, { role: 'user', message: message ? message : userMessage }]);
      setUserMessage('');
      setLoading(true); // Set loading to true when sending the message

      try {
        // Make API call to localhost:5000/llm_agent_gpt
        var params = {
          user_input: message ? message : userMessage,
          history: messages,
          system_prompt: systemPromptingBuilder(props.data),
          mode: modeCom
        }
        const response = await axios.post(URLDaylIAgent + Modele, 
        params, 
        { withCredentials: false });

        // Append the response from the API to the chat without overwriting user's question
        setMessages((prevMessages) => [
          ...prevMessages,
          { role: 'assistant', message: 'Processing...' }, // Display processing while waiting for the response
        ]);
        scrollToBottom();

        setTimeout(async () => {
          
          setMessages((prevMessages) => [
            ...prevMessages.slice(0, -1), // Remove the 'Processing...' message
            { role: 'assistant', message: response.data.message },
          ]);




                      // Update the sessionChat based on ID and new messages
                      updateSessionChat(messagesIdChat, [
                        ...messages,
                        { role: 'user', message: message ? message : userMessage  },
                        { role: 'assistant', message: response.data.message },
                      ], 
                      { role: 'USER', message: message ? message : userMessage  },
                      { role: 'ASSISTANT', message: response.data.message },
                      );


          setLoading(false); // Set loading to false after displaying the response
          scrollToBottom();
        }, 1500); // Adjust the delay time as needed
      } catch (error) {
        console.error('Error calling the API:', error);
        setLoading(false); // Set loading to false in case of an error
      }
    }
  };


  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };


  const [mode, setmode] = useState(1)
  
  



  const [label, setlabel] = useState("Communauté")
  const [modeCom, setmodeCom] = useState("Communauté")
  const [versionAnchor, setVersionAnchor] = useState(false);

  const handleVersionClick = (event) => {
    setVersionAnchor(true);
  };

  const handleVersionClose = (version, label, mode) => {
    setVersionAnchor(false);
    handleCloseBis(false)
    setModele(version)
    setlabel(label)
    setmodeCom(mode)
  };

  const [suggest, setsuggest] = useState(getRandomElements(GeneralQuestionsAboutArt, 10))


  const [anchorEl, setAnchorEl] = React.useState(null);
  const openBis = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseBis = () => {
    setAnchorEl(null);
  };
   

  function randomQuestions(){ 
    if (props.questions){
      setsuggest(getRandomElements(props.questions, props.nb))
    }
  }

  useEffect(() => {
    randomQuestions()
  }, [])


  async function updateChat(elem, index){
    var section = sessionsChat[index]
    setMessages(section["messages"])
    setMessagesIdChat(section["ID"])
    

    axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
      "jwt"
    )}`;  
    const response = await axios.get(URLDaylIAgent + '/SessionChatMessageId?sessionId=' + section["ID"]);
    console.log("response.data");
    console.log(response.data);
    setMessages(response.data?.data)
  }

 

  const getSessionChatId = async () => {
    try {
      axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
        "jwt"
      )}`; 
      const response = await axios.get(URLDaylIAgent + '/SessionChatId');
      console.log(response.data);
      
      // Ajouter l'id et l'étiquette aux données de session récupérées
      const sessionsWithData = response.data?.data?.map(session => ({
        ...session,
        "ID": session.id, // Assumer que l'identifiant est déjà dans les données
        "label": session.title, // Assumer que l'étiquette est déjà dans les données
        "user": "demo", // Assumer que l'utilisateur est fixé à "demo"
        "messages": [], // Assumer que les messages sont initialisés à un tableau vide
      }));
      
      // Mettre à jour l'état avec les nouvelles données de session
      setsessionsChat(prevSessionsChat => [ ...prevSessionsChat, ...sessionsWithData], () => {
        console.log("sessionsChat :", sessionsChat);
      });
      
    } catch (error) {
      console.error('Error fetching files list:', error);
    }
  };
  

  useEffect(() => {
    getSessionChatId()
  }, [])

  const [activeIndex, setActiveIndex] = useState(null);
  return (  

    <>
         {mode === 1 && 
          <div className="app-container">

            <div className="sidebar">
              <div className="sidebar-header">
                <h2>Dayl</h2>
                <AddCircleIcon style={{
                  position:"absolute",
                  cursor:"pointer",
                  right:"0px",
                  bottom:"-23px"
                }}  />
              </div>
              <Divider />
              <hr />
              <ChatSession sessionsChat={sessionsChat} />
               
              <Divider />
              <div className="sidebar-footer">
                <div>
                  <Avatar alt="Profile" src="https://static.vecteezy.com/ti/photos-gratuite/p1/24492193-profil-portrait-de-robot-bot-ai-avec-connecte-des-lunettes-blanc-contexte-synthetique-humanoide-robot-artificiel-intelligence-portrait-de-gynoide-futuriste-cyborg-banniere-avec-espace-pour-texte-copie-espace-photo.jpeg" />
                </div> 
                <div> 
                  <label style={{
                    position:"relative",
                    marginLeft:"10px"
                  }} > Agent Test  </label>
                  <SettingsIcon className="settings"  onClick={handleSettingsClick} />
                </div>
                <Menu
                  anchorEl={settingsAnchor}
                  open={Boolean(settingsAnchor)}
                  onClose={handleSettingsClose}
                > 
                  <MenuItem onClick={() => {
                    setmode(2)
                    handleSettingsClose()
                  }}> <SettingsIcon /> Parametres </MenuItem>
                  <MenuItem onClick={handleSettingsClose}> <RefreshIcon />  Historique </MenuItem>
                  <MenuItem onClick={handleSettingsClose}> <BarChartIcon />  Usage </MenuItem>

                </Menu>
              </div>
            </div>
            
              <div className="main-content">
                  <div className="textMainContent" >
                    Chat UI <span style={{
                      fontSize:"14px",
                      color:"grey"
                    }} > ({"Profil : " + auth.data.userType + " d'art"})   </span>
                  </div>
                  <div className="bodyMainContent" >
                      <img src={"https://horizon-ui.com/chatgpt-ai-template/_next/static/media/bg-image.4b3208f1.png"}  />
                      <div className="top-select">
                      <div  className={`icon-top-select${isActiveUi ? '-active' : ''}`}
                        onClick={() => {
                          setModele("/llm_agent_gpt")
                          setIsActiveUi(true)
                        }}
                      >
                        <AutoAwesomeIcon
                          style={{
                            color: "#1f1fe7",
                            fontSize: "18px",
                            marginRight: "5px"
                          }}
                        />
                        GPT-3.5
                      </div>
                      <div
                        className={`icon-top-select${!isActiveUi ? '-active' : ''}`}
                        onClick={() => {
                          setIsActiveUi(false)
                          handleVersionClick() 
                        }}
                      >
                          <Button 
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            style={{
                              fontSize:"11px",
                              fontFamily:"sans-serif",
                              fontWeight:"700",
                              color:"black"
                            }}
                          >
                            <BoltRounded
                              style={{
                                color: "#1f1fe7",
                                fontSize: "18px",
                                marginRight: "5px"
                              }}
                            />
                          {label}
                          </Button>
                      </div>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openBis}
            onClose={handleCloseBis}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
          <MenuItem onClick={() => handleVersionClose("/VectorStoreGPTBert", "Communauté publique ", "PUBLIC")}>
              <BoltRounded
              style={{
                  color: "#1f1fe7",
                  fontSize: "18px",
                  marginRight: "5px"
              }}
              />
               Communauté publique 
          </MenuItem>
          <MenuItem onClick={() => handleVersionClose("/VectorStoreGPTBert", "Communauté privée ", "PRIVATE")}>
              <BoltRounded
              style={{
                  color: "#1f1fe7",
                  fontSize: "18px",
                  marginRight: "5px"
              }}
              />
               Communauté privée 
          </MenuItem>

            <MenuItem onClick={() => handleVersionClose("/VectorStoreGPTBert", "Communauté académique (Étudiant) (publique) ", "STUDENTS")}>
                <BoltRounded
                style={{
                    color: "#1f1fe7",
                    fontSize: "18px",
                    marginRight: "5px"
                }}
                />
                Communauté académique (Étudiant) (publique) 
            </MenuItem>
            <MenuItem onClick={() => handleVersionClose("/VectorStoreGPTBert", " Communauté académique (corps professoral, encadrement administratif, etc.) (publique) ", "PROFESSORS")}>
                <BoltRounded
                style={{
                    color: "#1f1fe7",
                    fontSize: "18px",
                    marginRight: "5px"
                }}
                />
                 Communauté académique (corps professoral, encadrement administratif, etc.) (publique) 
            </MenuItem>
          </Menu>

                       
            
                      </div> 
                      

    
                      
                    <div className="bodyText">
                      <RefreshIcon style={{
                        position:"absolute",
                        right:"10px",
                        top:"10px",
                        cursor:"pointer"
                      }} onClick={randomQuestions} />
                        <Box style={{ fontSize:"11px", display: 'flex', flexWrap: 'wrap', padding: '3px', gap: '0px', justifyContent: 'center' }}>
                          {suggest?.map((item, index) => (
                            <div
                              className="element_suggest"
                              key={index}
                              style={{
                                padding: '10px',
                                margin: '5px',
                                cursor: 'pointer',
                                borderRadius: '10px',
                                border: '1px solid #ccc',
                                width: 'fit-content',
                                wordWrap: 'break-word',
                                fontFamily:"sans-serif",
                                fontSize:"14px",
                                transition: 'background 0.3s ease-in-out'
                              }}
                              onClick={(e) => {
                                handleSendMessage(item)
                              }}
                            >
                              {item}
                            </div>
                          ))}
                        </Box>

                        <div className="sessionContainer">
                          <div className="messagesContainer">
                            {messages.map((message, msgIndex) => (
                              <div key={msgIndex} className={`message ${message.role.toLowerCase()}`}>
                                {message.role.toLowerCase() === 'user' ? (
                                  <>
                                    <div className="messageContent userContent">
                                      {message.message}
                                      </div>
                                    <div className="avatar userAvatar"> 
                                    <Avatar  alt="Remy Sharp" src="https://us.123rf.com/450wm/larichev89/larichev891903/larichev89190300012/124784227-ic%C3%B4ne-bleue-de-l-utilisateur-dans-un-style-plat-%C3%A0-grandissime-bouton-de-compte.jpg?ver=6" />
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="avatar assistantAvatar">  
                                        <Avatar  alt="Remy Sharp" src="https://static.vecteezy.com/ti/photos-gratuite/p1/24492193-profil-portrait-de-robot-bot-ai-avec-connecte-des-lunettes-blanc-contexte-synthetique-humanoide-robot-artificiel-intelligence-portrait-de-gynoide-futuriste-cyborg-banniere-avec-espace-pour-texte-copie-espace-photo.jpeg" />
                                    </div>
                                    <div className="messageContent assistantContent">
                                      {message.message}
                                      <div className="iconContainer">
                                        <div>
                                          <ThumbUpOffAltIcon style={{
                                            cursor:"pointer",
                                            fontSize:"14px",
                                            color:"grey",
                                            marginRight:"10px"
                                          }} />
                                          <ThumbDownOffAltIcon style={{
                                            cursor:"pointer",
                                            fontSize:"14px",
                                            color:"grey"
                                          }}   /> 
                                          </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            ))}
                          </div>
                        </div> 
                    </div>



                    <div className="footerText">
                      {loading && 
                        <div style={{textAlign:"left", left:"13%", fontSize:"11px", color:"grey"}} >GPT is thinking...</div>
                      }
                      <input
                        type="text"
                        placeholder="Type your message..."
                        value={userMessage}
                        onChange={(e) => setUserMessage(e.target.value)}
                        style={{
                          width: '70%',
                          borderRadius: '20px', // You can adjust the border-radius to your preference
                          padding: '10px',
                          marginRight: '10px',
                          border: '1px solid #ccc',
                        }}
                      />
                      <SendIcon
                        style={{
                          color: "#1f1fe7",
                          fontSize: "30px",
                          cursor: "pointer",
                          top:"10px"
                        }}
                        onClick={(e) => {
                          handleSendMessage(inputText)
                        }}
                        /* onClick={handleSendMessage} */ // Implement the function to handle sending messages
                      />
                      <AttachFileIcon
                        style={{
                          color: "black",
                          fontSize: "26px",
                          cursor: "pointer",
                          top:"8px"
                        }}
                        /* onClick={handleAttachImage} */ // Implement the function to handle attaching images
                      />
                    </div>


                  </div>
              </div>
              </div>
              }


            {mode === 2 && 
                <UploadContainer {...props} setmode={setmode}  />
              }
    </>


  );
}