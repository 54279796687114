import React, {useEffect, useState} from "react";
import axios from "axios";
import {URLDayl} from "../../services/constantes"
import {Conversation} from "./Conversation";
import photo from "../../webapp/temp/arthur_1.jpg"
import photo2 from "../../webapp/temp/olivier_profil_1.jpeg"
import ChatModal from "./ChatModal";
import {Link} from "react-router-dom";
import AsyncSelect from "react-select/async";
import pers from "../../webapp/img/icons/ico_relations_1.svg";

/**
 * Chat menu with list of conversation
 * @param {object} user - user object from api
 * @param {object} setUser - user setter
 *  @param {boolean} isLogged - test if the user is logged
 *  @param {boolean} boolChat - test if the chat menu is open
 *  @param {boolean} setBoolChat - boolChat setter
 */

export default function ChatMenu({user, setUser, isLogged, boolChat, setBoolChat}) {

    const [boolDisplay, setBoolDisplay] = useState(false);
    const [listMsgBox, setListMsgBox] = useState([]);
    const [listSender, setListSender] = useState([]);
    const [convos, setConvos] = useState([])

    useEffect(() => {
        setNewConvo(undefined)
    },[])


    const [selectedValue, setSelectedValue] = useState(null);
    const [inputValue, setValue] = useState('');
    const [users, setUsers] = useState([]);
    const [link, setLink] = useState()
    const [showSearchUserModal, setShowSearchUserModal] = useState(false)
    const [newConvo, setNewConvo] = useState()



    const handleInputChange = value => {
        setValue(value);
    };

    const loadOptions = (inputValue) => {
        return axios.post(URLDayl + 'users/searchUsersByText', {freeText: inputValue}
        ).then((res) =>
            res.data);
    };

    // handle selection for async dropdown
    const handleChange = value => {
        // setSelectedValue(value);
        // link.current.click()
        //send the selected artist to the api


        // return <Link className={'linkToProfile'} to={`../../view/ProfileAmateur.js/${value.id}`} state={value.id}>
        // setConvos(convo => [...convo, ])
        setNewConvo(value.id)

    }




    useEffect(() => {
        const jwt = localStorage.getItem('jwt')
        axios.get(URLDayl + 'messages/inbox', {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        }).then((response) => {
            console.log(response.data)
            setConvos(response.data)
        })
    }, [])


    function handleVisible() {
        if (boolDisplay === false) {
            setBoolDisplay(true);
        } else {
            setBoolDisplay(false);
        }
        console.log(boolDisplay)

    }

    function addMessageBox() {

        let tempList = listMsgBox;

    }


    return (
        <div className="ChatMenu">
            {/* <!-- BOX MESSAGERIE FIXED BOTTOM - début --> */}
            <div className={boolDisplay === true ? "msgBottomBox open" : "msgBottomBox closed"}>

                <header>
                    <a href="">
                        {user.iconImage !== "" ? <img src={user && user.id && URLDayl + `images/contentUser/${user.id}/${user.iconImage}`}
                              alt="Messagerie"/> : <img src={"/static/media/find "}
                                                        alt="Messagerie"/>}
                        <span>Messagerie</span>
                    </a>
                    <button className="options"></button>
                    <button className="newMsg" onClick={() => setShowSearchUserModal(!showSearchUserModal)}></button>
                    <button className={boolDisplay === true ? "isOpen" : "isClosed"} onClick={handleVisible}></button>
                    <button className="close" onClick={e => setBoolChat(false)}></button>
                </header>

                <section>
                    {
                        convos.map((el) => {
                            return (
                                <div onClick={() => {
                                    setNewConvo(el.second.id);
                                }}>
                                <Conversation convo={el} user={user}/>
                                </div>
                            )
                        })
                    }

                    {/*<article >*/}
                    {/*    <a href="" className="pic"><img src={photo2} alt="Messagerie" /></a>*/}
                    {/*    <a href="" className="sender">Arthur de Frayssinet</a>*/}
                    {/*    <a href="" className="date">9 Juin 2022</a>*/}
                    {/*    <a href="" className="extract">Lorem Ipsum is simply dummy text of the printing and ...</a>*/}
                    {/*</article>*/}

                    {/*<article>*/}
                    {/*    <a href="" className="pic"><img src={photo2} alt="Messagerie" /></a>*/}
                    {/*    <a href="" className="sender">Arthur de Frayssinet</a>*/}
                    {/*    <a href="" className="date">9 Juin 2022</a>*/}
                    {/*    <a href="" className="extract">Lorem Ipsum is simply dummy text of the printing and ...</a>*/}
                    {/*</article>*/}

                    {/*<article>*/}
                    {/*    <a href="" className="pic"><img src={photo2} alt="Messagerie" /></a>*/}
                    {/*    <a href="" className="sender">Arthur de Frayssinet</a>*/}
                    {/*    <a href="" className="date">9 Juin 2022</a>*/}
                    {/*    <a href="" className="extract">Lorem Ipsum is simply dummy text of the printing and ...</a>*/}
                    {/*</article>*/}

                    {/*<article>*/}
                    {/*    <a href="" className="pic"><img src={photo2} alt="Messagerie" /></a>*/}
                    {/*    <a href="" className="sender">Arthur de Frayssinet</a>*/}
                    {/*    <a href="" className="date">9 Juin 2022</a>*/}
                    {/*    <a href="" className="extract">Lorem Ipsum is simply dummy text of the printing and ...</a>*/}
                    {/*</article>*/}

                </section>


                {showSearchUserModal && <div className="hashtagSearch" style={{bottom: 0}} data-tooltip="Recherche de personnes">
                    {/*<form style={{backgroundColor: 'orange'}}>*/}
                    {/*    <AsyncSelect*/}

                    {/*        style={{ borderWidth: "0", backgroundColor: "blue"  }}*/}
                    {/*        cacheOptions*/}
                    {/*        defaultOptions*/}
                    {/*        value={selectedValue}*/}
                    {/*        getOptionLabel={e => (e.lastName + " " + e.firstName)}*/}
                    {/*        getOptionValue={e => e.artisteId}*/}
                    {/*        loadOptions={loadOptions}*/}
                    {/*        onInputChange={handleInputChange}*/}
                    {/*        onChange={handleChange}*/}
                    {/*        placeholder={""}*/}
                    {/*    /></form>*/}
                    <form action="" style={{width: 300, borderColor: 'black', borderWidth: 1, borderStyle: "solid"}}>
                        {selectedValue && <Link className={'linkToProfile'} ref={(a) => setLink(a)}
                                                to={`../../view/ProfileAmateur.js/${selectedValue.id}`}
                                                state={selectedValue.id}/>}
                        <AsyncSelect
                            style={{
                                backgroundImage: `url(${pers})`,
                                backgroundSize: "25px 25px",
                                display: "flex",
                                flex: 1,
                                width: '100%'
                            }}
                            cacheOptions
                            defaultOptions

                            // components={{Option : CustomOption}}
                            value={selectedValue}
                            getOptionLabel={e => (e.firstName + " " + e.lastName)}
                            getOptionValue={e => e.id}
                            loadOptions={loadOptions}
                            onInputChange={handleInputChange}
                            onChange={handleChange}
                            placeholder={""}

                        />

                        {/*<input type="text" style={{ backgroundImage: `url(${pers})`, backgroundSize: "25px 25px" }} size="35" placeholder="" required />*/}
                        {/*<input type="button" value="" />*/}
                    </form>
                </div>}

                {/*{newConvo && newConvo.id && <ChatModal user={user} setUser={setUser} friend={newConvo.id} isLogged={isLogged}/> }*/}
            </div>
            {/* <!-- BOX MESSAGERIE FIXED BOTTOM - fin --> */}
            {/*<div className="messageBox" style={{height:boolDisplay === false &&  "50px"}}>*/}
            {/*    <div className="titleMessageBox">*/}
            {/*        <ul className="inlineList mb-a">*/}
            {/*       <li> <a className="titleA" style={{marginRight: "70px"}}> Messagerie</a></li>*/}
            {/*       <li> <button className="moreButton"></button></li>*/}
            {/*       <li> <button className="newMessageButton"></button></li>*/}
            {/*       <li> <button className={boolDisplay=== true ? "collapseButtonOpen" : "collapseButtonClose"} onClick={handleVisible} ></button></li>*/}

            {/*        </ul>*/}
            {/*    </div>*/}

            {/*    <div className={boolDisplay=== true ? "contentMessageBoxOpen" : "contentMessageBoxClose"}>*/}

            {/*    </div>*/}
            {/*</div>*/}

            {newConvo && <ChatModal user={user} setUser={setUser} friend={newConvo} isLogged={isLogged}/> }
        </div>
    );
}
