import React from "react";
import { Link } from "react-router-dom";
import { URLDaylImage } from "../../services/constantes";

const MovementPreviewGrid = ({ movements, onMovementSelect }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
      {movements.map((movement) => (
        <Link
          key={movement.id}
          to={`/view/DaylContent/${movement.id}`}
          className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer transform transition duration-300 hover:scale-105"
        >
          <div className="relative h-48">
            {movement.paintingId && (
              <img
                src={`${URLDaylImage}images/paintingPic/${movement.paintingId}`}
                alt={movement.movement}
                className="w-full h-full object-cover"
              />
            )}
            <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity duration-300 flex items-center justify-center p-4">
              <p className="text-white text-sm">{movement.summary}</p>
            </div>
          </div>
          <div className="p-4">
            <h3 className="text-lg font-semibold text-gray-800 truncate">
              {movement.movement}
            </h3>
            <p className="text-sm text-gray-600">{movement.dateStart}</p>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default MovementPreviewGrid;
