import { useEffect, useState } from "react";

import "react-toastify/dist/ReactToastify.css";
import RouterManager from "./Routes/RouterManager";
import { UserProvider } from "./contexts/userService";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import DeviceModal from "./component/general/DeviceModal";

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLaptop, setIsLaptop] = useState(true);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsLaptop(window.innerWidth >= 1024);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  useEffect(() => {
    if (!isLaptop) {
      setIsModalOpen(true);
    }
  }, [isLaptop]);

  return (
    <I18nextProvider i18n={i18n}>
      <UserProvider>
        <div className={`app ${isLaptop ? "" : "blur-sm"}`}>
          <RouterManager />
        </div>
        {!isLaptop && <DeviceModal open={isModalOpen} />}
      </UserProvider>
    </I18nextProvider>
  );
}

export default App;
