import { URLDayl } from "../../../services/constantes";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useUser } from "../../../contexts/userService";
import ImageGrid from "../ImageGrid/ImageGrid";
import { Button } from "@mui/material";

export default function SelectPaintingLists({ selected, setSelected }) {
  const [listList, setListList] = useState([]);
  const [selectedList, setSelectedList] = useState();

  const [paintings, setPaintings] = useState([]);

  const auth = useUser();
  console.log(paintings)

  useEffect(() => {
    axios
      .get(URLDayl + "userLists/byUserId", {
        params: { userId: auth.data.id },
        headers: { Authorization: `Bearer ${auth.data.jwt}` },
      })
      .then((response) => {
        setListList(response.data);
        console.log(response.data);
        const boolArray = response.data.map((el) => {
          return { ...el, bool: false };
        });
        setListList(boolArray);
      });
  }, []);

  useEffect(() => {
    axios
      .get(URLDayl + "userLists/detailByListId", {
        params: { listId: selectedList },
      })
      .then((response) => {
        setPaintings(response.data.listPaintings);
      });
  }, [selectedList]);

  return (
    <div className="w-full">
      {!selectedList ? (
        <div className="accountArtists w-full ">
          {listList.length > 0 &&
            listList.map((list, index) => (
              <div
                className="item"
                style={{
                  backgroundImage: `url(${URLDayl}images/paintingPic/${list.imageUrl})`,
                }}
              >
                <div className="name">{list.listName}</div>
                <div className="items-count">{list.countItems} oeuvres</div>
                <div
                  className="discover"
                  onClick={() => setSelectedList(list.id)}
                ></div>
              </div>
            ))}
        </div>
      ) : (
        paintings.length > 0 &&
        selected && (
          <>
          <div>
            <Button
              variant=""
              color={"primary"}
              sx={{
                border: "none",
                margin: 2,
                borderBottom: "2px solid",
                borderColor: "primary.main",
                borderRadius: "0px",
              }}
              onClick={() => {
                setSelectedList();
              }}
            >
              Retour sur mes listes
            </Button>
            <ImageGrid
              user={auth.data}
              isLogged={auth.data !== undefined}
              paintings={paintings}
              showTools={false}
              selectionMode={true}
              mutlipleSelection={false}
              selected={selected}
              setSelected={setSelected}
              maxNumPainting={50}
              setMaxNumPainting={() => {}}
              totalMaxNum={50}
              size={6}
            />
            </div>
          </>
        )
      )}
    </div>
  );
}
