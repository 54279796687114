import React, {useEffect, useState} from "react";
import numberOneSvg from "../../webapp/img/icons/number-one.svg";
import numberTwoSvg from "../../webapp/img/icons/number-two.svg";
import AutoCompleteCustom from "../PaintingSearch/AutoCompleteCustom";
import {useForm} from "react-hook-form";
import {useCompteExpert} from "../../contexts/CompteExpertService";
import {URLDayl} from "../../services/constantes";
import axios from "axios";
import TextareaAutosize from "react-textarea-autosize";
import {toast} from "react-toastify";


export const ColorCompteExpert = ({user}) => {

    const [selectedValue, setSelectedValue] = useState(null);
    const compteExpert = useCompteExpert();
    const [options, setOptions] = useState([]);
    const [optionsNonFiltered, setOptionsNonFiltered] = useState([]);
    const [color, setColor] = useState()

    useEffect(() => {
        axios.get(URLDayl + `paintingEntity/get106ColorsAll`).then((response) => {
            var mapped = response.data.map((el) => {
                return {name: el.color_name, id: el.id, rgb: el.rgb,statement: el.color_statement}
            })
            setOptions(mapped)
            setOptionsNonFiltered(mapped)
        })
    },[])


    const handleSelect = (e, value) => {
        axios.get(URLDayl + `paintingEntity/get106ColorsAll`).then((response) => {
            setColor(response.data.find((el) => el.color_name === value.name))
        })
    };
    const handleInputChange = async (e, value) => {
        setOptions(optionsNonFiltered.filter((el) => el.name.toLowerCase().includes(value.toLowerCase())))
    };
    const submitChangeColor = async () => {
        await axios
            .put(URLDayl + "paintingEntity/update106Colors", color)
            .then((response) => {
                toast.success("Color updated", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
            .catch((err) => {
                toast.error("Could not change color", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    };

    return (
        <>
            <div className="editMain center-tex">
                <h1>Modifier une page couleur</h1>
                <p className="how">
                    Entrez le nom de la couleur dont vous souhaitez modifier les
                    informations puis sélectionnez la langue d'édition afin de faire
                    apparaître le menu d'édition.
                </p>
            </div>

            <div className="editMain mt-b pb-a">
                <div className="inline">
                    <form className="subOpt" style={{marginTop: "30px"}}>
                        <img src={numberOneSvg}/>
                        <p>Nom de la couleur</p>

                        <AutoCompleteCustom
                            className={"thisisMyClass"}
                            options={options}
                            defaultValue={selectedValue}
                            onSelect={handleSelect}
                            onChange={handleInputChange}
                            placeholder="Nom de l'artiste"
                        />
                    </form>

                    <div className="subOpt" style={{marginTop: "30px"}}>
                        <img src={numberTwoSvg}/>
                        <p>Langue d'édition</p>
                        <ul className="langEdit">
                            <li
                                className={
                                    compteExpert.language === "FRENCH" ? "fr active" : "fr"
                                }
                                onClick={() => compteExpert.setLanguage("FRENCH")}
                            >
                                Français
                            </li>
                            <li
                                className={
                                    compteExpert.language === "ENGLISH" ? "en active" : "en"
                                }
                                onClick={() => compteExpert.setLanguage("ENGLISH")}
                            >
                                Anglais
                            </li>
                            <li
                                className={
                                    compteExpert.language === "SPANISH" ? "es active" : "es"
                                }
                                onClick={() => compteExpert.setLanguage("SPANISH")}
                            >
                                Espagnol
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            {color && compteExpert.language !== "" && (
                <section style={{width: "100%"}}>
                    <form className="editMain darkBgGradient">
                        <div className="editMain edit center-tex">
                            <h2>Informations générales pour cette couleur</h2>

                            <div className="editMain inline">
                                <div>
                                    <h3>Nom</h3>
                                    <input
                                        type="text"
                                        size="55"
                                        value={color ? color.color_name : ""}
                                        onChange={(value) => {
                                            setColor((previous) => ({
                                                ...previous,
                                                color_name: value.target.value,
                                            }));
                                        }}
                                        placeholder="Nom couleur"
                                    />
                                </div>
                                <div>
                                    <h3>RGB</h3>
                                    <input
                                        type="text"
                                        size="55"
                                        value={color ? color.rgb : ""}
                                        onChange={(value) => {
                                            setColor((previous) => ({
                                                ...previous,
                                                rgb: value.target.value,
                                            }));
                                        }}
                                        placeholder="RGB"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="editMain inline">
                            <h2>Color statement</h2>
                            <TextareaAutosize
                                minRows={5}
                                className="main-text"
                                placeholder={"saisie du texte"}
                                value={color.color_statement}
                                onChange={(value) => {
                                    setColor((previous) => ({
                                        ...previous,
                                        color_statement: value.target.value,
                                    }));
                                }}
                            />
                        </div>
                    </form>

                    <div className="editMain darkBgGradient pt-b pb-c">
                        <input
                            type="submit"
                            className="saveDraftBtn mt-a"
                            value="Enregistrer les modifications"
                            onClick={() => {
                                submitChangeColor();
                            }}
                        />
                    </div>

                </section>

            )}

            {/*{artist && compteExpert.language !== "" && (*/}
            {/*  <>*/}

            {/*    <div*/}
            {/*      className="editMain redac lightBgGradient-2 center-tex pb-b"*/}
            {/*      style={{ display: "block" }}*/}
            {/*    >*/}
            {/*      <h2 className="mt-a">Contenu rédactionnel sur l'artiste</h2>*/}

            {/*      <select*/}
            {/*        name="année"*/}
            {/*        className="mt-a mb-b"*/}
            {/*        onChange={(event) => setContenuEdition(event.target.value)}*/}
            {/*        value={contenuEdition}*/}
            {/*      >*/}
            {/*        <option value="0">*/}
            {/*          Choisissez un contenu rédactionnel à modifier*/}
            {/*        </option>*/}
            {/*        <option value="avisExperts">Avis d'expert</option>*/}
            {/*        <option value="elementBiographie">Element biographie</option>*/}
            {/*        <option value="2">Analyse chromatique</option>*/}
            {/*        <option value="artisteStatement">Artiste statement</option>*/}
            {/*        <option value="5">Actualités</option>*/}
            {/*        <option value="contenuWikipedia">Contenu wikipedia</option>*/}
            {/*      </select>*/}

            {/*      {change()}*/}
            {/*    </div>*/}

            {/*    <div className="editMain darkBgGradient pt-b pb-c">*/}
            {/*      <input*/}
            {/*        type="submit"*/}
            {/*        className="saveDraftBtn mt-a"*/}
            {/*        value="Enregistrer les modifications"*/}
            {/*        onClick={() => {*/}
            {/*          submitChangeArtist();*/}
            {/*        }}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </>*/}
            {/*)}*/}
        </>
    );
};
