import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import { Link } from "react-router-dom";
import { URLDayl } from "../../services/constantes";
import "react-multi-carousel/lib/styles.css";
import Fav from "../general/Fav";
import Box from "@mui/material/Box";
import { Slider } from "@mui/material";
import { withStyles } from "@mui/styles";
import HSLSliders from "../MatchingColor/HSLSliders";
import { useTranslation } from "react-i18next";
import { SearchOutlined } from "@mui/icons-material";
import SmallGrid from "../general/ImageGrid/SmallGrid";
import ColorPaletteItem from "../MatchingColor/ColorPaletteItem";

/**
 * This component display a color Picker and allow you to find paitings with the selected color
 * @param {object} user - user object from api
 * @param {object} setUser - user setter
 *  @param {boolean} isLogged - test if the user is logged
 * @emits - users/${user.id} : update user informations
 * @emits - paintingSearch/findByColor : get list of paintings by selected color


 */

const styles = {
  root: {
    borderRadius: 20,
    padding: 10,
    // boxShadow: "rgba(0, 0, 0, 0.36) 0px 3px 6px, rgba(0, 0, 0, 0.33) 0px 3px 6px"
    paddingRight: 0,
    paddingLeft: 0,
    marginBottom: 10,
    // width: "100%",
    // backgroundImage: 'linear-gradient( to right, rgb(255, 0, 0), rgb(255, 255, 0), rgb(0, 255, 0), rgb(0, 255, 255), rgb(0, 0, 255), rgb(255, 0, 255), rgb(255, 0, 0) )',
  },
  rail: {
    color: "transparent",
    backgroundColor: "transparent",
  },
  track: {
    color: "transparent",
    backgroundColor: "transparent",
  },
  thumb: {
    backgroundColor: "transparent",
    border: "3px solid white",
    borderRadius: "50%",
    width: 30,
    height: 30,
  },
};

export function calculateHSL(hsv) {
  // determine the lightness in the range [0,100]
  var l = ((2 - hsv.s / 100) * hsv.v) / 2;

  // store the HSL components
  const hsl = {
    h: hsv.h,
    s: (hsv.s * hsv.v) / (l < 50 ? l * 2 : 200 - l * 2),
    l: l,
  };

  // correct a division-by-zero error
  if (isNaN(hsl.s)) hsl.s = 0;
  if (isNaN(hsl.l)) hsl.l = 0;
  return hsl;
}

const hsb2hsl = (hue, saturation, brightness) => {
  saturation /= 100;
  brightness /= 100;
  let ll = (2 - saturation) * brightness;
  let ss = saturation * brightness;
  ss /= ll <= 1 ? (ll !== 0 ? ll : 1) : 2 - ll !== 0 ? 2 - ll : 1;
  ll /= 2;
  return [hue, Math.round(ss * 100), Math.round(ll * 100)];
};

export const linearGradientCompute = (hsb) => {
  const [h0, s0, l0] = hsb2hsl(hsb.h, hsb.s, 0);
  const [h1, s1, l1] = hsb2hsl(hsb.h, hsb.s, 100);
  const g0 = `hsl(${h0}, ${s0}%, ${l0}%)`;
  const g1 = `hsl(${h1}, ${s1}%, ${l1}%)`;
  // const gradient = (
  //     <linearGradient id="Brightness" x1="0" x2="0" y1="0" y2="1">
  //         <stop offset="0%" stopColor={g0} />
  //         <stop offset="100%" stopColor={g1} />
  //     </linearGradient>
  // );

  return `linear-gradient( to right, ${g0}, ${g1})`;
};

export const linearGradientCompute2 = (hsb) => {
  const [h0, s0, l0] = hsb2hsl(hsb.h, 0, hsb.v);
  const [h1, s1, l1] = hsb2hsl(hsb.h, 100, hsb.v);
  const g0 = `hsl(${h0}, ${s0}%, ${l0}%)`;
  const g1 = `hsl(${h1}, ${s1}%, ${l1}%)`;
  // const gradient = (
  //     <linearGradient id="Brightness" x1="0" x2="0" y1="0" y2="1">
  //         <stop offset="0%" stopColor={g0} />
  //         <stop offset="100%" stopColor={g1} />
  //     </linearGradient>
  // );

  return `linear-gradient( to right, ${g0}, ${g1})`;
};

export function calculateHSV(newHsl) {
  var h = newHsl.h;
  var s = newHsl.s / 100;
  var l = newHsl.l / 100;

  var v = l + s * Math.min(l, 1 - l);

  var hsv = {
    h: h,
    s: v === 0 ? 0 : 2 * (1 - l / v) * 100,
    v: v * 100,
  };

  // correct a division-by-zero error
  if (isNaN(hsv.s)) hsv.s = 0;
  return hsv;
}

export const HueSlider = withStyles(styles)(Slider);

export default function SearchColor({ user, setUser, isLogged }) {
  //Declaration des variables globales

  const [paintingByColor, setPaintingByColor] = useState([]);
  const [refreshKey] = useState(0);
  // const [color, setColor] = useState([]);

  const [color, setColor] = useColor(
    "hex",
    "#" + Math.floor(Math.random() * 16777215).toString(16)
  );
  const [hsl, setHSL] = useState(calculateHSL(color.hsv));

  //update user
  useEffect(() => {
    if (user)
      axios
        .get(URLDayl + `users/${user.id}`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setUser(response.data);
        });
  }, [refreshKey]);

  const handleChange = (color) => {
    const hsl = calculateHSL(color.hsv);

    setHSL(hsl);
    setColor(color);
  };

  useEffect(() => {
    // setColor({ hex: "#050655", rgb: { r: 5, g: 6, b: 85 } })
    axios
      .post(
        URLDayl + `paintingSearch/findByColorOptimised`,
        {
          colorCode: color.hex,
          sortOrder: "ASC",
          maxNumPainting: 9,
          minNumPainting: 1,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setPaintingByColor(response.data);

        const boolArray = response.data.map((el) => {
          return { ...el, bool: false };
        });

        setPaintingByColor(boolArray);
      });
  }, [color]);

  //get la largeur de la fenetre
  const { t } = useTranslation();

  //update la largeur d'un item quand la largeur de la fenetre change

  return (
    <div className="w-full h-full">
      {/* <!-- ******************************************************
			SECTION CHOISISSEZ VOTRE THÉMATIQUE COULEUR - début
			****************************************************** --> */}
      <section className="flex flex-row bg-black justify-center align-middle p-7 gap-5 h-full">
        <div className="flex flex-col justify-center align-middle">
          <div>
            <Box>
              <ColorPicker
                width={320}
                height={320}
                color={color}
                onChange={handleChange}
                hideHSV
                hideHEX
                hideRGB
              />
              <div>
                <HSLSliders
                  color={color}
                  setColor={setColor}
                  hsl={hsl}
                  setHSL={setHSL}
                />
              </div>
            </Box>
           
          </div>
        </div>
        <div className="flex flex-col items-center gap-5">
          <SmallGrid
            paintingsList={paintingByColor}
            user={user}
            isLogged={isLogged}
            size={6}
          />
           
        </div>
        {/* <!-- carousel - fin --> */}
      </section>

      {/* <!-- SECTION CHOISISSEZ VOTRE THÉMATIQUE COULEUR - fin --> */}
    </div>
  );
}
