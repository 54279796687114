import React from 'react';
import { useNavigate } from 'react-router-dom';

const About = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        // Clear the stored state data
        localStorage.removeItem('counter');
        // Navigate to the Home page
        navigate('/');
    };

    return (
        <div style={{marginTop: 500}}>
            <h1>About Page</h1>
            <p>This is the about page.</p>
            <button onClick={handleClick}>Go to Home</button>
        </div>
    );
};

export default About;