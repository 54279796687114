import React, { useState } from "react";
import { IconButton, Collapse } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { URLDaylImage } from "../../services/constantes";

export default function Header({ setIsOpen }) {
  const [open, setOpen] = useState(false);
  const handleIsOpen = () => {
    setIsOpen(true);
  };
  return (
    <div>
      <div className="relative">
        <div className="flex w-full justify-between p-6 bg-gray-200">
          <div className="text-3xl">EDITO</div>
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </div>
        <Collapse in={open}>
          <div className="p-6 pt-0 bg-gray-200 flex ">
            <div className=" w-3/4 italic">
              <p className="text-xl">
                La diffusion et la transmission des arts constituent un élément
                essentiel dans le développement <br /> de l'éducation et de la
                culture accessible à tous.
              </p>
              <p className="text-xl mt-2">
                Pour ce faire, nous avons développé une plateforme et des outils
                numériques, mis au service de l’art pictural
                <br /> (« matching » de peintures, extraction de couleurs,
                moteurs de recherche …).
              </p>
              <p className="text-xl mt-2">
                La plateforme Dayl se veut une solution complémentaire et
                additionnelle pour les étudiants et amateurs d’art
                <br /> qui souhaitent découvrir de nouveaux artistes et parfaire
                leurs connaissances.
              </p>
              <div className="flex flex-col gap-3 mt-3">
                <p className="text-xl font-bold m-0">L'équipe Dayl</p>
                <div
                  className=" cursor-pointer text-lg font-raleway rounded-full w-fit bg-black hover:bg-blue-900 text-white px-3 py-1"
                  onClick={handleIsOpen}
                >
                  Contactez nous !{" "}
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center w-1/4 gap-5">
              <div className="flex w-fit justify-center items-center px-5 py-3 border-2 border-black rounded-lg ">
                HISTOIRE DE LA PEINTURE
              </div>
              <div className="flex w-fit justify-center items-center px-5 py-3 border-2 border-black rounded-lg ">
                TECHNOLOGIES NUMERIQUES
              </div>
              <div className="flex w-fit justify-center items-center px-5 py-3 border-2 border-black rounded-lg ">
                COMMUNAUTE
              </div>
            </div>
          </div>
        </Collapse>
      </div>

      <div className="flex" style={{ height: "auto", alignItems: "stretch" }}>
        <div
          className="w-3/5"
          style={{ display: "flex", alignItems: "stretch" }}
        >
          <img
            src={`${URLDaylImage}images/paintingPic/15816`}
            alt="homeImage"
            style={{ width: "auto", objectFit: "contain" }}
          />
        </div>
        <div className="w-2/5 flex flex-col">
          <div className="flex flex-col bg-gray-100 justify-center items-center text-gray-700 h-3/5">
            <div
              style={{ fontFamily: "Cinzel" }}
              className="text-8xl font-bold"
            >
              01 <br /> DISCOVER
            </div>
            <div className="text-2xl font-raleway">
              <b>L’histoire de la peinture pour tous</b> <br />
              <i>Passionnés, Curieux, Néophytes, Experts, Artistes</i>
            </div>
          </div>
          <div className="flex text-2xl font-raleway h-2/5 justify-center">
            <div className="w-1/2  flex flex-col justify-center items-center">
              <div>
                <b>Un Site</b> <br /> 10 000 artistes
                <br /> 300 000 œuvres <br /> 300 mouvements
              </div>
            </div>
            <div className="w-1/2 bg-black text-white flex justify-center items-center p-10">
              <div>
                Des <b>contenus</b>, des <b>outils</b>, des <b>usages</b>{" "}
                adaptés à chacun
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
