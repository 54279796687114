import React, { useEffect, useState } from "react";
import axios from "axios";
import { URLDayl } from "../../services/constantes";
import close from "../../webapp/img/icons/ico_close_2.svg";
import { toast } from "react-toastify";
import { AiOutlinePlusCircle } from "react-icons/ai";
import ModalArtistStatement from "./ArtistStatement/ModalArtistStatement";
import { enumContenu } from "./PeintresCompteExpert";
import { actions } from "./ArtistStatement/ArtistStatementCompteExpert";
import BiographieElement from "../ArtistDetailPage/BiographieElement";
import { stringSplitting } from "../../services/Utils";
import { useCompteExpert } from "../../contexts/CompteExpertService";
import { AutoComplete } from "antd";
import AutoCompleteStyles from "../PaintingSearch/AutoCompleteStyles";
import AutoCompleteCustom from "../PaintingSearch/AutoCompleteCustom";
import { AvisExperts } from "./AvisExperts/AvisExperts";
import { enumTypeModalNetwork } from "../CompteAmateurPage/ModalNetwork";
import { Button } from "@mui/material";

/**
 * This component is a modal used to manage your network (add/delete friends,...)
 * @param {object} user - user object from api

 *  @param {boolean} boolModal - test if the network modal is open
 *  @param {boolean} setBoolModal - boolModal setter
 */

export default function ModalEditPainting({
  user,
  boolModal,
  setBoolModal,
  paintId,
}) {
  //  init Variables global
  const [isActive, setIsActive] = useState(1);

  const [artwork, setArtwork] = useState();

  const [keywords, setKeywords] = useState([]);
  const [keywordsToRemove, setKeywordsToRemove] = useState([]);
  const [keywordTemp, setKeywordTemp] = useState();
  const [originalKeywords, setOriginalKeywords] = useState([]);
  const [styles, setStyles] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [avisExpertModal, setAvisExpertModal] = useState(false);
  const [taggedArtworks, setTaggedArtworks] = useState([]);
  const [taggedArtworkModal, setTaggedArtworkModal] = useState(false);
  const [artist, setArtist] = useState();
  const [action, setAction] = useState(actions.Create);
  const [avisExpertPublications, setAvisExpertPublications] = useState([]);
  const [avisExpertPublicationsImages, setAvisExpertPublicationsImages] =
    useState([]);
  const [focusedPublication, setFocusedPublication] = useState();

  const [stylesOptions, setStylesOptions] = useState([]);

  // const [artworkName, setArtworkName] = useState(artwork.aw_title_Origin)
  // const [artworkYear, setArtworkYear] = useState(artwork.aw_year)

  // const [artworkName, setArtworkName] = useState(artwork.aw_title_Origin)
  // const [artworkName, setArtworkName] = useState(artwork.aw_title_Origin)

  const compteExpert = useCompteExpert();

  useEffect(() => {
    if (artwork) {
      console.log(artist);
      axios.get(URLDayl + `artist/${artwork.artist.id}`).then((res) => {
        setArtist(res.data);
      });
    }
  }, [artwork]);

  useEffect(() => {
    axios.get(URLDayl + `paintingFront/listStyles`).then((res) => {
      setStylesOptions(
        res.data
          .map((el) => {
            return {
              name: el.name,
            };
          })
          .sort((a, b) => b.name.localeCompare(a.name))
      );
    });
  }, []);

  useEffect(() => {
    let overlay = document.createElement("div");

    Object.assign(overlay.style, {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      zIndex: 1,
    });
    document.body.appendChild(overlay);
    document.body.style.overflow = "hidden";
    return () => {
      document.body.removeChild(overlay);
      document.body.style.overflow = "unset";
    };
  }, []);

  useEffect(async () => {
    if (artwork) {
      await axios
        .get(URLDayl + `artist/avisExpert/byArtworkId/${paintId}`)
        .then(async (res) => {
          const doubleTempImages = [];

          for (let i = 0; i < res.data.length; i++) {
            const tempImages = [];
            for (let j = 0; j < res.data[i].listImages.length; j++) {
              if (res.data[i].listImages[j] !== "")
                tempImages.push(
                  URLDayl +
                    `images/avisExpert/${res.data[i].artistId}/${res.data[i].id}?name=${res.data[i].listImages[j]}`
                );
            }

            for (let k = 0; k < res.data[i].taggedArtworks.length; k++)
              tempImages.push(
                URLDayl + `images/paintingPic/${res.data[i].taggedArtworks[k]}`
              );

            doubleTempImages.push(tempImages);
          }
          setAvisExpertPublicationsImages(doubleTempImages);

          setAvisExpertPublications(res.data);
        });
      // axios.get(URLDayl + `artist/avisExpert/byArtworkId/${paintId}`).then((res) => {
      //
      //     const doubleTempImages = []
      //
      //     for (let i = 0; i < res.data.length; i++) {
      //         const tempImages = []
      //         for (let j = 0; j < res.data[i].listImages.length; j++) {
      //             if (res.data[i].images[j] !== "")
      //                 tempImages.push(URLDayl + `images/avisExpert/${res.data[i].artistId}/${res.data[i].id}?name=${res.data[i].listImages[j]}`)
      //         }
      //         for (let k = 0; k < res.data[i].taggedArtworks.length; k++)
      //             tempImages.push(URLDayl + `images/paintingPic/${res.data[i].taggedArtworks[k]}`)
      //         doubleTempImages.push(tempImages)
      //     }
      //     console.log(res.data)
      //     setAvisExpertPublicationsImages(doubleTempImages)
      //     setAvisExpertPublications(res.data)
      // })
    }
  }, [paintId, artwork, refreshKey]);

  const updatePainting = async () => {
    await axios
      .post(
        URLDayl + `paintingEntity/update/${compteExpert.language || "ENGLISH"}`,
        artwork
      )
      .then(() => {
        toast.success("Painting modified", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        addKeywords();
        removeKeywords();
      })
      .catch(() => {
        toast.error("Could not change painting", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  let addKeywords = async () => {
    for (var i = 0; i < keywords.length; i++) {
      try {
        if (!originalKeywords.includes(keywords[i])) {
          await axios
            .post(URLDayl + "paintingEntity/update/keyword", {
              itemId: artwork.id,
              operation: "add",
              keyword: keywords[i].name,
            })
            .then((response) => {
              console.log("ADD " + keywords[i].name);
            })
            .catch((err) => {
              toast.error(
                "Painting could not be modified, please try again later.",
                {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                }
              );
            });
        }
      } catch (error) {
        console.log("keyword ", i);
      }
      console.log("axios request done");
    }
  };

  let removeKeywords = async () => {
    for (var i = 0; i < keywordsToRemove.length; i++) {
      try {
        await axios
          .post(URLDayl + "paintingEntity/update/keyword", {
            itemId: artwork.id,
            operation: "remove",
            keyword: keywordsToRemove[i].name,
          })
          .then((response) => {
            console.log(keywordsToRemove[i].name);
            toast.success("Keywords changed", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          })
          .catch((err) => {
            console.log("NOT" + keywordsToRemove[i].name);
            toast.error(
              "Painting could not be modified, please try again later.",
              {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          });
      } catch (error) {
        console.log("keyword ", i);
      }
      console.log("axios request done");
    }
  };

  const deletePost = (id) => {
    axios.get(URLDayl + `artist/avisExpert/${id}`).then((res) => {
      for (let i = 0; i < res.data.images.length; i++) {
        axios.delete(
          URLDayl +
            `images/removeAvisExpertImage?publicationId=${id}&imageName=${res.data.images[i]}&artistId=${artist.id}`
        );
      }
    });

    axios.delete(URLDayl + `artist/avisExpert/${id}`).then((res) => {
      toast.success("Post deleted", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setRefreshKey(refreshKey + 1);
      setAvisExpertPublications([]);
    });
  };

  const deactivateKeyword = (item) => {
    setKeywords((previous) => [
      ...previous.filter((e) => e.name !== item.name),
    ]);
    setArtwork((previous) => ({ ...previous, aw_keyw_import: keywords }));
  };

  useEffect(() => {
    axios.get(URLDayl + `paintingEntity/${paintId}`).then((response) => {
      console.log(response.data);

      setArtwork(response.data);
      const keywords = response.data.aw_keyw_import
        ? response.data.aw_keyw_import.split(",")
        : null;
      let keywords_sliced = [];
      if (keywords) {
        for (let i = 0; i < keywords.length; i++) {
          keywords_sliced.push({
            name: keywords[i],
            id: i.toString(),
          });
        }
      }
      setKeywords(keywords_sliced);
      setOriginalKeywords(keywords_sliced);

      const styles = response.data.aw_style_import
        ? response.data.aw_style_import.split(",")
        : null;
      let styles_sliced = [];
      if (styles) {
        for (let i = 0; i < styles.length; i++) {
          styles_sliced.push({
            name: styles[i],
            id: i.toString(),
          });
        }
      }
      setStyles(styles_sliced);
    });
  }, [paintId]);

  //Active class Tab
  const toggleClass = (val) => {
    setIsActive(val);
  };

  const onDeleteAnalyseChromatique = () => {
    axios
      .delete(URLDayl + `paintingEntity/analyseChromaEmpreinte/${paintId}`)
      .then((res) => {
        toast.success("Suppression", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const onSelectStyle = (e, value) => {
    setArtwork((previous) => ({
      ...previous,
      aw_style_import: value.join(),
    }));
    console.log("VALUE");
    console.log(value);
    console.log(artwork);

    // console.log(value.map((el) => el.name).join())
  };

  return (
    <div className="ModalNetwork">
      {/* <!-- MODAL - DÉBUT --> */}
      <div
        className="smart-modal-overlay"
        style={{ display: "block", zIndex: 4 }}
      ></div>

      <div
        className="smart-modal-content-l scrollAuto"
        style={{
          transform: "translate(-50%,-50%)",
          display: "inline-flex",
          flexWrap: "wrap",
          alignContent: "flex-start",
          zIndex: 5,
        }}
      >
        <h3>Modifier oeuvre #{artwork && artwork.id}</h3>
        <a
          className="smart-modal-close"
          onClick={(e) => setBoolModal(!boolModal)}
        >
          <img className="img-left" src={close} alt="Fermer" />
        </a>
        <div className="layoutArtiste">
          {artwork && (
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                width: "100%",
              }}
            >
              <img
                style={{
                  width: "auto",
                  height: 350,
                  overflow: "hidden",
                  borderRadius: 10,
                }}
                src={URLDayl + `images/paintingPic/${paintId}`}
                alt={artwork.aw_title_origin}
              />
            </div>
          )}
          <h4>Titre de l'oeuvre / année de création</h4>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              gap: "70px",
            }}
          >
            <div className="flex flex-col ">
              <div className="flex items-center">
                <input
                  className="input-field"
                  type="text"
                  value={artwork ? artwork.aw_title_origin : ""}
                  onChange={(value) => {
                    setArtwork((previous) => ({
                      ...previous,
                      aw_title_origin: value.target.value,
                    }));
                  }}
                  placeholder="Titre"
                  required
                />
                <div className="font-bold font-raleway"> EN</div>
              </div>
              <div className="flex items-center">
                <input
                  className="input-field"
                  type="text"
                  value={artwork ? artwork.aw_title_fr : ""}
                  onChange={(value) => {
                    setArtwork((previous) => ({
                      ...previous,
                      aw_title_fr: value.target.value,
                    }));
                  }}
                  placeholder="Titre"
                  required
                />
                <div className="font-bold font-raleway"> FR</div>
              </div>
              {/*<span className="required">*</span>*/}
            </div>

            <div style={{ width: "" }}>
              <input
                className="input-field"
                type="number"
                value={artwork ? artwork.aw_year : ""}
                onChange={(value) => {
                  setArtwork((previous) => ({
                    ...previous,
                    aw_year: value.target.value,
                  }));
                }}
                placeholder="Année"
                required
              />

              {/*<span className="required">*</span>*/}
            </div>
          </div>

          {/*STYLES*/}
          <h4>Styles</h4>

          <div
            style={{
              backgroundColor: "transparent",
              width: "50%",
              margin: "auto",
            }}
          >
            {artwork && (
              <AutoCompleteStyles
                options={stylesOptions}
                onChange={() => {}}
                onSelect={onSelectStyle}
                placeholder={"Styles"}
                defaultValues={artwork.aw_style_import
                  .split(",")
                  .filter((el) => el !== "")
                  .map((el) => {
                    return { name: el };
                  })}
              />
            )}
          </div>

          {/*<div className="hashList toLeft">*/}
          {/*    {*/}
          {/*        styles.map((item) => {*/}
          {/*            return <a key={item.name} href="#">{item.name}</a>*/}
          {/*        })*/}
          {/*    }*/}

          {/*    /!*<a href="#">Synthétisme</a>*!/*/}
          {/*</div>*/}

          <h4>Genre et Support</h4>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              gap: "70px",
            }}
          >
            <div style={{}}>
              <input
                className="input-field"
                type="text"
                value={artwork ? artwork.aw_genre_import : ""}
                onChange={(value) => {
                  setArtwork((previous) => ({
                    ...previous,
                    aw_genre_import: value.target.value,
                  }));
                }}
                placeholder="Genre"
                required
              />
              {/*<span className="required">*</span>*/}
            </div>

            <div style={{}}>
              <input
                className="input-field"
                type="text"
                value={artwork ? artwork.aw_medium : ""}
                onChange={(value) => {
                  setArtwork((previous) => ({
                    ...previous,
                    aw_medium: value.target.value,
                  }));
                }}
                placeholder="Support"
                required
              />
              {/*<span className="required">*</span>*/}
            </div>
          </div>

          <h4>Dimensions</h4>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "70px",
              justifyContent: "center",
            }}
          >
            <div>
              <input
                className="input-field"
                type="text"
                value={artwork ? artwork.aw_dim_height : ""}
                onChange={(value) => {
                  setArtwork((previous) => ({
                    ...previous,
                    aw_dim_height: value.target.value,
                  }));
                }}
                placeholder="height"
                required
              />
              {/*<span className="required">*</span>*/}
            </div>

            <div style={{}}>
              <input
                className="input-field"
                type="text"
                value={artwork ? artwork.aw_dim_width : ""}
                onChange={(value) => {
                  setArtwork((previous) => ({
                    ...previous,
                    aw_dim_width: value.target.value,
                  }));
                }}
                placeholder="width"
                required
              />
              {/*<span className="required">*</span>*/}
            </div>

            <div style={{}}>
              <input
                className="input-field"
                type="text"
                value={artwork ? artwork.aw_indic_dim : ""}
                onChange={(value) => {
                  setArtwork((previous) => ({
                    ...previous,
                    aw_indic_dim: value.target.value,
                  }));
                }}
                placeholder="unité"
                required
              />
              {/*<span className="required">*</span>*/}
            </div>
          </div>

          <h4>Lieu</h4>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: 30,
              justifyContent: "center",
            }}
          >
            <div style={{}}>
              <input
                className="input-field"
                type="text"
                value={artwork ? artwork.aw_locate : ""}
                onChange={(value) => {
                  setArtwork((previous) => ({
                    ...previous,
                    aw_locate: value.target.value,
                  }));
                }}
                placeholder="Lieu"
                required
              />
              {/*<span className="required">*</span>*/}
            </div>
          </div>

          <h4>Source et droits</h4>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "70px",
              justifyContent: "center",
            }}
          >
            <div style={{}}>
              <input
                className="input-field"
                type="text"
                value={artwork ? artwork.aw_desc_basic_source_1 : ""}
                onChange={(value) => {
                  setArtwork((previous) => ({
                    ...previous,
                    aw_desc_basic_source_1: value.target.value,
                  }));
                }}
                placeholder="Source"
                required
              />
              {/*<span className="required">*</span>*/}
            </div>

            <div style={{}}>
              <input
                className="input-field"
                type="text"
                value={artwork ? artwork.aw_licence_type : ""}
                onChange={(value) => {
                  setArtwork((previous) => ({
                    ...previous,
                    aw_licence_type: value.target.value,
                  }));
                }}
                placeholder="Droits"
                required
              />
              {/*<span className="required">*</span>*/}
            </div>
          </div>

          <h4>Bénéficiaires et Crédit line</h4>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "70px",
              justifyContent: "center",
            }}
          >
            <div style={{}}>
              <input
                className="input-field"
                type="text"
                value={artwork ? artwork.aw_beneficiaries : ""}
                onChange={(value) => {
                  setArtwork((previous) => ({
                    ...previous,
                    aw_beneficiaries: value.target.value,
                  }));
                }}
                placeholder="Bénéficiaires"
                required
              />
              {/*<span className="required">*</span>*/}
            </div>

            <div style={{}}>
              <input
                className="input-field"
                type="text"
                value={""}
                onChange={(value) => {}}
                placeholder="Copyright"
                required
              />
              {/*<span className="required">*</span>*/}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "70px",
              justifyContent: "center",
            }}
          >
            <div>
              <h4>Copyright notice</h4>
              <input
                className="input-field"
                type="text"
                value={artist ? artist.copyright_notice : ""}
                onChange={(value) => {
                  setArtist((previous) => ({
                    ...previous,
                    copyright_notice: value.target.value,
                  }));
                }}
                placeholder="Copyright notice"
                required
              />
            </div>
            <div>
              <h4>Copyright external</h4>
              <input
                className="input-field"
                type="text"
                value={artist ? artist.copyright_wrapper : ""}
                onChange={(value) => {
                  setArtist((previous) => ({
                    ...previous,
                    copyright_wrapper: value.target.value,
                  }));
                }}
                placeholder="Copyright wrapper"
                required
              />
            </div>
          </div>
          <h4 className="" style={{ marginBottom: 0, padding: 0 }}>
            Note Dayl
          </h4>
          <input
            className="input-field"
            type="number"
            value={artwork ? artwork.daylMark : ""}
            onChange={(event) => {
              const value = event.target.value;
              if (value >= 0 && value <= 10) {
                setArtwork((previous) => ({
                  ...previous,
                  daylMark: value,
                }));
              }
            }}
            placeholder="Dayl mark"
            required
          />
          <h4 className="" style={{ marginBottom: 0, padding: 0 }}>
            Analyse Chromatique:
          </h4>

          <Button
            variant="outlined"
            style={{ color: "black", border: "solid" }}
            onClick={onDeleteAnalyseChromatique}
          >
            Supprimer l'analyse chromatique
          </Button>

          <h4 className="" style={{ marginBottom: 0, padding: 0 }}>
            Mots clefs
          </h4>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              margin: "auto",
              gap: "10px",
            }}
          >
            <input
              type="text"
              placeholder="Ex : tree, road, sky …"
              className="input-field"
              value={keywordTemp}
              onChange={(event) => setKeywordTemp(event.target.value)}
            />
            <AiOutlinePlusCircle
              onClick={() => {
                setKeywords((key) => [
                  ...key,
                  {
                    name: keywordTemp,
                    id: keywords ? key[0] + keywords.length : 1,
                  },
                ]);
                setKeywordTemp(undefined);
              }}
              style={{ flex: 1, justifyContent: "center", alignSelf: "center" }}
              size={30}
            />
          </div>

          {/*TAGS*/}
          <div className="hashList toLeft" style={{ margin: "auto" }}>
            {keywords.map((item) => {
              return (
                <a
                  key={item.name}
                  style={{
                    backgroundColor: keywordsToRemove.includes(item)
                      ? "grey"
                      : null,
                  }}
                  onClick={() =>
                    keywordsToRemove.includes(item)
                      ? setKeywordsToRemove(
                          keywordsToRemove.filter((el) => el !== item)
                        )
                      : setKeywordsToRemove((key) => [...key, item])
                  }
                  href="#"
                >
                  {item.name}
                </a>
              );
            })}

            {/*<a href="#">Synthétisme</a>*/}
          </div>

          {avisExpertPublications &&
          avisExpertPublicationsImages &&
          avisExpertPublications.length > 0 ? (
            avisExpertPublications.map((el, index) => {
              return (
                <>
                  <BiographieElement
                    artist={"artist"}
                    bg={"bg"}
                    text={el ? stringSplitting(950, el.content) : ""}
                    direction={index % 2 === 0 ? "left" : "right"}
                    title={el.title}
                    imageList={avisExpertPublicationsImages[index]}
                  />
                  <div
                    className=""
                    style={{ marginTop: -50, marginBottom: 50 }}
                  >
                    <div>
                      <input
                        type="reset"
                        className="saveDraftBtn mt-a"
                        value="Modifer"
                        style={{ height: 50, width: "100%" }}
                        onClick={() => {
                          setAction(actions.Modifiy);
                          setAvisExpertModal(true);
                          setFocusedPublication(el);
                        }}
                      />
                    </div>
                    <div>
                      <input
                        type="submit"
                        className="submitFormBtn mt-a"
                        value="Supprimer"
                        onClick={() => {
                          // setBoolModal(false)
                          deletePost(el.id);
                        }}
                      />
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <></>
          )}

          <div style={{ display: "flex", justifyContent: "center" }}>
            <input
              type="submit"
              className="saveDraftBtn"
              value="Ajouter un avis expert"
              onClick={() => {
                // console.log("AVIS EXPERT")
                setAvisExpertModal(true);
                setAction(actions.Create);
              }}
            />
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <input
              type="submit"
              className="saveDraftBtn"
              value="Enregistrer les modifications"
              onClick={() => {
                setBoolModal(false);
                // console.log(artwork)
                updatePainting();
              }}
            />
          </div>
        </div>
      </div>
      {avisExpertModal && artist && artwork && (
        <ModalArtistStatement
          user={user}
          refreshKey={refreshKey}
          setRefreshKey={setRefreshKey}
          setBoolModal={setAvisExpertModal}
          contenu={enumContenu.AvisExperts}
          selected={[artwork.id]}
          setSelected={setTaggedArtworks}
          setArtworkModal={setTaggedArtworkModal}
          action={action}
          publication={focusedPublication}
          artist={artist}
          modePeinture={true}
        />
      )}
    </div>
  );
}
