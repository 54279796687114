import React, { useState, useEffect } from "react";
import axios from "axios";
import { Slider, CircularProgress, Box } from "@mui/material";
import { URLDayl } from "../../services/constantes";
import { URLDaylImage } from "../../services/constantes";

export default function SimpleRequests() {
  const [selectedColors, setSelectedColors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [grayValue, setGrayValue] = useState(0);
  const [opacity, setOpacity] = useState(1);
  const paintId = 15816;
  const isAnyColorSelected = selectedColors.length > 0;
  const handleResetColors = () => {
    setSelectedColors([]);
  };
  const colors = [
    { name: "Rouge", hex: "#FF0000" },
    { name: "Rouge Orange", hex: "#FF4000" },
    { name: "Orange", hex: "#FF8000" },
    { name: "Orange Jaune", hex: "#FFBF00" },
    { name: "Jaune", hex: "#FFFF00" },
    { name: "Jaune Vert", hex: "#BFFF00" },
    { name: "Vert", hex: "#00FF00" },
    { name: "Cyan", hex: "#00FFFF" },
    { name: "Bleu", hex: "#0000FF" },
    { name: "Bleu Violet", hex: "#8000FF" },
    { name: "Violet", hex: "#FF00FF" },
    { name: "Violet Rouge", hex: "#FF0080" },
    { name: "Blanc", hex: "#FFFFFF" },
    { name: "Gris Clair", hex: "#D3D3D3" },
    { name: "Gris", hex: "#A9A9A9" },
    { name: "Gris Fonce", hex: "#A9A9A9" },
    { name: "Gris Tres Fonce", hex: "#696969" },
    { name: "Noir", hex: "#000000" },
  ];

  const handleSubmit = () => {
    setLoading(true);
    const colorsToSend =
      selectedColors.length > 0
        ? selectedColors
        : colors.map((color) => color.name);

    const selectedValues = {
      paintingId: paintId,
      lumi: [
        "Tres lumineux",
        "Lumineux",
        "Moyennement lumineux",
        "Peu lumineux",
        "Tres peu lumineux",
        "Non lumineux",
      ],
      sat: [
        "Tres sature",
        "Sature",
        "Moyennement sature",
        "Peu sature",
        "Tres peu sature",
        "Non sature",
      ],
      colors: colorsToSend,
    };


    fetch(URLDayl + "color/analysis", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(selectedValues),
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        setImageUrl(url);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  };

  const handleColorSelection = (colorName) => {
    setSelectedColors((prev) =>
      prev.includes(colorName)
        ? prev.filter((c) => c !== colorName)
        : [...prev, colorName]
    );
  };

  const handleGrayChange = (e, newValue) => {
    setGrayValue(Math.floor((255 * newValue) / 100));
  };

  const handleOpacityChange = (e, newValue) => {
    setOpacity(newValue / 100);
  };

  const renderGrayShadesRow = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        gap: "10px",
        marginTop: "10px",
      }}
    >
      {colors.slice(-6).map((color, idx) => (
        <button
          key={idx}
          style={{
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            backgroundColor: color.hex,
            border: selectedColors.includes(color.name)
              ? "2px solid black"
              : "1px solid #323F4F",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={() => handleColorSelection(color.name)}
        />
      ))}
    </div>
  );

  useEffect(() => {
    if (imageUrl && opacity === 1) {
      const intervalId = setInterval(() => {
        setOpacity((prevOpacity) => {
          const newOpacity = prevOpacity - 0.05;
          if (newOpacity <= 0.25) {
            clearInterval(intervalId);
            return 0.25;
          }
          return newOpacity;
        });
      }, 50);

      return () => clearInterval(intervalId);
    }
  }, [imageUrl]);

  return (
    <div className="flex gap-5 justify-evenly items-center">
      <div className="flex flex-col items-center">
        <div
          style={{
            border: "4px solid black",
            padding: "10px",
            backgroundColor: "#323F4F",
            marginBottom: "20px",
          }}
        >
          <div
          className="text-white flex justify-center"
            onClick={handleResetColors}
            style={{ cursor: "pointer", textDecoration: "underline" }}
          >
            Reset Selection
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridGap: "10px",
              marginBottom: "5px",
            }}
          >
            {colors.slice(0, -6).map((color, idx) => (
              <button
                key={idx}
                style={{
                  padding: "5px",
                  borderRadius: "10px",
                  border: selectedColors.includes(color.name)
                    ? "3px solid black"
                    : "3px solid #323F4F",
                  backgroundColor: color.hex,
                  fontSize: "14px",
                  width: "100%",
                  height: "56px",
                  fontFamily: "cinzel",
                  fontWeight: "bold",
                  filter:
                    isAnyColorSelected && !selectedColors.includes(color.name)
                      ? "brightness(60%)"
                      : "brightness(100%)",
                }}
                onClick={() => handleColorSelection(color.name)}
              >
              </button>
            ))}
          </div>
          {renderGrayShadesRow()}
        </div>
        <button
          onClick={handleSubmit}
          style={{
            padding: "10px",
            borderRadius: "5px",
            fontSize: "18px",
            color: "white",
            border: "1px solid white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "40px",
          }}
        >
          {loading ? <CircularProgress size={20} color="inherit" /> : "Go"}
        </button>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "15px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              color: "white",
              display: "flex",
              gap: "5px",
              alignItems: "center",
              justifyContent: "center",
            }}
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
            }}
          >
            <img
              src={`${URLDaylImage}images/paintingPic/${paintId}`}
              alt={`Painting ${paintId}`}
              style={{
                opacity: opacity,
                position: "relative",
                maxHeight: "500px",
                zIndex: "2",
              }}
            />

            {imageUrl && (
              <img
                src={imageUrl}
                style={{
                  position: "absolute",
                  zIndex: "2",
                }}
                alt="Analysis Result"
              />
            )}
            <div
              style={{
                position: "absolute",
                backgroundColor: `rgb(${grayValue}, ${grayValue}, ${grayValue})`,
                height: "100%",
                width: "100%",
                zIndex: "1",
              }}
            ></div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              color: "white",
              marginTop: "10px",
              gap: "10px",
            }}
          >
            Opacité:
            <Box
              sx={{
                height: 200,
                display: "flex",
                alignItems: "center",
                label: "Opacity",
              }}
            >
              <Slider
                orientation="vertical"
                min={0}
                max={100}
                value={opacity * 100}
                onChange={handleOpacityChange}
                aria-labelledby="opacity-slider"
              />
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}
