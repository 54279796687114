import React, { useState, useRef } from "react";
import { useNavigate } from "react-router";
import ArtMovementsDisplay from "../component/DiscoverMovements/ArtMovementsDisplay";
import FindByStylePaintings from "./FindByStylePaintings";

export default function MovementPage({ title = true, onClick }) {
  const navigate = useNavigate();
  const [selectedMovement, setSelectedMovement] = useState(null);
  const artMovementsDisplayRef = useRef();

  const handleMovementClick = (el) => {
    setSelectedMovement(el);
  };

  return (
    <div className="flex flex-col">
      <div className="flex w-full mb-10">
        <div className="flex w-1/2 bg-black p-10 py-20 text-white justify-center align-middle text-3xl font-raleway font-bold text-center">
          L'HISTOIRE DE L'ART
          <br />à travers les mouvements artistiques <br />
          qui ont jalonné notre histoire
        </div>
        <div className="flex flex-col w-1/2 p-10 py-20 text-black justify-center align-middle font-raleway text-3xl font-bold text-center">
          <div>
            ACCEDEZ A LA FRISE HISTORIQUE <br /> DES MOUVEMENTS ARTISTIQUES
          </div>
          <div
            onClick={() => navigate("/FriseMouvementArtistique")}
            className="p-4 w-fit rounded-full bg-black text-white m-auto mt-2 cursor-pointer"
          >
            Go
          </div>
        </div>
      </div>
      <ArtMovementsDisplay
        onClick={handleMovementClick}
        flipcards={false}
        ref={artMovementsDisplayRef}
      />
      {selectedMovement && (
        <div>
          <FindByStylePaintings
            style={selectedMovement.movement}
            selectedMovement={selectedMovement}
          />
        </div>
      )}
    </div>
  );
}
