import React, {useEffect, useState} from "react";
import axios from "axios";
import {URLDayl} from "../../services/constantes"
import close from "../../webapp/img/icons/ico_close_2.svg"
import {toast} from "react-toastify"
import {useUser} from "../../contexts/userService";


/**
 * This component is used to add painting to a list in a grid
 * @param {object} user - user object from api
 *  @param {boolean} isLogged - test if the user is logged
 * @param {object} listPaint - paint object to add in list
 * @emits - userLists/save : create new list
 * @emits - userLists/byUserId : get user lists
 */

export default function ListModal({user, isLogged, listPaint}) {

    const [boolList, setBoolList] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);
    const [listTitle, setListTitle] = useState([]);
    const [listList, setListList] = useState([]);
    const [publicList, setPublicList] = useState(true)

    const auth = useUser()


    //Open List Modal//
    function handleList(paintId) {

        if (isLogged === true) {

            if (boolList === true)
                setBoolList(false)
            else
                setBoolList(true)


        } else {
            toast.warning("Connectez vous pour ajouter à une liste!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }


    }

    //Get User List//
    // useEffect(async () => {
    //
    //     if (auth.data && auth.data.jwt) {
    //         await axios.get(URLDayl + `userLists/byUserId?userId=${auth.data.id}`, {headers: {"Authorization": `Bearer ${auth.data.jwt}`}}
    //         )
    //             .then(async (response) => {
    //                 let boolArray = response.data;
    //                 await testList(boolArray);
    //
    //             });
    //     }
    //
    // }, [refreshKey]);


    useEffect(() => {
        const jwt = localStorage.getItem('jwt')
        axios.get(URLDayl + `userLists/byUserId?userId=${auth.data.id}`, ).then(async (res) => {
            console.log("THEN")
            const boolArray = res.data;
            await testList(boolArray);
        })
    }, [])
    //
    // useEffect(() => {
    //     const fetchData = async () => {
    //
    //         const jwt = localStorage.getItem('jwt')
    //         console.log("hello")
    //         console.log(jwt)
    //
    //         try {
    //             const response = await axios.get('http://localhost:8081/userLists/byUserId?userId=13', {
    //                 headers: {
    //                     'Authorization': `Bearer ${jwt}`
    //                 }
    //             });
    //             const boolArray = response.data;
    //             await testList(boolArray);
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     };
    //
    //     fetchData();
    // }, [refreshKey]);

    //Test si la peinture est deja ajouté ou non//
    const testList = async (tempListList) => {


        //On parcours les lists pour voir si la peinture est presente
        for (let i = 0; i < tempListList.length; i++)
            await axios.get(URLDayl + "userLists/detailByListIdOptimised", {params: {listId: tempListList[i].id}}
            )
                .then((response) => {

                    let tempList = response.data;
                    tempListList[i].boolAdded = false;
                    for (let j = 0; j < tempList.listPaintings.length; j++)

                        if (tempList.listPaintings[j].id === listPaint.id) {

                            tempListList[i].boolAdded = true;
                            break;
                        }


                });


        setListList(tempListList);
    }

    //Post new list to the API//
    const handleSubmit = (e) => {

        if (isLogged) {
            e.preventDefault();

            console.log(`Form submitted, ${listTitle}`);
            axios.post(URLDayl + "userLists/save",
                {
                    listName: listTitle,
                    rank: 0,
                    userId: user.id,
                    id: 0,
                    status: publicList ? "PUBLIC" : "PRIVATE",
                    detail: "",
                    imageUrl: ""
                }, {headers: {"Access-Control-Allow-Origin": "*", "Content-Type": "application/json"}}
            )
                .then((response) => {
                    setRefreshKey(oldKey => oldKey + 1);

                    toast.success("Nouvelle liste ajoutée!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                });
        } else {
            toast.warn("Connectez vous pour ajouter aux favoris!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }


    }

    //Post or delete new Paint to the list//
    const addRemovePaintToList = (list, paintId, index) => {

        let tempList = [...listList]

        if (isLogged) {

            if (list.boolAdded === false)
                axios.get(URLDayl + "userLists/addPaintingToList", {params: {listId: list.id, paintingId: paintId}}
                )
                    .then((response) => {

                        tempList[index].boolAdded = true
                        tempList[index].countItems = tempList[index].countItems + 1;
                        setListList(tempList)

                        toast.success("Oeuvre ajoutée à la liste!", {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });

                    });
            else
                axios.get(URLDayl + "userLists/removePaintingFromList", {params: {listId: list.id, paintingId: paintId}}
                )
                    .then((response) => {
                        tempList[index].boolAdded = false
                        tempList[index].countItems = tempList[index].countItems - 1;
                        setListList(tempList)


                        toast.success("Oeuvre retirée de la liste!", {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    });


        } else {
            toast.warning("Connectez vous pour ajouter à une liste!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }


    }

    return (
        <div className="ListModal">

            <button id="itemListState" className="openModalNow gridItemListAction not" data-target="1777"
                    onClick={(e) => handleList(listPaint.id)} type="button" value="notList"></button>


            {boolList === true &&
                <div>
                    <div className="smart-modal-overlay" style={{display: "block"}}></div>
                    <div id="1777" className="smart-modal-content scrollAuto" style={{
                        display: "block",
                        transform: "translate(-50%,-50%)",
                        top: "50%",
                        left: "50%",
                        minWidth: "0"
                    }}>
                        <h3>Ajouter à une liste</h3>
                        <a className="smart-modal-close"><img className="img-left"
                                                              onClick={() => handleList(listPaint.id)} src={close}
                                                              alt="Trier" style={{height: "20px"}}/></a>
                        <div className="addToList">
                            <form action="" onSubmit={handleSubmit}>
                                <div className="addListForm"
                                     style={{height: 40, backgroundColor: publicList ? "red" : "green", width: 40}}
                                     onClick={() => setPublicList(!publicList)}>

                                </div>
                                <div className="addListForm">
                                    <input onChange={(e) => setListTitle(e.target.value)} value={listTitle} type="text"
                                           placeholder="Créer une nouvelle liste"/>
                                    <button type="submit">Créer</button>
                                </div>
                            </form>

                            <h4 className="mt-c mb-a">Liste existante</h4>

                            {/* <!-- Liste disponible - début --> */}
                            {listList.length > 0 && listList.map((list, index) => {
                                console.log(list.boolAdded)
                                return (
                                    <div className="listDetails">
                                        <a className=""
                                           onClick={() => addRemovePaintToList(list, listPaint.id, index)} /* to={`../../view/ListeAmateurPage.js/${list.id}`} state={list.id} */
                                           style={{left: "50px", top: "8px"}}>{list.listName}  </a>
                                        {/* <div className="listItems">{list.countItems} oeuvres</div> */}
                                        <button type="button"
                                                onClick={() => addRemovePaintToList(list, listPaint.id, index)}
                                                className={list.boolAdded === true ? "addedToThisList" : "addToThisList"}></button>
                                    </div>)
                            })}


                            {/* <!-- Liste disponible - fin -->*/}


                        </div>
                    </div>
                </div>
            }
        </div>
    );
}