import Section from "./Section";
import { URLDaylImage } from "../../services/constantes";

export default function YouSection() {
  const sections = [
    {
      id: 17790,
      alt: "Background for Etudiants",
      text: "Artistes, acteurs de la culture",
    },
    {
      id: 10796,
      alt: "Background for Passionnés, Curieux",
      text: "Passionnés, Curieux",
    },
    {
      id: 102759,
      alt: "Background for Artistes, acteurs de la culture",
      text: "Artistes, acteurs de la culture",
    },
  ];

  return (
    <div>
      {" "}
      <div
        style={{ fontFamily: "Cinzel" }}
        className="text-8xl font-bold p-14 flex w-full justify-start bg-gray-100 mt-10"
      >
        03 YOU
      </div>
      <div className="flex w-full">
        <div className="w-3/5 p-5 flex flex-col">
          <div className="text-3xl text-bold font-raleway font-bold mt-10">
            Votre compte, votre réseau, vos actus, vos avis
          </div>
          <div className="flex flex-col ">
            <Section
              number="01"
              text="Dayl entend devenir une plateforme de partage, permettant aux experts, aux passionnés
          et aux différents acteurs de la culture de partager leur intérêt et passion pour l’art
          pictural."
              image=""
            />
            <Section
              number="02"
              text="Les futurs développements permettront de partager des actus, des avis, enrichir les
          informations de votre compte, bref, animer une communauté en réseau, portée par sa
          passion pour l’art pictural."
              image=""
            />
            <div className="flex flex-col w-3/4 font-raleway text-base p-3 gap-3">
              <div className="text-xl font-bold">Dayl aspire à :</div>
              <div>
                Devenir une vitrine de l’histoire de la peinture et rapprocher
                les artistes contemporains à l’histoire de la peinture
              </div>
              <div>
                Elargir sa communauté en proposant un espace de partage entre
                experts, professeurs,passionnés, étudiants à travers l’ensemble
                des supports numériques possibles
              </div>
            </div>
          </div>
        </div>
        <div className="w-2/5 flex flex-col justify-between font-raleway relative">
          {sections.map((section) => (
            <div
              key={section.id}
              className="h-1/3 flex p-7 text-2xl justify-center items-center relative overflow-hidden"
            >
              <img
                src={`${URLDaylImage}images/paintingPic/${section.id}`}
                alt={section.alt}
                className="absolute inset-0 w-full h-full object-cover"
              />
              <div className="relative z-10 flex p-2 w-full h-full bg-black">
                <span className="text-white font-raleway">{section.text}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
