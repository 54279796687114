import * as React from "react";
import { useEffect, useRef, useState } from "react";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import axios from "axios";
import { URLDayl } from "../../services/constantes";
import AutoCompleteCustom from "../PaintingSearch/AutoCompleteCustom";
import SearchBarPaintingsPopover from "./SearchBars/SearchBarPaintingsPopover";
import { useTranslation } from "react-i18next";

export default function CompactSearchBarPaintings({
  searchDTO,
  setSearchDTO,
  onSubmit,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [listStyles, setListStyles] = useState([]);
  const [artistTextOptions, setArtistTextOptions] = useState([]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(async () => {
    const { data } = await axios.get(`${URLDayl}movement/getMovements`);
    setListStyles(
      data
        .map(({ name }) => ({ name }))
        .sort((a, b) => -b.name.localeCompare(a.name))
    );
  }, []);

  const getData = async (
    apiPath,
    setData,
    nameKey,
    idKey,
    type = "",
    searchedtext = ""
  ) => {
    if (searchedtext) {
      const response = await axios.get(URLDayl + apiPath);
      const data = response.data.map((item) => ({
        name: item[nameKey],
        type,
        id: item[idKey],
      }));
      setData(data);
    }
  };

  const onInputChangeArtist = async (e, searchedtext, reason) => {
    setArtistTextOptions([]);
    getData(
      `artistSearch/findByPrefixOptimised?occurence=${searchedtext}`,
      setArtistTextOptions,
      "artist_name_tot",
      "artistIdtfIntern",
      "Artist",
      searchedtext
    );
  };

  const onInputChangeStyle = async (e, searchedtext) => {
    if (searchedtext === "" || searchedtext === null) {
      axios.get(URLDayl + `movement/getMovements`).then((res) => {
        setListStyles(
          res.data
            .map((el) => {
              return { name: el.name };
            })
            .sort((a, b) => -b.name.localeCompare(a.name))
        );
      });
    } else {
      setListStyles([]);
      if (searchedtext) {
        await axios
          .get(
            URLDayl +
              `paintingSearch/findStyleByPrefix?occurrence=${searchedtext}`
          )
          .then((res) => {
            setListStyles(
              res.data.map((el) => {
                return {
                  name: el.styleName,
                };
              })
            );
          });
      }
    }
  };

  const autoCompleteRef = useRef(null);

  const onSelectArtist = (item, key) => {
    if (item !== null)
      setSearchDTO((previous) => ({ ...previous, artistIdtf: item.id }));
    else {
      setSearchDTO((previous) => ({ ...previous, [key]: null }));
    }
  };

  const onSelectStyle = (e, item) => {
    if (item != null)
      setSearchDTO((previous) => ({ ...previous, style: item.name }));
    else {
      setSearchDTO((previous) => ({ ...previous, style: null }));
    }
  };
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: 50,
        alignItems: "center",
        marginBottom: 35,
      }}
    >
      <div>
        <AutoCompleteCustom
          onChange={onInputChangeArtist}
          placeholder={t("Artist")}
          onSelect={(e, item) => onSelectArtist(item, "artistIdtf")}
          options={artistTextOptions}
          className="blackAutoComplete"
          customWidth={280}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <AutoCompleteCustom
          ref={autoCompleteRef}
          // defaultValue={searchDTO.style ? { name: searchDTO.style } : null}
          onChange={onInputChangeStyle}
          placeholder={t("Style")}
          onSelect={(e, item) => onSelectStyle(e, item)}
          options={listStyles}
          className="blackAutoComplete"
          customWidth={280}
        />
      </div>

      <AutoCompleteCustom
        onChange={(e) => {
          setSearchDTO((previous) => ({
            ...previous,
            keyword: e.target.value,
          }));
        }}
        placeholder={t("Keyword")}
        allowFreeInput={true}
        className="blackAutoComplete"
        customWidth={280}
      />

      <div>
        <IconButton onClick={handleClick}>
          <ManageSearchIcon style={{ color: "black", fontSize: 40 }} />
        </IconButton>
        <IconButton onClick={onSubmit}>
          <SearchIcon style={{ color: "black", fontSize: 30 }} />
        </IconButton>

        <IconButton
          onClick={() => {
            autoCompleteRef.current.value = "";
          }}
        >
          <HighlightOffIcon style={{ color: "black", fontSize: 30 }} />
        </IconButton>
        <SearchBarPaintingsPopover
          searchDTO={searchDTO}
          setSearchDTO={setSearchDTO}
          handleClose={handleClose}
          anchorEl={anchorEl}
          onSubmit={onSubmit}
        />
      </div>
    </div>
  );
}
