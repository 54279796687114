import React, { useEffect } from "react";
import BiographieElement from "../ArtistDetailPage/BiographieElement";
import { stringSplitting } from "../../services/Utils";
import { enumContenu } from "../CompteExpert/PeintresCompteExpert";

export default function ElementsBiographiesArticles({
  user,
  articles,
  images,
  contenu,
  canZoom = true,
}) {
  useEffect(() => {
    console.log("IAMGE ARTICLES");
    console.log(images);
  }, []);

  return (
    <div style={{ paddingTop: 60 }}>
      <h2 className="explorerTitleH dot" style={{ marginBottom: 50 }}>
        {contenu === enumContenu.AvisExperts
          ? "Avis experts"
          : "Elements de biographies"}
      </h2>
      {articles &&
        articles.length > 0 &&
        articles.map((el, index) => {
          return (
            <div key={index} style={{}}>
              <BiographieElement
                artist={"artist"}
                bg={"bg"}
                text={el ? stringSplitting(950, el.content) : ""}
                direction={index % 2 === 0 ? "left" : "right"}
                title={el.title}
                imageList={images[index]}
                canZoom={canZoom}
              />
            </div>
          );
        })}
    </div>
  );
}
