import React, {useEffect, useState} from 'react'
import photo2 from "../../webapp/temp/arthur_1.jpg";
import {URLDayl} from "../../services/constantes";
import ChatModal from "./ChatModal";


export const Conversation = ({convo, user}) => {



    useEffect(() => {
        console.log(convo)
    },[])

    return (
<>
        <article>
            <a className="pic"><img src={URLDayl + `images/contentUser/${convo.second.id}/${convo.second.iconImage}`} alt={convo.second.firstName+' '+convo.second.lastName} /></a>
            <a className="sender">{convo.second.firstName+' '+convo.second.lastName}</a>
            <a className="date">9 Juin 2022</a>
            <a className="extract">{convo.first.content}</a>
        </article>

</>

    )
}