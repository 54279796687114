import React, { useEffect, useState } from "react";
import close from "../../../webapp/img/icons/ico_close_2.svg";
import axios from "axios";
import { URLDayl } from "../../../services/constantes";
import ImageGrid from "../../general/ImageGrid/ImageGrid";
import { useForm } from "react-hook-form";
import { useCompteExpert } from "../../../contexts/CompteExpertService";
import SearchBarPaintings from "../../general/SearchBarPaintings";

export default function ArtworkModalTag({
  user,
  artist,
  setBoolModal,
  selected,
  setSelected,
}) {
  const [paintings, setPaintings] = useState([]);
  const [license, setLicense] = useState("");
  const [style, setStyle] = useState("");
  const [genre, setGenre] = useState("");
  const [nationality, setNationality] = useState("");
  const [freeSearch, setFreeSearch] = useState("");
  const [year, setYear] = useState("");
  const [decade, setDecade] = useState("");
  const [keyword, setKeyword] = useState("");
  const [totalNumPaintings, setTotalNumPaintings] = useState(0);

  const [sizeSearch, setSizeSearch] = useState(40);

  const compteExpert = useCompteExpert();

  const [searchPainting, setSearchPainting] = useState([]);
  const [search, setSearch] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [coefWidth, setCoefWidth] = useState();
  const [listStyles, setListStyles] = useState([]);
  const [listOrigins, setListOrigins] = useState([]);
  const [listGenres, setListGenres] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [searchDTO, setSearchDTO] = useState({});
  const resetFilters = () => {
    setLicense("");
    setStyle("");
    setGenre("");
    setNationality("");
    setFreeSearch("");
    setYear("");
    setDecade("");
    setKeyword("");
  };

  const sendSearch = async (data) => {
    console.log(data);
    axios
      .post(
        URLDayl + "paintingSearch/findPaintingBySearchDto",
        {
          license: data.license,
          style: data.style,
          genre: data.genre,
          nationality: data.nationality,
          freeSearch: data.freeSearch,
          year: data.year,
          decade: data.decade,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            USER_PAINT: "0",
          },
        }
      )
      .then((response) => {
        setSearchPainting();
        setSearchPainting(response.data);
      });
  };
  useEffect(() => {
    let overlay = document.createElement("div");

    Object.assign(overlay.style, {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      zIndex: 1,
    });
    document.body.appendChild(overlay);
    document.body.style.overflow = "hidden";
    return () => {
      document.body.removeChild(overlay);
      document.body.style.overflow = "unset";
    };
  }, []);
  useEffect(() => {
    axios
      .get(
        URLDayl + "paintingFront/listStyles",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        setListStyles(
          response.data.sort((a, b) => -b.name.localeCompare(a.name))
        );
      });
    axios
      .get(
        URLDayl + "paintingFront/listOriginsAndNationalities",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        setListOrigins(response.data);
      });
    axios
      .get(
        URLDayl + "paintingFront/listGenres",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        setListGenres(
          response.data.sort((a, b) => -b.name.localeCompare(a.name))
        );
      });
  }, []);
  const onSubmit = () => {
    axios
      .post(
        URLDayl + `paintingSearch/findPaintingBySearchDtoNewEdition`,
        searchDTO,
        { params: { num: sizeSearch } }
      )
      .then((res) => {
        setSearchPainting(res.data.payload);
        setTotalNumPaintings(res.data.numberMax);
      });
  };

  useEffect(() => {
    axios
      .get(
        URLDayl + "paintingFront/listStyles",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        setListStyles(response.data);
      });
    axios
      .get(
        URLDayl + "paintingFront/listOrigins",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        setListOrigins(response.data);
      });
    axios
      .get(
        URLDayl + "paintingFront/listGenres",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        setListGenres(response.data);
      });
  }, []);

  // const sendSearch = async (data) => {
  //
  //     console.log(data)
  //     axios.post(URLDayl + "paintingSearch/findPaintingBySearchDto", {
  //             license: data.license, style: data.style, genre: data.genre, nationality: data.nationality,
  //             freeSearch: data.freeSearch, year: data.year, decade: data.decade
  //         },
  //         { headers: { "Access-Control-Allow-Origin": "*", "Content-Type": "application/json", "USER_PAINT": "0" } }
  //     )
  //         .then((response) => {
  //
  //             setSearchPainting(response.data);
  //
  //         });
  //
  // }

  useEffect(() => {
    axios
      .get(URLDayl + "paintingEntity/randomPaintings30")
      .then((response) => {
        setSearchPainting(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="ModalNetwork">
      <div
        className="smart-modal-overlay zindex3"
        style={{
          display: "block",
          marginTop: compteExpert.artistPageMode ? 1500 : 0,
        }}
      >
        <div
          className="smart-modal-content-l scrollAuto"
          style={{
            transform: "translate(-50%,-50%)",
            display: "inline-flex",
            flexWrap: "wrap",
            alignContent: "flex-start",
            maxWidth: 1600,
          }}
        >
          <h3>Séléctionner des oeuvres</h3>
          <a className="smart-modal-close" onClick={(e) => setBoolModal(false)}>
            <img className="img-left" src={close} alt="Trier" />
          </a>
          <div className="showSubs" style={{ width: "100vw" }}>
            <div className="white">
              <SearchBarPaintings
                searchDTO={searchDTO} 
                setSearchDTO={setSearchDTO}
                onSubmit={onSubmit}
              />
            </div>
            {searchPainting && searchPainting.length > 0 && (
              <div style={{width:"90vw",margin:"auto"}}>
                <ImageGrid
                  user={user}
                  paintings={searchPainting}
                  showTools={false}
                  selectionMode={true}
                  selected={selected}
                  setSelected={setSelected}
                  maxNumPainting={sizeSearch}
                  setMaxNumPainting={setSizeSearch}
                  totalMaxNum={totalNumPaintings}
                />
              </div>
            )}
          </div>
          <div>
            <input
              type="submit"
              className="submitFormBtn mt-a"
              value={"Valider"}
              onClick={() => {
                setBoolModal(false);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
