import { useEffect, useState } from "react";
import axios from "axios";
import { URLDayl, URLDaylImage } from "../services/constantes";
import { useLocation } from "react-router";
import {
  Stilllifes,
  Snowballs,
} from "../component/Colorimetrie/colorimetrieData";
import { Slider } from "@mui/material";
import { Box } from "@mui/system";
import { Opacity } from "@mui/icons-material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

export default function ColorimetrieAnalyse({ paintId }) {
  const [painting, setPainting] = useState([]);
  const [opacity, setOpacity] = useState(0);
  const [grayValue, setGrayValue] = useState(0);
  const location = useLocation();
  paintId = location.state;
  const isStill = (paintId) => paintId === 6653;
  const isStillValue = isStill(paintId);

  const defaultImage = isStillValue
    ? Stilllifes[1].painting
    : Snowballs[1].painting;

  const [displayedImage, setDisplayedImage] = useState(defaultImage);
  const [currentContent, setCurrentContent] = useState(
    isStillValue ? Stilllifes[1].content : Snowballs[1].content
  );
  const [activeTab, setActiveTab] = useState(
    isStillValue ? Stilllifes[1].button : Snowballs[1].button
  );

  useEffect(() => {
    axios
      .get(URLDayl + "paintingEntity/" + paintId)
      .then((response) => {
        setPainting(response.data);
      })
      .catch((error) => {
        console.error("Error fetching painting:", error);
      });
  }, []);

  const handleOpacityChange = (e) => {
    setOpacity(e.target.value / 100);
  };

  const handleGrayChange = (e) => {
    setGrayValue(Math.floor((255 * e.target.value) / 100));
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "30px",
        gap: "30px",
      }}
    >
      <div style={{ position: "relative" }}>
        <div
          style={{
            width: "500px",
            height: "500px",
            borderRadius: "20px",
            backgroundColor: `rgb(${grayValue}, ${grayValue}, ${grayValue})`,
            position: "relative",
            border: "2px solid black",
          }}
        ></div>

        <div style={{ position: "absolute", top: 0, left: 0 }}>
          <img
            src={displayedImage}
            alt="Result "
            style={{
              width: "500px",
              height: "500px",
              borderRadius: "20px",
              border: "2px solid black",
            }}
          />
        </div>

        <div style={{ position: "absolute", top: 0, left: 0 }}>
          <img
            src={`${URLDaylImage}images/paintingPic/${painting.id}`}
            alt={painting.name}
            style={{
              width: "500px",
              height: "500px",
              opacity: opacity,
              borderRadius: "20px",
              border: "2px solid black",
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          {isStillValue
            ? Stilllifes.map((item, index) => (
                <div>
                  <button
                    key={index}
                    onClick={() => {
                      setDisplayedImage(item.painting);
                      setCurrentContent(item.content);
                      setActiveTab(item.button);
                    }}
                  >
                    <div style={{ marginTop: "5px" }}>
                      <img
                        src={item.painting}
                        alt="Small preview"
                        style={{
                          width: "150px",
                          height: "auto",
                          borderRadius: "10px",
                          border:
                            activeTab === item.button
                              ? "2px solid black"
                              : "1px solid black ",
                          boxShadow:
                            activeTab === item.button ? "none" : "none",
                        }}
                      />
                    </div>
                    {item.button}
                  </button>
                </div>
              ))
            : Snowballs.map((item, index) => (
                <div>
                  <button
                    key={index}
                    onClick={() => {
                      setDisplayedImage(item.painting);
                      setCurrentContent(item.content);
                      setActiveTab(item.button);
                    }}
                  >
                    <div style={{ marginTop: "5px" }}>
                      <img
                        src={item.painting}
                        alt="Small preview"
                        style={{
                          width: "150px",
                          height: "auto",
                          borderRadius: "10px",
                          border:
                            activeTab === item.button
                              ? "2px solid black"
                              : "1px solid black ",
                          boxShadow:
                            activeTab === item.button ? "none" : "none",
                        }}
                      />
                    </div>
                    {item.button}
                  </button>
                </div>
              ))}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            alignItems: "center",
          }}
        >
          Grayscale:
          <Box
            sx={{
              height: 200,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Slider
              orientation="vertical"
              min={0}
              max={100}
              value={(grayValue / 255) * 100}
              onChange={handleGrayChange}
              aria-labelledby="grayscale-slider"
            />
          </Box>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            alignItems: "center",
          }}
        >
          Opacity:
          <Box
            sx={{
              height: 200,
              display: "flex",
              alignItems: "center",
              label: "Opacity",
            }}
          >
            <Slider
              orientation="vertical"
              min={0}
              max={100}
              value={opacity * 100}
              onChange={handleOpacityChange}
              aria-labelledby="opacity-slider"
            />
          </Box>
        </div>
      </div>
      <div>
        <div
          className={"swiperText"}
          style={{ alignSelf: "center", maxWidth: "950px" }}
        >
          <h2
            style={{
              borderLeft: "none",
              fontFamily: "Cinzel",
              fontWeight: "500",
            }}
          >
            {activeTab}
          </h2>
          <Swiper
            modules={[Navigation]}
            navigation={true}
            style={{width:"700px"}}
          >
            {currentContent.map((el) => {
              return (
                <SwiperSlide key={el}>
                  <div
                    style={{
                      padding: "30px 60px",
                      whiteSpace: "pre-wrap",
                      fontSize: "17px",
                      fontFamily: "Raleway!",
                    }}
                  >
                    {el}
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
