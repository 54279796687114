import React, {useEffect, useState} from "react";
import axios from "axios";
import {URLDayl} from "../../services/constantes"
import {Link} from "react-router-dom";
import {Pagination} from "@mui/material";


/**
 * This component contain your list of favorite artists
 * @param {object} user - user object from api
 * @param {object} setUser - user setter
 * @emits - artist/{id} : get artist informations
 * @emits - artistLiked/deleteFavoriteArtist/ : unfollow artist
 *
 */

const NUM_PER_PAGE = 24

export default function FavArtists({user, setUser}) {

    const [listArtist, setListArtist] = useState([]);
    const [refreshKey, setRefreshKey] = useState(0);
    const [totalPaintings, setTotalPainting] = useState(0)
    const [page, setPage] = useState(1)

    const handleChange = (event, value) => {
        setPage(value);
        window.scrollTo(0, 60);
    };

    //get Painting info//
    useEffect(async () => {

        setListArtist([])
        const chunkedArray = Array.from({length: Math.ceil(user.artistLiked.length / NUM_PER_PAGE)}, (_, index) => user.artistLiked.slice(index * NUM_PER_PAGE, index * NUM_PER_PAGE + NUM_PER_PAGE));

        setTotalPainting(user.artistLiked.length)

        for (let i = 0; i < chunkedArray[page-1].length; i++) {
            try {
                console.log(chunkedArray[page - 1][i])
                await axios
                    .get(URLDayl + `artist/${chunkedArray[page - 1][i]}`)
                    .then((response) => {
                        let boolArray = response.data;
                        boolArray.bool = false;
                        setListArtist((artist) => [...artist, boolArray]);
                        // const temp = availableArtists
                        // if (!temp.some((el) => el.artistIdtfIntern === response.data.artist.artistIdtfIntern)) {
                        // 	temp.push(response.data.artist)
                        // }
                    }).catch((e) => {

                    });
                // setLoading(false)
            } catch (e) {
            }
        }
    }, [refreshKey, user, page])

    //Delete user like//
    const deleteArtist = (artistId) => {


        axios.get(URLDayl + `artistLiked/deleteFavoriteArtist/${user.id}/${artistId}`,
            {headers: {"Access-Control-Allow-Origin": "*", "Content-Type": "application/json", "USER_PAINT": 0}}
        )
            .then((response) => {


                //update user
                axios.get(URLDayl + `users/${user.id}`, {
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Content-Type": "application/json"
                        }
                    }
                )
                    .then((response2) => {

                        setUser(response2.data)
                        setRefreshKey(oldKey => oldKey + 1);
                    });

            });


    }

    //afficher modale suppression//
    const handleBoolListTrue = (index) => {

        const tempBool = [...listArtist]

        tempBool[index].bool = true;
        setListArtist(tempBool);
    }

    //cacher modale suppression//
    const handleBoolListFalse = (index) => {

        const tempBool = [...listArtist]

        tempBool[index].bool = false;
        setListArtist(tempBool);

    }

    return (
        <div className="favArtists">
            <div className="accountArtists ">
                {listArtist.length > 0 &&
                    listArtist.map((artist, index) => (

                        <div className="item" style={{backgroundImage: `url(${URLDayl}images/artistPic/${artist.id})`}}>
                            <div className="name">{artist.artist_name_tot}</div>
                            <div className="items-count">{/* list.countItems */} oeuvres</div>
                            <Link className="discover" to={`../../view/ArtistDetailPage.js/${artist.id}`}
                                  state={artist.id} href="artiste.html"></Link>

                            <input type="button" className="unfollow" onClick={() => handleBoolListTrue(index)}
                                   value=""/>

                            {artist.bool && <div className="deleteConfirm">
                                <span className="deleteText">Supprimer?</span>
                                <button type="button" className="yes closeTwoParentsNow"
                                        onClick={() => deleteArtist(artist.id)} value="">Oui
                                </button>
                                <button type="button" className="no closeParentNow"
                                        onClick={() => handleBoolListFalse(index)} value="">Non
                                </button>
                            </div>}
                        </div>
                    ))}
            </div>
            {totalPaintings / NUM_PER_PAGE > 1 && (
                <Pagination
                    count={Math.ceil(totalPaintings / NUM_PER_PAGE)} // Calculate the total number of pages
                    page={page}
                    style={{alignSelf: "center", padding: "20px", justifyContent: "center"}}
                    sx={{justifyContent: "center"}}
                    variant="outlined"
                    className="paginationCentered"
                    onChange={handleChange} // Add the event handler for page change
                />
            )}

        </div>
    )

}