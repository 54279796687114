import { useEffect, useState } from "react";
import axios from "axios";
import SmallGrid from "../general/ImageGrid/SmallGrid";
import { URLDayl } from "../../services/constantes";

export default function MatchingPreview({paintId}) {
    const [matchList, setMatchList] = useState([]);
    useEffect(() => {
        const fetchRandomPaintingAndMatches = async () => {
            try {
              
             

                if (paintId) {
                    const response2 = await axios.get(URLDayl + `matchingSearch/findMatchForSource/STYLE/${paintId}/true`);
                    const boolArray = Object.values(response2.data.payload).map((el) => {
                        return { ...el, boolVis: true };
                    });
                    setMatchList(boolArray);

                
                }
            } catch (error) {
                console.error("There was an error fetching the data: ", error);
            }
        };

        fetchRandomPaintingAndMatches();
    }, [paintId]);

    return (
        <SmallGrid paintingsList={matchList} user={null} isLogged={false} size={6} />
    );
}
