import React from "react";
import { useState } from "react";

const GridHeader = ({
  year,
  setYear,
  orderDate,
  setOrderDate,
  setSize,
  isAutoScrollEnabled,
  setIsAutoScrollEnabled,
  matchingType,
  setMatchingType,
}) => {
  const [tempStartYear, setTempStartYear] = useState("");
  const [tempEndYear, setTempEndYear] = useState("");
  const options = ["STYLE", "COLOR", "CONTENT", "TECHNIQUE"];

  const sortOrderDate = () => {
    switch (orderDate) {
      case "ASC":
        setOrderDate("DESC");
        break;
      case "DESC":
        setOrderDate("ASC");
        break;
      default:
        setOrderDate("ASC");
        break;
    }
  };

  const handleOkClick = () => {
    setYear({
      startYear: tempStartYear,
      endYear: tempEndYear,
    });
    setOrderDate("ASC");
  };

  return (
    <div className="flex justify-between p-3">
      <div className="flex items-center">
        {orderDate !== undefined && (
          <button
            className={
              orderDate === ""
                ? "orderByDate"
                : orderDate === "ASC"
                  ? "orderByDateDown"
                  : "orderByDateUp"
            }
            data-tooltip="Filtrer les oeuvres chronologiquement"
            onClick={sortOrderDate}
          >
            {orderDate === ""
              ? "Trier par date"
              : orderDate === "ASC"
                ? "Du + ancien au + récent"
                : "Du + récent au + ancien"}
          </button>
        )}

        {year && (
          <form>
            <input
              className="inputYears"
              placeholder={
                year.startYear ? year.startYear : "à partir de (année)"
              }
              value={tempStartYear}
              onChange={(e) => setTempStartYear(e.target.value)}
            />
            <input
              className="inputYears"
              placeholder={year.endYear ? year.endYear : "jusqu'à (année)"}
              value={tempEndYear}
              onChange={(e) => setTempEndYear(e.target.value)}
            />
            <button className="" onClick={handleOkClick} type="button">
              OK
            </button>
          </form>
        )}
      </div>

      {matchingType && (
        <div className="flex justify-center items-center space-x-2 text-xl font-raleway">
          {options.map((option, index) => (
            <span
              key={option}
              className={`cursor-pointer ${matchingType.text === option || matchingType === option ? "font-bold" : ""}`}
              onClick={() =>
                setMatchingType({
                  ...matchingType,
                  text: option,
                  value: option === "TECHNIQUE" ? "TECH_AND_COLOR" : option,
                })
              }
            >
              {option}
              {index < options.length - 1 && " / "}
            </span>
          ))}
        </div>
      )}

      <div className="gridColsTypeMatch">
        <div className="go2Cols" onClick={() => setSize(2)}>
          L
        </div>
        <div type="button" className="go4Cols" onClick={() => setSize(4)}>
          M
        </div>
        <div type="button" className="go6Cols" onClick={() => setSize(6)}>
          S
        </div>
      </div>
    </div>
  );
};

export default GridHeader;
