// import Autocomplete from "@mui/material/Autocomplete";
// import TextField from "@mui/material/TextField";
// import {URLDaylImage} from "../../services/constantes";
// import * as React from "react";
// import {useEffect, useState} from "react";
//
//
// export default function AutoCompleteStyles({placeholder, onSelect, onChange, options}) {
//
//
//     useEffect(() => {
//         console.log(options)
//     },[options])
//
//     return (
//         <Autocomplete
//             style={{
//                 width: 500,
//                 zIndex: 99999999
//             }}
//             color={"white"}
//             autoComplete={true}
//             onChange={(e, value) => onSelect(e, value)}
//             onInputChange={(e, value) => onChange(e, value)}
//             options={options}
//             getOptionLabel={(option) => option.name}
//             sx={{width: 300}}
//             renderInput={(params) => <TextField {...params} label={placeholder}/>}
//
//         />
//     )
// }

import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { Chip } from "@mui/material";
import { useEffect, useState } from "react";

const filter = createFilterOptions();

export default function AutoCompleteStyles({
  options,
  onChange,
  onSelect,
  placeholder,
  defaultValues,
}) {
  const [value, setValue] = useState(null);

  

  return (
    <Autocomplete
      className="custom-autocomplete"
      multiple
      id="tags-filled"
      options={options.map((option) => option.name)}
      defaultValue={defaultValues.map((el) => el.name)}
      freeSolo
      style={{ backgroundColor: "transparent", zIndex: 9999999 }}
      renderTags={(value, getTagProps) => (
        <div>
          {value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              style={{ marginBottom: "8px", marginRight: "8px" }}
            />
          ))}
        </div>
      )}
      onChange={onSelect}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          label="Styles"
          placeholder="New style"
          InputProps={{
            className: "custom-input", // Add custom class name
            style: {
              color: "black",
              backgroundColor: "transparent",
              outline: "none",
              borderBottom: "1px solid #293d65",
            },
            ...params.InputProps,
          }}
          InputLabelProps={{
            style: { color: "black", top: "-8px" },
          }}
        />
      )}
    />
    //
    // <Autocomplete
    //     multiple
    //     id="tags-filled"
    //     options={top100Films.map((option) => option.title)}
    //     defaultValue={[top100Films[13].title]}
    //     freeSolo
    //     renderTags={(value, getTagProps) =>
    //         value.map((option, index) => (
    //             <Chip variant="outlined" label={option} {...getTagProps({ index })} />
    //         ))
    //     }
    //     renderInput={(params) => (
    //         <TextField
    //             {...params}
    //             variant="filled"
    //             label="freeSolo"
    //             placeholder="Favorites"
    //         />
    //     )}
    // />
  );
}
