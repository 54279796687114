import { Link } from "react-router-dom";
import "../../webapp/css/footer.css";

export default function FooterMenu({ listItems }) {
  return (
    <div className="footer-menu">
      <div className="menu-header">
        <h3 className="footer-menu-title">{listItems.title}</h3>
      </div>
      <div className="menu-items-container">
        {listItems &&
          listItems.items.map((el) => {
            return (
              <Link to={el.link} key={el.item} state={el.state}>
                <div className="menu-item">
                  <div className="bullet-button" />
                  <h3 className="menu-item-text">{el.item}</h3>
                </div>
              </Link>
            );
          })}
      </div>
    </div>
  );
}
