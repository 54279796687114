import ArtistGrid from "../general/ArtistGrid";
import Section from "./Section";

export default function LoveSection() {
  return (
    <div>
      <div
        style={{ fontFamily: "Cinzel" }}
        className="text-8xl font-bold p-14 flex w-full justify-end bg-gray-100 mt-10"
      >
        04 LOVE
      </div>
      <div className="flex gap-8">
        <div className="flex w-3/5">
          <ArtistGrid />
        </div>
        <div className="flex w-2/5 flex-col">
          <div className="text-3xl text-bold font-raleway font-bold mt-10">
            Créez, partagez vos galeries, collections, coups de cœur{" "}
          </div>
          <div className="flex flex-col">
            <Section
              number="01"
              text="Régulièrement, nous ouvrirons de nouvelles fonctionnalités pour
          permettre d’enrichir l’expérience utilisateur de chacun."
              image=""
            />
            <Section
              number="02"
              text="Vous pouvez aujourd’hui créer vos listes, liker vos coups de cœur,
          commenter et publier un avis sur une peinture…"
              image=""
            />
            <Section
              number="03"
              text="Vous pouvez aujourd’hui créer vos listes, liker vos coups de cœur,
              commenter et publier un avis sur une peinture…"
              image=""
              border={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
