import Carousel from "react-multi-carousel";
import Fav from "../general/Fav";
import { Link } from "react-router-dom";
import { URLDayl, URLDaylImage } from "../../services/constantes";

import CarouselPainting from "../general/CarouselPainting";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router";

export default function SameArtistCarousel({ paintId }) {
  const [matchList, setMatchList] = useState([]);
  const [painting, setPainting] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(URLDayl + "paintingEntity/" + paintId).then((res) => {
      setPainting(res.data);
      axios
        .get(
          URLDayl +
            `paintingSearch/findPaintingByArtistIdtfn?artistId=${res.data.artist.id}&number=20&minNumPainting=0`
        )
        .then((response2) => {
          setMatchList(response2.data.payload);
        });
    });
  }, [paintId]);

  return (
    <div className="MostMatched">
      <section className="center-col cont-sty-1 mt-c">
        <div className="cont-l-w">
          {painting && (
            <h2 className="mb-a dot explorerTitleH">
              Plus d'œuvres de{" "}
              <a
                className="underlineTrans"
                onClick={() =>
                  navigate(
                    "../../view/ArtistDetailPage.js/${painting.artistId}",
                    { state: painting.artist.id }
                  )
                }
                style={{
                  color: "black",
                }}
              >
                {painting.artist.artist_name_tot}
              </a>
            </h2>
          )}
        </div>

        {/* <!-- carousel - début --> */}
        {matchList && matchList.length > 0 && (
          <CarouselPainting
            paintings={matchList}
            artistName={false}
            isHidden={painting.artist.hideArtist}
          />
        )}
        {/* <!-- carousel - fin --> */}
      </section>
    </div>
  );
}
