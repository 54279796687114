import React, { useState } from "react";
import { useForm } from "react-hook-form";
import TextareaAutosize from "react-textarea-autosize";
import { URLDayl } from "../../services/constantes";
import axios from "axios";
import AsyncSelect from "react-select/async";
import { ArtistStatementCompteExpert } from "./ArtistStatement/ArtistStatementCompteExpert";
import GridChoosePainting from "./GridChoosePainting";
import { toast } from "react-toastify";
import { useCompteExpert } from "../../contexts/CompteExpertService";
import numberOneSvg from "../../webapp/img/icons/number-one.svg";
import numberTwoSvg from "../../webapp/img/icons/number-two.svg";
import AutoCompleteCustom from "../PaintingSearch/AutoCompleteCustom";

export const PeinturesCompteExpert = ({ user }) => {
  const [count1, setCount1] = useState(0);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();
  const [artist, setArtist] = useState({});
  const [searchPhrase, setSearchPhrase] = useState("");
  const [selectedValue, setSelectedValue] = useState();
  const [contenuEdition, setContenuEdition] = useState("0");
  const compteExpert = useCompteExpert();
  const [inputValue, setNewValue] = useState("");
  const [options, setOptions] = useState([]);

  const [artistDetails, setArtistDetail] = useState({
    origin: "",
    dob: "",
    dod: "",
    motsClefs: [],
  });

  const change = () => {
    switch (contenuEdition) {
      case "artisteStatement":
        return <ArtistStatementCompteExpert user={user} artist={artist} />;
      case "elementBiographie":
        return (
          <>
            <h3 className="mt-c">Elément de biographie</h3>
            <div className="inputInline">
              <div style={{ width: "100%" }}>
                <TextareaAutosize
                  style={{ paddingBottom: 10 }}
                  minRows={5}
                  placeholder={artist ? artist.artistIdtfIntern : ""}
                  // value={artist ? artist.artistIdtfIntern : ""}
                  onChange={(e) => setCount1(e.target.value.length)}
                />
                {/*<textarea name="message" rows="5" cols="33" {...register("biography", { value: artist && artist.id, onChange: e => setCount1(e.target.value.length) })}> </textarea>*/}
                <div className="charCount">{0}/255</div>
              </div>
            </div>
          </>
        );
      default:
        return <></>;
    }
  };

  const handleSelect = (e, value) => {
    if (value) {
      axios.get(URLDayl + `artist/${value.id}`).then((response) => {
        setArtist(response.data);
      });
    }
  };

  const handleInputChange = async (e, value) => {
    if (value.length > 3) {
      const res = await fetch(
        URLDayl + `artistSearch/findByPrefix?occurence=${value}`
      );
      const data = await res.json();
      const renamedData = data.map(item => ({
        ...item,
        name: item.artist_name_tot
      }));

      setOptions(renamedData);
    } else {
      setOptions([]);
    }
};


  return (
    <>
      <div className="editMain center-tex">
        <h1>Modifier une page Peinture</h1>
        <p className="how">
          Entrez le nom de l'oeuvre dont vous souhaitez modifier les
          informations puis sélectionnez la langue d'édition afin de faire
          apparaître le menu d'édition.
        </p>
      </div>

      <div className="editMain mt-b mb-b pb-a">
        <div className="inline">
          <form className="subOpt" style={{marginTop:"30px"}}>
            <img src={numberOneSvg} />
            <p>Nom de l'artiste</p>

            {/*<input type="text" id="artistName" name="artistName"/>*/}

            <AutoCompleteCustom
              className={"thisisMyClass"}
              options={options}
              defaultValue={selectedValue}
              onSelect={handleSelect}
              onChange={handleInputChange}
              placeholder="Nom de l'artiste"
            />
          </form>
          <div className="subOpt" style={{marginTop:"30px"}}>
            <img src={numberTwoSvg} />
            <p>Langue d'édition</p>
            <ul className="langEdit">
              <li
                className={
                  compteExpert.language === "FRENCH" ? "fr active" : "fr"
                }
                onClick={() => compteExpert.setLanguage("FRENCH")}
              >
                Français
              </li>
              <li
                className={
                  compteExpert.language === "ENGLISH" ? "en active" : "en"
                }
                onClick={() => compteExpert.setLanguage("ENGLISH")}
              >
                Anglais
              </li>
              <li
                className={
                  compteExpert.language === "SPANISH" ? "es active" : "es"
                }
                onClick={() => compteExpert.setLanguage("SPANISH")}
              >
                Espagnol
              </li>
            </ul>
          </div>
        </div>
      </div>
      {artist && compteExpert.language !== "" && (
        <>
          <div style={{ width: "100%", maxWidth: "none" }}>
            <GridChoosePainting
              artistIdtf={artist ? artist.id : ""}
              user={user}
            />
            {/*<GridChoosePainting artistIdtf={} user={user}/>*/}
          </div>
        </>
      )}
    </>
  );
};
