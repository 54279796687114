import React, { useEffect, useState } from "react";
import close from "../../../webapp/img/icons/ico_close_2.svg";
import TextareaAutosize from "react-textarea-autosize";
import { URLDayl, URLDaylImage } from "../../../services/constantes";
import axios from "axios";
import { toast } from "react-toastify";
import { actions } from "./ArtistStatementCompteExpert";
import { enumContenu } from "../PeintresCompteExpert";
import { useCompteExpert } from "../../../contexts/CompteExpertService";
import { useUser } from "../../../contexts/userService";

export default function ModalArtistStatement({
  user,
  artist,
  setBoolModal,
  action,
  publication,
  setRefreshKey,
  refreshKey,
  contenu,
  setArtworkModal,
  selected,
  setSelected,
  modePeinture,
}) {
  const [count, setCount] = useState(0);
  const [value, setValue] = useState("");
  const [title, setTitle] = useState("");
  const [image, setImage] = useState([]);
  const [imageToRemove, setImageToRemove] = useState([]);
  const [originalImage, setOriginalImage] = useState([]);
  const compteExpert = useCompteExpert();
  const auth = useUser();

  const [valueOfTitle, setValueOfTitle] = useState("");

  const changeHandler = (e) => {
    if (
      (contenu === enumContenu.ElementBiographies &&
        action === actions.Modifiy) ||
      (contenu === enumContenu.AvisExperts && action === actions.Modifiy)
    )
      setImage((img) => [...img, e.target.files]);
    else setImage(e.target.files);
  };

  useEffect(() => {
    let overlay = document.createElement("div");
    Object.assign(overlay.style, {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      zIndex: 1,
    });
    document.body.appendChild(overlay);
    document.body.style.overflow = "hidden";
    return () => {
      document.body.removeChild(overlay);
      document.body.style.overflow = "unset";
    };
  }, []);

  useEffect(() => {
    switch (contenu) {
      case enumContenu.ArtistStatement: {
        setTitle("artist statement");
        axios
          .get(
            URLDayl + `artist/artistStatementsByArtistId?artistId=${artist.id}`
          )

          .then((res) => {
            console.log(res);
            setValue(res.data.content);
            setCount(res.data.content.length);
          });
        break;
      }
      case enumContenu.ContenuWikipedia:
        setTitle("contenu Wikipedia");
        break;
      case enumContenu.AvisExperts:
        setTitle("avis expert");
        if (publication) {
          axios
            .get(URLDayl + `artist/avisExpert/${publication.id}`)
            .then((res) => {
              setValueOfTitle(res.data.title);
              setValue(res.data.content);
              setCount(res.data.content.length);
              const tempImg = [];
              for (let i = 0; i < res.data.images.length; i++) {
                if (res.data.images[i] !== "")
                  tempImg.push(
                    URLDayl +
                      `images/avisExpert/${res.data.artistId}/${publication.id}?name=${res.data.images[i]}`
                  );
              }
              setSelected(res.data.taggedArtworks);
              // for (let j = 0; j < res.data.taggedArtworks.length; j++)
              //     tempImg.push(URLDayl + `images/paintingPic/${res.data.taggedArtworks[j]}`)
              setImage(tempImg);
              setOriginalImage(tempImg);
            })
            .catch((err) => {
              toast.error("Could not update", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            });
        }
        break;
      case enumContenu.ElementBiographies:
        setTitle("element de biographie");
        axios
          .get(URLDayl + `artist/elementsBiographies?id=${publication.id}`)
          .then((res) => {
            setValueOfTitle(res.data.title);
            setValue(res.data.content);
            setCount(res.data.content.length);
            const tempImg = [];
            for (let i = 0; i < res.data.images.length; i++) {
              if (res.data.images[i] !== "")
                tempImg.push(
                  URLDayl +
                    `images/biography/${res.data.artistId}/${publication.id}?name=${res.data.images[i]}`
                );
            }
            setSelected(res.data.taggedArtworks);
            setImage(tempImg);
            setOriginalImage(tempImg);
            // if (actions.Modifiy === action)
            //     setImage(undefined)
          });
        break;
    }
  }, [contenu, artist.id]);

  // ELEMENT BIOGRAPHIE

  const uploadImageElementBiographie = async (el, res, data) => {
    await axios
      .post(
        URLDayl +
          `images/uploadBiographyImage?artistId=${artist.id}&imageName=${el.name}&publicationId=${res.data}`,
        data
      )
      .then((resImage) => {})
      .catch((err) => {})
      .catch((err) => {
        toast.error("Could not upload image", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log(err);
      });
  };

  const uploadElementBiographie = async () => {
    switch (action) {
      case actions.Create: {
        await axios
          .post(URLDayl + `artist/elementsBiographies/add`, {
            id: 0,
            artistId: artist.id,
            title: valueOfTitle,
            content: value,
            images: [],
            status: "DRAFT",
            userId: user.id,
            taggedArtworks: selected,
            language:
              compteExpert.language !== "" ? compteExpert.language : null,
          })
          .then(async (res) => {
            for (let i = 0; i < image.length; i++) {
              var data = new FormData();
              await data.append("image", image[i]);
              await uploadImageElementBiographie(image[i], res, data);
            }

            setBoolModal(false);
            setRefreshKey(refreshKey + 1);

            setRefreshKey(refreshKey + 1);
          })
          .catch((err) => {});
      }
    }
  };

  const updateElementBiographie = async () => {
    await axios
      .put(URLDayl + `artist/elementBiography/update`, {
        id: publication.id,
        content: value,
        title: valueOfTitle,
        artist_id: publication.artist_id,
        images: [],
        status: "DRAFT",
        user_id: user.id,
        taggedArtworks: selected,
        language: compteExpert.language !== "" ? compteExpert.language : null,
      })
      .then((res) => {
        toast.success("Post modified", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setRefreshKey(refreshKey + 1);
      });

    image &&
      image.length > originalImage.length &&
      [...image[image.length - 1]].map(async (el) => {
        var data = new FormData();

        await data.append("image", el);
        await axios
          .post(
            URLDayl +
              `images/uploadBiographyImage?artistId=${artist.id}&imageName=${el.name}&publicationId=${publication.id}`,
            data
          )
          .then((resImage) => {})
          .catch((err) => {})
          .catch((err) => {
            toast.error("Could not upload image", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            console.log(err);
          });
      });

    for (let i = 0; i < imageToRemove.length; i++) {
      await axios
        .delete(
          URLDayl +
            `images/removeElementBiographieImage?publicationId=${
              publication.id
            }&imageName=${imageToRemove[i].split("name=").pop()}&artistId=${
              artist.id
            }`
        )
        .then((res) => {
          toast.success("Post image deleted", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setRefreshKey(refreshKey + 1);
        })
        .catch((err) => {
          toast.error("Image could not be deleted", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };

  // ARTIST STATEMENT

  const uploadDataArtistStatement = () => {
    switch (action) {
      case actions.Modifiy: {
        axios
          .put(URLDayl + `artist/artistStatement/update`, {
            id: publication ? publication.id : 0,
            artistId: artist.id,
            content: value,
            author: user.firstName + " " + user.lastName,
            status: "DRAFT",
          })
          .then((res) => {
            setRefreshKey(refreshKey + 1);
            toast.success("Artist statement modified", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          })
          .catch((err) => {
            console.log(err);
            toast.error("ERROR", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
        break;
      }
      case actions.Create: {
        axios
          .post(URLDayl + `artist/artistStatement/add`, {
            id: 0,
            artistId: artist.id,
            content: value,
            author: user.firstName + " " + user.lastName,
            status: "DRAFT",
          })
          .then((res) => {
            setRefreshKey(refreshKey + 1);
            toast.success("Artist statement uploaded", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          })
          .catch((err) => {
            toast.error("ERROR", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      }
    }
  };

  const deletePost = () => {
    console.log(publication);
    axios
      .delete(URLDayl + `artist/artistStatement/id=${publication.id}`)
      .then((res) => {
        toast.success("Post deleted", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setRefreshKey(refreshKey + 1);
        console.log(publication.id);
      });
  };

  // AVIS EXPERTS

  const uploadAvisExperts = async () => {
    await axios
      .post(URLDayl + `artist/avisExpert/add`, {
        id: 0,
        artistId: artist.id,
        title: valueOfTitle,
        content: value,
        images: [],
        status: "DRAFT",
        userId: auth.data.id,
        taggedArtworks: selected,
        language: compteExpert.language !== "" ? compteExpert.language : null,
      })
      .then(async (res) => {
        console.log("IMAGES");
        console.log(image);

        for (let i = 0; i < image.length; i++) {
          var data = new FormData();
          await data.append("image", image[i]);
          await uploadAvisExpertImage(image[i], res, data);
        }

        toast.success("Posted", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setBoolModal(false);
        setRefreshKey(refreshKey + 1);

        setRefreshKey(refreshKey + 1);
      })
      .catch((err) => {
        toast.success("Could not be posted", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const uploadAvisExpertImage = async (el, res, data) => {
    await axios
      .post(
        URLDayl +
          `images/uploadAvisExpertImage?artistId=${artist.id}&imageName=${el.name}&publicationId=${res.data.id}`,
        data
      )
      .then((resImage) => {
        toast.success("image uploaded " + el.name, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        toast.error("Could not upload image", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log(err);
      });
  };

  const updateAvisExperts = async () => {
    await axios
      .put(URLDayl + `artist/avisExpert/update`, {
        id: publication.id,
        content: value,
        title: valueOfTitle,
        artist_id: artist.id,
        images: [],
        status: "DRAFT",
        user_id: publication.userId,
        taggedArtworks: selected,
        language: compteExpert.language !== "" ? compteExpert.language : null,
      })
      .then((res) => {
        toast.success("Post modified", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setRefreshKey(refreshKey + 1);
      })
      .catch((err) => {
        console.log();
        toast.error("Could not modify", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });

    image &&
      image.length > originalImage.length &&
      [...image[image.length - 1]].map(async (el) => {
        var data = new FormData();
        data.append("image", el);
        await axios
          .post(
            URLDayl +
              `images/uploadAvisExpertImage?artistId=${artist.id}&imageName=${el.name}&publicationId=${publication.id}`,
            data
          )
          .then((resImage) => {})
          .catch((err) => {
            toast.error("Could not upload image", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            console.log("could not upload image");
            console.log(err);
          });
      });

    for (let i = 0; i < imageToRemove.length; i++) {
      await axios
        .delete(
          URLDayl +
            `images/removeAvisExpertImage?publicationId=${
              publication.id
            }&imageName=${imageToRemove[i].split("name=").pop()}&artistId=${
              artist.id
            }`
        )
        .then((res) => {
          toast.success("Post image deleted", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setRefreshKey(refreshKey + 1);
        })
        .catch((err) => {
          toast.error("Image could not be deleted", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };

  return action !== actions.Delete ? (
    // <form id={"form"}>
    <div className="ModalNetwork" style={{ position: "absolute" }}>
      {/* <!-- MODAL GERER MON RESEAU - DÉBUT --> */}
      <div
        className="smart-modal-overlay"
        style={{
          display: "block",
          marginTop: compteExpert.artistPageMode ? 1500 : 0,
        }}
      ></div>

      <div
        className="smart-modal-content-l scrollAuto"
        style={{
          transform: "translate(-50%,-50%)",
          display: "inline-flex",
          flexWrap: "wrap",
          alignContent: "flex-start",
        }}
      >
        <h3>Modifier l'{title}</h3>
        <a
          className="smart-modal-close"
          onClick={(e) => {
            setBoolModal(false);
            setSelected([]);
          }}
        >
          <img className="img-left" src={close} alt="Trier" />
        </a>
        <div className="showSubs">
          {artist && (
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                width: "100%",
              }}
            >
              {/*<img style={{width: 'auto', height: 350, overflow: 'hidden', borderRadius: 10,}}*/}
              {/*     src={URLDayl + `images/paintingPic/${paintId}`} alt={artwork.aw_title_Origin}/>*/}
            </div>
          )}

          {contenu !== enumContenu.ArtistStatement &&
            contenu !== enumContenu.ContenuWikipedia && (
              <input
                type="text"
                className="title"
                style={{}}
                value={valueOfTitle ? valueOfTitle : ""}
                onChange={(value) => {
                  setValueOfTitle(value.target.value);
                }}
                placeholder="Title"
                required
              />
            )}

          <TextareaAutosize
            style={{ paddingBottom: 10 }}
            minRows={5}
            placeholder={"Votre avis"}
            value={value}
            onChange={(e) => {
              setCount(e.target.value.length);
              setValue(e.target.value);
            }}
          />

          <div style={{ width: "4%" }} className="charCount">
            {count}/255
          </div>

          {contenu !== enumContenu.ArtistStatement &&
            modePeinture === false && (
              <div>
                <input
                  type="submit"
                  className="saveDraftBtn"
                  value={"Mentionner une oeuvre"}
                  onClick={() => {
                    setArtworkModal(true);
                  }}
                />
              </div>
            )}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 20,
              justifyContent: "center",
              width:"100%",
              overflow: "auto",
            }}
          >
            {contenu !== enumContenu.ArtistStatement &&
              selected &&
              selected.length > 0 &&
              selected.map((img) => (
                <div
                  className="imageContainer"
                  alt={artist && artist.artist_name_tot}
                  style={{
                    backgroundImage: `url(${URLDaylImage}images/paintingPic/full/${img})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <div
                    className={"imageSelectedTaggedArtwork"}
                    style={{
                      opacity: imageToRemove.includes(img) ? 0.5 : 1,
                    }}
                    onClick={() =>
                      setSelected(selected.filter((el) => el !== img))
                    }
                  >
                    Remove
                  </div>
                </div>
                // <img
                //
                //
                //
                // //      onClick={() => {
                // //     !imageToRemove.includes(img) ? setImageToRemove(imgs => [...imgs, img]) : setImageToRemove(imageToRemove.filter((el) => el !== img))
                // // }}
                // />
              ))}
          </div>
          <div className="deleteText">Click image to remove</div>

          {/*{contenu !== enumContenu.ArtistStatement && (*/}
          {/*  <input*/}
          {/*    type="file"*/}
          {/*    name="file"*/}
          {/*    onChange={changeHandler}*/}
          {/*    multiple={true}*/}
          {/*  />*/}
          {/*)}*/}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 20,
              justifyContent: "center",
            }}
          >
            {contenu !== enumContenu.ArtistStatement &&
              image &&
              [...image].map((file, index) => (
                <img
                  className={"imageSelectedTaggedArtwork"}
                  style={{
                    width: `${100 / image.length}%`,
                    borderRadius: 20,
                    gap: 20,
                    maxWidth: "30%",
                    opacity: imageToRemove.includes(file) ? 0.5 : 1,
                  }}
                  src={
                    (action === actions.Modifiy &&
                      contenu === enumContenu.ElementBiographies) ||
                    (contenu === enumContenu.AvisExperts &&
                      action === actions.Modifiy)
                      ? file
                      : URL.createObjectURL(file)
                  }
                  alt={artist && artist.artist_name_tot}
                  onClick={() => {
                    !imageToRemove.includes(file)
                      ? setImageToRemove((imgs) => [...imgs, file])
                      : setImageToRemove(
                          imageToRemove.filter((el) => el !== file)
                        );
                  }}
                />
              ))}
          </div>

          <div>
            <input
              type="submit"
              className="saveDraftBtn"
              value={action === actions.Create ? "Enregister" : "Modifier"}
              onClick={async () => {
                switch (contenu) {
                  case enumContenu.ArtistStatement: {
                    uploadDataArtistStatement();
                    setBoolModal(false);
                    setSelected([]);

                    break;
                  }
                  case enumContenu.ContenuWikipedia:
                    setSelected([]);
                    break;
                  case enumContenu.AvisExperts:
                    switch (action) {
                      case actions.Create: {
                        await uploadAvisExperts();
                        setSelected([]);
                        setBoolModal(false);
                        break;
                      }
                      case actions.Modifiy: {
                        // console.log("LANGUGAGE " + compteExpert.language)
                        // console.log(compteExpert.language !== "" ? compteExpert.language : null)
                        // console.log(imageToRemove)

                        updateAvisExperts();
                        setSelected([]);
                        setBoolModal(false);
                        break;
                      }
                    }
                    break;
                  case enumContenu.ElementBiographies:
                    switch (action) {
                      case actions.Create: {
                        uploadElementBiographie();
                        setBoolModal(false);
                        setSelected([]);
                        break;
                      }
                      case actions.Modifiy: {
                        // console.log(originalImage)
                        // console.log(image)
                        // console.log([...image].slice(0,-1))

                        // [...image].map((el) => {
                        //     console.log("GO")
                        //     console.log(el)
                        //     console.log(URL.createObjectURL(el))
                        //
                        // })

                        // image && [...image[image.length - 1]].map((el) => {
                        //
                        //     if (!originalImage.includes(el)) {
                        //         console.log(URL.createObjectURL(el))
                        //
                        //     }
                        //
                        //
                        // })
                        updateElementBiographie();
                        setSelected([]);
                        setBoolModal(false);
                        // setSelected([])
                        break;
                      }
                    }

                    break;
                }

                // setBoolModal(false)
              }}
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    // </form>

    //SUPPRIMER

    <div style={{}}>
      <div className="smart-modal-overlay" style={{ display: "block" }}></div>
      <div
        className="smart-modal-content-l scrollAuto"
        style={{
          transform: "translate(-50%,-50%)",
          display: "inline-flex",
          // flexWrap: "wrap",
          alignContent: "flex-start",
          // flex: 1,
        }}
      >
        <h3>Supprimer l'artist statement?</h3>
        <div className="inputInline mt-c">
          <div>
            <input
              type="reset"
              className="saveDraftBtn "
              value="Annuler"
              style={{}}
              onClick={() => {
                setBoolModal(false);
              }}
            />
          </div>
          <div>
            <input
              type="submit"
              className="saveDraftBtn"
              value="Supprimer"
              onClick={() => {
                setBoolModal(false);
                deletePost();
              }}
              style={{}}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
