
const getRandomElements = (array, numElements) => {
    const shuffledArray = array.slice().sort(() => Math.random() - 0.5);
    return shuffledArray.slice(0, numElements);
  };


export const PrefixQuestionsMouvements = (questions, mouvement) => {
const data = questions.map((question, index) => {
    return question.replace(/{{mouvement}}/g, "'" + mouvement + "'");
});
return data
}

export const PrefixQuestionsPeintureArtiste = (questions, peinture, artiste) => {
  const data = questions.map((question, index) => {
    let modifiedQuestion = question.replace(/{{peinture}}/g, "'" + peinture + "'");
    modifiedQuestion = modifiedQuestion.replace(/{{artiste}}/g, "'" + artiste + "'");
    return modifiedQuestion;
  }); 
  return data;
}

export const PrefixQuestionsArtiste = (questions, artiste) => {
  const data = questions.map((question, index) => {
      return question.replace(/{{artiste}}/g, "'" + artiste + "'");
  });
  return data
  }

export default getRandomElements;