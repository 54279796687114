import React from "react";
import Painting from "../component/OpusPage/Painting";

import TabsOpusPage from "../component/OpusPage/TabsOpusPage";

export default function OpusPage({
  user,
  setUser,
  paintId,
  setPaintId,
  isLogged,
}) {
  return (
    <div className="OpusPage">
      <Painting
        paintId={paintId}
        user={user}
        setUser={setUser}
        isLogged={isLogged}
      />
      <TabsOpusPage
        paintId={paintId}
        user={user}
        setUser={setUser}
        isLogged={isLogged}
        setPaintId={setPaintId}
      />
    </div>
  );
}
