import React, { useEffect, useState } from "react";
import axios from "axios";
import { URLDayl,URLDaylImage } from "../../services/constantes";
import close from "../../webapp/img/icons/ico_close_2.svg";
import artist from "../../webapp/img/icons/ico_artists_2.svg";
import filter from "../../webapp/img/icons/ico_filters_3.svg";
import { Link } from "react-router-dom";
import ListModal from "../general/ListModal";
import Fav from "../general/Fav";
import ListModal2 from "../general/ListModal2";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";
import PaintingItem from "../general/ImageGrid/PaintingItem";
import {
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Chip,
  OutlinedInput,
} from "@mui/material";

export default function DisplayList({ paintId, user, isLogged }) {
  const [listDetail, setListDetail] = useState([]);
  const [listArtist, setListArtist] = useState([]);
  const [boolModalArtist, setBoolModalArtist] = useState(false);
  const [boolAllArtist, setBoolAllArtist] = useState(true);
  const [orderDate, setOrderDate] = useState(0);
  const [tempList, setTempList] = useState([]);
  const [selectedArtists, setSelectedArtists] = useState([]);
  const [index, setIndex] = useState();


  useEffect(() => {
    window.scrollTo(0, parseInt(sessionStorage.getItem("listPaintListe")));
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      sessionStorage.setItem("listPaintListe", window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Call Jquery script
  useEffect(() => {
    const loadFile = async () => {
      const { modal } = await import("../../webapp/js/js_source/modalBox");
      const { init } = await import("../../webapp/js/js_source/colorPicker");
      const { grid } = await import("../../webapp/js/gridActions.min.js");
      var curColor = "rgba(255,0,0,1)";

      init(curColor);
      modal();
      grid();
    };
    loadFile();
  }, []);

  // Get List info
  useEffect(() => {
    axios
      .get(URLDayl + `userLists/detailByListId?listId=${paintId}`)
      .then((response) => {
        const boolArray = response.data.listPaintings.map((el) => ({
          ...el,
          boolVis: true,
        }));

        setListDetail(boolArray);
        setTempList(boolArray);

        let tempListArtist = response.data.listPaintings.filter(
          (v, i) =>
            response.data.listPaintings
              .map((match) => match.artist_idtf_intern)
              .indexOf(v.artist_idtf_intern) === i
        );

        const temp = [];

        response.data.listPaintings.forEach((painting) => {
          if (!temp.includes(painting.artist.artist_name_tot))
            temp.push(painting.artist.artist_name_tot);
        });

        setListArtist(temp);

        // setListArtist(tempListArtist)
        // tempListArtist = tempListArtist.map((el) => ({
        //   ...el,
        //   boolList: true,
        // }));
      });
  }, []);

  // Chooses the artists to filter
  const setTriArtist = (index) => {
    let tempList = [...listArtist];
    let tempListDetail = [...listDetail];

    if (tempList[index].boolList === true) {
      tempList[index].boolList = false;
      tempListDetail.map((list) => {
        if (list.artist_idtf_intern === tempList[index].artist_idtf_intern) {
          list.boolVis = false;
        }
      });
    } else {
      tempList[index].boolList = true;
      tempListDetail.map((list) => {
        if (list.artist_idtf_intern === tempList[index].artist_idtf_intern) {
          list.boolVis = true;
        }
      });
    }
    setListArtist([...tempList]);
    setListDetail([...tempListDetail]);

    testAllArtists();
  };

  // Tests if all artists are selected
  const testAllArtists = () => {
    let count = 0;

    listArtist.map((artist) => {
      if (artist.boolList === true) {
        count++;
      }
    });

    if (count === listArtist.length) {
      setBoolAllArtist(true);
    } else {
      setBoolAllArtist(false);
    }
  };

  // Handles selecting or deselecting all artists
  const allArtists = () => {
    let tempList = [...listArtist];
    let tempListDetail = [...listDetail];

    if (boolAllArtist === true) {
      for (let i = 0; i < tempList.length; i++) tempList[i].boolList = false;
      for (let i = 0; i < tempListDetail.length; i++)
        tempListDetail[i].boolVis = false;
      setBoolAllArtist(false);
    } else {
      for (let i = 0; i < tempList.length; i++) tempList[i].boolList = true;
      for (let i = 0; i < tempListDetail.length; i++)
        tempListDetail[i].boolVis = true;
      setBoolAllArtist(true);
    }
    setListArtist([...tempList]);
    setListDetail([...tempListDetail]);
  };

  // Handles opening the artist filter modal
  const openModalArtist = () => {
    setBoolModalArtist(!boolModalArtist);
  };

  // Handles selecting artists from the Select component
  const handleChangeSelect = (event) => {
    const {
      target: { value },
    } = event;

    setSelectedArtists(value);
  };

  // Handles sorting the paintings by date
  const handleOrderDate = () => {
    if (orderDate === 0) {
      setListDetail([...listDetail].sort((a, b) => b.aw_year - a.aw_year));
      setOrderDate(1);
    } else if (orderDate === 1) {
      const bufferOrder = [...listDetail].filter(
        (paint) => paint.aw_year === null || paint.aw_year === 0
      );
      const bufferOrder2 = [...listDetail]
        .filter((paint) => paint.aw_year > 0)
        .sort((a, b) => a.aw_year - b.aw_year);
      const sortedList = [...bufferOrder, ...bufferOrder2];

      setListDetail(sortedList);
      setOrderDate(2);
    } else if (orderDate === 2) {
      setListDetail(tempList);
      setOrderDate(0);
    }
  };
  const useStyles = makeStyles({
    checkbox: {
      color: "#101e39",
      "&$checked": {
        color: "#101e39",
      },
    },
    checked: {},
  });
  const classes = useStyles();
  let globalIndex = 0;


  return (
    <div className="DisplayList">
      <section className="center-col">
        <div className="orderingBox">
          <button
            className={
              orderDate === 0
                ? "orderByDate"
                : orderDate === 1
                  ? "orderByDateDown"
                  : "orderByDateUp"
            }
            onClick={handleOrderDate}
            style={{ backgroundColor: "#F3F3F3" }}
          >
            {orderDate === 0
              ? "Tri par date"
              : orderDate === 1
                ? "Tri décroissant"
                : "Tri croissant "}
          </button>
          <Select
            multiple
            style={{ minWidth: 200 }}
            displayEmpty
            value={selectedArtists}
            labelId="demo-multiple-checkbox-label"
            onChange={handleChangeSelect}
            input={<OutlinedInput label="" placeholder="Select Artists" />}
            renderValue={(selected) =>
              selected.length > 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                    maxWidth: "1040px",
                  }}
                >
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              ) : (
                <em>Select Artists</em>
              )
            }
            // MenuProps={MenuProps}
          >
            {listArtist
              .sort((a, b) => a.localeCompare(b))
              .map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox
                    className={classes.checkbox}
                    classes={{ checked: classes.checked }}
                    checked={selectedArtists.indexOf(name) > -1}
                  />
                  <ListItemText
                    style={{ marginRight: "20px", fontFamily: "" }}
                    primary={name}
                  />
                </MenuItem>
              ))}
          </Select>
        </div>

        <div className="gridContent items w-full flex flex-row flex-wrap gap-5 my-5 justify-center items-center">
          {listDetail.length > 0 &&
            listDetail.map(
              (paint, index) =>
                (selectedArtists.includes(paint.artist.artist_name_tot) ||
                  selectedArtists.length === 0) && (
                    <PaintingItem
                    key={paint.id}
                    listPaint={paint}
                    index={globalIndex++}
                    setIndex={setIndex}
                    
                    showTools={true}
                    isLogged={isLogged}
                    size={4}
                    URLDaylImage={URLDaylImage}
                    selectionMode={false}
                    user={user}
                  />)
            )}
        </div>
      </section>
    </div>
  );
}
