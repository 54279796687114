import React from "react";
import "react-color-palette/lib/css/styles.css";
import NewColorPicker from "../component/MatchingColor/NewColorPicker";

/**
 * This component display the Matching Color Page
 * @param {object} user - user object from api
 * @param {object} setUser - user setter
 * @param {boolean} isLogged - test if the user is logged
 */

export default function MatchingColor({ user, setUser, isLogged }) {
  return (
    <div className="MatchingColor">
      <NewColorPicker user={user} isLogged={isLogged} setUser={setUser} />
    </div>
  );
}
