import React from "react";

const Section = ({ number, title, text, image, border = true }) => {
  const sectionContainerStyle = {
    position: "relative",
  };

  const afterStyle = {
    content: '""',
    position: "absolute",
    bottom: 0,
    left: "10%",
    width: "50%",
    height: "2px",
    backgroundColor: "black",
  };

  return (
    <div
      className={`flex items-center font-raleway border-black py-5 gap-10 ${border ? "" : ""}`}
      style={border ? sectionContainerStyle : {}}
    >
      {border && <div style={afterStyle}></div>}
      <div className="text-5xl italic" style={{ fontFamily: "Cinzel" }}>
        {number}
      </div>
      <div className="flex gap-10">
        <div className="flex flex-col w-3/4">
          <div className="font-bold text-xl">{title}</div>
          <div className="text-base">{text}</div>
        </div>
      </div>
    </div>
  );
};

export default Section;
