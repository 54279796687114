import React from "react";
import "../../webapp/css/core.css";
import "../../webapp/css/carousels.css"
import "../../webapp/css/blocks.css"
import "../../webapp/css/account.css"
import "../../webapp/css/expert.css"
import "../../webapp/css/opusPage.css"

import CustomFooter from "./Footer";
// import "../HomePage/ImageMasonryComponent.css"

/**
 * website footer
 * @param {object} user - user object from api
 */

export default function SqueletteFoot({ user }) {
  return (
    <div className="SqueletteFoot">

      <footer>
          <CustomFooter/>
      </footer>
      {/* <!-- FOOTER - fin -->

            <!-- APPELS JAVASCRIPT - début --> */}
      <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.1.1/jquery.min.js"></script>
      <script src="js/isLoading.min.js"></script>
      <script src="js/owlCarousel.min.js"></script>
      <script src="js/colorPicker.min.js"></script>
      <script src="js/modalBox.min.js"></script>
      <script src="js/serializeIt.min.js"></script>
      <script src="js/checkLister.min.js"></script>
      <script src="js/accordion.min.js"></script>
      <script src="js/sliderBox.min.js"></script>
      <script src="js/zoomBox.min.js"></script>
      <script src="js/niceFileUpload.min.js"></script>
      <script src="js/gridActions.min.js"></script>
      {/* <!-- APPELS JAVASCRIPT - fin --> */}
    </div>
  );
}