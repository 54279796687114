import React, { useEffect, useState } from "react";
import { PeintresCompteExpert } from "../component/CompteExpert/PeintresCompteExpert";
import { PeinturesCompteExpert } from "../component/CompteExpert/PeinturesCompteExpert";
import { CompteExpertProvider } from "../contexts/CompteExpertService";
import { useUser } from "../contexts/userService";
import MouvementCompteExpert from "../component/CompteExpert/MouvementCompteExpert";
import { useNavigate } from "react-router";
import { ColorCompteExpert } from "../component/CompteExpert/ColorCompteExpert";
import { ContenuDaylCompteExpert } from "../component/CompteExpert/ContenuDaylCompteExpert";

export default function CompteExpertPage({ user, setUser, isLogged, friend }) {
  const [isActive, setIsActive] = useState(0);
  const auth = useUser();
  const navigate = useNavigate();

  const renderTab = () => {
    switch (isActive) {
      case 0:
        return <PeintresCompteExpert user={user} />;
      // return <ArtistStatementCompteExpert/>
      case 1:
        return <PeinturesCompteExpert user={user} />;
      case 2:
        return <MouvementCompteExpert user={user} />;
      case 3:
        return <ColorCompteExpert user={user} />;
      case 4:
        return <ContenuDaylCompteExpert />;
      default:
        return <div></div>;
    }
  };

  useEffect(() => {
    if (!auth.data || auth.data === null) {
      navigate("../../view/LogIn.js");
    }
  }, []);

  return (
    <>
      {auth.data && (
        <CompteExpertProvider>
          <div className="darkBgGradient-2">
            <div className="userTop">
              <h1>{user && user.firstName + " " + user.lastName}</h1>
              <p>Compte Expert</p>
              <nav>
                <ul>
                  <li>
                    <a href="#" onClick={() => setIsActive(0)}>
                      Éditer une page peintre
                    </a>
                  </li>
                  <li>
                    <a href="#" onClick={() => setIsActive(1)}>
                      Éditer une page peinture
                    </a>
                  </li>
                  <li>
                    <a href="#" onClick={() => setIsActive(2)}>
                      Éditer une page mouvement
                    </a>
                  </li>
                  <li>
                    <a href="#" onClick={() => setIsActive(3)}>
                      Éditer une page couleur
                    </a>
                  </li>
                  <li>
                    <a href="#" onClick={() => setIsActive(4)}>
                      Éditer une page Contenu
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <main className="center-col">
            <div className="editMain">{renderTab()}</div>
          </main>
        </CompteExpertProvider>
      )}
    </>
  );
}
