import React, { useEffect, useState, useRef } from "react";
import { URLDayl } from "../../services/constantes";
import axios from "axios";
import "../../App.css";
import "../../webapp/css/core.css";
import "../../webapp/css/carousels.css";
import "../../webapp/css/blocks.css";
import "../../webapp/css/animations.css";
import "../../webapp/css/account.css";
import "../../webapp/css/atelier.css";
import "../../webapp/css/stats.css";
import "../../webapp/css/social.css";
import "../../webapp/css/socialRemodal.css";
import "../ArtistDetailPage/CustomGaleryCarousel/CustomGaleryCarousel.css";
import Logo from "../../webapp/img/logos/logo_2.svg";
import ChatMenu from "../Chat/ChatMenu";
import { useUser } from "../../contexts/userService";
import SearchIcon from "@mui/icons-material/Search";
import PaintingSearchAsyncSelect from "../PaintingSearch/PaintingSearchAsyncSelect";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ProfileMenuIcon from "./ProfileMenuIcon";
import LanguageSelector from "./languageSelector";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AgentGPTHeader from "./AgentGpt/AgentGPTHeader";
import { GeneralQuestionsAboutArt } from "../../services/QuestionsTemplate";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
/**
 * website header
 * @param {object} user - user object from api
 * @param {object} setUser - user setter
 *  @param {boolean} isLogged - test if the user is logged
 * @emits - users/${user.id} : update user informations

 */

export default function SqueletteHead({ user, setUser, isLogged }) {
  const [boolChat, setBoolChat] = useState(false);
  const auth = useUser();
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const dropdownRef = useRef(null);
  const searchRef = useRef(null);

  const handleClick = (path, state = null) => {
    navigate(path, { state });
  };

  useEffect(() => {
    if (user)
      axios
        .get(URLDayl + `users/${user.id}`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setUser(response.data);
        })
        .catch((err) => {
          console.log("ERROR HEAD");
        });
  }, [setUser, user]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowSearch(false);
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const { t } = useTranslation();

  return (
    <div className="" style={{ height: "70px" }}>
      <header>
        <div className="flex items-center justify-between bg-white border-b-2 border-black h-[70px] left-0 fixed top-0 w-screen z-[999997] ">
          <div className="flex items-center gap-5 ml-5">
            <nav className="menu">
              <ul className="title-menu">
                <li>
                  <div
                    style={{
                      display: "block",
                    }}
                  >
                    <MenuIcon
                      style={{
                        color: "black",
                        fontSize: "50px",
                        marginTop: "5px",
                      }}
                    />
                  </div>
                  <div className="wrap-list ">
                    <div
                      onClick={() => handleClick("/")}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={Logo}
                        className="w-20 my-5 mx-10"
                        alt="AI Artworks - Accueil"
                      />
                    </div>
                    <div className="flex flex-col">
                      <div
                        className="newmenu-item active border-t border-black"
                        onClick={() => handleClick("/FriseMouvementArtistique")}
                      >
                        DAYL- Frise historique
                      </div>
                      <div
                        className="newmenu-item active"
                        onClick={() =>
                          handleClick("view/customRequests/11332", 11332)
                        }
                      >
                        DAYL- Chroma
                      </div>
                      <div
                        className="newmenu-item active"
                        onClick={() => handleClick("view/MatchingImage2.js")}
                      >
                        {t("Match_Your_Artworks")}
                      </div>
                      <div
                        className="newmenu-item active"
                        onClick={() => handleClick("view/CompteAmateurPage.js")}
                      >
                        {t("My_Account")}
                      </div>

                      <div
                        className="newmenu-item active"
                        onClick={() => handleClick("/view/HDPaintings.js")}
                      >
                        {t("HD_Artworks")}
                      </div>
                      <div
                        className="newmenu-item active"
                        onClick={() => handleClick("/view/DaylContent")}
                      >
                        DAYL- Contenus
                      </div>
                      <div
                        className="newmenu-item"
                        // onClick={() => handleClick("/StartingPage")}
                      >
                        Presentation DAYL
                      </div>

                      {/* <div
                        className=" border-b border-black py-5 px-10 text-xl font-raleway hover:text-sky-400 cursor-pointer"
                        onClick={() => handleClick("/DAYL_Mediation")}
                      >
                        {t("DAYL_Mediation")}
                      </div>
                      <div
                        className="newmenu-item"
                        onClick={() =>
                          handleClick("/Chromatic_Analysis_Presentation")
                        }
                      >
                        {t("Chromatic_Analysis_Presentation")}
                      </div> */}

                      <div
                        className="newmenu-item"
                        // onClick={() => handleClick("/PageStatistiques")}
                      >
                        {t("Trends")}
                      </div>
                      <div
                        className="newmenu-item"
                        // onClick={() => handleClick("/Partners")}
                      >
                        {t("Partners")}
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </nav>
            <img
              src={Logo}
              className=" h-10 w-auto cursor-pointer"
              alt="Retour à l'accueil"
              onClick={() => handleClick("/")}
            />
          </div>

          <div className="flex justify-center align-middle items-center text-black text-lg gap-16">
            <div className="relative" ref={dropdownRef}>
              <div
                className="cursor-pointer underlinedButton flex justify-between items-center"
                onClick={() => setShowDropdown((prev) => !prev)}
              >
                COULEURS
                <KeyboardArrowDownIcon style={{ fontSize: 35 }} />
              </div>
              {showDropdown && (
                <div className="absolute mt-2 bg-white text-sm z-10 w-fit font-raleway text-nowrap flex flex-col border-l-2 border-black shadow-lg">
                  <div
                    className="cursor-pointer hover:bg-gray-100 p-2"
                    onClick={() => {
                      handleClick("view/MatchingColor.js", {
                        hex: "#000000",
                        rgb: { r: 0, g: 0, b: 0 },
                        hsv: { h: 0.6593406593406593, s: 0, v: 0 },
                      });
                      setShowDropdown(false);
                    }}
                  >
                    JOUEZ AVEC COULEURS
                  </div>
                  <div
                    className="cursor-pointer hover:bg-gray-100 p-2"
                    onClick={() => {
                      handleClick("view/DiscoverColors.js");
                      setShowDropdown(false);
                    }}
                  >
                    COULEURS REMARQUABLES
                  </div>
                </div>
              )}
            </div>
            <div
              className="cursor-pointer underlinedButton"
              onClick={() => handleClick("/view/DiscoverArtiste.js")}
            >
              ARTISTES
            </div>
            <div
              className="cursor-pointer underlinedButton"
              onClick={() => handleClick("/view/PaintingSearch.js")}
            >
              PEINTURES
            </div>
            <div
              className="cursor-pointer underlinedButton"
              onClick={() => handleClick("view/MovementPage.js")}
            >
              MOUVEMENTS
            </div>
            <div
              className="cursor-pointer underlinedButton"
              onClick={() => handleClick("view/MatchingImage2.js")}
            >
              MATCH
            </div>
          </div>
          <div className="flex gap-3 items-center mr-5">
            <div ref={searchRef}>
              <SearchIcon
                style={{
                  height: 33,
                  width: 33,
                  color: "black",
                  fontSize: 20,
                }}
                onClick={() => setShowSearch(!showSearch)}
              />
              {showSearch && (
                <PaintingSearchAsyncSelect setActive={setShowSearch} />
              )}
            </div>
            <div
              style={{
                height: 33,
                width: 33,
                color: "black",
                fontSize: 20,
              }}
            >
              <LanguageSelector style={{ width: "30px" }} />
            </div>

            {auth && auth.data && (
              <div
                style={{
                  cursor: "pointer",
                  color: "black",
                }}
              >
                <AgentGPTHeader
                  data={"Tu es un agent expert dans le domaine de l'art , "}
                  questions={GeneralQuestionsAboutArt}
                  nb={10}
                  color={"black"}
                />
              </div>
            )}

            {auth.data ? (
              <ProfileMenuIcon />
            ) : (
              <div onClick={() => handleClick("/view/LogIn.js")}>
                <AccountCircleIcon style={{ color: "black", fontSize: 40 }} />
              </div>
            )}
          </div>
        </div>

        {/* <!-- ******************************************************
                ***********************************************************

                MENU GLISSANT BORD GAUCHE - fin

                ***********************************************************
			          ******************************************************* --> */}
      </header>
      {/* <!-- HEADER - fin --> */}

      {boolChat === true && (
        <ChatMenu user={user} boolChat={boolChat} setBoolChat={setBoolChat} />
      )}
    </div>
  );
}
