import React, { useEffect, useState } from "react";
import axios from "axios";
import { URLDayl } from "../../services/constantes";
import { Menu, MenuItem } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { toColor } from "react-color-palette";

export default function ColorPaletteItem({
    color,
    master_color,
    setColor,
    notHex,
    selectedColor,
  }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [childColors, setChildColors] = useState([]);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    useEffect(() => {
      axios
        .get(
          URLDayl +
            `paintingEntity/getColorsByMaster?master_color=${master_color}`
        )
        .then((res) => {
          setChildColors(res.data);
        });
    }, [master_color]);
  
    return (
      <>
        <div
          onMouseOver={handleClick}
          style={{
            backgroundColor: color,
            width: 35,
            height: 35,
            borderRadius: 100,
            boxShadow:
              "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.13) 0px 3px 6px",
          }}
        ></div>
  
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{
            onMouseLeave: handleClose,
          }}
          PaperProps={{ style: { width: "fit-content" } }}
        >
          <h3
            style={{
              fontWeight: "bold",
              justifyContent: "center",
              textAlign: "center",
              marginTop: 30,
              marginBottom: 20,
            }}
          >
            {master_color}
          </h3>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 20,
              padding: 50,
              paddingTop: 0,
              maxWidth: 500,
              justifyContent: "center",
            }}
          >
            {childColors.length > 0 &&
              childColors.map((el, index) => (
                <Tooltip title={el.color_name} arrow key={el.id || index}>
                  <MenuItem
                    onClick={() => {
                      notHex
                        ? setColor((previous) => ({
                            ...previous,
                            color: el.color_name,
                          }))
                        : setColor(toColor("hex", el.hex));
                      handleClose();
                    }}
                    style={{
                      backgroundColor: el.hex,
                      width: 35,
                      height: 35,
                      borderRadius: 100,
                      boxShadow:
                        "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.13) 0px 3px 6px",
                    }}
                  />
                </Tooltip>
              ))}
          </div>
        </Menu>
      </>
    );
  }
  