/*!
ACCORDION ... is a simple accordion function
by Stéphane ertz (v1.0 - 02/2022)
*/
import $ from "jquery";

export function accordion() {
	$(".accordion > .accordionItem.isActive").children("div").slideDown();
	$(".accordion > .accordionItem.isActive").children(".desc").slideDown();
	$(".accordion > .accordionItem").click(function() {
		$(this).siblings(".accordionItem").removeClass("isActive").children("div").slideUp();
		$(this).siblings(".accordionItem").removeClass("isActive").children(".desc").slideUp();
		$(this).toggleClass("isActive").children("div").slideToggle("ease-out");
		$(this).toggleClass("isActive").children(".desc").slideToggle("ease-out");
	});
};