import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import {URLDayl, URLDaylImage} from "../../services/constantes"
import { Link } from "react-router-dom";

/**
 * List of similar artists
 * @param {number} paintId - artist ID
 * @emits - similarArtists/findByArtistId/${paintId}/?numberOfArtists=49 : get list of similar artists
 */
export default function SimilarArtist({paintId }) {
    const [listSimilarArtist, setListSimilarArtist] = useState([]);
    useEffect(() => {
        axios.get(URLDayl + `similarArtists/findByArtistId/${paintId}?numberOfArtists=10`).then((response) => {
            setListSimilarArtist(response.data);
            
        });
    }, [paintId]);
    return (
        <div className="SimilarArtists">
            <section id="#" className="center-col">
                <div className="cont-l-w">
                    <h2 className="mt-c">Artistes similaires</h2>
                    <div className="accountArtists mt-b">
                        {listSimilarArtist.length > 0 &&
                            listSimilarArtist.map((listSimilar, index) => {

                                if (listSimilar.artistDisplayDTO == null) {
                                    return null
                                }
                                return (
                                    <div key={index} className="item" style={{ backgroundImage: `url(${URLDaylImage}images/artistPic/${listSimilar.artistDisplayDTO.id})`, backgroundRepeat: "no-repeat", backgroundPosition: "center center", backgroundSize: "cover" }}>
                                        {/* <div className="base" > */}
                                        {/* <div className="img-wrapper"> */}
                                        <div className="name">{listSimilar.artistDisplayDTO != null && listSimilar.artistDisplayDTO.name}</div>
                                        <div className="items-count">{listSimilar.artistDisplayDTO && listSimilar.artistDisplayDTO.numberOfPAinting} Oeuvres</div>
                                        <Link className="discover" to={`../../view/ArtistDetailPage.js/${listSimilar.artistDisplayDTO.id}`} state={listSimilar.artistDisplayDTO.id} ></Link>
                                        {/* <img src={`https://art.novagen.tech/medias/artist/${listSimilar.artistDisplayDTO != null && listSimilar.artistDisplayDTO.functionalId}/_mini/${listSimilar.artistDisplayDTO != null && listSimilar.artistDisplayDTO.backgroundImage}`} alt="Nom de l'artiste" /> */}
                                        {/* </div> */}
                                        {/* </div> */}
                                    </div>
                                )
                            })}
                    </div>
                </div>
            </section>
        </div>
    );
}