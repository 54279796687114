import React, { useRef, useEffect, useState } from "react";
import * as d3 from "d3";
import { URLDayl } from "../services/constantes";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Tooltip = ({ data }) => {
  const tooltipStyle = {
    position: "absolute",
    left: `${data.x + 20}px`,
    top: "50px",
    backgroundColor: "white",
    border: "1px solid black",
    padding: "10px",
    borderRadius: "5px",
    zIndex: 1000,
    maxWidth: "500px",
  };

  return (
    <div style={tooltipStyle}>
      <h3 className="text-lg font-semibold text-gray-800 mb-2 uppercase">
        {data.name}
      </h3>
      <p className="text-base font-bold text-gray-600 mb-2 italic">
        {data.year}
      </p>
      <p className="text-base text-gray-700 mb-3">{data.description}</p>
      {data.paintId && (
        <img
          src={data.imageUrl}
          alt={data.name}
          className="w-full h-auto rounded-sm"
        />
      )}
    </div>
  );
};

const FriseMouvementArtistique = () => {
  const ref = useRef();
  const [data, setData] = useState(null);
  const [retrievedData, setRetrievedData] = useState(null);
  const [isPostImpressionismTree, setIsPostImpressionismTree] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [tooltipData, setTooltipData] = useState(null);
  const navigate = useNavigate();
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          URLDayl + "editorial-contents/movements/detailed",
          {
            headers: {
              "Cache-Control": "no-cache",
              Pragma: "no-cache",
              Expires: "0",
            },
            params: {
              _: new Date().getTime(),
            },
          }
        );
        const fetchedData = response.data;
        setRetrievedData(fetchedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (retrievedData) {
      const transformedData = transformData(retrievedData);
      setData(transformedData);
    }
  }, [retrievedData]);

  const transformData = (movements) => {
    const movementMap = new Map();
    movements.forEach((movement) => {
      movementMap.set(movement.id, {
        id: movement.id,
        name: movement.movement || "",
        year: movement.dateStart ? movement.dateStart.toString() : "",
        description: movement.summary || "",
        paintId: movement.paintingId ? movement.paintingId.toString() : "",
        children: movement.children || [],
      });
    });

    const root = movementMap.get(182);
    const postImpressionism = movementMap.get(15);
    if (!root || !postImpressionism) {
      return null;
    }

    const processNode = (node, includeChildren = true) => {
      const processedNode = { ...node };
      if (includeChildren) {
        processedNode.children = node.children
          .map((child) => {
            if (typeof child === "object" && child !== null) {
              const fullChildData = movementMap.get(child.id);
              return fullChildData ? processNode(fullChildData) : null;
            }
            return null;
          })
          .filter(Boolean);
      }
      if (processedNode.name === "classicisme") {
        const rococoNode = Array.from(movementMap.values()).find(
          (m) => m.name === "rococo"
        );
        if (rococoNode) {
          processedNode.children.push(processNode(rococoNode));
        }
      }
      return processedNode;
    };

    const mainTree = processNode(root);
    const postImpressionismTree = processNode(postImpressionism);

    const findAndModifyPostImpressionism = (node) => {
      if (node.id === 15) {
        node.children = [{ name: "VOIR PLUS", id: "see-more" }];
        return true;
      }
      for (const child of node.children || []) {
        if (findAndModifyPostImpressionism(child)) {
          return true;
        }
      }
      return false;
    };

    findAndModifyPostImpressionism(mainTree);

    const postImpressionismWithParent = {
      name: "VOIR PLUS",
      id: "see-more-parent",
      children: [postImpressionismTree],
    };

    return { mainTree, postImpressionismTree: postImpressionismWithParent };
  };

  useEffect(() => {
    if (data === null) {
      return;
    }

    const svgSelection = d3.select(ref.current);
    svgSelection.selectAll("*").remove();
    const svg = svgSelection
      .append("svg")
      .attr("width", window.innerWidth)
      .attr("height", window.innerHeight)
      .style("background-color", "#FAFAFA");

    const g = svg.append("g");

    const root = d3.hierarchy(
      isPostImpressionismTree ? data.postImpressionismTree : data.mainTree
    );

    const fixedDepth = 300;
    root.each((d) => {
      d.y = d.depth * fixedDepth;
    });

    const tree = d3
      .tree()
      .size([window.innerHeight * 1.5, window.innerWidth * 2]);

    tree(root);

    const zoom = d3
      .zoom()
      .scaleExtent([0.4, 2])
      .on("zoom", (event) => {
        g.attr("transform", event.transform);
      });

    const rootNodePositionx = root.descendants()[0].y;
    const initialTranslation = [rootNodePositionx + 40, 0];

    svg
      .call(zoom)
      .call(
        zoom.transform,
        d3.zoomIdentity.translate(...initialTranslation).scale(0.6)
      );

    const linkGenerator = d3
      .linkHorizontal()
      .x((d) => d.y)
      .y((d) => d.x - 8);

    const linkGroup = g.append("g").attr("transform", "translate(50,50)");
    const nodeGroup = g.append("g").attr("transform", "translate(50,50)");

    linkGroup
      .selectAll("path")
      .data(root.links())
      .enter()
      .append("path")
      .attr("d", linkGenerator)
      .attr("fill", "none")
      .attr("stroke", "black")
      .attr("stroke-width", 1)
      .attr("stroke-dasharray", "2,2");

    nodeGroup
      .selectAll("text")
      .data(root.descendants())
      .enter()
      .append("text")
      .attr("x", (d) => d.y)
      .attr("y", (d) => d.x)
      .attr("dy", function (d) {
        return d.data.name === "VOIR PLUS" ? "0" : "-3";
      })
      .attr("fill", "#black")
      .attr("text-anchor", "middle")
      .attr("paint-order", "stroke")
      .attr("stroke", "#FAFAFA")
      .attr("stroke-width", 6)
      .text((d) => d.data.name)
      .style("cursor", (d) =>
        d.data.name === "VOIR PLUS" ? "pointer" : "default"
      )
      .style("font-weight", (d) =>
        (d.children && d.children.length) >= 1 ||
        d.data.name ===
          (isPostImpressionismTree ? "POST-IMPRESSIONNISME" : "ART BYZANTIN")
          ? "bold"
          : "normal"
      )
      .style("font-size", (d) =>
        (d.children && d.children.length) >= 1 ||
        d.data.name ===
          (isPostImpressionismTree ? "POST-IMPRESSIONNISME" : "ART BYZANTIN")
          ? 15
          : 13
      )
      .style("font-family", "Cinzel")
      .classed("voir-plus", (d) => d.data.name === "VOIR PLUS")
      .on("click", (event, d) => {
        if (d.data.name === "VOIR PLUS" || d.data.id === "see-more-parent") {
          setIsPostImpressionismTree(!isPostImpressionismTree);
        } else {
          navigate(`/view/DaylContent/${d.data.id}`);
        }
      })
      .on("mouseover", (event, d) => {
        event.stopPropagation();
        setIsTooltipVisible(true);

        if (d.data.name === "VOIR PLUS" || d.data.id === "see-more-parent") {
          return;
        }

        const paintId = d.data.paintId.startsWith("#")
          ? d.data.paintId.substring(1)
          : d.data.paintId;
        const imageUrl = `${URLDayl}images/paintingPic/${paintId}`;
        const newTooltipData = {
          name: d.data.name,
          year: d.data.year,
          description: d.data.description,
          paintId: paintId,
          imageUrl: imageUrl,
          x: event.clientX,
          y: event.clientY,
        };

        if (JSON.stringify(newTooltipData) !== JSON.stringify(tooltipData)) {
          setTooltipData(newTooltipData);
        }
      })
      .on("mouseout", (event) => {
        event.stopPropagation();
        setIsTooltipVisible(false);
        setTooltipData(null);
      });
  }, [data, isPostImpressionismTree]);

  return (
    <>
      <>
        {showModal && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50 flex items-center justify-center">
            <div className="p-6 border shadow-lg rounded-md bg-white max-w-md w-full">
              <div className="text-center">
                <h3 className="text-xl font-semibold text-gray-900 mb-4">
                  Bienvenue sur notre Frise DAYL
                </h3>
                <p className="text-gray-600 mb-4">
                  Découvrez l'histoire de l'art à travers notre frise
                  interactive. Explorez les différents mouvements artistiques,
                  leurs caractéristiques et leurs influences.
                </p>
                <p className="text-gray-600 mb-4">
                  N'hésitez pas à zoomer et à vous déplacer pour explorer et
                  découvrir de nouveaux genres.
                </p>
                <p className="text-gray-600 mb-6">
                  La frise continue après le post-impressionnisme. Cliquez sur
                  "VOIR PLUS" pour découvrir la suite.
                </p>
                <button
                  id="ok-btn"
                  className="px-4 py-2 w-fit bg-blue-500 text-white text-base font-medium rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
                  onClick={() => setShowModal(false)}
                >
                  Commencer
                </button>
              </div>
            </div>
          </div>
        )}
        <div ref={ref}></div>
        {isTooltipVisible && tooltipData && <Tooltip data={tooltipData} />}
      </>
    </>
  );
};

export default FriseMouvementArtistique;
