import React, {useEffect, useState} from "react";
import axios from "axios";
import {URLDayl} from "../../services/constantes"
import ModalEditPainting from "./ModalEditPainting";
import $ from "jquery";
import CloseIcon from '@mui/icons-material/Close';
import {enumTypeGrid} from "../general/ImageGrid/ImageGrid";

export default function GridChoosePainting({user, artistIdtf}) {

    const [userSession, setUserSession] = useState([user]);
    const [painting, setPainting] = useState([]);
    const [matchList, setMatchList] = useState([]);
    const [refreshKey, setRefreshKey] = useState(0);

    const [tempList, setTempList] = useState([]);
    const [editModal, setEditModal] = useState(false)
    const [artistId, setArtistId] = useState("wikiart_august_macke")
    const [selectedPainting, setSelectedPainting] = useState()

    const [col, setCol] = useState(4)
    const [refreshCol, setRefreshCol] = useState(0)

    const [maxNumberPainting, setMaxNumberPainting] = useState(48)
    const [totalNumberPainting, setTotalNumberPainting] = useState(0)
    const [orderDate, setOrderDate] = useState("")
    const [year, setYear] = useState({startYear: "", endYear: ""})


    useEffect(() => {


        axios.get(URLDayl + `paintingSearch/findPaintingByArtistIdtfn?artistId=${artistIdtf}&number=${maxNumberPainting}&minNumPainting=${0}&sort=${orderDate}&startYear=${year.startYear}&endYear=${year.endYear}`).then((response) => {
            setMatchList(response.data.payload);
            setTotalNumberPainting(response.data.numberMax)

            const boolArray = response.data.payload.map((el) => {
                return {...el, bool: false, boolList: false}
            });

            setTempList(boolArray)

            setMatchList(boolArray)
        });
    }, [artistIdtf, maxNumberPainting, orderDate, refreshKey]);


    const sortOrderDate = () => {

        switch (orderDate) {
            case "ASC":
                setOrderDate("DESC")
                break
            case "DESC":
                setOrderDate("ASC")
                break
            default:
                setOrderDate("ASC")
                break
        }

    }

    return (
        <div className="SimilarPaint">
            {/*
				<!-- ******************************************************
				GRILLE AUTRES OEUVRES DE CETTE ARTISTE - début
				****************************************************** --> */}
            <section className="center-col center-tex">
                <div className="cont-l-w center-tex">
                    <h2>Liste des oeuvres de {matchList.length > 0 && matchList[0].artisteName}</h2>
                </div>


                <div className="orderingBox">

                    {
                        year && <form>
                            <input className="inputYears" placeholder={"à partir de (année)"} value={year.startYear}
                                   onChange={(e) => setYear({startYear: e.target.value, endYear: year.endYear})}/>
                            <input className="inputYears" placeholder={"jusqu'à (année)"} value={year.endYear}
                                   onChange={(e) => setYear({startYear: year.startYear, endYear: e.target.value})}/>
                            <button className="defineYears" onClick={() => {
                                setOrderDate("");
                                setRefreshKey(refreshKey + 1)
                            }} type={"button"}>OK
                            </button>
                        </form>
                    }

                    {/* <!-- Dropdown type de matching - fin --> */}
                    {/* <!-- Bouton matching par année (+ ancien au + récent) - début --> */}
                    {<button
                        className={orderDate === "" ? "orderByDate" : orderDate === "ASC" ? "orderByDateDown" : "orderByDateUp"}
                        data-tooltip="Filtrer les oeuvres chronologiquement"
                        onClick={sortOrderDate}>{orderDate === "" ? "Trier par date" : orderDate === "ASC" ? "Du + ancien au + récent" : "Du + récent au + ancien"}</button>}
                    {/* <!-- Bouton matching par année (+ ancien au + récent) - fin --> */}

                    {orderDate !== "" && orderDate !== undefined &&
                        <button style={{height: 20, width: 20, backgroundColor: "red"}}
                                onClick={() => setOrderDate("")}>

                        </button>}


                    {/* <!-- Bouton matching par artistes - début --> */}
                    {/*<button className="orderByArtist" onClick={openModalArtist} data-tooltip="Filtrer les artistes présents dans la sélection">Trier par artiste(s)</button>*/}
                    {/* <!-- Choix du type de grille - début --> */}
                    <ul className="gridColsTypeMatch" data-tooltip="Choisir le nombre d'oeuvres affichées par ligne">
                        <li></li>
                        <li>
                            <button type="button" className="go2Cols" onClick={() => setCol(2)}>2</button>
                        </li>
                        <li>
                            <button type="button" className="go4Cols" onClick={() => setCol(4)}>4</button>
                        </li>
                        <li>
                            <button type="button" className="go6Cols" onClick={() => setCol(6)}>6</button>
                        </li>
                    </ul>
                    {/* <!-- Choix du type de grille - début --> */}
                    {/* <!-- Bouton matching par artistes - fin --> */}
                </div>


                {/* <!-- Modal artistes matchés & filtrer le matching - début --> */}
                {editModal && selectedPainting &&
                    <ModalEditPainting user={user} setBoolModal={setEditModal} boolModal={editModal}
                                       paintId={selectedPainting}/>}
                <div className="cont-l-w" style={{zIndex: 3}}>
                    <div className="itemGallery mt-a">
                        <div className="gridContent items">
                            {matchList.length > 0 &&
                                matchList.map((match, index) => (

                                    <div className="item" style={{
                                        width: col === 4 ? 320 : col === 6 ? 206 : 660,
                                        height: col === 4 ? 320 : col === 6 ? 206 : 660
                                    }} onClick={(e) => {
                                        setSelectedPainting(match.id)
                                        setEditModal(!editModal)

                                    }}>
                                        <div className="base"
                                             style={{backgroundImage: `url(${URLDayl}images/paintingPic/${match.id})`}}>


                                        </div>
                                        <div className="hidden">
                                            <div className="revealShadow"></div>
                                            <h3 className="itemArtist">{match.artisteName}</h3>
                                            <h4 className="itemName">{match.name}</h4>
                                            <div className="itemYear">{match.year}</div>
                                            <ul className="hListGridItem">
                                                {/*<li>*/}
                                                {/*    <Link className="GridviewItem"*/}
                                                {/*          to={`../../view/OpusPage.js/${match.id}`} state={match.id}>*/}
                                                {/*        Voir*/}
                                                {/*    </Link>*/}
                                                {/*</li>*/}
                                                {/*<li>*/}
                                                {/*    <Link className="GridmakeMatch"*/}
                                                {/*          to={`../../view/MatchingPage.js/${match.id}`}*/}
                                                {/*          state={match.id}>*/}
                                                {/*        match*/}
                                                {/*    </Link>*/}
                                                {/*</li>*/}
                                            </ul>

                                        </div>

                                        {/*<!-- oeuvre - fin --> */}
                                    </div>
                                ))}
                        </div>
                    </div>
                    {/* <!-- items row - fin --> */}
                </div>

                <div className="mt-b mb-c">
                    <a className="viewMore" onClick={() => {
                        setMaxNumberPainting(maxNumberPainting + 20)
                        setRefreshCol(refreshCol + 1)
                    }}>
                        Afficher + d'oeuvres
                    </a>
                </div>
            </section>
            {/* <!-- GRILLE AUTRES OEUVRES DE CET.TE ARTISTE - fin --> */}
        </div>
    );
}