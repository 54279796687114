import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

const ContactForm = ({ isOpen, setIsOpen }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [responseMessage, setResponseMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const timestamp = new Date().toISOString();

    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbwlqYTlUKd_o0p3Nu2x-Gu3sNTaXOGAXlXX8L7DqJLmK6K9l6X696OzzFXEhdId7-gu/exec",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            ...formData,
            timestamp,
          }),
        }
      );

      const result = await response.json();
      console.log(result);
      if (result.result === "success") {
        setResponseMessage("Form submitted successfully!");
      } else {
        setResponseMessage("There was an error submitting the form.");
      }
    } catch (error) {
      setResponseMessage("There was an error submitting the form.");
      console.error("Error:", error);
    }
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 font-raleway">
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-2xl">
            <button
              className="absolute top-2 self-end text-xl right-2 text-gray-500 hover:text-gray-700"
              onClick={() => setIsOpen(false)}
            >
              <CloseIcon />
            </button>
            <div className="text-2xl font-bold mb-4">
              Si vous avez des suggestions, des propositions ou des remarques,
              n'hésitez pas à nous contacter !
            </div>
            <form onSubmit={handleSubmit}>
              <label className="block mb-2 font-raleway text-base">
                Name:
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-200"
                />
              </label>
              <label className="block mb-2 font-raleway text-base">
                Email:
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-200"
                />
              </label>
              <label className="block mb-4 font-raleway text-base">
                Message:
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-200"
                />
              </label>
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="w-fit m-auto bg-blue-500 text-white py-2 px-5 rounded-full hover:bg-blue-600"
                >
                  Submit
                </button>
              </div>
            </form>
            {responseMessage && (
              <p className="mt-4 text-center text-green-500">
                {responseMessage}
              </p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ContactForm;
