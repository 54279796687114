import React, { useEffect, useState } from "react";
import axios from "axios";
import { URLDayl } from "../../../services/constantes";
import ModalArtistStatement from "./ModalArtistStatement";
import TextareaAutosize from "react-textarea-autosize";
import { enumContenu } from "../PeintresCompteExpert";
import { toast } from "react-toastify";

export const actions = {
  Modifiy: 0,
  Create: 1,
  Delete: 2,
};

export const ArtistStatementCompteExpert = ({ user, artist }) => {
  const [count, setCount] = useState(0);
  const [values, setValues] = useState([]);
  const [image, setImage] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);

  const [newImages, setNewImages] = useState([]);
  const [modal, setModal] = useState(false);
  const [action, setAction] = useState(actions.Create);


  useEffect(() => {
    if (!artist)
      toast.error("Please select an artist", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
  }, [artist]);

  useEffect(() => {
    axios
      .get(URLDayl + `artist/artistStatementsByArtistId?artistId=${artist.id}`)
      .then((res) => {
        setValues(res.data);
      });
  }, [artist, refreshKey]);

  return (
    <>
      <h3 className="mt-b">Artist statement</h3>
      {values.length > 0 && (
        <>
          {values.map((value, index) => (
            <React.Fragment key={index}>
              <p style={{ width: "80%", margin: "auto" }}>
                {values[index].content}
              </p>

              <div className="statementLine mt-b">
                <div>
                  <input
                    type="reset"
                    className="saveDraftBtn mt-a"
                    value="Modifier"
                    style={{ height: 50, width: "fit-content" }}
                    onClick={() => {
                      setModal(true);
                      setAction(actions.Modifiy);
                    }}
                  />
                </div>
                <div>
                  <input
                    type="submit"
                    className="saveDraftBtn mt-a"
                    value="Supprimer"
                    onClick={() => {
                      setAction(actions.Delete);
                      setModal(true);
                    }}
                    style={{ height: 50, width: "fit-content" }}
                  />
                </div>
              </div>
            </React.Fragment>
          ))}

          <div className="inputInline">
            <div style={{ width: "100%" }}>
              <input
                type="submit"
                className="submitFormBtn mt-a"
                value="Add artist statement"
                onClick={() => {
                  setModal(!modal);
                  setAction(actions.Create);
                }}
                style={{ height: 50, width: "100%" }}
              />

              {/*<TextareaAutosize style={{paddingBottom: 10}} minRows={4}*/}
              {/*                  placeholder={artist ? artist.artistIdtfIntern : ""}*/}
              {/*                  value={value}*/}
              {/*                  // onChange={e => {*/}
              {/*                  //     setCount(e.target.value.length);*/}
              {/*                  //     setValue(e.target.value)*/}
              {/*                  // }}*/}
              {/*/>*/}

              {/*<div style={{width: '4%'}} className="charCount">{count}/255</div>*/}
            </div>
          </div>
        </>
      )}

      {modal && (
        <ModalArtistStatement
          modePeinture={false}
          user={user}
          artist={artist}
          setBoolModal={setModal}
          action={action}
          publication={values}
          setRefreshKey={setRefreshKey}
          refreshKey={refreshKey}
          contenu={enumContenu.ArtistStatement}
        />
      )}

      {/*<input type="file" name="file" onChange={changeHandler} multiple={true}/>*/}

      {/*{image && [...image].map((file)=>(*/}
      {/*    <img src={URL.createObjectURL(file)} />*/}
      {/*))}*/}
    </>
  );
};
