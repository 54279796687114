import React, { useState } from "react";
import axios from "axios";
import { URLDayl } from "../../services/constantes";
import AutoCompleteCustom from "../PaintingSearch/AutoCompleteCustom";

const SearchBar = ({ onMovementSelect }) => {
  const [searchResults, setSearchResults] = useState([]);

  const searchMovements = async (prefix) => {
    if (prefix.length > 0) {
      try {
        const response = await axios.get(
          URLDayl + "editorial-contents/movements/search",
          {
            params: { prefix, page: 0, size: 10 },
          }
        );
        setSearchResults(response.data.content);
      } catch (error) {
        console.error("Error searching movements:", error);
      }
    } else {
      setSearchResults([]);
    }
  };

  const handleSearchInputChange = (event, value) => {
    searchMovements(value);
  };

  const handleMovementSelect = (event, movement) => {
    if (movement) {
      onMovementSelect(movement.id);
    }
  };
  return (
    <div className="mb-8">
      <AutoCompleteCustom
        options={searchResults}
        defaultValue={null}
        onSelect={handleMovementSelect}
        onChange={handleSearchInputChange}
        placeholder="Search Movements"
      />
    </div>
  );
};

export default SearchBar;
