import React, { useEffect, useState } from "react";
import { URLDayl, URLDaylImage } from "../../services/constantes";
import axios from "axios";
import EmpreinteChromatique from "./EmpreinteChromatique";

export const enumAnalyseChromatiqueTab = {
  teinte: 0,
  saturation: 1,
  lum: 2,
  empreinte: 3,
};

export default function AnalyseChromatiquePainting({ paintId }) {
  const [points, setPoints] = useState();
  const [painting, setPainting] = useState();

  useEffect(() => {
    setPoints();
    axios
      .get(URLDayl + `paintingEntity/analyseChromaEmpreinte/${paintId}`)
      .then((res) => {
        setPoints(res.data.jsonRawValue);
      });
  }, [paintId]);

  useEffect(() => {
    axios.get(URLDayl + `paintingEntity/${paintId}`).then((res) => {
      setPainting(res.data);
    });
  }, [paintId]);

  return (
    <div className="container mx-auto px-4 py-8">
      <p className="mt-5 mb-10 w-2/3 mx-auto text-xl text-gray-700 text-center">
        Cet outil analyse l'extension de chaque couleur au sein de l'œuvre en
        fonction de son degré de luminosité. Permet d'étudier l'écart de chaque
        couleur entre sa luminosité maximale (lorsque la couleur est claire) et
        sa luminosité minimale (lorsque la couleur est foncée).
      </p>
      <div className="flex justify-evenly items-center mt-10">
        {painting && (
          <img
            src={`${URLDaylImage}images/paintingPic/full/${paintId}`}
            alt={painting.title || "Painting"}
            className="max-h-[500px] max-w-[50vw] w-auto cursor-pointer shadow-lg transition-all duration-300 hover:shadow-xl"
            style={{
              filter: painting.artist.hideArtist ? "blur(10px)" : "none",
            }}
          />
        )}

        {points && (
          <div className="w-1/3">
            <div className="border-2 border-gray-200 rounded-xl bg-white shadow-md hover:shadow-lg transition-shadow duration-300">
              <EmpreinteChromatique id={paintId} data1={points} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
