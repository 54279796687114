import StillLife1 from "../../webapp/img/ColometrieResults/still-life-with-fruit_ORANGE+BLEU.png";
import StillLife2 from "../../webapp/img/ColometrieResults/still-life-with-fruit_JAUNE+VIOLET.png";
import StillLife3 from "../../webapp/img/ColometrieResults/still-life-with-fruit_ROUGE+VERT.png";

import Snowballs1 from "../../webapp/img/ColometrieResults/snowballs_Echelle-des-GRIS.png";
import Snowballs2 from "../../webapp/img/ColometrieResults/snowballs_LUMINEUX.png";
import Snowballs3 from "../../webapp/img/ColometrieResults/snowballs_TRES-LUM.png";

export const Stilllifes = [
  {
    painting: StillLife1,
    button: "Orange + Bleu",
    content: [
      <p>
        Matisse mobilise tout un jeu de <b>contrastes des complémentaires</b>.
        D'abord les complémentaires <b>rouge et vert</b> sont systématiquement
        étalées sur des plages <b>proches les unes des autres</b>. Elles
        constituent majoritairement les parties les plus ombragées de la toile.{" "}
        <b>Les complémentaires jaune et violet</b> sont beaucoup plus
        condensées. Le jaune étant utilisé principalement pour rendre l'éclat de
        la <b>lumière renvoyée par les objets</b> (tasse, cruche, bol,
        bouteilles), cette couleur sera visible notamment au niveau des{" "}
        <b>contours</b> afin de rendre la couleur ambiante plus lumineuse.
      </p>,

      <p>
        Les <b>violets</b>, plus sombres, servent à faire les <b>ombres</b>,
        mais placés à proximité des jaunes ils aboutissent à une synthèse
        lumineuse (en synthèse optique jaune + violet = blanc lumineux) ce qui
        permet d'atténuer l'obscurité des zones d'ombre et de{" "}
        <b>conserver un chromatism</b> aussi important{" "}
        <b>aussi bien à l'ombre qu'à la lumière.</b>
        <br />
        Quant aux complémentaires <b>orange et bleu</b>, il s'agit du contraste
        qui s'écarte le plus du réalisme lumineux. Les plages de couleurs orange
        et bleu aboutissent plutôt à une abstraction vers une chromaticité pure,
        plutôt que relevant d'une interaction naturaliste avec la lumière.
      </p>,
    ],
  },
  {
    painting: StillLife2,
    button: "Jaune + Violet",
    content: [
      <p>
        Matisse mobilise tout un jeu de <b>contrastes des complémentaires</b>.
        D'abord les complémentaires <b>rouge et vert</b> sont systématiquement
        étalées sur des plages <b>proches les unes des autres</b>. Elles
        constituent majoritairement les parties les plus ombragées de la toile.{" "}
        <b>Les complémentaires jaune et violet</b> sont beaucoup plus
        condensées. Le jaune étant utilisé principalement pour rendre l'éclat de
        la <b>lumière renvoyée par les objets</b> (tasse, cruche, bol,
        bouteilles), cette couleur sera visible notamment au niveau des{" "}
        <b>contours</b> afin de rendre la couleur ambiante plus lumineuse.
      </p>,

      <p>
        Les <b>violets</b>, plus sombres, servent à faire les <b>ombres</b>,
        mais placés à proximité des jaunes ils aboutissent à une synthèse
        lumineuse (en synthèse optique jaune + violet = blanc lumineux) ce qui
        permet d'atténuer l'obscurité des zones d'ombre et de{" "}
        <b>conserver un chromatism</b> aussi important{" "}
        <b>aussi bien à l'ombre qu'à la lumière.</b>
        <br />
        Quant aux complémentaires <b>orange et bleu</b>, il s'agit du contraste
        qui s'écarte le plus du réalisme lumineux. Les plages de couleurs orange
        et bleu aboutissent plutôt à une abstraction vers une chromaticité pure,
        plutôt que relevant d'une interaction naturaliste avec la lumière.
      </p>,
    ],
  },
  {
    painting: StillLife3,
    button: "Rouge + Vert",
    content: [
      <p>
        Matisse mobilise tout un jeu de <b>contrastes des complémentaires</b>.
        D'abord les complémentaires <b>rouge et vert</b> sont systématiquement
        étalées sur des plages <b>proches les unes des autres</b>. Elles
        constituent majoritairement les parties les plus ombragées de la toile.{" "}
        <b>Les complémentaires jaune et violet</b> sont beaucoup plus
        condensées. Le jaune étant utilisé principalement pour rendre l'éclat de
        la <b>lumière renvoyée par les objets</b> (tasse, cruche, bol,
        bouteilles), cette couleur sera visible notamment au niveau des{" "}
        <b>contours</b> afin de rendre la couleur ambiante plus lumineuse.
      </p>,

      <p>
        Les <b>violets</b>, plus sombres, servent à faire les <b>ombres</b>,
        mais placés à proximité des jaunes ils aboutissent à une synthèse
        lumineuse (en synthèse optique jaune + violet = blanc lumineux) ce qui
        permet d'atténuer l'obscurité des zones d'ombre et de{" "}
        <b>conserver un chromatism</b> aussi important{" "}
        <b>aussi bien à l'ombre qu'à la lumière.</b>
        <br />
        Quant aux complémentaires <b>orange et bleu</b>, il s'agit du contraste
        qui s'écarte le plus du réalisme lumineux. Les plages de couleurs orange
        et bleu aboutissent plutôt à une abstraction vers une chromaticité pure,
        plutôt que relevant d'une interaction naturaliste avec la lumière.
      </p>,
    ],
  },
];

export const Snowballs = [
  {
    painting: Snowballs1,
    button: "Echelle des gris",
    content: [
      <p>
        Cette oeuvre montre une <b>luminosité intense</b>, due principalement à
        la grande plage de luminosité en haut à droite qui irradie l'ambiance de
        la scène et du fait que <b>le jaune</b>, couleur dominante dans cette oeuvre,
        est intrinsèquement une teinte lumineuse en soi. Cependant, Matisse se
        montre très parcimonieux dans <b>l'usage de ses blancs purs</b> et de ses
        <b> différents niveaux de gris purs</b>, s'y prêtant uniquement pour
        donner une légère réverbération <b>au niveau des contours des objets.</b>
      </p>,

      <p>
        Matisse emploie l'échelle de luminosité (l'échelle des gris) uniquement
        au niveau du <b>rayonnement des objets</b>, laissant transparaître, de
        manière presque invisible, la lumière à l'état pur (donc achromatique),
        ce qui déroge à l'orthodoxie <b>impressionniste</b> qui voulait que
        chaque parcelle de lumière soit systématiquement colorée.
      </p>,
    ],
  },
  {
    painting: Snowballs2,
    button: "Lumineux",
    content: [
      <p>
        Le tableau est divisé en trois niveaux de luminosité :<br />
        1. la diagonale supérieure droite montre la partie la{" "}
        <b>plus lumineuse</b> de la toile, ainsi que certaines de fleurs,
        constitué principalement les complémentaires jaune et violet. Il s'agit
        ici d'une influence de l'impressionnisme qui essaye de capter l'énergie
        lumineuse par la couleur principalement : la juxtaposition des{" "}
        <b>complémentaires jaune et violet</b> acte l'intense luminosité de la
        partie éclairée, non pas seulement parce qu'elles sont déjà très
        lumineuses, mais aussi du fait que leur mélange optique restitue un
        blanc nacré et lumineux dans l'oeil. Matisse s'approprie les méthodes
        impressionnistes pour rendre une lumière <b>diffuse et colorée</b> et
        contraster avec le reste de la toile, en réalité peu lumineuse.
      </p>,

      <p>
        2. La partie la plus lumineuse après les plages de complémentaire jaune
        et violet, se situe dans la restitution des <b>couleurs ambiantes</b>,
        c'est-à-dire à l'endroit où la lumière percute l'objet. C'est donc au
        niveau des <b>contours</b>, ou aux alentours de la forme des objets, que
        se joue l'interaction entre la lumière reçue par l'objet et cette même
        lumière renvoyée à son tour par l'objet. L'enjeu pour le peintre est
        donc de convertir subtilement les zones de forte luminosité jaune et
        violet vers les zones peu lumineuses où les couleurs sont plus variées.
      </p>,

      <p>
        3. les parties les moins lumineuses occupent la majeure partie de la
        surface de la toile et exposent l'ensemble de la{" "}
        <b>richesse chromatique</b>, à l'exception des jaunes et des violets.
        L'exercice pour Matisse ici, qui œuvre dans une structure
        impressionniste, est de déterminer les niveaux d'exposition que non pas
        seulement "lumineuse" à proprement parler mais surtout{" "}
        <b>chromatique</b>. Ici, l'enjeu est de montrer que les ombres sont tout
        aussi colorées que les parties <b>lumineuses</b> et qu'elles jouent un
        rôle nécessaire dans l'équilibre chromatique de la peinture, au lieu de
        simplement atténuer les couleurs. De plus, la forte structuration
        chromatique des parties obscures de la toile, plus complexes que les
        parties lumineuses, tend à annuler le rôle même de l'ombre dans son rôle
        d'indication volumétrique de l'espace tridimensionnel pour ne constituer
        qu'un simple support plane chromatique sans profondeur, dotant la
        couleur d'une puissance d'évocation spatiale entièrement abstraite. L'
        <b>ombre, avec Matisse, perd ses deux rôles traditionnels</b>{" "}
        d'atténuation de la couleur et d'indication de relief ce qui, d'une
        certaine manière, apparaît déjà comme une radicalisation de
        l'impressionnisme et annonce le traitement entièrement novateur des
        ombres (jusqu'à leur disparition) dans sa future période fauve.
      </p>,
    ],
  },
  {
    painting: Snowballs3,
    button: "Tres lumineux",
    content: [
      <p>
        Le tableau est divisé en trois niveaux de luminosité :<br />
        1. la diagonale supérieure droite montre la partie la{" "}
        <b>plus lumineuse</b> de la toile, ainsi que certaines de fleurs,
        constitué principalement les complémentaires jaune et violet. Il s'agit
        ici d'une influence de l'impressionnisme qui essaye de capter l'énergie
        lumineuse par la couleur principalement : la juxtaposition des{" "}
        <b>complémentaires jaune et violet</b> acte l'intense luminosité de la
        partie éclairée, non pas seulement parce qu'elles sont déjà très
        lumineuses, mais aussi du fait que leur mélange optique restitue un
        blanc nacré et lumineux dans l'oeil. Matisse s'approprie les méthodes
        impressionnistes pour rendre une lumière <b>diffuse et colorée</b> et
        contraster avec le reste de la toile, en réalité peu lumineuse.
      </p>,

      <p>
        2. La partie la plus lumineuse après les plages de complémentaire jaune
        et violet, se situe dans la restitution des <b>couleurs ambiantes</b>,
        c'est-à-dire à l'endroit où la lumière percute l'objet. C'est donc au
        niveau des <b>contours</b>, ou aux alentours de la forme des objets, que
        se joue l'interaction entre la lumière reçue par l'objet et cette même
        lumière renvoyée à son tour par l'objet. L'enjeu pour le peintre est
        donc de convertir subtilement les zones de forte luminosité jaune et
        violet vers les zones peu lumineuses où les couleurs sont plus variées.
      </p>,

      <p>
        3. les parties les moins lumineuses occupent la majeure partie de la
        surface de la toile et exposent l'ensemble de la{" "}
        <b>richesse chromatique</b>, à l'exception des jaunes et des violets.
        L'exercice pour Matisse ici, qui œuvre dans une structure
        impressionniste, est de déterminer les niveaux d'exposition que non pas
        seulement "lumineuse" à proprement parler mais surtout{" "}
        <b>chromatique</b>. Ici, l'enjeu est de montrer que les ombres sont tout
        aussi colorées que les parties <b>lumineuses</b> et qu'elles jouent un
        rôle nécessaire dans l'équilibre chromatique de la peinture, au lieu de
        simplement atténuer les couleurs. De plus, la forte structuration
        chromatique des parties obscures de la toile, plus complexes que les
        parties lumineuses, tend à annuler le rôle même de l'ombre dans son rôle
        d'indication volumétrique de l'espace tridimensionnel pour ne constituer
        qu'un simple support plane chromatique sans profondeur, dotant la
        couleur d'une puissance d'évocation spatiale entièrement abstraite. L'
        <b>ombre, avec Matisse, perd ses deux rôles traditionnels</b>{" "}
        d'atténuation de la couleur et d'indication de relief ce qui, d'une
        certaine manière, apparaît déjà comme une radicalisation de
        l'impressionnisme et annonce le traitement entièrement novateur des
        ombres (jusqu'à leur disparition) dans sa future période fauve.
      </p>,
    ],
  },
];
