import React, {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import {URLDayl} from "../../services/constantes";
import TopPaintingsCarousel from "./TopPaintingsCarousel";
import SelectPaintingModal from "../general/SelectPaintingModal/SelectPaintingModal";

export default function CompteExpertTopPaintings({style, styleId}) {
    const [topPaintings, setTopPaintings] = useState([]);
    const [modal, setModal] = useState(false);
    const [refreshKeyPaintings, setRefreshKeyPaintings] = useState(0);
    const [searchDTO, setSearchDTO] = useState({style: style})
    const [selected, setSelected] = useState([])


    const onSendPictures = async () => {


        axios
            .post(URLDayl + "top_paintings_movement/update", {
                id: styleId, paintings: selected
            })
            .then((res) => {
                setRefreshKeyPaintings(refreshKeyPaintings + 1)
            })
            .catch((err) => {
                toast.error("Error", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });

        setSelected([]);
    };

    useEffect(() => {
        axios
            .get(URLDayl + "top_paintings_movement/paintingsByMovement", {
                params: {movementId: styleId},
            })
            .then((res) => {

                setTopPaintings(res.data);
                setSelected(res.data.map((el) => el.painting.id))
            })
            .catch((err) => {
            });
    }, [style, refreshKeyPaintings]);

    return (<div>
            {topPaintings && topPaintings.length > 0 && (<TopPaintingsCarousel
                    paintings={topPaintings}
                    refreshKey={refreshKeyPaintings}
                    setRefreshKey={setRefreshKeyPaintings}
                    deleteMode={true}
                />)}
            <div style={{width: "70%", margin: "auto"}}>
                <input
                    type="reset"
                    className="saveDraftBtn mt-a"
                    value="Choisir les tops peintures"
                    style={{height: 50, marginBottom: 50, marginTop: "z50px"}}
                    onClick={() => {
                        setModal(!modal);
                    }}
                />
            </div>

            {modal && (// <ChoosePaintings
                //   selected={taggedPaintings}
                //   setSelected={setTaggedPaintings}
                //   setModal={setModal}
                //   sendPictures={onSendPictures}
                // />
                <SelectPaintingModal selected={selected} setSelected={setSelected} open={modal}
                                     setOpen={setModal} setFile={() => {
                }} selectedFile={false} multiple={true} defaultSearchDTO={searchDTO} onSubmit={onSendPictures}/>)}
        </div>);
}
