import { Button, Modal, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import SelectPaintingContainer from "./SelectPaintingContainer";

export default function SelectPaintingModal({
  open,
  setOpen,
  selected,
  setSelected,
  setFile,
  selectedFile,
  multiple,
  onSubmit,
  defaultSearchDTO,
  colometrie = false,
}) {
  const handleClose = () => {
    setSelected([]);
    setOpen(false);
  };

  return (
    <Modal
      style={{ marginTop: 50, padding: 50, marginBottom: 40, zIndex: 300 }}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <SelectPaintingContainer
        defaultSearchDTO={defaultSearchDTO}
        setFile={setFile}
        setModal={setOpen}
        selected={selected}
        setSelected={setSelected}
        selectedFile={selectedFile}
        multiple={multiple}
        onSave={onSubmit}
        colometrie={colometrie}
      />
    </Modal>
  );
}
