import { useUser } from "../contexts/userService";
import { useEffect, useState } from "react";
import axios from "axios";
import { URLDayl } from "../services/constantes";
import ImageGrid from "../component/general/ImageGrid/ImageGrid";
import { useParams } from "react-router";

export default function FindByGenrePaintings() {
  const auth = useUser();
  const [paintings, setPaintings] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [maxNumPainting, setMaxNumPainting] = useState(48);
  const [sort, setSort] = useState("");

  const { genre } = useParams();

  useEffect(() => {
    axios
      .get(
        URLDayl +
          `paintingSearch/findPaintingsByGenre?genre=${genre}&numberOfPaintings=${maxNumPainting}&sort=${sort}`
      )
      .then((res) => {
        setPaintings(res.data);
      });
  }, [genre, refreshKey, sort]);

  return (
    <div style={{ marginTop: 30 }}>
      <h1
        style={{
          textAlign: "center",
          marginBottom: 10,
          fontSize: 50,
          fontFamily: "raleway",
        }}
      >
        Oeuvres taggées {genre}
      </h1>
      <ImageGrid
        user={auth.data}
        isLogged={auth.data !== undefined}
        paintings={paintings}
        showTools={true}
        selectionMode={false}
        orderDate={sort}
        setOrderDate={setSort}
        maxNumPainting={maxNumPainting}
        setMaxNumPainting={setMaxNumPainting}
        refreshKey={refreshKey}
        setRefreshKey={setRefreshKey}
      />
    </div>
  );
}
