import React, {
  useRef,
  createRef,
  useEffect,
  useState,
  forwardRef,
} from "react";
import FlipImage from "./FlipImage";
import { URLDaylImage } from "../../services/constantes";
import axios from "axios";
import Papa from "papaparse";
import { URLDayl } from "../../services/constantes";

const ArtMovementsDisplay = forwardRef(
  ({ onClick, flipcards, title = true }, ref) => {
    const [movementDetails, setMovementDetails] = useState([]);

    useEffect(() => {
      const fetchMovements = async () => {
        try {
          const movementsPromise = axios.get(
            `${URLDayl}movement/getAllMovementsDetailed`
          );
          const csvPromise = new Promise((resolve, reject) => {
            Papa.parse("/MovementsDates.csv", {
              download: true,
              header: true,
              dynamicTyping: true,
              complete: (result) => resolve(result.data),
              error: (error) => reject(error),
            });
          });

          const [movementsResponse, csvData] = await Promise.all([
            movementsPromise,
            csvPromise,
          ]);
          const movementsData = movementsResponse.data;

          const movementInfo = csvData.reduce((acc, row) => {
            acc[row.Name.toUpperCase().trim()] = {
              startYear: row.StartYear,
              century: row.Century,
            };
            return acc;
          }, {});

          const sortedMovements = movementsData
            .map((movement) => {
              const nameKey = movement.movement.toUpperCase().trim();
              movement.century =
                movementInfo[nameKey]?.century || "Unknown Century";
              return movement;
            })
            .sort((a, b) => {
              const startYearA =
                movementInfo[a.movement.toUpperCase().trim()]?.startYear ||
                Infinity;
              const startYearB =
                movementInfo[b.movement.toUpperCase().trim()]?.startYear ||
                Infinity;

              if (startYearA !== startYearB) {
                return startYearA - startYearB;
              }
              return a.movement.localeCompare(b.movement);
            });

          setMovementDetails(sortedMovements);
        } catch (error) {
          console.error("Error fetching or parsing data:", error);
        }
      };

      fetchMovements();
    }, []);

    const movementRefs = useRef({});

    const scrollToMovement = (movementId) => {
      const element = movementRefs.current[movementId]?.current;
      if (element) {
        const container = element.closest(".overflow-x-auto");
        if (container) {
          const elementRect = element.getBoundingClientRect();
          const containerRect = container.getBoundingClientRect();

          const isInView =
            elementRect.left >= containerRect.left &&
            elementRect.right <= containerRect.right;

          if (!isInView) {
            container.scrollTo({
              left: element.offsetLeft - container.offsetLeft,
              behavior: "smooth",
            });
          }
        }

        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    };

    const onPeriodButtonClick = (period) => {
      const targetMovementId = movementDetails.find(
        (el) => el.movement === period
      )?.id;
      if (targetMovementId) {
        scrollToMovement(targetMovementId);
      }
    };

    const handleClick = (el) => {
      onClick(el);
    };

    const renderTimelineButtons = () => {
      const periods = [
        { label: "VIe - XVIIIe siècle", period: "Byzantine" },
        { label: "XIX siècle", period: "Neoclassicism" },
        { label: "1880-1900", period: "American Realism" },
        { label: "1900-1920", period: "Ecole de Paris" },
        { label: "Post 1920", period: "Surrealism" },
      ];

      return (
        <div className="w-full flex">
          {periods.map(({ label, period }) => (
            <div
              key={period}
              onClick={() => onPeriodButtonClick(period)}
              className="period-button flex w-1/5 justify-center items-center font-raleway text-lg"
            >
              {label}
            </div>
          ))}
        </div>
      );
    };

    const renderMovements = () => {
      return flipcards
        ? movementDetails.map((el) => {
            if (!movementRefs.current[el.id]) {
              movementRefs.current[el.id] = React.createRef();
            }
            return (
              <div onClick={() => handleClick(el)} key={el.id}>
                <FlipImage
                  title={el.movement}
                  flipSubtitle={el.century}
                  imageSrc={`${URLDaylImage}images/paintingPic/${el.image}`}
                  ref={movementRefs.current[el.id]}
                />
              </div>
            );
          })
        : movementDetails.map((el, index) => {
            if (!movementRefs.current[el.id]) {
              movementRefs.current[el.id] = createRef();
            }
            const isOdd = index % 2 !== 0;
            return (
              <div
                className="flex flex-col gap-2 shrink-0 w-[20vw] relative"
                key={el.id}
                ref={movementRefs.current[el.id]}
              >
                {isOdd && (
                  <div className="flex flex-col justify-end  h-[17vw] w-[15vw]">
                    {el.short_context}
                    <div className="italic font-bold">
                      <br />
                      {el.century}
                    </div>
                  </div>
                )}

                {isOdd && (
                  <div className="h-[2px] bg-black w-[20vw] timeline-line"></div>
                )}

                <div
                  className="flex flex-col h-[17vw] w-[15vw] cursor-pointer"
                  onClick={() => handleClick(el)}
                >
                  <div className=" text-start uppercase font-raleway text-lg font-bold">
                    {el.movement}
                  </div>

                  <div className="w-[15vw] h-[15vw] relative overflow-hidden">
                    {el.image ? (
                      <img
                        src={`${URLDaylImage}images/paintingPic/${el.image}`}
                        alt={el.movement}
                        className="absolute inset-0 w-full h-full object-cover"
                      />
                    ) : null}
                  </div>
                </div>
                {!isOdd && (
                  <div className="h-[2px] bg-black w-[20vw] timeline-line even"></div>
                )}

                {!isOdd && (
                  <div className="flex flex-col items-start w-[15vw] p-4 font-raleway">
                    <div className="italic font-bold">{el.century}</div>
                    <br />
                    {el.short_context}
                  </div>
                )}
              </div>
            );
          });
    };

    return (
      <div className="flex flex-col">
        {title && (
          <div className="text-2xl  p-6  text-black m-5 font-raleway  font-bold">
            EXPRESSION ARTISTIQUE ET HISTOIRE{" "}
          </div>
        )}

        {renderTimelineButtons()}
        <div className="relative">
          <div className="flex overflow-y-hidden shrink-0 m-10 overflow-x-auto">
            {renderMovements()}
          </div>
        </div>
      </div>
    );
  }
);

export default ArtMovementsDisplay;
