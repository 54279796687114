import "../../webapp/css/matchingColor.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { URLDayl } from "../../services/constantes";
import RectanglePaletteItem from "./RectanglePaletteItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { toColor } from "react-color-palette";

export default function ColorHeader({ color, setColor }) {
  const [allColorsPopOver, setAllColorsPopOver] = useState(false);
  const [allDefinedHex, setAllDefinedHex] = useState([]);
  const [allColors, setAllColors] = useState([]);
  const [childColorsByMaster, setChildColorsByMaster] = useState({});
  const [selectedColorMain, setSelectedColorMain] = useState();
  const [colorIsMain, setColorIsMain] = useState(false);
  const [openedRectangle, setOpenedRectangle] = useState("");
  const [colorName, setColorName] = useState("");
  const [colorStatement, setColorStatement] = useState("");
  const [showFullStatement, setShowFullStatement] = useState(false);

  const handleOpenRectangle = (master_color) => {
    if (openedRectangle === master_color) {
      setOpenedRectangle(null);
    } else {
      setOpenedRectangle(master_color);
    }
  };

  useEffect(() => {
    axios.get(URLDayl + "paintingEntity/get106ColorsAll").then((res) => {
      setAllDefinedHex(res.data.map((el) => el.hex));
      setAllColors(res.data);
      const colorsByMaster = res.data.reduce((acc, color) => {
        const { master_color } = color;
        if (!acc[master_color]) {
          acc[master_color] = [];
        }
        acc[master_color].push(color);
        return acc;
      }, {});
      setChildColorsByMaster(colorsByMaster);
    });
  }, []);

  useEffect(() => {
    if (color) {
      const include = allDefinedHex.includes(color.hex);
      setColorIsMain(include);
      if (include) {
        const selectedColor = allColors.find(
          (element) => element.hex === color.hex
        );
        setSelectedColorMain(selectedColor);
        setColorStatement(selectedColor.color_statement || "");
      } else {
        setSelectedColorMain(undefined);
        setColorStatement("");
      }
    }
  }, [color, allColors, allDefinedHex]);

  useEffect(() => {
    if (Object.keys(childColorsByMaster).length > 0 && !color) {
      const masterColors = Object.keys(childColorsByMaster);
      const randomMasterColorIndex = Math.floor(Math.random() * masterColors.length);
      const randomMasterColor = masterColors[randomMasterColorIndex];
      setOpenedRectangle(randomMasterColor);

      const childColors = childColorsByMaster[randomMasterColor];
      const randomChildColorIndex = Math.floor(Math.random() * childColors.length);
      const randomChildColor = childColors[randomChildColorIndex];

      setColor(toColor("hex", randomChildColor.hex));
      setColorName(randomChildColor.color_name);
      setColorStatement(randomChildColor.color_statement || "");
    }
  }, [childColorsByMaster, color, setColor, setColorName, setColorStatement]);

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const truncateAtFirstNewline = (text) => {
    const newlineIndex = text.indexOf("\n");
    if (newlineIndex !== -1) {
      return text.substring(0, newlineIndex);
    }
    return text;
  };

  return (
    <div className="">
      <div className="w-full">
        <div className="text-3xl p-6 pb-0 text-black font-raleway m-5 font-bold">
          COULEURS REMARQUABLES
        </div>
        <div className="text-2xl px-6 py-3 text-black font-raleway m-5">
          ELLES APPARTIENNENT A L’HISTOIRE DE LA PEINTURE
        </div>
      </div>
      <div className="flex mt-10 ml-10 items-center justify-between">
        <div className="flex">
          {Object.keys(childColorsByMaster).map((masterColor) => (
            <RectanglePaletteItem
              key={masterColor}
              isOpen={openedRectangle === masterColor}
              onOpen={() => handleOpenRectangle(masterColor)}
              color={childColorsByMaster[masterColor][0].hex}
              master_color={masterColor}
              setColor={setColor}
              selectedColor={color}
              setColorName={setColorName}
              childColors={childColorsByMaster[masterColor]}
            />
          ))}
        </div>
      </div>
      {colorStatement && (
        <>
          <div className="text-2xl px-6 pt-3 text-black font-raleway mt-5 ml-5 mb-7 flex gap-2">
            LA COULEUR
            <div className="font-bold">{" " + colorName}</div>
          </div>
          <div className="flex flex-col gap-3 w-10/12 m-auto text-xl p-5 text-black font-raleway border-black border-2 rounded-xl whitespace-pre-line">
            {showFullStatement
              ? colorStatement
              : truncateAtFirstNewline(colorStatement)}
            <button
              onClick={() => setShowFullStatement(!showFullStatement)}
              className="text-black flex flex-col m-auto justify-center items-center italic font-bold "
            >
              {showFullStatement ? (
                <>
                  Voir moins <KeyboardArrowUpIcon fontSize="large" />
                </>
              ) : (
                <>
                  Voir plus <KeyboardArrowDownIcon fontSize="large" />
                </>
              )}
            </button>
          </div>
        </>
      )}
    </div>
  );
}
