import { Box, Slider } from "@mui/material";

export default function Sliders({
  grayValue,
  handleGrayChange,
  opacity,
  handleOpacityChange,
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "20px",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "20px",
        width: "20%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          alignItems: "center",
          color: "black",
        }}
      >
        Fond:
        <Box
          sx={{
            height: 200,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Slider
            orientation="vertical"
            min={0}
            max={100}
            value={(grayValue / 255) * 100}
            onChange={handleGrayChange}
            aria-labelledby="grayscale-slider"
          />
        </Box>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          alignItems: "center",
          color: "black",
        }}
      >
        Opacité:
        <Box
          sx={{
            height: 200,
            display: "flex",
            alignItems: "center",
            label: "Opacity",
          }}
        >
          <Slider
            orientation="vertical"
            min={0}
            max={100}
            value={opacity * 100}
            onChange={handleOpacityChange}
            aria-labelledby="opacity-slider"
          />
        </Box>
      </div>
    </div>
  );
}
