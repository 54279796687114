import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { URLDayl, SALT } from "../services/constantes";
import { toast } from "react-toastify";
import vis from "../webapp/img/icons/password_display_1.svg";
import unvis from "../webapp/img/icons/password_hide_1.svg";
import sha1 from "sha1";
import { useNavigate } from "react-router-dom";
// Helper function to get query parameters
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function RenewPassword() {
  const query = useQuery();
  const token = query.get("token");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    validatePassword(newPassword);
  }, [newPassword]);

  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 10) errors.push("Au moins 10 caractères");
    if (!/[a-z]/.test(password)) errors.push("Une lettre minuscule");
    if (!/[A-Z]/.test(password)) errors.push("Une lettre majuscule");
    if (!/[$!@;#]/.test(password))
      errors.push("Un caractère spécial parmi : $!@;#");

    setPasswordErrors(errors);
    setIsValidPassword(errors.length === 0);
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      toast.warning("Les mots de passe ne correspondent pas!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (!isValidPassword) {
      toast.warning("Le mot de passe ne respecte pas les critères requis.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    try {
      console.log("Sending request to:", `${URLDayl}/users/updatePassword`);
      console.log("Payload:", { clearPassword: newPassword, token: token });

      const response = await axios.post(
        `${URLDayl}users/updatePassword`,
        {
          clearPassword: sha1(newPassword + SALT),
          token: token,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Response:", response);

      toast.success("Mot de passe réinitialisé avec succès!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate("/view/Login.js");
    } catch (error) {
      console.error("Error:", error.response || error.message);

      toast.error("Erreur lors de la réinitialisation du mot de passe!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-6 bg-white rounded shadow-md">
        <h1 className="text-2xl font-semibold text-center text-gray-900">
          Réinitialiser le mot de passe
        </h1>
        <form onSubmit={handlePasswordReset} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Nouveau mot de passe
            </label>
            <div className="relative">
              <input
                type={showNewPassword ? "text" : "password"}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                className="w-full px-3 py-2 mt-1 border rounded shadow-sm focus:outline-none focus:ring focus:border-blue-300"
              />
              <img
                src={showNewPassword ? unvis : vis}
                alt="Toggle visibility"
                onClick={toggleNewPasswordVisibility}
                className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer bg-black rounded-lg w-6 p-1"
              />
            </div>
            <ul className="mt-2 text-sm text-gray-600">
              {passwordErrors.map((error, index) => (
                <li key={index} className="flex items-center">
                  <span className="mr-2 text-red-500">&#x2717;</span>
                  {error}
                </li>
              ))}
              {isValidPassword && (
                <li className="flex items-center text-green-500">
                  <span className="mr-2">&#x2713;</span>
                  Mot de passe valide
                </li>
              )}
            </ul>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Confirmer le mot de passe
            </label>
            <div className="relative">
              <input
                type={showNewPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                className="w-full px-3 py-2 mt-1 border rounded shadow-sm focus:outline-none focus:ring focus:border-blue-300"
              />
            </div>
          </div>
          <button
            type="submit"
            className={`w-full px-4 py-2 text-white rounded focus:outline-none focus:ring focus:ring-blue-300 ${
              isValidPassword
                ? "bg-blue-600 hover:bg-blue-700"
                : "bg-gray-400 cursor-not-allowed"
            }`}
            disabled={!isValidPassword}
          >
            Réinitialiser
          </button>
        </form>
      </div>
    </div>
  );
}

export default RenewPassword;
