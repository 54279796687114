import { withStyles } from '@mui/styles';
import { Select, MenuItem } from '@mui/material';
import * as React from 'react';

export const stylesCustomSelectField = {
    root: {
        backgroundColor: 'rgba(213,213,213,0.37)',
        borderRadius: 10,
        marginBottom: 10,
    },
    outlined: {
        '&:not(.Mui-disabled)': {
            '&:hover': {
                borderColor: 'transparent',
            },
        },
    },
    select: {
        '&:focus': {
            backgroundColor: 'transparent',
        },
    },
};

const CustomSelectTemp = withStyles(stylesCustomSelectField)(({ classes, children, ...props }) => (
    <Select {...props} className={classes.root} MenuProps={{ classes: { paper: classes.select } }}>
        {children}
    </Select>
));

export default function CustomSelectField({ width = 165, placeholder, title, ...params }) {
    const style = { width };

    return (
        title !== undefined ? (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ padding: 10, fontWeight: 'bold', fontSize: 16, }}>
                    {title}
                </div>
                <CustomSelectTemp {...params} style={style}>
                    <MenuItem value={"male"}>Male</MenuItem>
                    <MenuItem value={"female"}>Female</MenuItem>
                </CustomSelectTemp>
            </div>
        ) : (
            <CustomSelectTemp {...params} style={style}>
                <MenuItem value={"male"}>Male</MenuItem>
                <MenuItem value={"female"}>Female</MenuItem>
            </CustomSelectTemp>
        )
    );
}
