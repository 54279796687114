import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { URLDayl, URLDaylImage } from "../../services/constantes";

import { Link } from "react-router-dom";
import SaveMatch from "../general/SaveMatch";
import { toast } from "react-toastify";

import { Button, Typography } from "@mui/material";
import { useUser } from "../../contexts/userService";

/**
 * @param {object} user - user object from api
 * @param {object} setUser - user setter
 *  @param {boolean} isLogged - test if the user is logged
 * @param {string} matchingType - type of matcing used
 *  @param {string} setMatchingType - matchingType setter
 * @emits - paintingEntity/${paintId} : get painting informations
 * @emits - paintingEntity/numberFavorite/${paintId} : get number of likes
 */

export default function PaintingToMatch({
  user,
  setUser,
  paintId,
  isLogged,
  matchingType,
  setMatchingType,
}) {
  const [painting, setPainting] = useState();
  const [commentText, setCommentText] = useState("");

  const auth = useUser();

  const postComment = () => {
    if (auth.data) {
      axios
        .post(URLDayl + "publication/save", {
          content: commentText,
          userId: auth.data.id,
          publicationStatus: "PRIVATE",
          rePublicationId: 0,
          taggedPainting: paintId,
          publicationType: "MATCHING",
          matchingType: matchingType.value,
        })
        .then((res) => {
          setCommentText("");
          toast.success("Posted", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } else {
      toast.error("Please log in", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  //get Painting info//
  useEffect(() => {
    axios.get(URLDayl + `paintingEntity/${paintId}`).then((response) => {
      setPainting(response.data);
    });
  }, [paintId]);

  // Call Jquery script
  useEffect(() => {
    const loadFile = async () => {
      const { select } = await import("../../webapp/js/customFormElements.min");

      select();
    };
    loadFile();
  }, []);

  return painting ? (
    <div className="flex w-full">
      <div className="flex flex-col w-2/3">
        <div className="flex flex-col bg-gray-300 text-black justify-center p-11 items-center border-2 border-black border-b-0 border-x-0 border-t-0 text-3xl font-bold">
          <div>RESULTAT DE MATCHING</div>
          <div>« RECHERCHE DES PLUS PROCHES VOISINS »</div>
        </div>
        <div className="flex justify-center bg-black">
          <img
            src={`${URLDaylImage}images/paintingPic/full/${painting.id}`}
            alt="painting"
            className="max-h-[55vh] max-w-[50vw] w-auto"
          />
        </div>
      </div>
      <div className="flex flex-col w-1/3">
        <div className="flex flex-col text-center justify-center bg-white p-2 border-2 border-black border-b-0 border-r-0  border-t-0 text-2xl font-bold">
          « MATCHINGS »
          <div className="text-xl font-normal">
            STYLE <br /> COULEUR <br /> FORME
            <br /> TECHNIQUE
          </div>
        </div>
        <div className="bg-black text-white flex flex-col h-full justify-center">
          <div className="text-2xl">
            {painting.aw_title_origin} - {painting.aw_year}
          </div>
          <div className="text-xl">
            <Link
              to={`../../view/ArtistDetailPage.js/${painting.artist.id}`}
              state={painting.artist.id}
              href="artiste.html"
            >
              {painting.artist.artist_name_tot}
            </Link>
          </div>

          <div className="flex gap-1 items-center">
            <textarea
              onChange={(e) => {
                setCommentText(e.target.value);
              }}
              className="bg-black text-white border border-dotted rounded-none resize-none w-96 h-30 p-3 mt-4"
              placeholder="Write your comment here..."
            ></textarea>

            <Button
              variant={"outlined"}
              color={"secondary"}
              onClick={() => {
                postComment();
              }}
            >
              <Typography color={"secondary"}>Post</Typography>
            </Button>
          </div>
          <SaveMatch
            user={user}
            isLogged={isLogged}
            matchPaint={painting}
            matchingType={matchingType}
          />
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
