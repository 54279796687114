import React, { useEffect, useState } from "react";
import { URLDayl } from "../../services/constantes";
import ModalArtistStatement from "./ArtistStatement/ModalArtistStatement";
import { actions } from "./ArtistStatement/ArtistStatementCompteExpert";
import axios from "axios";
import { stringSplitting } from "../../services/Utils";
import BiographieElement from "../ArtistDetailPage/BiographieElement";
import { toast } from "react-toastify";
import { enumContenu } from "./PeintresCompteExpert";
import ArtworkModalTag from "./ArtworkModalTag/ArtworkModalTag";
import { useCompteExpert } from "../../contexts/CompteExpertService";
import SelectPaintingModal from "../general/SelectPaintingModal/SelectPaintingModal";

export const ElementsBiographies = ({ user, artist }) => {
  const [count, setCount] = useState(0);
  const [title, setTitle] = useState("");
  const [value, setValue] = useState("");
  const [image, setImage] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [modal, setModal] = useState(false);
  const [publications, setPublications] = useState();
  const [action, setAction] = useState(actions.Create);
  const [images, setImages] = useState([]);
  const [focusedPublication, setFocusedPublication] = useState();
  const [artworkModal, setArtworkModal] = useState(false);
  const [taggedArtwork, setTaggedArtwork] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (!artist)
      toast.error("Please select an artist", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
  }, [artist]);

  const compteExpert = useCompteExpert();

  useEffect(() => {
    axios
      .get(
        URLDayl +
          `artist/elementsBiographies/artist?artistId=${artist.id}&language=${compteExpert.language}`
      )
      .then((res) => {
        const doubleTempImages = [];

        for (let i = 0; i < res.data.length; i++) {
          const tempImages = [];
          for (let j = 0; j < res.data[i].images.length; j++) {
            if (res.data[i].images[j] !== "")
              tempImages.push(
                URLDayl +
                  `images/biography/${artist.id}/${res.data[i].id}?name=${res.data[i].images[j]}`
              );
          }

          for (let k = 0; k < res.data[i].taggedArtworks.length; k++)
            tempImages.push(
              URLDayl + `images/paintingPic/${res.data[i].taggedArtworks[k]}`
            );

          doubleTempImages.push(tempImages);
        }

        setImages(doubleTempImages);
        setPublications(res.data);
      })
      .catch((err) => {
        console.log("ERROR");
        console.log(err);
      });
  }, [artist.id, refreshKey, modal, compteExpert.language, page]);

  const hidePost = (item) => {
    axios
      .post(URLDayl + "artist/elementsBiographies/changeStatus", {
        idItem: item.id,
        newStatus: item.status === "DRAFT" ? "HIDDEN" : "DRAFT",
      })
      .then((res) => {
        setRefreshKey(refreshKey + 1);
        toast.success("Status du contenu modifié", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        toast.error("Error", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  // const changeHandler = (e) => {
  //     setImage(e.target.files)
  // }

  // const onSubmitImages = async (data) => {
  //
  //     var file = await data;
  //     var formdata = new FormData();
  //     formdata.append('image', file);
  //     console.log(file)
  //
  //     var xhr = new XMLHttpRequest();
  //     xhr.open('POST', URLDayl + `images/uploadBiographyImage?artistId=${artist.id}`);
  //     //xhr.setRequestHeader('Content-Type', 'multipart/form-data');
  //     xhr.send(formdata);
  //
  //     xhr.onreadystatechange = function () {
  //         if (xhr.readyState != 4) return;
  //         if (xhr.status != 200) {
  //             toast.warning("Un problème est survenu dans l'enregistrement de vos images!", {
  //                 position: "top-right",
  //                 autoClose: 3000,
  //                 hideProgressBar: false,
  //                 closeOnClick: true,
  //                 pauseOnHover: true,
  //                 draggable: true,
  //                 progress: undefined,
  //             });
  //         } else {
  //
  //             toast.success("Images enregistrée!", {
  //                 position: "top-right",
  //                 autoClose: 3000,
  //                 hideProgressBar: false,
  //                 closeOnClick: true,
  //                 pauseOnHover: true,
  //                 draggable: true,
  //                 progress: undefined,
  //             });
  //         }
  //     };
  //
  // }

  // useEffect(() => {
  //     setValue("")
  // }, [artist])

  // const postElementsBiographie = () => {
  //     // console.log(value)
  //     // image && [...image].map((el) => {
  //     //     console.log(image)
  //     // })
  //
  //     if (artist === undefined) {
  //         toast.error("Please select an artist", {
  //             position: "top-right",
  //             autoClose: 3000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //         });
  //     }
  //     else {
  //         const imagesName = image && [...image].map((el, index) => {
  //             return index.toString()
  //         })
  //
  //         console.log(image)
  //
  //
  //         const temp = {
  //             id: 0,
  //             artistId: artist.id,
  //             title: title,
  //             content: value,
  //             images: imagesName,
  //             status: "ONLINE"
  //         }
  //
  //         onSubmitImages(image[0])
  //
  //         console.log("ARTIST ID")
  //         console.log(artist.id)
  //
  //         console.log(temp)
  //     }
  //
  //
  // }

  const deletePost = (id) => {
    axios.get(URLDayl + `artist/elementsBiographies?id=${id}`).then((res) => {
      for (let i = 0; i < res.data.images.length; i++) {
        axios.delete(
          URLDayl +
            `images/removeElementBiographieImage?publicationId=${id}&imageName=${res.data.images[i]}&artistId=${artist.id}`
        );
      }
    });

    axios
      .delete(URLDayl + `artist/elementsBiographies?id=${id}`)
      .then((res) => {
        toast.success("Post deleted", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setRefreshKey(refreshKey + 1);
        setPublications(undefined);
      });
  };

  return (
    <>
      {
        <div className="">
          <input
            type="submit"
            className="saveDraftBtn"
            value="Create element de biographie"
            onClick={() => {
              setModal(!modal);
              setAction(actions.Create);
            }}
          />

          {/*<TextareaAutosize style={{paddingBottom: 10}} minRows={4}*/}
          {/*                  placeholder={artist ? artist.artistIdtfIntern : ""}*/}
          {/*                  value={value}*/}
          {/*                  // onChange={e => {*/}
          {/*                  //     setCount(e.target.value.length);*/}
          {/*                  //     setValue(e.target.value)*/}
          {/*                  // }}*/}
          {/*/>*/}

          {/*<div style={{width: '4%'}} className="charCount">{count}/255</div>*/}
        </div>
      }

      <div style={{ marginTop: 50 }}>
        {
          publications && publications.length > 0 ? (
            publications.map((el, index) => {
              return (
                <div
                  style={{
                    padding: 20,
                    marginBottom: 50,
                  }}
                >
                  <BiographieElement
                    artist={"artist"}
                    bg={"bg"}
                    text={el ? stringSplitting(950, el.content) : ""}
                    direction={index % 2 === 0 ? "left" : "right"}
                    title={el.title}
                    imageList={images[index]}
                  />
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      gap: "50px",
                    }}
                  >
                    <input
                      type="reset"
                      className="saveDraftBtn"
                      value="Modifer"
                      onClick={() => {
                        setFocusedPublication(el);
                        setAction(actions.Modifiy);
                        setModal(true);
                      }}
                    />

                    <input
                      type="reset"
                      className="saveDraftBtn"
                      value={el.status === "DRAFT" ? "CACHER" : "AFFICHER"}
                      onClick={() => {
                        hidePost(el);
                      }}
                    />

                    <input
                      type="submit"
                      className="saveDraftBtn"
                      value="Supprimer"
                      onClick={() => {
                        // setBoolModal(false)
                        deletePost(el.id);
                      }}
                    />
                  </div>
                </div>
              );
            })
          ) : (
            // <TextareaAutosize style={{paddingBottom: 10}} minRows={4}
            //                   placeholder={artist ? artist.id : ""}
            //                   value={value}
            //                   onChange={e => {
            //                       // setCount(e.target.value.length);
            //                       // setValue(e.target.value)
            //                   }}
            // />

            <></>
          )
          // <div style={{width: '100%'}}>
          //
          //     <input type="submit" className="submitFormBtn mt-a" value="Crée un élement de biographie" onClick={() => {
          //         setModal(!modal)
          //
          //     }}
          //            style={{height: 50, width: '100%'}}/>
          //
          //
          //     {/*<TextareaAutosize style={{paddingBottom: 10}} minRows={4}*/}
          //     {/*                  placeholder={artist ? artist.artistIdtfIntern : ""}*/}
          //     {/*                  value={value}*/}
          //     {/*                  // onChange={e => {*/}
          //     {/*                  //     setCount(e.target.value.length);*/}
          //     {/*                  //     setValue(e.target.value)*/}
          //     {/*                  // }}*/}
          //     {/*/>*/}
          //
          //
          //     {/*<div style={{width: '4%'}} className="charCount">{count}/255</div>*/}
          // </div>
          // <div className="inputInline mt-c">
          //     <div>
          //         <input type="reset" className="saveDraftBtn mt-a" value="Annuler"
          //                style={{height: 50, width: '100%'}} onClick={() => {
          //             // setBoolModal(false)
          //
          //         }}/>
          //     </div>
          //     <div>
          //         <input type="submit" className="submitFormBtn mt-a" value="Supprimer" onClick={() => {
          //             // setBoolModal(false)
          //             // deletePost()
          //         }}
          //                style={{height: 50, width: '100%'}}/>
          //     </div>
          // </div>
        }

        {modal && (
          <div>
            <ModalArtistStatement
              artist={artist}
              refreshKey={refreshKey}
              setRefreshKey={setRefreshKey}
              user={user}
              modePeinture={false}
              publication={
                action === actions.Modifiy && focusedPublication
                  ? focusedPublication
                  : publications
              }
              action={action}
              setBoolModal={setModal}
              selected={taggedArtwork}
              setSelected={setTaggedArtwork}
              contenu={enumContenu.ElementBiographies}
              setArtworkModal={setArtworkModal}
            />
          </div>
        )}

        {/* {artworkModal && (
          <ArtworkModalTag
            artist={artist}
            user={user}
            setBoolModal={setArtworkModal}
            selected={taggedArtwork}
            setSelected={setTaggedArtwork}
          />
        )} */}

        <SelectPaintingModal
          open={artworkModal}
          setOpen={setArtworkModal}
          selected={taggedArtwork}
          setSelected={setTaggedArtwork}
          multiple={false}
          colometrie={true}
          onSubmit={()=>(console.log(taggedArtwork))}
        />

        {/*<div className="">*/}

        {/*<input type="text" style={{width: '100%'}}*/}

        {/*       value={title ? title : ''}*/}

        {/*       onChange={(value) => {*/}
        {/*           setTitle(value.target.value)*/}
        {/*       }}*/}

        {/*       placeholder="Title" required/>*/}

        {/*<TextareaAutosize style={{paddingBottom: 10}} minRows={4}*/}

        {/*                  placeholder={artist ? artist.id : ""}*/}
        {/*                  value={value}*/}
        {/*                  onChange={e => {*/}
        {/*                      setCount(e.target.value.length);*/}
        {/*                      setValue(e.target.value)*/}
        {/*                  }}/>*/}
        {/*<div style={{width: '4%'}} className="charCount">{count}/255</div>*/}
        {/*</div>*/}

        {/*<input type="file" name="file" onChange={changeHandler} multiple={true}/>*/}

        {/*<div style={{display: "flex", flexDirection: 'row', gap: 20, justifyContent: 'center'}}>*/}
        {/*    {image && [...image].map((file) => (*/}
        {/*        <img style={{width: `${100 / image.length}%`, borderRadius: 20, gap: 20, maxWidth: '30%'}}*/}
        {/*             src={URL.createObjectURL(file)} alt={artist && artist.artist_name_fam}/>*/}
        {/*    ))}*/}
        {/*</div>*/}

        {/*<input type="submit" className="submitFormBtn mt-a" value="Poster" onClick={() => {*/}
        {/*    postElementsBiographie()*/}
        {/*}}*/}
        {/*       style={{height: 50, width: '100%'}}/>*/}
      </div>
    </>
  );
};
