import close from "../../webapp/img/icons/ico_close_2.svg";
import TextareaAutosize from "react-textarea-autosize";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {URLDayl} from "../../services/constantes";
import {toast} from "react-toastify";


export default function ModalCommentTopPaintings ({topPaintingId, setModal}) {

    const [comment, setComment] = useState("")

    useEffect(() => {
       axios.get(URLDayl + "top_paintings_movement/getById", {params: {id: topPaintingId}}).then((res) => {
           setComment(res.data.comment)
       }).catch((err) => {

       })
    },[])

    const handleSubmit = () => {
        axios.post(URLDayl + "top_paintings_movement/addComment", null,{params: {id: topPaintingId, comment: comment}}).then((res) => {
            toast.success("Success", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            }).catch((err) => {
            toast.error("Error", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            }
        )
    }
    useEffect(() => {
        let overlay = document.createElement("div");
    
        Object.assign(overlay.style, {
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          zIndex: 1,
        });
        document.body.appendChild(overlay);
        document.body.style.overflow = "hidden";
        return () => {
          document.body.removeChild(overlay);
          document.body.style.overflow = "unset";
        };
      }, []);
    return (
        <div className="ModalNetwork">

            {/* <!-- MODAL GERER MON RESEAU - DÉBUT --> */}
            <div className="smart-modal-overlay" style={{display: "block"}}></div>

            <div className="smart-modal-content-l scrollAuto" style={{
                transform: "translate(-50%,-50%)",
                display: "inline-flex",
                flexWrap: "wrap",
                alignContent: "flex-start",
                minHeight: "40%",
            }}>
                <h3>Commenter cette oeuvre</h3>
                <a className="smart-modal-close" onClick={(e) => {

                    setModal(false)

                }}><img className="img-left"
                        src={close}
                        alt="Trier"/></a>
                <div className="showSubs">

                    <TextareaAutosize minRows={5}
                                      placeholder={"saisie du texte"}
                                      value={comment}
                                      onChange={e => {
                                          setComment(e.target.value)
                                      }}
                    />

                    <div>
                        <input type="submit" className="submitFormBtn mt-a" value="Ajouter ce commentaire" onClick={(e) => {
                            handleSubmit(e)
                            setModal(false)
                        }} />
                    </div>

                </div>
            </div>

        </div>
    )
}