import React, { useEffect, useState } from "react";
import SocialNetworkPostList from "./SocialNetworkPostList";
import MyComments from "./MyComments";
import newpost from "../../../webapp/img/icons/newpost.svg";
import newcomment from "../../../webapp/img/icons/newcomment.svg";
import newedit from "../../../webapp/img/icons/newedit.svg";
import { useUser } from "../../../contexts/userService";
import { URLDayl, URLDaylImage } from "../../../services/constantes";
import axios from "axios";
import LockIcon from "@mui/icons-material/Lock";
import { Skeleton, Stack } from "@mui/material";
import { toast } from "react-toastify";
import NoPostYet from "../../SocialMedia/NoPostYet";
import ModalNetwork, { enumTypeModalNetwork } from "../ModalNetwork";
import { useNavigate } from "react-router";
import ModalNetwork2 from "../../general/ModalNetwork2";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
export const friendshipStatus = {
  loading: 0,
  requested: 1,
  following: 2,
  follow: 3,
};

function ProfileTab({ friend }) {
  const [activeTab, setActiveTab] = useState("Private");
  const auth = useUser();
  const [refreshKey, setRefreshKey] = useState(0);
  const [boolNetWork, setBoolNetWork] = useState(false);
  const [imageError, setImageError] = useState(false);

  const [typeModalNetwork, setTypeModalNetwork] = useState(
    enumTypeModalNetwork.Followers
  );

  const handleMenuClick = (tabName) => {
    setActiveTab(tabName);
  };

  const [restricted, setRestricted] = useState(!friend ? false : undefined);
  const [frienship, setFriendship] = useState(0);
  const [imageSrc, setImageSrc] = useState(null);

  const menuItems = [
    { tabName: "Public", icon: newpost },
    { tabName: "Private", icon: newcomment },
  ];

  const [statistics, setStatistics] = useState();

  const [myPosts, setMyPosts] = useState([]);

  useEffect(() => {
    axios
      .get(URLDayl + `friends/statistics/${friend ? friend.id : auth.data.id}`)
      .then((res) => {
        setStatistics(res.data);
      });
    axios
      .get(
        URLDayl +
          `publication/byUserId?userId=${friend ? friend.id : auth.data.id}`,
        { params: { status: "NETWORK" } }
      )
      .then((res) => {
        setMyPosts(res.data);
      });
  }, []);

  useEffect(() => {
    if (friend)
      axios
        .get(
          URLDayl + `friends/listOfFollowers?userId=${friend.id}&requested=true`
        )
        .then((friendList) => {
          const friendshipExist = friendList.data.find(
            (item) => item.friendId === auth.data.id
          );
          if (friendshipExist === undefined) {
            setRestricted(true);
            setFriendship(friendshipStatus.follow);
            return;
          }

          switch (friendshipExist.status) {
            case "ACCEPTED":
              setFriendship(friendshipStatus.following);
              setRestricted(false);
              break;
            case "REQUESTED":
              setFriendship(friendshipStatus.requested);
              setRestricted(true);
              break;
            default:
              setFriendship(friendshipStatus.follow);
              setRestricted(true);
              break;
          }
        });
  }, [friend, frienship]);

  const renderTabContent = () => {
    switch (activeTab) {
      case "Public":
        return <SocialNetworkPostList posts={myPosts} friend={friend} />;
      case "Private":
        return <MyComments />;
      default:
        return <MyComments />;
    }
  };

  const renderContent = () => {
    switch (restricted) {
      case false:
        return <div className="profileTab">{renderTabContent()}</div>;
      case true:
        return (
          <div style={style.overlay}>
            <div style={style.content}>
              <LockIcon style={{ fontSize: "100px", marginBottom: "20px" }} />
              <p
                style={{
                  marginBottom: "0px",
                  fontSize: "17px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                  textAlign: "center",
                  borderRadius: "10px",
                  padding: "10px",
                  backgroundColor: "white",
                }}
              >
                RESTRICTED <br /> You must be in this person's network to be
                able to view their account
              </p>
            </div>
          </div>
        );
      default:
        return (
          <Stack spacing={1}>
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="rectangular" width={210} height={60} />
            <Skeleton variant="rounded" width={210} height={60} />
          </Stack>
        );
    }
  };

  const style = {
    overlay: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backdropFilter: "blur(10px) brightness(1)",
      zIndex: 1000,
      borderRadius: "20px",
    },
    content: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "0px",
      zIndex: 1,
      padding: "20px",
      height: "100%",
      justifyContent: "start",
    },
  };

  const handleFollowToggle = () => {
    // setIsFollowing((prevState) => !prevState);
    if (restricted && friend)
      axios
        .get(URLDayl + `friends/add/${auth.data.id}/${friend.id}`)
        .then((res) => {
          toast.success("Demande d'ajoute envoyée", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setFriendship(friendshipStatus.requested);
        });
    else {
      axios
        .get(URLDayl + `friends/delete/${auth.data.id}/${friend.id}`)
        .then((res) => {
          toast.success("Suppression.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      setFriendship(friendshipStatus.follow);
    }
  };

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await axios.get(
          `${URLDaylImage}images/userIconImage`,
          {
            responseType: "blob",
            headers: {
              Authorization: `Bearer ${auth.data.jwt}`,
            },
            params: {
              userId: friend ? friend.id : auth.data.id,
            },
          }
        );
        const imageBlob = response.data;
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setImageSrc(imageObjectURL);
      } catch (error) {
        setImageError(true);
      }
    };

    fetchImage();
  }, [auth, friend]);

  const navigate = useNavigate();
  return (
    <div className="profileContainer">
      {
        <div>
          <div className="shadowBox">
            <div
              className="profileBanner"
              style={{
                backgroundImage:
                  friend && friend.backgroundImage
                    ? `url(${URLDaylImage}images/userBackgroundImage/${friend.id})`
                    : !friend && auth.data.backgroundImage
                      ? `url(${URLDaylImage}images/userBackgroundImage/${auth.data.id})`
                      : "none",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              {!friend && (
                <div className="editButton">
                  <CreateOutlinedIcon
                    style={{
                      cursor: "pointer",
                      top: "20px",
                      right: "20px",
                      color: "white",
                      border: "black",
                      fontSize: "25px",
                    }}
                    onClick={() => {
                      navigate("../../view/UserInfo.js");
                    }}
                  />
                </div>
              )}
            </div>
            <div className="profileSection">
              <div className="section1">
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {imageError ? (
                    <AccountCircleIcon
                      className="profilePic"
                      style={{ color: "black" }}
                    />
                  ) : (
                    <img
                      className="profilePic"
                      style={{ objectFit: "cover" }}
                      src={imageSrc}
                      alt="profile pic"
                      onError={() => setImageError(true)}
                    />
                  )}

                  <div className="userInfo">
                    <p className="seperateDot">
                      {friend
                        ? friend.pseudo !== ""
                          ? friend.pseudo
                          : friend.firstName + " " + friend.lastName
                        : auth.data.pseudo !== ""
                          ? auth.data.pseudo
                          : auth.data.firstName + " " + auth.data.lastName}
                    </p>
                    <p>{friend ? friend.country : auth.data.country}</p>
                  </div>
                </div>
                <div className="bio">
                  <p>{friend ? friend.biography : auth.data.biography}</p>
                </div>
              </div>
              <div className="section2">
                {statistics && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 10,
                      justifyContent: "center",
                    }}
                  >
                    <h3
                      style={{ fontWeight: "bold" }}
                      className={"onHoverTextUnderline"}
                      onClick={() => {
                        restricted || setTypeModalNetwork(friend ? 0 : 1);
                        setBoolNetWork(true);
                      }}
                    >
                      {statistics.numberFollowers} followers
                    </h3>
                    <h3>| </h3>
                    <h3
                      style={{ fontWeight: "bold" }}
                      className={"onHoverTextUnderline"}
                      onClick={() => {
                        restricted || setTypeModalNetwork(friend ? 1 : 2);
                        setBoolNetWork(true);
                      }}
                    >
                      {statistics.numberFollowing} following
                    </h3>
                  </div>
                )}
                {friend && (
                  <div
                    className={`followBtn ${restricted ? "" : "following"}`}
                    onClick={handleFollowToggle}
                  >
                    {frienship === friendshipStatus.loading
                      ? "Loading"
                      : frienship === friendshipStatus.follow
                        ? "Follow"
                        : frienship === friendshipStatus.following
                          ? "Following"
                          : "Requested"}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      }
      <div
        className="shadowBox"
        style={{ marginTop: "40px", minHeight: "300px" }}
      >
        <div>
          <p className="menuTitle">Comments</p>
          {
            <div className="profileMenu">
              {menuItems.slice(0, !friend ? 2 : 1).map((menuItem, index) => (
                <React.Fragment key={menuItem.tabName}>
                  <div
                    className={`profileMenuItem ${
                      activeTab === menuItem.tabName ? "active" : ""
                    }`}
                    onClick={() => handleMenuClick(menuItem.tabName)}
                  >
                    {menuItem.icon && <img src={menuItem.icon} alt="Logo" />}
                    <p>{menuItem.tabName}</p>
                  </div>
                  {index !== menuItems.length - 1 && index > 1 && (
                    <div className="menuSeparator" />
                  )}
                </React.Fragment>
              ))}
            </div>
          }

          {renderContent()}
        </div>
        <ModalNetwork2
          typeModalNetwork={typeModalNetwork}
          user={friend || auth.data}
          setRefreshKey={setRefreshKey}
          refreshKey={refreshKey}
          setBoolNetWork={setBoolNetWork}
          boolNetWork={boolNetWork}
          friendId={friend}
        />
      </div>
    </div>
  );
}

export default ProfileTab;
