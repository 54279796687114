import React, { useEffect, useState } from "react";
import axios from "axios";
import { URLDayl } from "../../services/constantes";
import ImageGrid, { enumTypeGrid } from "../general/ImageGrid/ImageGrid.js";

/**
 * This component display the matched paintings
 * @param {object} user - user object from api
 * @param {object} setUser - user setter
 *  @param {boolean} isLogged - test if the user is logged
 * @param {string} matchingType - type of matcing used
 *  @param {string} setMatchingType - matchingType setter
 * @emits - paintingEntity/${paintId} : get painting informations
 * @emits - matchingSearch/findMatchForSource/${matchingType.value}/${response.data.aw_idtf_intern}/true : get list of match

 */
//TODO : UNCOMMENT AND REENABLE SORT BY ARTIST

export default function ResultMatch({
  user,
  paintId,
  isLogged,
  matchingType,
  setMatchingType,
}) {
  const [matchList, setMatchList] = useState([]);
  // const [listArtist, setListArtist] = useState([]);
  // const [boolModalArtist, setBoolModalArtist] = useState(false);
  // const [boolAllArtist, setBoolAllArtist] = useState(true);
  const [orderDate, setOrderDate] = useState("");
  const [tempList, setTempList] = useState([]);
  const [maxNumPainting, setMaxNumPainting] = useState(24);
  const [refreshKey, setRefreshKey] = useState(0);

  //get Painting Match//
  useEffect(() => {
    axios.get(URLDayl + `paintingEntity/${paintId}`).then((response) => {
      axios
        .get(
          URLDayl +
            `matchingSearch/findMatchForSource/${matchingType.value}/${paintId}/true`
        )
        .then((response2) => {
          // axios.get(URLDayl + `matchingSearch/findMatchForSource/${matchingType.value}/${response.data.aw_idtf_intern}/true`).then((response2) => {

          const boolArray = Object.values(response2.data.payload).map((el) => {
            return { ...el, boolVis: true };
          });
          setMatchList(boolArray);
          //liste temporaire pour le tri par date
          setTempList(boolArray);

          //on recupère tous les artistes
          // let tempListArtist = boolArray.filter((v, i) => {
          //   return (
          //     boolArray.map((match) => match.artistId).indexOf(v.artistId) === i
          //   );
          // });
          // tempListArtist = tempListArtist.map((el) => {
          //   return { ...el, boolList: true };
          // });
          // setListArtist([...tempListArtist]);
        });
    });
  }, [paintId, matchingType]);

  //Choisis les artistes à trier
  // function setTriArtist(index) {
  //   let tempList = [...listArtist];
  //   let tempMatch = [...matchList];

  //   if (tempList[index].boolList === true) {
  //     tempList[index].boolList = false;
  //     tempMatch.map((match) => {
  //       if (match.artistId === tempList[index].artistId) match.boolVis = false;
  //     });
  //   } else {
  //     tempList[index].boolList = true;
  //     tempMatch.map((match) => {
  //       if (match.artistId === tempList[index].artistId) match.boolVis = true;
  //     });
  //   }
  //   setListArtist([...tempList]);
  //   setMatchList([...tempMatch]);

  //   testAllArtists();
  // }

  //Test si tt les artistes sont selectionnés
  // function testAllArtists() {
  //   let count = 0;

  //   listArtist.map((artist) => {
  //     if (artist.boolList === true) {
  //       count++;
  //     }
  //   });

  //   if (count === listArtist.length) {
  //     setBoolAllArtist(true);
  //   } else {
  //     setBoolAllArtist(false);
  //   }
  // }

  // //OnClick bouton "Tous"
  // function allArtists() {
  //   let tempList = [...listArtist];

  //   if (boolAllArtist === true) {
  //     for (let i = 0; i < tempList.length; i++) tempList[i].boolList = false;
  //     for (let i = 0; i < matchList.length; i++) matchList[i].boolVis = false;
  //     setBoolAllArtist(false);
  //   } else {
  //     for (let i = 0; i < tempList.length; i++) tempList[i].boolList = true;
  //     for (let i = 0; i < matchList.length; i++) matchList[i].boolVis = true;
  //     setBoolAllArtist(true);
  //   }
  //   setListArtist([...tempList]);
  // }

  // //Ouvre la modale de tri artiste
  // function openModalArtist() {
  //   if (boolModalArtist === true) {
  //     setBoolModalArtist(false);
  //   } else {
  //     setBoolModalArtist(true);
  //   }
  // }

  function handleOrderDate() {
    let bufferOrder;
    let bufferOrder2;
    if (orderDate === "") {
      setMatchList(matchList.sort((a, b) => b.year - a.year));
      setOrderDate("DESC");
    } else if (orderDate === "DESC") {
      bufferOrder = matchList.filter(function (paint) {
        return paint.year === null || paint.year === 0;
      });

      bufferOrder2 = matchList
        .filter(function (paint) {
          return paint.year > 0;
        })
        .sort((a, b) => a.year - b.year);

      bufferOrder.unshift(...bufferOrder2);
      setMatchList(bufferOrder);
      setOrderDate("ASC");
    } else if (orderDate === "ASC") {
      setMatchList(tempList);
      setOrderDate("");
    }
  }

  // Call Jquery script
  useEffect(() => {
    const loadFile = async () => {
      // const { modal } = await import("../../webapp/js/js_source/modalBox");
      const { grid } = await import("../../webapp/js/gridActions.min.js");
      const { checkBox } = await import(
        "../../webapp/js/js_source/checkLister"
      );

      // modal();
      grid();
      checkBox();
    };
    loadFile();
  }, []);

  return (
    <div className="ResultMatch">
      <section className="" style={{ marg: "0" }}>
        <div className=" flex justify-between items-center mt-10 ml-5 mb-5">
          {/*  <button
            className="orderByArtist"
            onClick={openModalArtist}
            data-tooltip="Filtrer les artistes présents dans la sélection"
          >
            Trier par artiste(s)
          </button>
         {boolModalArtist === true && (
            <div>
              <div
                className="smart-modal-overlay"
                style={{ display: "block" }}
              ></div>
              <div
                id="1777"
                className="smart-modal-content scrollAuto"
                style={{
                  display: "block",
                  transform: "translate(-50%,-50%)",
                  top: "50%",
                  left: "50%",
                }}
              >
                <h3>Artistes</h3>
                <a className="smart-modal-close" onClick={openModalArtist}>
                  <img className="img-left" src={close} alt="Trier" />
                </a>
                <div className="checkLister all">
                  <button
                    className={
                      boolAllArtist === true ? "checkbox checked" : "checkbox "
                    }
                    onClick={allArtists}
                  ></button>
                  <span>Tous</span>
                </div>
         
                {listArtist.length > 0 &&
                  listArtist.map((artist, index) => {
                    return (
                      <div className="checkLister">
                        <div
                          className={
                            artist.boolList === true
                              ? "checkbox checked"
                              : "checkbox"
                          }
                          onClick={(e) => setTriArtist(index)}
                        ></div>
                        <span>{artist.artisteName}</span>
                      </div>
                    );
                  })}
              </div>
            </div>
          )} */}
        </div>

        <ImageGrid
          user={user}
          isLogged={isLogged}
          paintings={matchList}
          showTools={true}
          selectionMode={false}
          maxNumPainting={maxNumPainting}
          setMaxNumPainting={setMaxNumPainting}
          totalMaxNum={100}
          orderDate={orderDate}
          setOrderDate={handleOrderDate}
          typeGrid={enumTypeGrid.ArtistPaintingsGrid}
          refreshKey={refreshKey}
          setRefreshKey={setRefreshKey}
          header={true}
          matchingType={matchingType}
          setMatchingType={setMatchingType}
        />
      </section>
    </div>
  );
}
