import { Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import SqueletteHead from "../component/general/SqueletteHead";
import SqueletteFoot from "../component/general/SqueletteFoot";
import OpusPageWrapper from "../component/OpusPage/OpusPageWrapper";
import CompteAmateurPage from "../view/CompteAmateurPage";
import FavArtists from "../component/CompteAmateurPage/FavArtists";
import ArtistDetailpage from "../view/ArtistDetailPage";
import MatchingPage from "../view/MatchingPage";
import ListeAmateurPage from "../view/ListeAmateurPage";
import MatchingColor from "../view/MatchingColor";
import SignIn from "../view/SignIn";
import LogIn from "../view/LogIn";
import Atelier from "../view/Atelier";
import GoToTop from "../services/ScrollToTop";
import { ToastContainer } from "react-toastify";
import { URLDayl } from "../services/constantes";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import UserInfo from "../view/UserInfo";
import ProfileAmateur from "../view/ProfileAmateur";
import CompteExpertPage from "../view/CompteExpertPage";
import MatchingKeyword from "../view/MatchingKeyword";
import { useUser } from "../contexts/userService";
import FindByStylePaintings from "../view/FindByStylePaintings";
import FindByGenrePaintings from "../view/FindByGenrePaintings";
import PaintingSearch from "../view/PaintingSearch";
import MatchingImage2 from "../view/MatchingImage2";
import FindByAwMediumPaintings from "../view/FindByAwMediumPaintings";
import HDPaintings from "../view/HDPaintings";
import ChatPage from "../view/ChatPage";
import PageStatistiques from "../view/PageStatistiques";
import About from "../view/About";
import SearchByHashtag from "../view/SearchByHashtag";
import FriseMouvementArtistique from "../view/FriseMouvementArtistique";
import Colorimetrie from "../view/Colorimetrie";
import ColorimetrieAnalyse from "../view/ColorimetrieAnalyse";
import Requests from "../view/customRequests";
import { MediaProvider } from "../contexts/ColorimetrieUpload";
import GptConfiguration from "../component/general/AgentGpt/GptConfiguration";
import { useNavigate } from "react-router-dom";
import MovementPage from "../view/MovementPage";
import DiscoverColors from "../view/DiscoverColors";
import DiscoverArtist from "../view/DiscoverArtist";
import RenewPassword from "../view/RenewPassword";
import DaylContent from "../view/DaylContent";
import HomePage from "../view/HomePage";
/**
 * This component is the root of the project, it contains all the routes to the different pages
 */

function RouterManager() {
  const [paintId, setPaintId] = useState();
  const [user, setUser] = useState(null);
  const [isLogged, setIsLogged] = useState(false);
  const [isActive, setIsActive] = useState(1);
  const [friend, setFriend] = useState();
  const auth = useUser();
  const navigate = useNavigate();

  //go to top of page on load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [paintId]);

  //temporary get user on page refresh
  useEffect(() => {
    const fetchData = async () => {
      try {
        auth.setData(false);
        const id = localStorage.getItem("id");

        await axios
          .get(URLDayl + `users/${id}`)
          .then((response) => {
            if (response.data !== null) {
              auth.setData({
                ...response.data,
                jwt: localStorage.getItem("jwt"),
              });
              setIsLogged(true);
            }

            setUser(response.data);
          })
          .catch((err) => {
            auth.setData(undefined);
            console.log("ERROR ROUTER MANAGER");

            console.log(localStorage.getItem("id"));
          });
      } catch (err) {
        auth.setData(undefined);
        console.log("ERROR ROUTER MANAGER");
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <ToastContainer
        autoClose={false}
        draggableDirection="y"
        style={{ zIndex: "99999999", fontSize: "14px" }}
      />
      <SqueletteHead
        isActive={isActive}
        setIsActive={setIsActive}
        user={user}
        setUser={setUser}
        isLogged={isLogged}
      />
      <div
        className="App"
        style={{ display: "flex", flexDirection: "column", minHeight: "60vh" }}
      >
        <Routes>
          <Route
            path="/"
            element={
              <HomePage user={user} isLogged={isLogged} setUser={setUser} />
            }
          />
          {/* <Route path="view/SignInPage.js" element={isLogged ? <CompteAmateurPage user={user} setUser={setUser} /> : <SignInPage user={user} setIsLogged={setIsLogged} isLogged={isLogged} setUser={setUser}/>} /> */}
          <Route
            path="view/CompteAmateurPage.js"
            element={
              <CompteAmateurPage
                isActive={isActive}
                setIsActive={setIsActive}
                user={user}
                setUser={setUser}
                setIsLogged={setIsLogged}
                isLogged={isLogged}
              />
            }
          />
          <Route
            path="view/LogIn.js"
            element={
              /* isLogged ? <CompteAmateurPage user={user} setUser={setUser} setIsLogged={setIsLogged} isLogged={isLogged} /> :  */
              <LogIn
                isActive={isActive}
                setIsActive={setIsActive}
                user={user}
                setIsLogged={setIsLogged}
                isLogged={isLogged}
                setUser={setUser}
              />
            }
          />
          <Route path="/renewPassword" element={<RenewPassword />} />

          <Route path="/renewPassword/:token" element={<RenewPassword />} />

          <Route
            path="view/SignIn.js"
            element={
              /* isLogged ? <CompteAmateurPage user={user} setUser={setUser} setIsLogged={setIsLogged} isLogged={isLogged} /> : */
              <SignIn
                isActive={isActive}
                setIsActive={setIsActive}
                user={user}
                setIsLogged={setIsLogged}
                isLogged={isLogged}
                setUser={setUser}
              />
            }
          />
          <Route
            path="component/CompteAmateurPage/FavArtists.js"
            element={<FavArtists />}
          />
          <Route
            path="view/OpusPage.js/:id"
            element={
              <OpusPageWrapper
                user={user}
                setUser={setUser}
                isLogged={isLogged}
                setPaintId={setPaintId}
              />
            }
          />

          <Route
            path="view/ArtistDetailPage.js/:id"
            element={
              <ArtistDetailpage
                setPaintId={setPaintId}
                user={user}
                setUser={setUser}
                isLogged={isLogged}
              />
            }
          />
          <Route
            path="view/ListeAmateurPage.js/:id"
            element={
              <ListeAmateurPage
                paintId={paintId}
                user={user}
                isLogged={isLogged}
              />
            }
          />
          <Route
            path="view/MatchingColor.js"
            element={
              <MatchingColor
                user={user}
                paintId={paintId}
                setPaintId={setPaintId}
                setUser={setUser}
                isLogged={isLogged}
              />
            }
          />
          <Route
            path="view/DiscoverColors.js"
            element={
              <DiscoverColors
                user={user}
                paintId={paintId}
                setPaintId={setPaintId}
                setUser={setUser}
                isLogged={isLogged}
              />
            }
          />
          <Route
            path="view/MatchingKeyword.js"
            element={<MatchingKeyword user={user} isLogged={isLogged} />}
          />
          <Route
            path="view/Atelier.js"
            element={
              <Atelier
                user={user}
                setUser={setUser}
                setIsLogged={setIsLogged}
                isLogged={isLogged}
              />
            }
          />
          <Route
            path="view/UserInfo.js"
            element={
              <UserInfo
                user={user}
                setUser={setUser}
                setIsLogged={setIsLogged}
                isLogged={isLogged}
              />
            }
          />
          <Route
            path="view/ProfileAmateur.js/:friendId"
            element={
              <ProfileAmateur
                isActive={isActive}
                setIsActive={setIsActive}
                user={user}
                setUser={setUser}
                setIsLogged={setIsLogged}
                isLogged={isLogged}
                friend={friend}
                setFriend={setFriend}
              />
            }
          />
          <Route
            path="view/Colorimetrie.js"
            element={
              <MediaProvider>
                <Colorimetrie />{" "}
              </MediaProvider>
            }
          />
          <Route
            path="view/CustomRequests/:paintingId"
            element={
              <MediaProvider>
                <Requests />{" "}
              </MediaProvider>
            }
          />
          <Route
            path="view/ColorimetrieAnalyse/:paintingId"
            element={<ColorimetrieAnalyse />}
          />

          <Route
            path="FriseMouvementArtistique"
            element={<FriseMouvementArtistique />}
          />
          <Route
            path="view/CompteExpertPage.js/:id"
            element={<CompteExpertPage user={user} />}
          />
          <Route path="view/DiscoverArtiste.js" element={<DiscoverArtist />} />

          <Route
            path="view/paintingSearchByStyle.js/:style"
            element={<FindByStylePaintings />}
          />
          <Route
            path="view/paintingSearchByGenre.js/:genre"
            element={<FindByGenrePaintings />}
          />
          <Route
            path="view/MatchingPage.js/:paintId"
            element={
              <MatchingPage
                user={user}
                setPaintId={setPaintId}
                setUser={setUser}
                isLogged={isLogged}
              />
            }
          />
          <Route path="view/paintingSearch.js" element={<PaintingSearch />} />

          <Route path="view/MatchingImage2.js" element={<MatchingImage2 />} />
          <Route
            path="view/paintingSearchByAwMedium.js/:aw_medium"
            element={<FindByAwMediumPaintings />}
          />
          <Route path="view/chatPage.js" element={<ChatPage />} />
          <Route path="/about" element={<About />} />
          <Route
            path="view/PageStatistiques.js"
            element={<PageStatistiques />}
          />
          <Route path="view/DaylContent" element={<DaylContent />} />
          <Route
            path="view/DaylContent/:movementId"
            element={<DaylContent />}
          />

          <Route
            path="view/MovementPage.js"
            element={
              <MovementPage
                displayContent={true}
                onClick={(el) =>
                  navigate(`../view/paintingSearchByStyle.js/${el.movement}`)
                }
              />
            }
          />

          <Route
            path="view/SearchByHashtag.js/:hashtag"
            element={<SearchByHashtag />}
          />
          <Route path="view/HDPaintings.js" element={<HDPaintings />} />

          {/** GPT CONFIGURATION TEMPLATE */}
          <Route path="view/GptConfiguration" element={<GptConfiguration />} />
        </Routes>
        {/* <ChatMenu /> */}
        {/* <ChatModal/> */}

        <GoToTop />
      </div>
      <SqueletteFoot />
    </>
  );
}

export default RouterManager;
