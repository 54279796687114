import React, {useEffect, useState} from "react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import BiographieElement from "./BiographieElement";
import {stringSplitting} from "../../services/Utils";
import {URLDayl, URLDaylImage} from "../../services/constantes";
import axios from "axios";

/**
 * Artist Biography in ArtistPage
 * @param {string} bg - random image from this artist
 * @param {object} artist - artist informations from api
 */

export default function AvisExpert({bg, artist}) {

    const [publications, setPublications] = useState()
    const [images, setImages] = useState([])

    useEffect(() => {
    }, [artist])


    useEffect(() => {
        axios.get(URLDayl + `artist/avisExpert/byArtistId/${artist.id}`).then((res) => {

            const doubleTempImages = []

            for (let i = 0; i < res.data.length; i++) {
                const tempImages = []
                for (let j = 0; j < res.data[i].images.length; j++) {
                    if (res.data[i].images[j] !== "")
                        tempImages.push(URLDayl + `images/avisExpert/${artist.id}/${res.data[i].id}?name=${res.data[i].images[j]}`)
                }
                for (let k = 0; k < res.data[i].taggedArtworks.length; k++)
                    tempImages.push(URLDaylImage + `images/paintingPic/${res.data[i].taggedArtworks[k]}`)
                doubleTempImages.push(tempImages)
            }
            setImages(doubleTempImages)
            setPublications(res.data)


        }).catch((err) => {
            console.log("ERROR")
            console.log(err)
        })
    }, [artist.id])


    return (
        <div className="Biography">
            {/* <!-- Biographie debut */}
            <section className="center-col mb-d">
                <div className="RandomQuickFactBox cont-l-w">


                    {publications && publications.length > 0 && publications.map((el, index) => {
                        return (<>
                            <BiographieElement artist={"artist"} bg={"bg"}
                                               text={el ? stringSplitting(950, el.content) : ""}
                                               direction={index % 2 !== 0 ? "left" : "right"} title={el.title}
                                               imageList={images[index]}/>
                        </>)
                    })


                    }

                </div>
            </section>
            {/* <!-- Biographie - fin --> */}
        </div>
    );
}