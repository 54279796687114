import ImageGrid from "../component/general/ImageGrid/ImageGrid";
import { useEffect, useState } from "react";
import { URLDayl } from "../services/constantes";
import axios from "axios";
import { useLocation } from "react-router-dom";

export default function MatchingKeyword({ user, isLogged }) {
  const location = useLocation();
  const [paintings, setPaintings] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [maxNumPainting, setMaxNumPainting] = useState(20);
  useEffect(() => {
    setKeyword(location.state);
  }, [location.state]);

  useEffect(() => {
    axios
      .post(
        URLDayl +
          `paintingSearch/findPaintingByKeyword?keyword=${keyword}&startItem=0&maxItem=${maxNumPainting}`
      )
      .then((res) => {
        setPaintings(res.data);
      });
  }, [keyword, maxNumPainting]);

  return (
    <div style={{ marginTop: 100 }}>
      <p
        style={{
          alignSelf: "center",
          width: "100%",
          textAlign: "center",
          fontWeight: "bold",
          fontSize: 30,
        }}
      >
        Keyword: {keyword}
      </p>
      <ImageGrid
        user={user}
        isLogged={isLogged}
        paintings={paintings}
        showTools={true}
        selectionMode={false}
        maxNumPainting={maxNumPainting}
        setMaxNumPainting={setMaxNumPainting}
      />
    </div>
  );
}
