import React from "react";

import {useLocation} from "react-router-dom";
import DisplayInfoList from "../component/ListeAmateurPage/DisplayInfoList";
import DisplayList from "../component/ListeAmateurPage/DisplayList";


/**
 * This component display the List Page
 * @param {object} user - user object from api
 * @param {number} paintId - list Id
 * @param {boolean} isLogged - test if the user is logged

 */

export default function ListeAmateurPage({user, isLogged, paintId}) {


    const location = useLocation();
    paintId = location.state;

    return (
        <div>
            <DisplayInfoList paintId={paintId} user={user} isLogged={isLogged}/>
            <DisplayList paintId={paintId} user={user} isLogged={isLogged}/>
        </div>

    );
}

