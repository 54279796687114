import React, { useState, useRef, useEffect } from "react";
import WifiFindIcon from "@mui/icons-material/WifiFind";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import {
  Modal,
  Box,
  TextareaAutosize,
  Button,
  Typography,
} from "@mui/material";
import axios from "axios";
import { URLDaylIAgent } from "../../../services/constantes";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import GptConfiguration from "./GptConfiguration";
import { ModalHeader } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";

const TypedText = ({ text }) => {
  const [displayedText, setDisplayedText] = useState("");

  useEffect(() => {
    let index = 0;

    const intervalId = setInterval(() => {
      setDisplayedText((prevText) => prevText + text[index]);

      index += 1;
      if (index === text.length) {
        clearInterval(intervalId);
      }
    }, 10);

    return () => clearInterval(intervalId);
  }, [text]);

  return <span>{displayedText}</span>;
};

const AgentGPTHeader = (props) => {
  const [open, setOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [userMessage, setUserMessage] = useState("");
  const [loading, setLoading] = useState(false); // Added loading state
  const messagesEndRef = useRef(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function systemPromptingBuilder(data) {
    if (data) {
      //var prompt = "Réponds brièvement, si tu ne connais pas la réponse, indique simplement que tes données ne te permettent pas de répondre à cette question, Tu es un agent expert dans le domaine de l'art. et surtout sur l'artiste et oeuvre de " + data.artist.artist_name_tot + "dans l'oeuvre de " + data.aw_title_origin
      return data;
    }
    return "Tu es un agent expert dans le domaine de l'art.";
  }

  // Inside your handleSendMessage function in React
  const handleSendMessage = async (message) => {
    if (userMessage.trim() !== "" || message.trim() !== "") {
      // Append user message to the chat
      setMessages([
        ...messages,
        { role: "user", message: message ? message : userMessage },
      ]);
      setUserMessage("");
      setLoading(true); // Set loading to true when sending the message

      try {
        // Make API call to localhost:5000/llm_agent_gpt
        var params = {
          user_input: message ? message : userMessage,
          history: messages,
          system_prompt: systemPromptingBuilder(props.data),
        };
        const response = await axios.post(
          URLDaylIAgent + "/llm_agent_gpt",
          params,
          { withCredentials: false }
        );

        // Append the response from the API to the chat without overwriting user's question
        setMessages((prevMessages) => [
          ...prevMessages,
          { role: "assistant", message: "Processing..." }, // Display processing while waiting for the response
        ]);
        scrollToBottom();

        setTimeout(() => {
          setMessages((prevMessages) => [
            ...prevMessages.slice(0, -1), // Remove the 'Processing...' message
            { role: "assistant", message: response.data.message },
          ]);
          setLoading(false); // Set loading to false after displaying the response
          scrollToBottom();
        }, 1500); // Adjust the delay time as needed
      } catch (error) {
        console.error("Error calling the API:", error);
        setLoading(false); // Set loading to false in case of an error
      }
    }
  };

  const renderChat = () => {
    return messages.map((msg, index) => (
      <Typography
        key={index}
        variant="body1"
        sx={{
          color: msg.role === "user" ? "black" : "black",
          backgroundColor: msg.role === "user" ? "#f0f0f0" : "white",
          padding: "8px",
          borderRadius: "4px",
          marginBottom: "8px",
          fontFamily: "sans-serif",
        }}
        style={{
          fontFamily: "sans-serif",
        }}
      >
        <strong
          style={{
            fontFamily: "sans-serif",
          }}
        >
          {msg.role === "user" ? "User: " : "Assistant: "}
        </strong>

        {msg.role === "assistant" ? (
          <span
            style={{
              fontFamily: "sans-serif",
            }}
          >
            {msg.message === "Processing..." && loading ? (
              "Processing..."
            ) : (
              <TypedText text={msg.message} />
            )}
          </span>
        ) : (
          <span
            style={{
              fontFamily: "sans-serif",
            }}
          >
            {msg.message}
          </span>
        )}
      </Typography>
    ));
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const modalBody = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "80vw",
        height: "83vh",
        maxHeight: "83vh",
        p: 2,
        bgcolor: "background.paper",
        boxShadow: 24,
        borderRadius: 2,
        overflowY: "auto",
      }}
    >
      <CloseIcon
        style={{
          position: "absolute",
          right: "12px",
          top: "12px",
          cursor: "pointer",
          color: "black",
          zIndex: 1,
        }}
        onClick={handleClose}
      />

      <GptConfiguration questions={props.questions} nb={props.nb} />
    </Box>
  );

  return (
    <>
      <div
        className="flex justify-center items-center text-lg font-raleway font-bold gap-2 cursor-pointer"
        onClick={handleOpen}
      >
        <WifiFindIcon
          style={{
            fontSize: 33,
            color: props.color ? props.color : "white",
          }}
          onClick={handleOpen}
        />
        {props.text && "CHAT"}
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {modalBody}
      </Modal>
    </>
  );
};

export default AgentGPTHeader;
