import * as React from "react";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Likes from "../../CompteAmateurPage/Likes";
import { useUser } from "../../../contexts/userService";
import { Button } from "@mui/material";
import CompactSearchBarPaintings from "../CompactSearchBarPaintings";
import ImageGrid from "../ImageGrid/ImageGrid";
import axios from "axios";
import { URLDayl, URLDaylImage } from "../../../services/constantes";
import Lists from "../../CompteAmateurPage/Lists";
import SelectPaintingLists from "./SelectPaintingLists";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      className="width-full"
    >
      {value === index && <div className="w-full">{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function SelectPaintingContainer({
  setModal,
  selected,
  setSelected,
  setFile,
  selectedFile,
  multiple = false,
  onSave = () => {},
  defaultSearchDTO,
  colometrie = false,
}) {
  const [value, setValue] = React.useState(0);
  const handleUploadClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*"; // Specify the file types to be accepted, such as images
    input.onchange = handleFileChange; // Handle the selected file
    input.click(); // Trigger the file input
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleFileChange = (event) => {
    const fileTemp = event.target.files[0];
    setFile(fileTemp);
    setSelected([]);
  };

  const auth = useUser();

  const [searchDTO, setSearchDTO] = useState(defaultSearchDTO || {});
  const [constSearchDTO, setConstSearchDTO] = useState({});
  const [maxNumPainting, setMaxNumPainting] = useState(0);

  const [paintings, setPaintings] = useState([]);
  const [totalNumPaintings, setTotalNumPaintings] = useState(0);

  useEffect(() => {
    onSubmit();
  }, []);

  const onSubmit = async () => {
    try {
      const { data } = await axios.post(
        `${URLDayl}paintingSearch/findPaintingBySearchDtoNewEdition`,
        searchDTO
      );
      setPaintings(data.payload);
      setConstSearchDTO(searchDTO);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        height: "95%",

        borderRadius: 3,
        overflow: "auto",
      }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: "divider", minWidth: 200 }}
      >
        <Tab label="Search painting" {...a11yProps(0)} />
        <Tab label="Liked" {...a11yProps(1)} />
        <Tab label="Lists" {...a11yProps(2)} />
        {selectedFile !== false && (
          <Tab
            label="Upload file"
            {...a11yProps(3)}
            onClick={handleUploadClick}
          />
        )}
      </Tabs>
      <TabPanel
        value={value}
        index={0}
        style={{ marginLeft: "auto", marginRight: "auto",width:"75vw" }}
      >
        <div
          style={{
            display: "flex",
            marginBottom: 50,
            justifyContent: "center",
            borderRadius: 20,
            gap: 20,
            flex: 1,
            paddingLeft: 50,
            paddingRight: 50,
            alignSelf: "center",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: 20,
          }}
          className={""}
        >
          <CompactSearchBarPaintings
            onSubmit={onSubmit}
            setSearchDTO={setSearchDTO}
            searchDTO={searchDTO}
            setConstSearchDto={constSearchDTO}
          />
        </div>
        {paintings.length > 0 && (
          <ImageGrid
            user={auth.data}
            isLogged={auth.data !== undefined}
            paintings={paintings}
            showTools={false}
            selectionMode={true}
            mutlipleSelection={!multiple}
            selected={selected}
            setSelected={setSelected}
            maxNumPainting={maxNumPainting}
            setMaxNumPainting={setMaxNumPainting}
            totalMaxNum={totalNumPaintings}
          />
        )}
        {/*<div style={{*/}
        {/*    position: "fixed",*/}
        {/*    zIndex: 10,*/}
        {/*    display: "flex",*/}
        {/*    flexDirection: "row",*/}
        {/*    gap: 20,*/}
        {/*    bottom: -40,*/}
        {/*    transform: "translate(-50%,-50%)",*/}
        {/*    left: "50%",*/}
        {/*    padding: 10,*/}
        {/*    width: "80%",*/}
        {/*    backgroundColor: "white",*/}
        {/*    height: "auto",*/}
        {/*    minHeight: 100,*/}
        {/*    borderRadius: 5*/}
        {/*}}>*/}
        {/*    {*/}
        {/*        selected.map((el) => {*/}
        {/*            return (*/}
        {/*                <div style={{*/}
        {/*                    width: 80, height: 80, borderRadius: 10,*/}
        {/*                    backgroundImage: `url(${URLDaylImage}images/paintingPic/${el})`,*/}
        {/*                    backgroundRepeat: "no-repeat",*/}
        {/*                    backgroundPosition: "center center",*/}
        {/*                    backgroundSize: "cover",*/}

        {/*                }} onClick={() => {*/}
        {/*                    setSelected(selected.filter((el2) => el2 !== el))*/}
        {/*                }}></div>)*/}
        {/*        })*/}
        {/*    }*/}
        {/*    <Button*/}
        {/*        variant="outlined"*/}
        {/*        color={"primary"}*/}
        {/*        onClick={() => {*/}
        {/*            setModal(false)*/}
        {/*        }}*/}
        {/*        style={{border: 'solid', position: "absolute", right: 20, top: "25%"}}*/}

        {/*    >*/}
        {/*        Save*/}
        {/*    </Button>*/}
        {/*</div>*/}
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Likes
          user={auth.data}
          setUser={null}
          selectionMode
          multiple
          setSelected={setSelected}
          selected={selected}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <SelectPaintingLists selected={selected} setSelected={setSelected} />
      </TabPanel>
      {selectedFile !== false && (
        <TabPanel value={value} index={3} style={{ margin: "auto" }}>
          <Button
            variant="outlined"
            color={"primary"}
            style={{ border: "solid" }}
            onClick={handleUploadClick}
          >
            Upload File
          </Button>
        </TabPanel>
      )}

      {/*<TabPanel value={value} index={2}>*/}
      {/*    <Lists user={auth.data} setUser={null}/>*/}
      {/*</TabPanel>*/}

      <div
        style={{
          position: "fixed",
          zIndex: 10,
          display: "flex",
          flexDirection: "row",
          gap: 20,
          bottom: -40,
          transform: "translate(-50%,-50%)",
          left: "50%",
          padding: 10,
          width: "80%",
          backgroundColor: "white",
          height: "auto",
          minHeight: 100,
          borderRadius: 5,
        }}
      >
        {selected &&
          selected.length > 0 &&
          selected.map((el) => {
            return (
              <div
                style={{
                  width: 80,
                  height: 80,
                  borderRadius: 10,
                  backgroundImage: `url(${URLDaylImage}images/paintingPic/${el})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
                }}
                onClick={() => {
                  setSelected(selected.filter((el2) => el2 !== el));
                }}
              ></div>
            );
          })}
        {selected.length < 1 && selectedFile && (
          <div
            style={{
              width: 80,
              height: 80,
              borderRadius: 10,
              backgroundImage: `url(${URL.createObjectURL(selectedFile)})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "cover",
            }}
            onClick={() => {
              setFile();
            }}
          ></div>
        )}
        <Button
          variant="outlined"
          color={"primary"}
          onClick={() => {
            setModal(false);
            onSave();
          }}
          style={{
            border: "solid",
            position: "absolute",
            right: 20,
            top: "25%",
          }}
        >
          {colometrie ? "GO" : "Save"}
        </Button>
      </div>
    </Box>
  );
}
