import React, {createContext, useContext, useEffect, useState} from 'react';

//Create the Auth Context with the data type specified
//and a empty object
const UserContext = createContext({});

const UserProvider = ({children}) => {
    const [data, setData] = useState(false)

    return (
        <UserContext.Provider value={{data, setData}}>
            {children}
        </UserContext.Provider>
    );
};

//A simple hooks to facilitate the access to the AuthContext
// and permit components to subscribe to AuthContext updates
function useUser() {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }

    return context;
}

export {UserContext, UserProvider, useUser};