import {
  calculateHSL,
  calculateHSV,
  HueSlider,
  linearGradientCompute,
  linearGradientCompute2,
} from "../HomePage/SearchColor";
import React, { useEffect, useState } from "react";
import { toColor } from "react-color-palette";
import { useTranslation } from "react-i18next";

export function calculateHSL2(hsv, value) {
  // determine the lightness in the range [0,100]
  var l = ((2 - hsv.s / 100) * hsv.v) / 2;

  // store the HSL components
  const hsl = {
    h: hsv.h,
    s: (hsv.s * hsv.v) / (l < 50 ? l * 2 : 200 - l * 2),
    l: l,
  };

  // correct a division-by-zero error
  if (isNaN(hsl.s)) hsl.s = 0;
  if (isNaN(hsl.l)) hsl.l = 0;
  console.log("test");
  console.log(`${hsl.h}, ${hsl.s}%, ${value}%`);
  return `${hsl.h}, ${hsl.s}%, ${value === 0 ? 0 : hsl.l}%`;
}

export default function HSLSliders({ hsl, setHSL, color, setColor }) {
  const handleHueChange = (e) => {
    setHSL(calculateHSL({ h: e.target.value, s: color.hsv.s, v: color.hsv.v }));
    // setHSL({h: e.target.value, s: hsl.s, l: hsl.l})
    setColor(
      toColor("hsv", { h: e.target.value, s: color.hsv.s, v: color.hsv.v })
    );
  };

  const handleSaturationChange = (e) => {
    // setHSL({h: hsl.h, s: e.target.value, l: hsl.l})
    setHSL(calculateHSL({ h: hsl.h, s: e.target.value, v: color.hsv.v }));
    setColor(
      toColor("hsv", { h: color.hsv.h, s: e.target.value, v: color.hsv.v })
    );
  };

  const handleLuminosityChange = (e) => {
    // setHSL({h: hsl.h, s: hsl.s, l: e.target.value})
    setHSL(calculateHSL({ h: color.hsv.h, s: color.hsv.s, v: e.target.value }));
    setColor(
      toColor("hsv", { h: color.hsv.h, s: color.hsv.s, v: e.target.value })
    );
  };
  const {t}=useTranslation();

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "",
        borderRadius: 10,
        height: "auto",
        justifyContent: "space-between",
      }}
    >
      <div>
        <div style={{ textAlign: "left", fontSize: "16px",marginLeft:"10px" }}>{t('Hue')}</div>
        <HueSlider
          value={color.hsv.h}
          style={{
            marginTop: 10,
            border: "2px solid white",
            backgroundImage:
              "linear-gradient( to right, rgb(255, 0, 0), rgb(255, 255, 0), rgb(0, 255, 0), rgb(0, 255, 255), rgb(0, 0, 255), rgb(255, 0, 255), rgb(255, 0, 0) )",
          }}
          onChange={handleHueChange}
          aria-labelledby="hue-slider"
          min={0}
          max={360}
        />
      </div>
      <div>
        <div style={{ textAlign: "left", fontSize: "16px",marginLeft:"10px" }}>{t('Saturation')}</div>
        <HueSlider
          value={color.hsv.s}
          style={{
            marginTop: 10,
            border: "2px solid white",
            backgroundImage: linearGradientCompute2(color.hsv),
          }}
          onChange={handleSaturationChange}
          aria-labelledby="saturation-slider"
          min={0}
          max={100}
        />
      </div>
      <div>
        <div style={{ textAlign: "left", fontSize: "16px" ,marginLeft:"10px"}}>{t('Brightness')} </div>
        <HueSlider
          value={color.hsv.v}
          style={{
            marginTop: 10,
            border: "1px solid white",
            backgroundImage: linearGradientCompute(color.hsv),
          }}
          onChange={handleLuminosityChange}
          aria-labelledby="luminosity-slider"
          min={0}
          max={100}
        />
      </div>
    </div>
  );
}
