import React, { useState, useEffect } from "react";
import axios from "axios";
import Slider from "react-slick";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  FormControl,
  MenuItem,
  Select,
  Button,
  TextField,
} from "@mui/material";
import { URLDayl } from "../../../services/constantes";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { toast } from "react-toastify";
import DownloadIcon from "@mui/icons-material/Download";
function CustomNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ChevronRightIcon
      className={`${className} carousel-painting-arrows-custom-next`}
      style={{
        ...style,
        color: "white",
        fontSize: 60,
        position: "absolute",
        top: "50%",
        right: "-30px",
        transform: "translateY(-50%)",
      }}
      onClick={onClick}
    />
  );
}

function CustomPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ChevronLeftIcon
      className={`${className} carousel-painting-arrows-custom-prev`}
      style={{
        ...style,
        color: "white",
        fontSize: 60,
        position: "absolute",
        top: "50%",
        left: "-30px",
        transform: "translateY(-50%)",
      }}
      onClick={onClick}
    />
  );
}

export default function AnalysisSection({
  selectedvalues,
  refetchTrigger,
  setRefetchTrigger,
  auth,
  colors,
}) {
  const [currentTab, setCurrentTab] = useState("Mes requêtes");
  const [requets, setRequets] = useState([]);
  const [images, setImages] = useState([]);
  const [analyses, setAnalyses] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [newRequest, setNewRequest] = useState({ title: "", content: "" });

  useEffect(() => {
    if (auth.data && currentTab === "Mes requêtes") {
      fetchRequests();
    }
  }, [currentTab, selectedvalues, auth, refetchTrigger]);

  useEffect(() => {
    if (currentTab === "Mes analyses") {
      fetchAnalyses();
    }
  }, [currentTab, auth.data.id]);

  useEffect(() => {
    if (currentTab === "Mes analyses") {
      fetchAllAnalyses();
    }
  }, [currentTab]);

  const fetchRequests = () => {
    axios
      .get(`${URLDayl}colorimetrie/request/getByUserId/${auth.data.id}`)
      .then((response) => {
        setRequets(response.data);
        fetchImages(response.data);
      })
      .catch((error) => console.error("Error fetching requests", error));
  };

  const fetchImages = (requests) => {
    const fetchImages = requests.map((request) =>
      axios
        .get(`${URLDayl}images/color/request/${request.id}`, {
          responseType: "blob",
        })
        .then((imageResponse) => URL.createObjectURL(imageResponse.data))
        .catch((error) => {
          console.error("Error fetching image for request", error);
          return null;
        })
    );

    Promise.all(fetchImages).then((images) => {
      setImages(images.filter((image) => image !== null));
    });
  };

  const fetchAnalyses = () => {
    axios
      .get(`${URLDayl}colorimetrie/analyse/getByUserId/${auth.data.id}`)
      .then((response) => {
        setAnalyses(response.data);
      })
      .catch((error) => console.error("Error fetching analyses", error));
  };

  const fetchAllAnalyses = () => {
    axios
      .get(`${URLDayl}colorimetrie/analyse/getAll`)
      .then((response) => {
        setAnalyses(response.data);
      })
      .catch((error) => console.error("Error fetching analyses", error));
  };

  const handleDeleteRequest = (requestId) => {
    axios
      .delete(`${URLDayl}color/deleteById/${requestId}`)
      .then(() => {
        toast.success("Request deleted successfully", { autoClose: 2000 });
        fetchRequests();
      })
      .catch((error) => {
        console.error("Error deleting the request:", error);
        toast.error("Failed to delete the request");
      });
  };

  const handleRequestChange = (field) => (event) => {
    setNewRequest({ ...newRequest, [field]: event.target.value });
  };

  const handleSubmitRequest = () => {
    const data = {
      paintingId: selectedRequest.painting.id,
      userId: selectedRequest.user.id,
      sat: [selectedRequest.sat],
      lumi: [selectedRequest.lumi],
      colors: [selectedRequest.colors],
      content: newRequest.content,
    };

    axios
      .put(`${URLDayl}color/request/update`, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        setSelectedRequest(null);
        setNewRequest({ title: "", content: "" });
      })
      .catch((error) => console.error("Error creating analysis", error));
  };

  const sliderSettings = {
    infinite: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    speed: 500,
    slidesToShow: Math.min(images.length, 3),
    slidesToScroll: Math.min(images.length, 3),
  };

  return (
    <div className="flex flex-col mt-5">
      <div className="mt-4 ml-10">
        <FormControl>
          <Select
            value={currentTab}
            onChange={(e) => setCurrentTab(e.target.value)}
            displayEmpty
          >
            <MenuItem value="Mes requêtes">Mes requêtes</MenuItem>
            <MenuItem value="Mes analyses">Mes analyses</MenuItem>
            <MenuItem value="Mes études">Mes études</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="mt-4">
        {currentTab === "Mes requêtes" && (
          <div className="m-auto flex flex-col w-full">
            <div className="w-full bg-black p-5">
              <Slider
                {...sliderSettings}
                className="w-10/12 m-auto"
                key={images.length}
              >
                {images.map((url, index) => (
                  <div
                    key={index}
                    className="h-[400px] w-auto px-2.5 box-border flex justify-center items-center"
                  >
                    <img
                      src={url}
                      alt="Request"
                      onClick={() => setSelectedRequest(requets[index])}
                      className={`max-h-96 w-auto cursor-pointer object-fit ${
                        selectedRequest?.id === requets[index]?.id
                          ? "border-2"
                          : "border-2"
                      } border-white border-solid`}
                    />
                    <div className="flex mt-2 items-center gap-2">
                      <a
                        href={url}
                        download={`image_${index + 1}.jpg`}
                        className="mt-0 pt-0 text-white rounded"
                      >
                        <DownloadIcon />
                      </a>
                      <div className="flex items-center">
                        {requets[index].colors
                          .split(",")
                          .slice(0, 6)
                          .map((color, idx) => (
                            <div
                              key={idx}
                              style={{
                                width: "20px",
                                height: "20px",
                                borderRadius: "50%",
                                backgroundColor: colors.find(
                                  (c) => c.name === color
                                )?.hex,
                                border: "1px solid black",
                                marginRight: "5px",
                              }}
                            />
                          ))}
                        {requets[index].colors.split(",").length > 6 && (
                          <span className="ml-1 text-xl text-white">...</span>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
            {/* {selectedRequest && (
              <div className="mb-10 w-10/12 m-auto">
                <TextField
                  label="Content"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  margin="normal"
                  value={newRequest.content}
                  onChange={handleRequestChange("content")}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmitRequest}
                >
                  Save Request
                </Button>
                <div
                  onClick={() => handleDeleteRequest(selectedRequest.id)}
                  className="border border-black p-5 w-fit mt-5 cursor-pointer"
                >
                  Delete
                </div>
              </div>
            )} */}
          </div>
        )}
        {currentTab === "Mes analyses" && (
          <div> {/* Render analyses tab content here */} </div>
        )}
        {currentTab === "Mes études" && (
          <div> {/* Render studies tab content here */} </div>
        )}
      </div>
    </div>
  );
}
