import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { URLDayl } from "../../services/constantes";
import { Link } from "react-router-dom";
import { set } from "react-hook-form";
import { toast } from "react-toastify";
import { useUser } from "../../contexts/userService";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

/**
 * This component contain your list of painting list
 * @param {object} user - user object from api
 * @param {object} setUser - user setter
 * @emits - userLists/save : create new list
 * @emits - userLists/byUserId : get user lists
 * @emits - userLists/deleteById?listId=${listId} : delete selected list
 */

export default function Lists({ user, setUser, friend }) {
  const [listList, setListList] = useState([]);
  const [listTitle, setListTitle] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [publicList, setPublicList] = useState(true);
  const auth = useUser();
  const [toggleState, setToggleState] = useState(0);

  const handleToggle = (event, newState) => {
    if (newState !== null) {
      setToggleState(newState);
    }
  };
  //Post new list to the API//
  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(
        URLDayl + "userLists/save",
        {
          listName: listTitle,
          rank: 0,
          userId: user.id,
          id: 0,
          status: publicList ? "PUBLIC" : "PRIVATE",
          detail: "",
          imageUrl: "",
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setRefreshKey((oldKey) => oldKey + 1);

        toast.success("Nouvelle liste ajoutée!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  //Get User List//
  useEffect(() => {
    axios
      .get(URLDayl + "userLists/byUserId", {
        params: { userId: user.id },
        headers: { Authorization: `Bearer ${auth.data.jwt}` },
      })
      .then((response) => {
        setListList(response.data);
        const boolArray = response.data.map((el) => {
          return { ...el, bool: false };
        });
        setListList(boolArray);
      });
  }, [refreshKey]);

  //Delete user list//
  const deleteList = (listId) => {
    axios
      .delete(URLDayl + `userLists/deleteById?listId=${listId}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setListList(response.data);
        setRefreshKey((oldKey) => oldKey + 1);

        toast.success("Liste supprimée!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const style = {
    overlay: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backdropFilter: "blur(10px) brightness(1)",
      zIndex: 10000,
      borderRadius: "20px",
    },
    content: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "0px",
      zIndex: 10,
      padding: "20px",
      height: "100%",
      justifyContent: "start",
    },
  };

  //afficher modale suppression//
  const handleBoolListTrue = (index) => {
    const tempBool = [...listList];

    tempBool[index].bool = true;
    setListList(tempBool);
  };

  //cacher modale suppression//
  const handleBoolListFalse = (index) => {
    const tempBool = [...listList];

    tempBool[index].bool = false;
    setListList(tempBool);
  };

  return (
    <div className="profileContainer">
      <div className="shadowBox" style={{ minHeight: "300px" }}>
        <div className="Lists">
          <div
            style={{
              display: "flex",
              width: "80%",
              justifyContent: "end",
              margin: "auto",
              marginTop: "20px",
              alignItems: "center",
            }}
          >
            <ToggleButtonGroup
              value={toggleState}
              exclusive
              onChange={handleToggle}
              aria-label="lock toggle"
            >
              <Tooltip title={"Privée"} arrow placement="top">
                <ToggleButton value={-1} aria-label="locked" color="error">
                  <LockOutlinedIcon style={{ color: "black" }} />
                </ToggleButton>
              </Tooltip>

              <Tooltip title={"Tout"} arrow placement="top">
                <ToggleButton
                  value={0}
                  aria-label="neutral"
                  style={{ color: "black" }}
                >
                  ALL
                </ToggleButton>
              </Tooltip>
              <Tooltip title={"Publique"} arrow placement="top">
                <ToggleButton value={1} aria-label="unlocked" color="success">
                  <LockOpenOutlinedIcon style={{ color: "black" }} />
                </ToggleButton>
              </Tooltip>
            </ToggleButtonGroup>
          </div>
          {/* <!-- créer une nouvelle liste - fin --> */}

          <div className="accountArtists ">
            {listList.length > 0 &&
              listList
                .filter((list) => {
                  if (toggleState === -1) return list.status === "PRIVATE";
                  if (toggleState === 1) return list.status === "PUBLIC";
                  return true;
                })
                .map((list, index) => (
                  <div
                    className="item"
                    style={{
                      backgroundImage: `url(${URLDayl}images/paintingPic/${list.imageUrl})`,
                    }}
                  >
                    <div className="name">{list.listName}</div>
                    <div className="items-count">{list.countItems} oeuvres</div>
                    <Link
                      className="discover"
                      to={`../../view/ListeAmateurPage.js/${list.id}`}
                      state={list.id}
                    ></Link>

                    <input
                      type="button"
                      className="unfollow"
                      onClick={() => handleBoolListTrue(index)}
                      value=""
                    />

                    {list.bool && (
                      <div className="deleteConfirm">
                        <span className="deleteText">Supprimer ?</span>
                        <button
                          type="button"
                          className="yes closeTwoParentsNow"
                          onClick={() => deleteList(list.id)}
                          value=""
                          style={{ marginRight: "10px", marginLeft: "10px" }}
                        >
                          Oui
                        </button>
                        <button
                          type="button"
                          className="no closeParentNow"
                          onClick={() => handleBoolListFalse(index)}
                          value=""
                        >
                          Non
                        </button>
                      </div>
                    )}
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
}
