import React, { useEffect, useState } from "react";
import "../webapp/css/output.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { URLDayl } from "../services/constantes";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ContactForm from "../component/ContactFrom/ContactForm";
import HeroCarousel from "../component/HomePage/HeroCarousel";
import Header from "../component/HomePage/Header";
import ArtSection from "../component/HomePage/ArtSection";
import YouSection from "../component/HomePage/YouSection";
import LoveSection from "../component/HomePage/LoveSection";
export default function HomePage({ user, setUser, isLogged }) {
  const [paintIdMatch, setPaintIdMatch] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const handleClick = (path, state = null) => {
    navigate(path, { state });
  };

  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);

  useEffect(() => {
    const fetchRandomPainting = async () => {
      try {
        const response = await axios.get(
          URLDayl + "paintingFront/randomPaintingsOptimised?maxInt=1"
        );
        const paintIdMatch = response.data[0].id;
        setPaintIdMatch(paintIdMatch);
      } catch (error) {
        console.error(
          "There was an error fetching the random painting: ",
          error
        );
      }
    };
    fetchRandomPainting();
  }, []);

  return (
    <div className="w-full flex flex-col">
      <Header setIsOpen={() => setIsOpen(true)} />
      <HeroCarousel
        user={user}
        isLogged={isLogged}
        setUser={setUser}
        paintIdMatch={paintIdMatch}
        handleClick={handleClick}
      />
      <ArtSection handleClick={handleClick} />
      <YouSection handleClick={handleClick} />
      <ContactForm isOpen={isOpen} setIsOpen={setIsOpen} />
      <LoveSection />
    </div>
  );
}
