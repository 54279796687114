import React, { useEffect, useState } from "react";
import { InstagramEmbed } from "react-social-media-embed";
import { URLDayl } from "../../../services/constantes";
import axios from "axios";

function OurDaylNews() {
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(`${URLDayl}instagram/permalinks`);
        const slicedPosts = response.data.slice(0, 10);
        setPosts(slicedPosts);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchPosts();
  }, []);

  return (
    <div className="container mx-auto px-4">
      {error && <p className="text-red-500 text-center my-4">{error}</p>}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {posts.map((post, index) => (
          <div key={index} className="w-full">
            <InstagramEmbed url={post} width="100%" />
          </div>
        ))}
      </div>
      <div className="text-center mt-8 mb-4">
        <a
          href="https://www.instagram.com/discoverartyoulove"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:text-blue-800 underline"
        >
          Discover{" "}
          <i>
            <b>More </b>
          </i>
          {"  "}
          Art You Love on Instagram
        </a>
      </div>
    </div>
  );
}

export default OurDaylNews;
