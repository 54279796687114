import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import ThemeWrapper from './themes/ThemeWrapper';

// Function to dynamically load Matomo script
const loadMatomoScript = () => {
  const script = document.createElement('script');
  script.async = true;
  script.src = 'https://analytics.swatics.eu/js/container_SGBaus0H.js';
  document.head.appendChild(script);

  const mtmScript = document.createElement('script');
  mtmScript.innerHTML = `
    var _mtm = window._mtm = window._mtm || [];
    _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
  `;
  document.head.appendChild(mtmScript);
};

// Check if the environment is production
if (process.env.NODE_ENV === 'production') {
  loadMatomoScript();
}

ReactDOM.render(
  <BrowserRouter>
    <ThemeWrapper>
      <App />
    </ThemeWrapper>
  </BrowserRouter>,
  document.getElementById('root')
);
