import React, {createContext, useContext, useEffect, useState} from 'react';

//Create the Auth Context with the data type specified
//and a empty object
const CompteExpertContext = createContext({});

const CompteExpertProvider = ({children}) => {
    const [language, setLanguage] = useState("")
    const [artistPageMode, setArtistPageMode] = useState(false)

    return (
        <CompteExpertContext.Provider value={{language, setLanguage, artistPageMode, setArtistPageMode}}>
            {children}
        </CompteExpertContext.Provider>
    );
};

//A simple hooks to facilitate the access to the AuthContext
// and permit components to subscribe to AuthContext updates
function useCompteExpert() {
    const context = useContext(CompteExpertContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }

    return context;
}

export {CompteExpertContext, CompteExpertProvider, useCompteExpert};