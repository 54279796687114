import React, { useEffect, useState } from "react";
import axios from "axios";
import { URLDayl } from "../../services/constantes";
import { useUser } from "../../contexts/userService";
import { Box, Button, Modal, Stack, Switch, Typography } from "@mui/material";
import CustomTextField from "../general/SearchBars/CustomTextField";
import { Divider } from "antd";
import {
  Cancel as CancelIcon,
  LockOpenOutlined,
  LockOutlined,
  Save,
  Search as SearchIcon,
} from "@mui/icons-material";
import { list } from "postcss";

/**
 * This component display informations about this list
 * @param {object} user - user object from api
 * @param {number} paintID - id of the list
 * @emits - userLists/detailByListId?listId=${paintId} : get list informations
 * @emits - userLists/update : update list informations
 */

const styles = {
  header: {
    justifyContent: "space-between",
    paddingTop: 30,
    paddingBottom: 20,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
    paddingLeft: 50,
    paddingRight: 50,
  },
  browse: {
    // paddingLeft: 50,
    fontWeight: "bold",
    fontSize: 20,

    textDecoration: "underline",
    textUnderlineOffset: 3,
  },
  padding: {
    padding: 50,
    paddingTop: 0,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    gap: 20,
  },
  title: {
    padding: 10,
    fontWeight: "bold",
    fontSize: 16,
  },
};

export default function DisplayInfoList({ paintId, user }) {
  const [listInfo, setListInfo] = useState([]);
  const [templistInfo, setTempListInfo] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const auth = useUser();
  const [open, setOpen] = useState(false);
  const [publicList, setPublicList] = useState(true);
  const [tempName, setTempName] = useState("");
  const [tempDesc, setTempDesc] = useState("");

  //get List info//
  useEffect(() => {
    axios
      .get(URLDayl + `userLists/detailByListId?listId=${paintId}`)
      .then((response) => {
        setListInfo(response.data.userListDTO);
        setTempListInfo(response.data.userListDTO);
        setPublicList(response.data.userListDTO.status === "PUBLIC");
        setTempName(response.data.userListDTO.listName);
        setTempName(response.data.userListDTO.description);
      });
  }, [refreshKey]);

  // Call Jquery script
  useEffect(() => {
    const loadFile = async () => {
      const { modal } = await import("../../webapp/js/js_source/modalBox");

      modal();
    };
    loadFile();
  }, []);

  //TODO
  const postListInfo = (e) => {
    e.preventDefault();

    axios
      .post(URLDayl + "userLists/update", {
        id: paintId,
        listName: tempName === "" ? listInfo.listName : tempName,
        detail: tempDesc === "" ? listInfo.detail : tempDesc,
        status: publicList ? "PUBLIC" : "PRIVATE",
      })
      .then((response) => {
        setRefreshKey((oldKey) => oldKey + 1);
      });
  };
  return (
    <div className="DisplayInfoList">
      {/* <!-- SECTION PRESENTATION DE LA LISTE - début --> */}
      <div className="cont-sty-5" style={{ paddingBottom: "0" }}>
        <section className="center-col">
          <div className=" flex w-full justify-end pr-20">
            <Button variant={"contained"} onClick={() => setOpen(!open)}>
              Edit{" "}
            </Button>
          </div>
          <div className="cont-l-w  flex items-center center-col max-w-4xl">
            <h1 className="mb-a text-3xl font-raleway">{listInfo.listName}</h1>
            <p className="mb-a text-lg font-raleway">{listInfo.detail}</p>

            <Modal
              open={open}
              onClose={() => {
                setOpen(false);
                setTempDesc("");
                setTempName("");
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  borderRadius: 3,
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 600,
                  bgcolor: "background.paper",
                  border: "2px solid #000",
                  boxShadow: 24,
                  paddingLeft: 3,
                  paddingRight: 3,
                  // p: 4,
                }}
              >
                <div>
                  <div style={styles.header}>
                    <div style={styles.browse}>Edit list</div>
                    <div
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 10,
                      }}
                    >
                      {publicList ? (
                        <div className="text-lg justify-center items-center">
                          PUBLIC <LockOpenOutlined />
                        </div>
                      ) : (
                        <div className="text-lg justify-center items-center">
                          PRIVATE <LockOutlined />
                        </div>
                      )}

                      <Switch
                        checked={publicList}
                        onChange={(e) => {
                          setPublicList(!publicList);
                        }}
                      ></Switch>
                    </div>
                  </div>
                  <div style={styles.padding}>
                    <div>
                      <CustomTextField
                        title={"Name of list :"}
                        defaultValue={listInfo.listName}
                        placeholder=""
                        onChange={(e) => setTempName(e.target.value)}
                      />
                      <CustomTextField
                        title={"Description"}
                        defaultValue={listInfo.detail}
                        maxRows={6}
                        minRows={4}
                        multiline
                        onChange={(e) => setTempDesc(e.target.value)}
                        placeholder="Add an optional description"
                        height={400}
                      />

                      <Divider />
                      <Stack
                        direction="row"
                        spacing={10}
                        mt={1}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setOpen(false);
                            setTempName("");
                            setTempDesc("");
                          }}
                          startIcon={<CancelIcon />}
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={(e) => {
                            postListInfo(e);
                            setOpen(false);
                            // onSubmit();

                            // handleClose()
                          }}
                          variant="contained"
                          endIcon={<Save />}
                        >
                          Save
                        </Button>
                      </Stack>
                    </div>
                  </div>
                </div>
              </Box>
            </Modal>

            {/* <!-- Modal modification de la liste - début --> */}
            {/*{   <div className="smart-modal">*/}
            {/*    <a className=" smart-modal-open modifyList" data-target="modifyList" rel="nofollow">*/}
            {/*        <img src={edit} alt="Éditer mon profil"/>*/}
            {/*    </a>*/}

            {/*    <div id="modifyList" className="smart-modal-content scrollAuto">*/}
            {/*        <h3>Modifier la liste</h3>*/}
            {/*        <a className="smart-modal-close"><img className="img-left" src={close} alt="Trier"/></a>*/}
            {/*        <form className="modalForm" onSubmit={postListInfo}>*/}
            {/*            <label for="listName" className="mt-b">Nom de la liste</label>*/}
            {/*            <input type="text" id="listName" size="35" value={templistInfo?.listName}*/}
            {/*                   onChange={e => setTempListInfo({...templistInfo, listName: e.target.value})}*/}
            {/*                   required/>*/}
            {/*            <label for="listDesc">Description de la liste</label>*/}
            {/*            <textarea id="listDesc" className="mb-b" maxlength="255"*/}
            {/*                      value={templistInfo?.detail} onChange={e => setTempListInfo({*/}
            {/*                ...templistInfo,*/}
            {/*                detail: e.target.value*/}
            {/*            })}/>*/}
            {/*            <input type="submit" className="submit mt-b smart-modal-close"*/}
            {/*                   value="Enregistrer les modifications"*/}
            {/*                   style={{position: "relative", right: "0", top: "0"}}/>*/}
            {/*        </form>*/}
            {/*    </div>*/}

            {/*</div>}*/}
            {/* <!-- Modal modification de la liste - fin --> */}
          </div>
        </section>
      </div>
      {/* <!-- SECTION PRESENTATION DE LA LISTE - fin --> */}
    </div>
  );
}
