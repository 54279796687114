import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CustomTextField from "../general/SearchBars/CustomTextField";

const AutoCompleteCustom = React.forwardRef(
  (
    {
      placeholder,
      title = "",
      onSelect,
      onChange,
      options,
      value,
      className,
      customWidth = 200,
      allowFreeInput = false,
      custom = false,
      label = true,
      isOptionEqualToValue,
    },
    ref
  ) => {
    const handleOnChange = (e, value) => {
      onSelect(e, value);
    };

    const handleOnInputChange = (e, value) => {
      onChange(e, value);
    };

    return (
      <Autocomplete
        className={`${className}`}
        style={{ width: customWidth }}
        color="black"
        autoComplete={true}
        onChange={handleOnChange}
        onInputChange={handleOnInputChange}
        options={options || []}
        value={value}
        getOptionLabel={(option) => {
          if (typeof option === "string") return option;
          return option.name || option.movement || "";
        }}
        isOptionEqualToValue={
          isOptionEqualToValue ||
          ((option, value) => option.name === value?.name)
        }
        freeSolo={allowFreeInput}
        renderInput={(params) =>
          custom ? (
            <CustomTextField
              {...params}
              ref={ref}
              title={title}
              width={customWidth}
              placeholder={placeholder}
            />
          ) : label ? (
            <TextField {...params} inputRef={ref} label={placeholder} />
          ) : (
            <TextField
              {...params}
              inputRef={ref}
              placeholder={placeholder}
              label={""}
            />
          )
        }
      />
    );
  }
);

export default AutoCompleteCustom;
