import Slider from "react-slick";
import { URLDayl, URLDaylImage } from "../../services/constantes";
import { useUser } from "../../contexts/userService";
import axios from "axios";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import ModalCommentTopPaintings from "./ModalCommentTopPaintings";

function CustomNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ChevronRightIcon
      className={`${className} carousel-painting-arrows-custom-next`}
      style={{ ...style, color: "white", fontSize: 60 }}
      onClick={onClick}
    />
  );
}

function CustomPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ChevronLeftIcon
      className={`${className} carousel-painting-arrows-custom-prev`}
      style={{ ...style, color: "white", fontSize: 60 }}
      onClick={onClick}
    />
  );
}

export default function TopPaintingsCarousel({
  paintings,
  refreshKey,
  setRefreshKey,
  deleteMode,
}) {
  const auth = useUser();

  const [modalComment, setModalComment] = useState(false);
  const [commentedPaintingId, setCommentedPaintingId] = useState();

  useEffect(() => {
    if (modalComment === false) setCommentedPaintingId();
  }, [modalComment]);

  const onDelete = (id) => {
    axios
      .delete(URLDayl + "top_paintings_movement/deleteTopPaintingById", {
        params: { id: id },
      })
      .then((res) => {
        setRefreshKey(refreshKey + 1);
      });
  };

  const handleCommentButtonClick = (id) => {
    setCommentedPaintingId(id);
    setModalComment(true);
    console.log("Comment button clicked");
  };

  const handleDeleteButtonClick = (id) => {
    onDelete(id);
    console.log("Delete button clicked");
  };

  var settings = {
    infinite: true,
    speed: 500,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 750,
        settings: {
          slidesToShow: Math.min(paintings.length, 1),
          slidesToScroll: Math.min(paintings.length, 1),
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: Math.min(paintings.length, 2),
          slidesToScroll: Math.min(paintings.length, 2),
        },
      },
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: Math.min(paintings.length, 3),
          slidesToScroll: Math.min(paintings.length, 3),
        },
      },
      {
        breakpoint: 1550,
        settings: {
          slidesToShow: Math.min(paintings.length, 4),
          slidesToScroll: Math.min(paintings.length, 4),
        },
      },
      {
        breakpoint: 1850,
        settings: {
          slidesToShow: Math.min(paintings.length, 5),
          slidesToScroll: Math.min(paintings.length, 5),
        },
      },
      {
        breakpoint:2000,
        settings: {
          slidesToShow: Math.min(paintings.length, 6),
          slidesToScroll: Math.min(paintings.length, 6),
        },
      },
    ],
  };

  return (
    <div className="MostMat ched">
      <section className="center-col cont-sty-1">
        <div className="carousel-container">
          <Slider {...settings}>
            {paintings.length > 0 &&
              paintings.map((matchPaint, index) => (
                <div className="carousel-slide" key={index}>
                  <div
                    className="carousel-card"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      className="bt-wrapper"
                      style={{
                        backgroundImage: `url(${URLDaylImage}images/paintingPic/${matchPaint.painting.id})`,
                      }}
                    >
                      <div className="overlay">
                        <div className="carousel-title">
                          {matchPaint.painting.aw_title_origin}
                          <div className="date">
                            {matchPaint.painting.aw_year}
                          </div>
                        </div>
                        <div className="carousel-action">
                          <button
                            className="bt-action voir"
                            onClick={() =>
                              handleCommentButtonClick(matchPaint.painting.id)
                            }
                          >
                            comment
                          </button>
                          <button
                            className="bt-action match"
                            onClick={() =>
                              handleDeleteButtonClick(matchPaint.painting.id)
                            }
                          >
                            delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </Slider>
        </div>
      </section>
      {modalComment && <ModalCommentTopPaintings topPaintingId={commentedPaintingId} setModal={setModalComment}/>}
    </div>
  );
  
}
