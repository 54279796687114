import React, {useEffect, useState} from "react";
import {actions} from "../ArtistStatement/ArtistStatementCompteExpert";
import BiographieElement from "../../ArtistDetailPage/BiographieElement";
import {stringSplitting} from "../../../services/Utils";
import ModalArtistStatement from "../ArtistStatement/ModalArtistStatement";
import {enumContenu} from "../PeintresCompteExpert";
import axios from "axios";
import {URLDayl} from "../../../services/constantes";
import {toast} from "react-toastify";
import ArtworkModalTag from "../ArtworkModalTag/ArtworkModalTag";
import {useCompteExpert} from "../../../contexts/CompteExpertService";
import {Pagination} from "@mui/material";

const NUM_PER_PAGE = 10

export const AvisExperts = ({user, artist}) => {
    const [publications, setPublications] = useState();
    const [action, setAction] = useState(actions.Create);
    const [modal, setModal] = useState(false);
    const [focusedPublication, setFocusedPublication] = useState();
    const [refreshKey, setRefreshKey] = useState(0);
    const [images, setImages] = useState();
    const [artworkModal, setArtworkModal] = useState(false);
    const [taggedArtwork, setTaggedArtwork] = useState([]);
    const [page, setPage] = useState(1)
    const [totalCountExpertPov, setTotalCountExpertPov] = useState(0)

    const compteExpert = useCompteExpert();

    useEffect(() => {
        if (!artist)
            toast.error("Please select an artist", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
    }, [artist]);

    const handlePageChange = (event, value) => {

        setPage(value);
        window.scrollTo(0, 60);

    };

    const deletePost = (id) => {
        axios.get(URLDayl + `artist/avisExpert/${id}`).then((res) => {
            for (let i = 0; i < res.data.images.length; i++) {
                axios.delete(
                    URLDayl +
                    `images/removeAvisExpertImage?publicationId=${id}&imageName=${res.data.images[i]}&artistId=${artist.id}`
                );
            }
        });

        axios.delete(URLDayl + `artist/avisExpert/${id}`).then((res) => {
            toast.success("Post deleted", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setRefreshKey(refreshKey + 1);
            setPublications(undefined);
        });
    };

    const hidePost = (item) => {
        axios.post(URLDayl + "artist/avisExpert/changeStatus", {
            idItem: item.id,
            newStatus: item.status === "DRAFT" ? "HIDDEN" : "DRAFT"
        }).then((res) => {
            setRefreshKey(refreshKey + 1)
            toast.success("Status du contenu modifié", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }).catch((err) => {
            toast.error("Error", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        })
    }

    useEffect(async () => {
      await axios
          .get(
              URLDayl +
              `artist/avisExpert/byArtistId/${artist.id}?language=${compteExpert.language}&start=${page - 1}&end=${NUM_PER_PAGE}&hidden=${true}`
          )
          .then((res) => {
            const doubleTempImages = [];

            for (let i = 0; i < res.data.length; i++) {
              const tempImages = [];
              for (let j = 0; j < res.data[i].images.length; j++) {
                if (res.data[i].images[j] !== "")
                  tempImages.push(
                      URLDayl +
                      `images/avisExpert/${artist.id}/${res.data[i].id}?name=${res.data[i].images[j]}`
                  );
              }
              for (let k = 0; k < res.data[i].taggedArtworks.length; k++)
                tempImages.push(
                    URLDayl + `images/paintingPic/${res.data[i].taggedArtworks[k]}`
                );
              doubleTempImages.push(tempImages);
            }
            setImages(doubleTempImages);
            setPublications(res.data);
          });

      await axios.get(URLDayl + `artist/countAvisExpert/byArtistId/${artist.id}`).then((res) => {
        setTotalCountExpertPov(res.data)
      })

    }, [artist.id, refreshKey, modal, page]);

    return (
        <div>
            <div>
                <input
                    type="submit"
                    className="saveDraftBtn"
                    value="Create avis expert"
                    onClick={() => {
                        setModal(!modal);
                        setAction(actions.Create);
                    }}
                />
            </div>

            <div style={{marginTop: 50}}>
                {publications && images && publications.length > 0 ? (
                    publications.map((el, index) => {
                        return (
                            <>
                                <div
                                    style={{marginTop: "50px", width: "80%", margin: "auto"}}
                                >
                                    <BiographieElement
                                        artist={"artist"}
                                        bg={"bg"}
                                        text={el ? stringSplitting(950, el.content) : ""}
                                        direction={index % 2 === 0 ? "left" : "right"}
                                        title={el.title}
                                        imageList={images[index]}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "center",
                                        gap: "50px",
                                    }}
                                >
                                    <div>
                                        <input
                                            type="reset"
                                            className="saveDraftBtn "
                                            value="Modifer"
                                            onClick={() => {
                                                setFocusedPublication(el);
                                                setAction(actions.Modifiy);
                                                setModal(true);
                                            }}
                                        />
                                    </div>
                                    <input
                                        type="reset"
                                        className="saveDraftBtn"
                                        value={el.status === "DRAFT" ? "CACHER" : "AFFICHER"}
                                        onClick={() => {
                                            hidePost(el)
                                        }}
                                    />
                                    <div>
                                        <input
                                            type="submit"
                                            className="saveDraftBtn"
                                            value="Supprimer"
                                            onClick={() => {
                                                // setBoolModal(false)
                                                deletePost(el.id);
                                            }}
                                        />
                                    </div>
                                </div>
                            </>
                        );
                    })
                ) : (
                    <></>
                )}

                {totalCountExpertPov / NUM_PER_PAGE > 1 && (
                <Pagination
                    count={Math.ceil(totalCountExpertPov / NUM_PER_PAGE)} // Calculate the total number of pages
                    page={page}
                    style={{alignSelf: "center", padding: "20px", justifyContent: "center"}}
                    sx={{justifyContent: "center"}}
                    variant="outlined"
                    className="paginationCentered"
                    onChange={handlePageChange} // Add the event handler for page change
                />
                )}

                {modal && (
                    <ModalArtistStatement
                        artist={artist}
                        refreshKey={refreshKey}
                        setRefreshKey={setRefreshKey}
                        user={user}
                        modePeinture={false}
                        publication={
                            action === actions.Modifiy && focusedPublication
                                ? focusedPublication
                                : publications
                        }
                        action={action}
                        setBoolModal={setModal}
                        contenu={enumContenu.AvisExperts}
                        setArtworkModal={setArtworkModal}
                        selected={taggedArtwork}
                        setSelected={setTaggedArtwork}
                    />
                )}

                {artworkModal && (
                    <ArtworkModalTag
                        artist={artist}
                        user={user}
                        setBoolModal={setArtworkModal}
                        selected={taggedArtwork}
                        setSelected={setTaggedArtwork}
                    />
                )}
            </div>
        </div>
    );
};
