import close from "../../webapp/img/icons/ico_close_2.svg";
import React, { useEffect, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { URLDayl } from "../../services/constantes";
import axios from "axios";
import { toast } from "react-toastify";
import { useCompteExpert } from "../../contexts/CompteExpertService";

export default function ModalEditMouvement({
  setModal,
  placeholder,
  type,
  id,
}) {
  const compteExpert = useCompteExpert();
  const [comment, setComment] = useState(placeholder);

  const [data, setData] = useState({
    id: id,
    presentation: "",
    characteristiques: "",
    topPaintings: "",
    presentation_fr: "",
    characteristiques_fr: "",
    topPaintings_fr: "",
    movement: "",
    dateCreation: "",
    dateModification: "",
    author: "",
  });

  useEffect(() => {
    let overlay = document.createElement("div");

    Object.assign(overlay.style, {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      zIndex: 1,
    });
    document.body.appendChild(overlay);
    document.body.style.overflow = "hidden";
    return () => {
      document.body.removeChild(overlay);
      document.body.style.overflow = "unset";
    };
  }, []);

  const submit = async () => {
    var dataSend = {};

    if (compteExpert.language === "FRENCH") {
      if (type === "PRES")
        dataSend = {
          id: id,
          presentation: "",
          characteristiques: "",
          topPaintings: "",
          presentation_fr: comment,
          characteristiques_fr: "",
          topPaintings_fr: "",
          movement: "",
          dateCreation: "",
          dateModification: "",
          author: "",
        };
      else {
        dataSend = {
          id: id,
          presentation: "",
          characteristiques: "",
          topPaintings: "",
          presentation_fr: "",
          characteristiques_fr: comment,
          topPaintings_fr: "",
          movement: "",
          dateCreation: "",
          dateModification: "",
          author: "",
        };
      }
    } else {
      if (type === "PRES")
        dataSend = {
          id: id,
          presentation: comment,
          characteristiques: "",
          topPaintings: "",
          presentation_fr: "",
          characteristiques_fr: "",
          topPaintings_fr: "",
          movement: "",
          dateCreation: "",
          dateModification: "",
          author: "",
        };
      else {
        dataSend = {
          id: id,
          presentation: "",
          characteristiques: comment,
          topPaintings: "",
          presentation_fr: "",
          characteristiques_fr: "",
          topPaintings_fr: "",
          movement: "",
          dateCreation: "",
          dateModification: "",
          author: "",
        };
      }
    }

    axios
      .post(URLDayl + "movement/update", dataSend)
      .then((res) => {
        toast.success("Success", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        toast.error("Error", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <div className="ModalNetwork">
      {/* <!-- MODAL - DÉBUT --> */}
      <div
        className="smart-modal-overlay"
        style={{ display: "block", zIndex: 4 }}
      ></div>

      <div
        className="smart-modal-content-l scrollAuto"
        style={{
          transform: "translate(-50%,-50%)",
          display: "inline-flex",
          flexWrap: "wrap",
          alignContent: "flex-start",
          zIndex: 5,
        }}
      >
        <h3>Modifier le mouvement </h3>
        <a
          className="smart-modal-close"
          onClick={(e) => {
            setModal(false);
            document.body.style.overflow = "unset";
          }}
        >
          <img className="img-left" src={close} alt="Fermer" />
        </a>
        <TextareaAutosize
          minRows={5}
          className="main-text"
          placeholder={"saisie du texte"}
          value={comment}
          onChange={(e) => {
            setComment(e.target.value);
          }}
        />
        <div>
          <input
            type="reset"
            className="saveDraftBtn"
            value="Enregistrer"
            style={{ width: "100%" }}
            onClick={() => {
              submit();
              setModal(false);
              document.body.style.overflow = "unset";
            }}
          />
        </div>
      </div>
    </div>
  );
}
