import React, { useEffect, useState, useRef } from "react";
import { URLDaylImage } from "../../../services/constantes";
import Lightbox from "../Lightbox/Lightbox";
import NoPostYet from "../../SocialMedia/NoPostYet";
import GridHeader from "./GridHeader";
import PaintingItem from "./PaintingItem";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

export const enumTypeGrid = {
  ArtistPaintingsGrid: 0,
};

export default function ImageGrid({
  user,
  isLogged,
  paintings,
  showTools,
  selectionMode,
  selected,
  setSelected,
  maxNumPainting,
  setMaxNumPainting,
  totalMaxNum,
  orderDate,
  setOrderDate,
  year,
  setYear,
  matchingType,
  setMatchingType,
  mutlipleSelection = false,
  header = true,
  autoScroll = true,
  s = 6,
  fixed = false,
  isHidden = false,
}) {
  const [size, setSize] = useState(s);
  const [index, setIndex] = useState();
  const [isAutoScrollEnabled, setIsAutoScrollEnabled] = useState(autoScroll);
  const [listMatch, setListMatch] = useState(paintings);
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const loadFile = async () => {
      const { modal } = await import("../../../webapp/js/js_source/modalBox");

      const { grid } = await import("../../../webapp/js/gridActions.min.js");

      modal();
      grid();
    };
    loadFile();
  }, [paintings]);

  useEffect(() => {
    setListMatch(paintings);
  }, [paintings]);

  const horizontalScrollContainerStyle = {
    display: "flex",
    flexDirection: "row",
    overflowX: "auto",
    whiteSpace: "nowrap",
    gap: "12px",
    scrollBehavior: "smooth",
  };

  let globalIndex = 0;

  const columns = [];
  let paintingsPerColumn;
  switch (size) {
    case 4:
      paintingsPerColumn = 2;
      break;
    case 6:
      paintingsPerColumn = 3;
      break;
    case 2:
      paintingsPerColumn = 1;
      break;
    default:
      paintingsPerColumn = 2;
  }

  for (let i = 0; i < paintings.length; i += paintingsPerColumn) {
    columns.push(paintings.slice(i, i + paintingsPerColumn));
  }

  const scrollPaintingsContainer = () => {
    const scrollAmount = window.innerWidth * 0.8;
    scrollContainerRef.current.scrollLeft += scrollAmount;
  };

  return paintings.length > 0 ? (
    <div className="">
      <div className="">
        {header && (
          <GridHeader
            year={year}
            setYear={setYear}
            orderDate={orderDate}
            setOrderDate={setOrderDate}
            setSize={setSize}
            isAutoScrollEnabled={isAutoScrollEnabled}
            setIsAutoScrollEnabled={setIsAutoScrollEnabled}
            matchingType={matchingType}
            setMatchingType={setMatchingType}
          />
        )}

        <div className="flex h-[80vh] items-center bg-black">
          <div className="flex-1 overflow-hidden" style={{ padding: "30px 0" }}>
            <div
              style={horizontalScrollContainerStyle}
              ref={scrollContainerRef}
            >
              {columns.map((column, columnIndex) => (
                <div
                  key={columnIndex}
                  className="gridContent flex flex-col gap-3"
                >
                  {column.map((painting, paintingIndex) => (
                    <PaintingItem
                      key={painting.id}
                      listPaint={painting}
                      index={globalIndex++}
                      setIndex={setIndex}
                      selected={selected}
                      setSelected={setSelected}
                      showTools={showTools}
                      isLogged={isLogged}
                      size={fixed ? 6 : size}
                      URLDaylImage={URLDaylImage}
                      selectionMode={selectionMode}
                      multipleSelection={mutlipleSelection}
                      user={user}
                      isHidden={isHidden}
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>

          {totalMaxNum > maxNumPainting && (
            <div
              className="flex flex-col justify-center items-center gap-5 bg-black text-white cursor-pointer text-2xl hover-effect-div-black h-full flex-shrink-0"
              onClick={() => {
                setMaxNumPainting(maxNumPainting + 30);
                scrollPaintingsContainer();
              }}
              style={{ width: "150px" }}
            >
              <div>
                <AddCircleOutlineIcon style={{ fontSize: "50px" }} />
              </div>
              D'oeuvres
            </div>
          )}
        </div>
      </div>

      <Lightbox
        title={"yes"}
        isLogged={isLogged}
        user={user}
        index={index}
        setIndex={setIndex}
        matchList={listMatch}
      />
    </div>
  ) : (
    <div>
      <NoPostYet title={"No results "} />
    </div>
  );
}
