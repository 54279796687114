import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import GetAppIcon from "@mui/icons-material/GetApp";
export default function Result({
  paintId,
  setBoolModal,
  URLDaylImage,
  media,
  imageUrl,
  opacity,
  grayValue,

  handleSaveRequest,
  auth,
  colors,
  selectedColors,
  painting,
}) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: "10px",
        width: "50%",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "auto",
          justifyContent: "space-evenly",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: "white",
              display: "flex",
              gap: "5px",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "15px",
            }}
          >
            <div
              style={{
                justifySelf: "center",
                fontSize: "25px",
                color: "black",
                fontFamily: "raleway",
              }}
            >
              Choissisez la peinture de votre choix :
            </div>

            <ImageSearchIcon
              className="cursor-pointer text-2xl text-black"
              fontSize="large"
              onClick={() => setBoolModal(true)}
            />
          </div>
          <div
            className="shadow-2xl"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
              marginBottom: "20px",
              border: "5px solid black",
              width: "fit-content",
            }}
          >
            {paintId !== -1 ? (
              <img
                src={`${URLDaylImage}images/paintingPic/${paintId}`}
                alt={`Painting ${paintId}`}
                style={{
                  opacity: opacity,
                  position: "relative",
                  maxHeight: "60vh",
                  width: "auto",
                  zIndex: "2",
                }}
              />
            ) : (
              media && (
                <img
                  src={URL.createObjectURL(media)}
                  alt="Uploaded"
                  style={{
                    opacity: opacity,
                    position: "relative",
                    maxHeight: "60vh",
                    width: "auto",
                    zIndex: "2",
                  }}
                />
              )
            )}
            {imageUrl && (
              <img
                src={imageUrl}
                style={{
                  position: "absolute",
                  zIndex: "2",
                  maxHeight: "60vh",
                  width: "auto",
                }}
                alt="Analysis Result"
              />
            )}
            <div
              style={{
                position: "absolute",
                backgroundColor: `rgb(${grayValue}, ${grayValue}, ${grayValue})`,
                height: "100%",
                width: "auto",
                zIndex: "1",
              }}
            ></div>
          </div>
          <div className="flex justify-between">
            <div className="flex gap-2 mt-2">
              {imageUrl &&
                selectedColors.slice(0, 5).map((color, idx) => (
                  <div
                    key={idx}
                    style={{
                      width: "20px",
                      height: "20px",
                      borderRadius: "50%",
                      backgroundColor: colors.find((c) => c.name === color).hex,
                      border: "1px solid black",
                    }}
                  />
                ))}
              {imageUrl && selectedColors.length > 5 && <div>...</div>}
              {imageUrl && (
                <>
                  <button
                    onClick={() => {
                      const resultImageLink = document.createElement("a");
                      resultImageLink.href = imageUrl;
                      resultImageLink.download = "result_image.png";
                      resultImageLink.click();
                      fetch(`${URLDaylImage}images/paintingPic/${paintId}`)
                        .then((response) => response.blob())
                        .then((blob) => {
                          const url = URL.createObjectURL(blob);
                          const baseImageLink = document.createElement("a");
                          baseImageLink.href = url;
                          baseImageLink.download = "base_image.png";
                          document.body.appendChild(baseImageLink);
                          baseImageLink.click();
                          document.body.removeChild(baseImageLink);
                        })
                        .catch((error) => {
                          console.error("Error fetching base image:", error);
                        });
                    }}
                    style={{ height: "fit-content" }}
                  >
                    <GetAppIcon style={{ marginRight: "5px" }} />
                  </button>
                </>
              )}
            </div>
            <div className="flex gap-5 items-center justify-center">
              {painting && painting.artist && (
                <div className="flex flex-col items-end text-lg">
                  <div style={{ fontFamily: "raleway", fontStyle: "italic" }}>
                    {painting.aw_title_origin}, {painting.aw_year}
                  </div>
                  <p style={{ fontFamily: "raleway", fontStyle: "italic" }}>
                    {painting.artist.artist_name_tot}
                  </p>
                </div>
              )}
              {auth.data && (
                <div
                  onClick={() => handleSaveRequest()}
                  className="cursor-pointer rounded p-1 px-2 border-2 border-black h-fit"
                >
                  SAVE
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
