import React from "react";
import Artist from "../component/ArtistDetailPage/Artist";
import { useParams } from "react-router-dom";
import { useUser } from "../contexts/userService";

/**
 * This component display artist Page
 * @param {object} user - user object from api
 * @param {object} setUser - user setter
 *  @param {boolean} isLogged - test if the user is logged
 * @param {number} paintId - selected artist Id
 */

export default function ArtistDetailpage({ user, setUser }) {
  const auth = useUser();

  const { id } = useParams();

  return (
    <div>
      <Artist
        paintId={id}
        user={user}
        setUser={setUser}
        isLogged={auth.data ? true : false}
      />
    </div>
  );
}
