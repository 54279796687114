import React from "react";
import { useParams } from "react-router-dom";
import OpusPage from "../../view/OpusPage";

function OpusPageWrapper({ user, setUser, isLogged, setPaintId }) {
  const { id } = useParams();

  return (
    <OpusPage
      paintId={id}
      setPaintId={setPaintId}
      user={user}
      setUser={setUser}
      isLogged={isLogged}
    />
  );
}

export default OpusPageWrapper;
