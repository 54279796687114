import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import { URLDayl } from "../services/constantes";
import SocialNetworkPostList from "../component/CompteAmateurPage/remodal/SocialNetworkPostList";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import AutoCompleteCustom from "../component/PaintingSearch/AutoCompleteCustom";

export default function SearchByHashtag() {
  const { hashtag } = useParams();
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const [hashtagSearch, setHashtagSearch] = useState([]);
  const [searchedTextHashtag] = useState("");

  const onSelectHashtagInput = (e, item) => {
    navigate(`../../view/SearchByHashtag.js/${item.name.substring(1)}`);
  };

  const useStyles = makeStyles({
    userSearchBar: {
      "& .MuiOutlinedInput-root": {
        backgroundColor: "white",
        borderRadius: 10,
        borderBottom: "1px solid #d9d9d9",
        width: "210px",
        paddingBottom: "1px",
      },
      "& .MuiOutlinedInput-input": {
        padding: "5px 12px",
        border: "none",
        width: "200px",
        fontSize: "20px",
        fontFamily: "raleway",
        textAlign: "center",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .MuiOutlinedInput-notchedOutline::focus": {
        border: "1px",
      },
      "& .Mui-focused": {
        backgroundColor: "white",
        borderBottom: "1px solid #d9d9d9",
      },
    },
  });

  const classes = useStyles();

  const onChangeHashtagInput = async (e, searchedtext) => {
    setHashtagSearch([]);
    if (searchedtext) {
      const response = await axios.get(
        URLDayl +
          `publication/searchPrefixByHashtag?prefix=%23${searchedTextHashtag}`
      );
      const data = response.data.map((item, index) => ({
        name: item,
        id: index,
      }));
      setHashtagSearch(data);
    }
  };
  useEffect(() => {
    axios
      .get(URLDayl + `publication/byHashtag?hashtag=${hashtag}`)
      .then((res) => {
        setPosts(res.data);
      });
  }, [hashtag]);

  return (
    <div
      style={{
        width: "80%",
        display: "flex",
        marginTop: "100px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <div>
        <Button
          variant=""
          color={"primary"}
          sx={{
            border: "none",
            borderBottom: "2px solid",
            borderColor: "primary.main",
            borderRadius: "0px",
          }}
          onClick={() => {
            navigate("../../view/compteAmateurPage.js");
          }}
        >
          Revenir sur mon compte
        </Button>
      </div>
      <div
        className="shadowBox"
        style={{ minHeight: "300px", marginLeft: "100px", width: "70%" }}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
              position: "sticky",
              top: 70,
              backdropFilter: "blur(2px)",
              zIndex: 100,
              height: "60px",
            }}
          >
            <AutoCompleteCustom
              label={false}
              className={classes.userSearchBar}
              onSelect={onSelectHashtagInput}
              onChange={onChangeHashtagInput}
              options={hashtagSearch}
              defaultValue={{ name: "#" + hashtag, value: hashtag }}
            />
          </div>
          {
            <div className="socialTabs">
              <SocialNetworkPostList posts={posts} />
            </div>
          }
        </div>
      </div>
    </div>
  );
}
