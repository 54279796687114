import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { URLDayl, URLDaylImage } from "../../services/constantes";
import { toast } from "react-toastify";
import ListPaintArtist from "./ListPaintArtist";
import SimilarArtist from "./SimilarArtist";
import Biography from "./Biography";
import AvisExpert from "./AvisExpert";
import { useUser } from "../../contexts/userService";
import SingleSlideCarousel from "../general/SingleSlideCarousel";
import ArtistBox from "./AritistBox";
import Painting from "../OpusPage/Painting";
import { is } from "@react-spring/shared";

/**
 * Display information about the artist and child components in tabs
 * @emits - artist/byIdtf/{idtf} : get painter informations
 * @emits - artistLiked/add(remove)FavoriteArtist/ : follow/unfollow artist
 * @emits - users/{id} : refresh user info
 * @param {object} user - user object from api
 * @param {object} setUser - user setter
 * @param {boolean} isLogged - test if the user is logged
 * @param {number} paintId - selected artist Id
 */
export default function Artist({ user, setUser, paintId, isLogged }) {
  const [artist, setArtist] = useState([]);
  const [bg, setBg] = useState("");
  const [refreshKey, setRefreshKey] = useState(0);
  const [boolLike, setBoolLike] = useState(false);
  const [isActive, setIsActive] = useState(1);
  const [TopPaint, setTopPaint] = useState([]);
  const [artistStatements, setArtistStatements] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [boxes, setBoxes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isLoadingArtist, setIsLoadingArtist] = useState(true);
  const isHidden = artist?.hideArtist;

  useEffect(() => {
    setIsLoadingArtist(true);
    axios.get(URLDayl + `artist/${paintId}`).then((response) => {
      setArtist(response.data);
      setBoxes(response.data.artist_style.filter((el) => el !== ""));
      setIsLoadingArtist(false);
    });
  }, [paintId, editModal]);

  useEffect(() => {
    axios
      .get(URLDayl + `artist/artistStatementsByArtistId?artistId=${paintId}`)
      .then((res) => {
        setArtistStatements(res.data);
      });
  }, [paintId]);

  useEffect(() => {
    if (user && artist) {
      for (let i = 0; i < user.artistLiked.length; i++) {
        if (user.artistLiked[i] === artist.id) {
          setBoolLike(true);
        }
      }
    }
  }, [artist]);

  useEffect(() => {
    if (!isLoadingArtist && artist.artist_idtf_intern) {
      axios
        .get(
          URLDayl +
            `paintingSearch/getTopPaintingsFromArtist?artist_idtf_intern=${artist.artist_idtf_intern}`
        )
        .then((res3) => {
          setTopPaint(res3.data);
        });
    }
  }, [artist.artist_idtf_intern, isLoadingArtist]);

  //Follow/Unfollow l'artiste//
  function follow() {
    if (user && artist) {
      if (boolLike === false) setBoolLike(true);
      else setBoolLike(false);

      if (user) {
        if (boolLike === false)
          axios
            .get(
              URLDayl + `artistLiked/addFavoriteArtist/${user.id}/${artist.id}`,
              {},
              {
                headers: {
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                },
              }
            )
            .then((response) => {
              setBoolLike(true);
            });
        else
          axios
            .get(
              URLDayl +
                `artistLiked/deleteFavoriteArtist/${user.id}/${artist.id}`,
              {},
              {
                headers: {
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                },
              }
            )
            .then((response) => {
              setBoolLike(false);

              console.log(response.data);
            });
      }
    } else {
      toast.warning("Connectez-vous pour suivre cet artiste!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    //refresh user
    if (user)
      axios
        .get(URLDayl + `users/${user.id}`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        })
        .then((response2) => {
          setUser(response2.data);
        });

    setRefreshKey((oldKey) => oldKey + 1);
  }
  const handleHelloButtonClick = () => {
    setShowModal(true);
  };

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showModal]);

  const auth = useUser();
  return (
    <div className="Artist">
      <section className="center-col">
        {/* TOP BG ARTISTE - DÉBUT */}
        <div
          className="cont-f-w artistBg"
          style={{
            backgroundImage: `url(${URLDaylImage}images/artistPic/${paintId})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover",
          }}
        ></div>
        {/* TOP BG ARTISTE - FIN */}
        <ArtistBox
          artist={artist}
          boolLike={boolLike}
          follow={follow}
          TopPaint={TopPaint}
          artistStatements={artistStatements}
          user={user}
          boxes={boxes}
          setShowModal={setShowModal}
          editModal={editModal}
          setEditModal={setEditModal}
        />
      </section>

      <div
        className="bg-black"
        style={{
          width: "100%",
        }}
      >
        <div>
          <SingleSlideCarousel paintings={TopPaint} artistName={false} />
        </div>
      </div>
      {/* TABS CONTENUS ARTISTE - DÉBUT */}
      <div className="w-full">
        <div className="flex items-center justify-center   mx-auto mt-10 mb-10">
          <div
            className={` flex justify-center align-middle p-10 border-solid border-black border-2 text-lg ${isActive === 1 ? "bg-black text-white" : "text-black bg-gray-200"} cursor-pointer`}
            onClick={() => setIsActive(1)}
            style={{ minWidth: "300px", minHeight: "100px" }}
          >
            OEUVRES
          </div>

          <div
            className={`flex justify-center align-middle  p-10 border-solid border-black border-2 text-lg ${isActive === 2 ? "bg-black text-white" : "text-black bg-gray-200"} cursor-pointer`}
            onClick={() => setIsActive(2)}
            style={{ minWidth: "300px", minHeight: "100px" }}
          >
            ELEMENTS DE BIOGRAPHIE
          </div>
          <div
            className={`flex justify-center align-middle  p-10 border-solid border-black border-2 text-lg ${isActive === 3 ? "bg-black text-white" : "text-black bg-gray-200"} cursor-pointer`}
            onClick={() => setIsActive(3)}
            style={{ minWidth: "300px", minHeight: "100px" }}
          >
            AVIS D'EXPERT{" "}
          </div>
        </div>
      </div>
      {/* TABS CONTENUS ARTISTE - FIN */}

      {isActive === 1 && (
        <div style={{ zIndex: 1 }}>
          <ListPaintArtist
            paintId={paintId}
            user={user}
            setUser={setUser}
            isLogged={isLogged}
            isHidden={isHidden}
          />

          <SimilarArtist paintId={paintId} />
        </div>
      )}
      {isActive === 2 && (
        <div>
          <Biography bg={bg} artist={artist} />
        </div>
      )}
      {isActive === 3 && (
        <div>
          <AvisExpert artist={artist} user={user} />
        </div>
      )}
      {isActive === 4 && <div></div>}
    </div>
  );
}
