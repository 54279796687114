import React from "react";
import { Link } from "react-router-dom";
import Fav from "../Fav";
import ListModal2 from "../ListModal2";
import { URLDaylImage } from "../../../services/constantes";

const PaintingItem = ({
  listPaint,
  index,
  setIndex,
  selected,
  setSelected,
  showTools,
  isLogged,
  size = 6,
  selectionMode,
  multipleSelection,
  handleClick,
  user,
  customSize,
  displayYear = true,
  isHidden = false,
}) => {
  const handleSelection = (paintId) => {
    if (selected.includes(paintId)) {
      setSelected(selected.filter((id) => id !== paintId));
    } else if (multipleSelection) {
      setSelected([...selected, paintId]);
    } else {
      setSelected([paintId]);
    }
  };

  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  };

  const getDimensions = () => {
    if (customSize) {
      return { height: customSize, width: customSize };
    }
    return {
      height: size === 4 ? "35vh" : size === 6 ? "23vh" : "65vh",
      width: size === 4 ? "35vh" : size === 6 ? "23vh" : "65vh",
    };
  };

  const dimensions = getDimensions();
  return (
    <div key={index} className="item" style={dimensions}>
      <div
        className={`base ${isHidden ? "blur-lg" : ""}`}
        style={{
          backgroundImage: `url(${URLDaylImage}images/paintingPic/${listPaint.id})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        {showTools && (
          <div className="toolBoxPainting">
            <button
              id="itemCommentState"
              className="eye yes"
              type="button"
              onClick={() => setIndex(index)}
            />
            <Fav user={user} isLogged={isLogged} favPaint={listPaint} />
            <div style={{ position: "absolute", right: "37px" }}>
              <ListModal2
                user={user}
                isLogged={isLogged}
                listPaint={listPaint}
                tooltip={false}
                styleButton={{
                  color: "white",
                  zIndex: "3",
                  fontSize: "30px",
                }}
              />
            </div>
          </div>
        )}
      </div>
      {selectionMode && selected.includes(listPaint.id) && (
        <div
          className="shadowOverlay"
          onClick={() => handleSelection(listPaint.id)}
        >
          <div className="revealShadow"></div>
        </div>
      )}
      <div
        className="hidden"
        onClick={() => selectionMode && handleSelection(listPaint.id)}
      >
        <div className="revealShadow"></div>
        <h3 className="itemArtist">{listPaint.artisteName}</h3>
        <h4 className="itemName">
          {listPaint.name ? truncateString(listPaint.name, 15) : null}
        </h4>
        {displayYear && <div className="itemYear">{listPaint.year}</div>}
        <ul className="hListGridItem">
          <li>
            <div className="smart-modal">
              <Link
                className="GridviewItem"
                onClick={handleClick}
                to={`../../view/OpusPage.js/${listPaint.id}`}
                state={listPaint.id}
              >
                Voir
              </Link>
            </div>
          </li>
          {showTools && (
            <li>
              <Link
                className="GridmakeMatch"
                to={`../view/MatchingPage.js/${listPaint.id}`}
              >
                match
              </Link>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default PaintingItem;
