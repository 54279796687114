import React, { useEffect, useRef, useState } from "react";
import ImageGrid from "../component/general/ImageGrid/ImageGrid";
import { useUser } from "../contexts/userService";
import axios from "axios";
import { URLDayl } from "../services/constantes";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";

export const MatchTypeSelector = ({ matchType, setMatchType }) => {
  const options = ["STYLE", "COLOR", "CONTENT"];

  return (
    <div className="flex justify-center items-center space-x-2 text-xl font-raleway">
      {options.map((option, index) => (
        <span
          key={option}
          className={`cursor-pointer ${matchType === option ? "font-bold" : ""}`}
          onClick={() => setMatchType(option)}
        >
          {option}
          {index < options.length - 1 && " / "}
        </span>
      ))}
    </div>
  );
};

export default function MatchingImage2() {
  const [selectedFile, setSelectedFile] = useState();
  const [filePreview, setFilePreview] = useState();

  const [paintings, setPaintings] = useState([]);
  const [maxNumPainting, setMaxNumPainting] = useState(0);
  const [matchType, setMatchType] = useState("STYLE");
  const [loading, setLoading] = useState(false);
  const [matchings, setMatchings] = useState();
  const auth = useUser();
  const changeHandler = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const previewUrl = URL.createObjectURL(file);
      setFilePreview(previewUrl);
    }
  };

  useEffect(() => {
    if (matchings) {
      switch (matchType.value) {
        case "STYLE":
          setPaintings(matchings.STYLE);
          break;
        case "COLOR":
          setPaintings(matchings.COLOR);
          break;
        case "CONTENT":
          setPaintings(matchings.CONTENT);
          break;
        default:
          setPaintings(matchings.STYLE);
      }
    }
  }, [matchType, matchings]);

  const handleMatchClick = async () => {
    if (!selectedFile) {
      toast.error("Please select a file first.");
      return;
    }
    setLoading(true);
    var formData = new FormData();
    formData.append("image", selectedFile);
    try {
      const response = await axios.post(
        URLDayl + `images/submitImageForMatching`,
        formData
      );
      setMatchings(response.data.mapOfMatch);
      setPaintings(response.data.mapOfMatch.STYLE);
    } catch (error) {
      toast.error("Error uploading file");
    } finally {
      setLoading(false);
    }
  };

  const fileInputRef = useRef(null);

  return (
    <div style={{ marginTop: 0 }}>
      <div className="flex flex-col w-full gap-10 mb-10">
        <div className="flex w-full">
          <div className="flex justify-center items-center text-3xl p-11 bg-black text-white w-2/5">
            MATCHEZ VOS OEUVRES
          </div>
          <div className="flex justify-center items-center bg-gray-300 text-black text-xl p-5 w-3/5">
            Vous avez des photos de certaines de vos œuvres ? <br /> Vous avez
            pris en photo des œuvres lors d’une exposition ?<br /> - <br />
            LANCEZ UN MATCH ET CONFRONTER VOTRE PHOTO A L’HISTOIRE DE L’ART
          </div>
        </div>
        <div className=" flex w-full justify-between min-h-96">
          <div className="flex flex-col justify-center items-center w-1/4 gap-5">
            <div className="text-xl italic">
              Choisissez une photo sur votre ordinateur
            </div>
            <div
              onClick={() => fileInputRef.current.click()}
              className="text-2xl p-5 border-2 border-solid border-black cursor-pointer"
            >
              Upload File
            </div>
            <input
              type="file"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={changeHandler}
            />
          </div>

          {filePreview ? (
            <img
              src={filePreview}
              alt="Preview"
              className="max-h-[50vh] max-w-[40vw] w-auto border-2 border-solid border-black"
            />
          ) : (
            <div className="flex w-[40vw] border-2 border-solid border-black justify-center items-center text-lg">
              Nous acceptons les formats Png et Jpg <br />
              D’une taille max de 1mb
            </div>
          )}

          <div
            className="flex flex-col bg-black w-1/4 text-white  text-2xl cursor-pointer justify-center items-center hover-effect-div-black"
            onClick={handleMatchClick}
          >
            Lancer un <b>MATCH</b>
          </div>
        </div>
      </div>
      {paintings && paintings.length > 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            flexDirection: "column",
          }}
        >
          <ImageGrid
            user={auth.data}
            isLogged={auth.data !== undefined}
            paintings={paintings}
            showTools={true}
            selectionMode={false}
            maxNumPainting={maxNumPainting}
            setMaxNumPainting={setMaxNumPainting}
            matchingType={matchType}
            setMatchingType={setMatchType}
          />
        </div>
      ) : (
        loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )
      )}
    </div>
  );
}
