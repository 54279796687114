import React, { useEffect, useState } from "react";
import axios from "axios";
import { useUser } from "../contexts/userService";
import { useTranslation } from "react-i18next";
import ImageGrid from "../component/general/ImageGrid/ImageGrid";
import SearchBarPaintings from "../component/general/SearchBarPaintings";
import { URLDayl } from "../services/constantes";
import SingleSlideCarousel from "../component/general/SingleSlideCarousel";
import ArtMovementsDisplay from "../component/DiscoverMovements/ArtMovementsDisplay";

export default function PaintingSearch() {
  const [paintings, setPaintings] = useState([]);
  const [topPaintings, setTopPaintings] = useState([]);
  const [maxNumPainting, setMaxNumPainting] = useState(40);
  const [totalNumPainting, setTotalNumPainting] = useState(0);
  const [refreshKey, setRefreshKey] = useState(0);

  const [searchDTO, setSearchDTO] = useState(
    JSON.parse(sessionStorage.getItem("searchBarPaintingPage") || "{}")
  );
  const auth = useUser();
  const { t } = useTranslation();
  const [isSearched, setIsSearched] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [selectedMovement, setSelectedMovement] = useState(null);

  useEffect(() => {
    if (!isSearched) {
      axios
        .get(`${URLDayl}paintingFront/randomPaintingsOptimised`, {
          params: { maxInt: maxNumPainting },
        })
        .then((res) => {
          setPaintings(res.data.reverse());
        });
    } else {
      axios
        .post(
          URLDayl + `paintingSearch/findPaintingBySearchDtoOptimised`,
          searchDTO,
          {
            params: { maxInt: maxNumPainting },
          }
        )
        .then((res) => {
          setPaintings(res.data.payload);
          setTotalNumPainting(res.data.numberMax);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearched, maxNumPainting, refreshKey]);

  useEffect(() => {
    if (selectedMovement) {
      axios
        .get(`${URLDayl}top_paintings_movement/paintingsByMovement`, {
          params: { movementId: selectedMovement.id },
        })
        .then((res) => {
          const formattedTopPaintings = res.data.map((el) => ({
            id: el.painting.id,
            artistId: el.painting.artist.id,
            artisteName: el.painting.artist.artist_name_tot,
            year: el.painting.aw_year,
            name: el.painting.aw_title_origin,
          }));
          setTopPaintings(formattedTopPaintings);
        });
    } else {
      setTopPaintings([]);
    }
  }, [selectedMovement]);

  const onSubmit = () => {
    sessionStorage.setItem("searchBarPaintingPage", JSON.stringify(searchDTO));
    axios
      .post(
        URLDayl + `paintingSearch/findPaintingBySearchDtoOptimised`,
        searchDTO,
        { params: maxNumPainting }
      )
      .then((res) => {
        setPaintings(res.data.payload);
        setTotalNumPainting(res.data.numberMax);
        setHasSearched(true);
      });
    setIsSearched(true);
    setRefreshKey(refreshKey + 1);
  };
  return (
    <div>
      <div className="bg-black">
        <h1
          style={{
            textAlign: "center",
            textTransform: "uppercase",
            fontFamily: "Cinzel",
            paddingTop: 70,
            paddingBottom: 0,
            fontWeight: "normal",
            color: "white",
            height: "100%",
            fontSize: "30px",
          }}
        >
          {t("Search_Paintings")}
        </h1>

        <SearchBarPaintings
          onSubmit={onSubmit}
          searchDTO={searchDTO}
          setSearchDTO={setSearchDTO}
        />
      </div>
      {hasSearched && paintings.length === 0 && (
        <div className="text-center py-10">
          <h2 className="text-2xl font-bold text-gray-700">No Results Found</h2>
          <p className="text-gray-500 mt-2">
            Try adjusting your search criteria
          </p>
        </div>
      )}
      {paintings.length > 0 && (
        <ImageGrid
          user={auth.data}
          isLogged={auth.data ? true : false}
          paintings={paintings}
          showTools={true}
          selectionMode={false}
          totalMaxNum={totalNumPainting}
          maxNumPainting={maxNumPainting}
          setMaxNumPainting={setMaxNumPainting}
        />
      )}
      <ArtMovementsDisplay
        flipcards={true}
        onClick={(el) => setSelectedMovement(el)}
      />

      <div className="mt-10">
        {selectedMovement && topPaintings.length > 0 && (
          <div>
            <div className="m-5 text-2xl font-bold font-raleway">
              {`Top Paintings for: ${selectedMovement.movement}`.toUpperCase()}
            </div>
            <div
              className="bg-black"
              style={{
                width: "100%",
              }}
            >
              <div>
                <SingleSlideCarousel
                  paintings={topPaintings}
                  artistName={false}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
