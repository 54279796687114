import NoPhotographyIcon from "@mui/icons-material/NoPhotography";

const styles = {
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    gap: 20,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    padding: 10,
    height: "60vh",
  },
};

export default function NoPostYet({ title = "No posts yet" }) {
  return (
    <div style={styles.container}>
      <NoPhotographyIcon style={{ fontSize: 60 }} />
      <h1 style={{}}>{title}</h1>
    </div>
  );
}
