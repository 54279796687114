import React, { useState } from "react";
import PaintingItem from "./PaintingItem";
import { URLDaylImage } from "../../../services/constantes";


const SmallGrid = ({ paintingsList, user, isLogged, grid3 = true,customSize }) => {
  let globalIndex = 0;
  const [index, setIndex] = useState();
  const itemSize = `calc(64vh / 3 - 10px)`;




  return (
    <div className={`gridContent items ${grid3 ? "grid3" : "grid4"}`}>
      {paintingsList.length > 0 &&
        paintingsList
          .slice(0, grid3 ? 9 : 12)
          .map((match, index) => (
            <PaintingItem
              key={match.id}
              listPaint={match}
              index={globalIndex++}
              setIndex={setIndex}
              showTools={false}
              isLogged={isLogged}
              URLDaylImage={URLDaylImage}
              user={user}
              customSize={customSize?customSize:itemSize}
              displayYear={false}
            />
          ))}
    </div>
  );
};

export default SmallGrid;
