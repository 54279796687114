import React, { useState, useRef } from "react";
import { useLocation } from "react-router";
import { URLDaylImage } from "../services/constantes";
import "../webapp/css/matchingColor.css";
import { useEffect } from "react";
import axios from "axios";
import { URLDayl } from "../services/constantes";
import AnalysisSection from "../component/Colorimetrie/CustomRequests/AnalysisSection";
import { MediaContext } from "../contexts/ColorimetrieUpload";
import { useContext } from "react";
import SelectPaintingModal from "../component/general/SelectPaintingModal/SelectPaintingModal";
import { useNavigate } from "react-router-dom";
import { useUser } from "../contexts/userService";
import ColorSelection from "../component/Colorimetrie/CustomRequests/ColorSelection";
import Result from "../component/Colorimetrie/CustomRequests/Result";
import Sliders from "../component/Colorimetrie/CustomRequests/Sliders";

export default function Requests() {
  const auth = useUser();
  const userId = auth.data.id;
  const location = useLocation();
  const paintId = location.state;
  const [boolModal, setBoolModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const navigate = useNavigate();
  const { setMedia } = useContext(MediaContext);

  const [loading, setLoading] = useState(false);
  const [painting, setPainting] = useState([]);
  const [opacity, setOpacity] = useState(1);
  const [selectedValues, setSelectedValues] = useState({});

  const [grayValue, setGrayValue] = useState(0);
  const primaryColors = ["Rouge", "Jaune", "Bleu"];
  const secondaryColors = ["Vert", "Orange", "Violet"];
  const [selectedColors, setSelectedColors] = useState([]);
  const [luminosity, setLuminosity] = useState([]);
  const [saturation, setSaturation] = useState([]);

  const [primaryColorsSelected, setPrimaryColorsSelected] = useState(false);
  const [secondaryColorsSelected, setSecondaryColorsSelected] = useState(false);
  const isAnyColorSelected = selectedColors.length > 0;
  const [imageUrl, setImageUrl] = useState(null);
  const textareaRef = useRef(null);
  const { media } = useContext(MediaContext);
  const [refetchTrigger, setRefetchTrigger] = useState(false);

  const colors = [
    { name: "Rouge", hex: "#FF0000" },
    { name: "Rouge Orange", hex: "#FF4000" },
    { name: "Orange", hex: "#FF8000" },
    { name: "Orange Jaune", hex: "#FFBF00" },
    { name: "Jaune", hex: "#FFFF00" },
    { name: "Jaune Vert", hex: "#BFFF00" },
    { name: "Vert", hex: "#00FF00" },
    { name: "Cyan", hex: "#00FFFF" },
    { name: "Bleu", hex: "#0000FF" },
    { name: "Bleu Violet", hex: "#8000FF" },
    { name: "Violet", hex: "#FF00FF" },
    { name: "Violet Rouge", hex: "#FF0080" },
    { name: "Blanc", hex: "#FFFFFF" },
    { name: "Gris Claire", hex: "#D3D3D3" },
    { name: "Gris", hex: "#A9A9A9" },
    { name: "Gris Fonce", hex: "#A9A9A9" },
    { name: "Gris Tres Fonce", hex: "#696969" },
    { name: "Noir", hex: "#000000" },
  ];

  const handleOpacityChange = (e) => {
    setOpacity(e.target.value / 100);
  };

  const handleGrayChange = (e) => {
    setGrayValue(Math.floor((255 * e.target.value) / 100));
  };
  const handleColorSelection = (color, isCheckbox = false) => {
    if (isCheckbox) {
      let newSelectedColors = [];
      if (primaryColors.includes(color)) {
        newSelectedColors = primaryColorsSelected ? [] : primaryColors;
        setPrimaryColorsSelected(!primaryColorsSelected);
        setSecondaryColorsSelected(false);
      } else {
        newSelectedColors = secondaryColorsSelected ? [] : secondaryColors;
        setSecondaryColorsSelected(!secondaryColorsSelected);
        setPrimaryColorsSelected(false);
      }
      setSelectedColors(newSelectedColors);
    } else {
      setPrimaryColorsSelected(false);
      setSecondaryColorsSelected(false);

      if (selectedColors.includes(color)) {
        setSelectedColors((prevColors) =>
          prevColors.filter((c) => c !== color)
        );
      } else {
        setSelectedColors((prevColors) => [...prevColors, color]);
      }
    }
  };
  const handleSaveRequest = () => {
    const dataToSend = {
      ...selectedValues,
      userId: userId,
    };

    const apiUrl = `${URLDayl}color/analysis/save`;
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(apiUrl, dataToSend, { headers })
      .then((response) => {
        console.log("Save successful");
      })
      .catch((error) => {
        console.error("Save error", error);
      });
    setRefetchTrigger((prev) => !prev);
  };
  const handleSubmit = () => {
    setLoading(true);
    const colorsToSend =
      selectedColors.length > 0
        ? selectedColors
        : colors.map((color) => color.name);

    let mappedLuminosity =
      luminosity.length > 0 ? luminosity : Object.values(luminosityScale);
    let mappedSaturation =
      saturation.length > 0 ? saturation : Object.values(saturationScale);

    const newSelectedValues = {
      paintingId: paintId,
      lumi: mappedLuminosity,
      sat: mappedSaturation,
      colors: colorsToSend,
    };

    setSelectedValues(newSelectedValues);

    fetch(URLDayl + "color/analysis", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newSelectedValues),
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        setImageUrl(url);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  };

  const adjustHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const luminosityScale = {
    "Tres lumineux": "Tres lumineux",
    Lumineux: "Lumineux",
    "Moyennement lumineux": "Moyennement lumineux",
    "Peu lumineux": "Peu lumineux",
    "Tres peu lumineux": "Tres peu lumineux",
    "Non lumineux": "Non lumineux",
  };

  const saturationScale = {
    "Tres sature": "Tres sature",
    Sature: "Sature",
    "Moyennement sature": "Moyennement sature",
    "Peu sature": "Peu sature",
    "Tres peu sature": "Tres peu sature",
    "Non sature": "Non sature",
  };

  useEffect(() => {
    if (imageUrl) {
      const intervalId = setInterval(() => {
        setOpacity((prevOpacity) => {
          const newOpacity = prevOpacity - 0.05;
          if (newOpacity <= 0.25) {
            clearInterval(intervalId);
            return 0.25;
          }
          return newOpacity;
        });
      }, 50);

      return () => clearInterval(intervalId);
    }
  }, [imageUrl]);

  useEffect(() => {
    if (paintId !== -1) {
      axios
        .get(URLDayl + "paintingEntity/" + paintId)
        .then((response) => {
          setPainting(response.data);
          setSelectedColors([]);
          setLuminosity([]);
          setSaturation([]);
          setImageUrl(null);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching painting:", error);
        });
    }
  }, [paintId]);

  useEffect(() => {
    adjustHeight();
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          background: "white",
          maxHeight: "90vh",
          marginTop: "10px",
        }}
      >
        <div className="flex justify-center w-full">
          <ColorSelection
            colors={colors}
            selectedColors={selectedColors}
            isAnyColorSelected={isAnyColorSelected}
            handleColorSelection={handleColorSelection}
            handleSubmit={handleSubmit}
            loading={loading}
            luminosity={luminosity}
            setLuminosity={setLuminosity}
            saturation={saturation}
            setSaturation={setSaturation}
            luminosityScale={luminosityScale}
            saturationScale={saturationScale}
            setSelectedColors={setSelectedColors}
          />
          <Result
            paintId={paintId}
            setBoolModal={setBoolModal}
            URLDaylImage={URLDaylImage}
            media={media}
            imageUrl={imageUrl}
            opacity={opacity}
            grayValue={grayValue}
            handleSaveRequest={handleSaveRequest}
            auth={auth}
            colors={colors}
            selectedColors={selectedColors}
            painting={painting}
          />
          <Sliders
            grayValue={grayValue}
            handleGrayChange={handleGrayChange}
            opacity={opacity}
            handleOpacityChange={handleOpacityChange}
          />
        </div>
      </div>
      {auth.data && (
        <AnalysisSection
          selectedValues={selectedValues}
          refetchTrigger={refetchTrigger}
          setRefetchTrigger={setRefetchTrigger}
          auth={auth}
          colors={colors}
        />
      )}

      <SelectPaintingModal
        open={boolModal}
        setOpen={setBoolModal}
        selected={selected}
        setSelected={setSelected}
        setFile={(file) => {
          setSelectedMedia(file);
          setMedia(file);
        }}
        multiple={false}
        selectedFile={false}
        colometrie={true}
        onSubmit={() => {
          if (selectedMedia) {
            navigate("/view/customRequests/-1", { state: -1 });
          } else if (selected.length > 0) {
            navigate(`/view/customRequests/${selected[0]}`, {
              state: selected[0],
            });
          }
        }}
      />
    </>
  );
}
