import React, { useEffect, useState } from "react";
import ElementsBiographiesArticles from "./ElementsBiographiesArticles";
import { enumContenu } from "../CompteExpert/PeintresCompteExpert";
import AnalyseChromatiquePainting from "./AnalyseChromatiquePainting";
import SameArtistCarousel from "./SameArtistCarousel";
import MatchedCarousel from "./MatchedCarousel";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { URLDayl, URLDaylImage } from "../../services/constantes";
import DonneesTechniques from "./DonneesTechniques";

export default function TabsOpusPage({
  user,
  setUser,
  paintId,
  setPaintId,
  isLogged,
}) {
  const [tab, setTab] = useState(1);
  const location = useLocation();
  paintId = location.state;

  const [elementsBiographies, setElementsBiographies] = useState();
  const [elementsBiographiesImages, setElementsBiographiesImages] = useState();
  const [avisExperts, setAvisExperts] = useState();
  const [avisExpertsImages, setAvisExpertsImages] = useState();
  const [artwork, setArtwork] = useState();

  useEffect(async () => {
    setElementsBiographies();
    setAvisExperts();
    setAvisExpertsImages();
    setElementsBiographiesImages();
    await axios.get(URLDayl + `paintingEntity/${paintId}`).then((res) => {
      setArtwork(res.data);
    });
  }, [paintId]);

  useEffect(async () => {
    if (artwork) {
      await axios
        .get(
          URLDayl +
            `artist/elementBiographies/byArtworkId/${paintId}?hidden=true`
        )
        .then((res) => {
          const doubleTempImages = [];

          for (let i = 0; i < res.data.length; i++) {
            const tempImages = [];
            for (let j = 0; j < res.data[i].listImages.length; j++) {
              if (res.data[i].listImages[j].length !== "") {
                tempImages.push(
                  URLDayl +
                    `images/biography/${res.data[i].artistId}/${res.data[i].id}?name=${res.data[i].listImages[j]}`
                );
              }
            }

            for (let k = 0; k < res.data[i].taggedArtworks.length; k++)
              tempImages.push(
                URLDayl +
                  `images/paintingPic/full/${res.data[i].taggedArtworks[k]}`
              );

            doubleTempImages.push(tempImages);
          }

          setElementsBiographiesImages(doubleTempImages);

          setElementsBiographies(res.data);
        });

      await axios
        .get(URLDayl + `artist/avisExpert/byArtworkId/${paintId}?hidden=true`)
        .then(async (res) => {
          const doubleTempImages = [];

          for (let i = 0; i < res.data.length; i++) {
            const tempImages = [];
            for (let j = 0; j < res.data[i].images.length; j++) {
              if (res.data[i].listImages[j] !== "")
                tempImages.push(
                  URLDayl +
                    `images/avisExpert/${res.data[i].artistId}/${res.data[i].id}?name=${res.data[i].listImages[j]}`
                );
            }

            for (let k = 0; k < res.data[i].taggedArtworks.length; k++) {
              tempImages.push(
                URLDaylImage +
                  `images/paintingPic/full/${res.data[i].taggedArtworks[k]}`
              );
            }

            doubleTempImages.push(tempImages);
          }
          setAvisExpertsImages(doubleTempImages);

          setAvisExperts(res.data);
        });
    }
  }, [paintId, artwork]);

  return (
    <div>
      <div className="tabs-container">
        <div className="tab">
          <button
            className={tab === 0 ? "active" : ""}
            onClick={() => setTab(0)}
          >
            Empreinte Chromatique
          </button>
          <button
            className={tab === 1 ? "active" : ""}
            onClick={() => setTab(1)}
          >
            + d'oeuvres
          </button>
          <button
            className={tab === 2 ? "active" : ""}
            onClick={() => setTab(2)}
          >
            + d'info
          </button>
        </div>
      </div>

      <>
        <div
          style={{
            display: tab === 0 ? "flex" : "none",
            flexDirection: "column",
          }}
        >
          <AnalyseChromatiquePainting paintId={paintId} />
        </div>
        <div
          style={{
            display: tab === 2 ? "flex" : "none",
            flexDirection: "column",
          }}
        >
          <section className="center-col darkBgGradient mt-c pb-c">
            <div className="cont-l-w ">
              {avisExperts && avisExperts.length > 0 && (
                <ElementsBiographiesArticles
                  user={user}
                  articles={avisExperts}
                  images={avisExpertsImages}
                  contenu={enumContenu.AvisExperts}
                  canZoom={false}
                />
              )}
            </div>
          </section>
          {artwork && <DonneesTechniques Painting={artwork} />}
        </div>
        <div
          style={{
            display: tab === 1 ? "flex" : "none",
            flexDirection: "column",
          }}
        >
          <SameArtistCarousel paintId={paintId} />

          <MatchedCarousel paintId={paintId} />
        </div>
      </>
    </div>
  );
}
