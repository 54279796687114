import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../contexts/userService";
import pngDaylDark from "../../webapp/img/logos/pngDaylDark.png";
import { CompteExpertProvider } from "../../contexts/CompteExpertService";
import ModalEditArtist from "../CompteExpert/ModalEditArtist";
import BoxCloud from "./BoxCloud";
import React, { useState } from "react";
import { Card, CardContent, Typography, Button, Box } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AgentGPTHeader from "../general/AgentGpt/AgentGPTHeader";
import { PrefixQuestionsArtiste } from "../general/AgentGpt/utils";
import { PrefixQuestionsAboutArtist } from "../../services/QuestionsTemplate";

const ArtistBox = ({
  artist,
  boolLike,
  follow,
  TopPaint,
  artistStatements,
  user,
  boxes,
  setShowModal,
  editModal,
  setEditModal,
}) => {
  const navigate = useNavigate();
  const auth = useUser();
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % artistStatements.length);
  };

  const handlePrevious = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + artistStatements.length) % artistStatements.length
    );
  };
  const handleHelloButtonClick = () => {
    setShowModal(true);
  };
  return (
    <div className="cont-n-w artistBox">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div className="left-section">
          {/* BREADCRUMBS - DÉBUT */}
          <ol className="topBc clear mb-a">
            <li>
              <Link to="../../" style={{ color: "black" }}>
                Accueil
              </Link>
            </li>
            <li className="#">
              <Link to="../view/DiscoverArtiste.js" style={{ color: "black" }}>
                Artistes
              </Link>
            </li>
            <li className="#">{artist && artist.artist_name_tot}</li>
          </ol>
          {/* BREADCRUMBS - FIN */}

          {/* NOM DE L'ARTISTE - DÉBUT */}
          <h1 className="text-2xl">{artist && artist.artist_name_tot}</h1>

          {/* NOM DE L'ARTISTE - FIN */}

          {/* DATE DE NAISSANCE / DATE DE DÉCÈS / LIEU D'ORIGINE - DÉBUT */}
          <div className="artistQuickFacts">
            <span>
              {artist && artist.artist_birthyear}-
              {artist && artist.artist_deathyear} (
              {artist && artist.artist_nationality})
            </span>
          </div>
          {/* DATE DE NAISSANCE / DATE DE DÉCÈS / LIEU D'ORIGINE  - FIN */}
          <div className="flex justify-between items-center gap-5">
            {/* BTN SUIVRE / SUIVI ARTISTE - DÉBUT */}
            {boolLike === false && (
              <button
                className="w-fit px-5 py-3 font-cinzel font-bold text-white text-base bg-black rounded-md hover:opacity-80 transition-opacity"
                onClick={follow}
                type="button"
                value="#"
              >
                &#x2b;&nbsp;&nbsp; Suivre
              </button>
            )}
            {boolLike === true && (
              <button
                className="w-fit px-5 py-3 font-cinzel font-bold text-white text-base bg-black rounded-md hover:opacity-80 transition-opacity"
                onClick={follow}
                type="button"
                value="#"
              >
                &#x2713;&nbsp;&nbsp; Suivi
              </button>
            )}
            <div className="">
              {auth && auth.data && (
                <AgentGPTHeader
                  data={"Sur l'artiste : " + artist.artist_name_tot + ", "}
                  //questions={GeneralQuestionsAboutArt}
                  questions={PrefixQuestionsArtiste(
                    PrefixQuestionsAboutArtist,
                    artist.artist_name_tot
                  )}
                  color={"black"}
                  nb={5}
                  text={true}
                />
              )}
            </div>
          </div>
        </div>

        <div className="right-section">
          {/* BTN ÉDITION COMPTE EXPERT / MODAL D'ÉDITION - DÉBUT */}
          {auth.data && auth.data.userType === "expert" && (
            <div
              className="expertEditBtn"
              onClick={() => setEditModal(!editModal)}
            >
              Modifier le contenu
            </div>
          )}
          {editModal && (
            <CompteExpertProvider>
              <ModalEditArtist
                user={user}
                artistId={artist.id}
                boolModal={editModal}
                setBoolModal={setEditModal}
              />
            </CompteExpertProvider>
          )}

          <div className="mt-a">
            <BoxCloud boxes={boxes} />
          </div>

          {/* BTN ÉDITION COMPTE EXPERT / MODAL D'ÉDITION - FIN */}
        </div>
      </div>
      {artistStatements.length > 0 && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          margin="20px"
        >
          {artistStatements.length > 1 && (
            <Button
              onClick={handlePrevious}
              disabled={artistStatements.length <= 1}
            >
              <ArrowBackIosNewIcon />
            </Button>
          )}
          <p className="artistQuickStatement">
            {artistStatements[currentIndex].content}
          </p>

          {artistStatements.length > 1 && (
            <Button
              onClick={handleNext}
              disabled={artistStatements.length <= 1}
            >
              <ArrowForwardIosIcon />
            </Button>
          )}
        </Box>
      )}
    </div>
  );
};

export default ArtistBox;
