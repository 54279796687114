import React, { useState, useEffect } from "react";
import { URLDayl } from "../../services/constantes";
import axios from "axios";
import CarouselColometrie from "../general/CarouselColometrie";

export default function Selector({isCustom,title,white=false,paintIds}) {
  
  const [paintings, setPaintings] = useState([]);

  useEffect(() => {
    const requests = paintIds.map((paintId) =>
      axios.get(URLDayl + "paintingEntity/" + paintId)
    );
    Promise.all(requests)
      .then((responses) => {
        const paintings = responses.map((res) => res.data);
        setPaintings(paintings);
      })
      .catch((error) => {
        console.error("Error fetching paintings:", error);
      });
  }, []);
  return (
    <div className={white?"":"darkBgGradient"} style={{ width: "100%", height: "100%", color:"white",paddingBottom:"30px" }}>
      { title && <div>
        <h2 style={{margin:"40px"}}>{title}</h2>
      </div>}
      <div>
        <CarouselColometrie paintings={paintings} custom={isCustom}/>
      </div>
    </div>
  );
}
