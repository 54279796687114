import ArtMovementsDisplay from "../DiscoverMovements/ArtMovementsDisplay";

export default function ArtSection({ handleClick }) {
  return (
    <div>
      <div className="bg-gray-100 mt-7">
        <div
          style={{ fontFamily: "Cinzel" }}
          className="text-8xl font-bold p-14 flex w-full justify-end"
        >
          02 ART
        </div>
        <div className="text-2xl font-raleway p-3 text-gray-700">
          L’Histoire de l’Art à travers ses <b>Mouvements</b>
        </div>
      </div>
      <div>
        <ArtMovementsDisplay
          onClick={() => handleClick("view/MovementPage.js", {})}
          flipcards={false}
          title={false}
        />
      </div>
    </div>
  );
}
