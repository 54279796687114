import React, { useEffect, useState } from "react";
import down from "../../webapp/img/icons/ico_close_3.svg";
import right from "../../webapp/img/icons/ico_viewItemPage_2.svg";
import { Link } from "react-router-dom";

export default function DonneesTechniques({ Painting }) {
  const [boolAccordion, setBoolAccordion] = useState(true);

  function handleAccordion() {
    setBoolAccordion(!boolAccordion);
  }

  return (
    <section className="center-col mt-c ">
      <div className="cont-l-w darkBg pb-c">
        <div className="">
          {/* <!-- Données techniques - début --> */}
          <div className="row70">
            <h2 className="mb-a mt-b">
              Données techniques{" "}
              <img
                src={boolAccordion ? down : right}
                style={{
                  width: "28px",
                  height: "28px",
                  margin: "auto",
                }}
                onClick={handleAccordion}
              ></img>
            </h2>
            {boolAccordion === true && (
              <div className="toLeftDataList mb-b">
                <div>
                  <span className="dataName">Style</span>
                  {Painting.aw_style_import.split(",").map((style, index) => {
                    return (
                      <Link
                        key={style + index}
                        style={{ textDecoration: "underline" }}
                        className="dataValue"
                        to={`../../view/MovementPage.js`}
                      >
                        {style}
                      </Link>
                    );
                  })}
                </div>
                <div>
                  <span className="dataName">Genre</span>
                  {Painting.aw_genre_import.split(",").map((genre, index) => {
                    return (
                      <Link
                        key={genre + index}
                        style={{ textDecoration: "underline" }}
                        className="dataValue"
                        to={`../../view/paintingSearchByGenre.js/${genre}`}
                      >
                        {genre}
                      </Link>
                    );
                  })}
                </div>
                <div>
                  <span className="dataName">Support</span>
                  {Painting.aw_medium.split(",").map((medium, index) => {
                    return (
                      <Link
                        key={medium + index}
                        style={{ textDecoration: "underline" }}
                        className="dataValue"
                        to={`../../view/paintingSearchByAwMedium.js/${medium}`}
                      >
                        {medium}
                      </Link>
                    );
                  })}
                </div>
                <div>
                  <span className="dataName">Dimensions</span>
                  <span className="dataValue">
                    {Math.floor(Painting.aw_dim_width)}
                    {Math.floor(Painting.aw_dim_width) && "x"}
                    {Math.floor(Painting.aw_dim_height)}
                    {Math.floor(Painting.aw_dim_width) && "cm"}
                  </span>
                </div>
                <div>
                  <span className="dataName">Lieu</span>
                  <span className="dataValue">{Painting.aw_locate}</span>
                </div>
                <div>
                  <span className="dataName">Source</span>
                  <span className="dataValue">{Painting.source}</span>
                </div>
                <div>
                  <span className="dataName">Droits</span>
                  <span className="dataValue">{Painting.aw_licence_type}</span>
                </div>
                <div>
                  <span className="dataName">Copyright</span>
                  <span className="dataValue">
                    {Painting.artist.artist_copyright}
                  </span>
                </div>
                <div>
                  <span className="dataName">Crédits</span>
                  <span className="dataValue"></span>
                </div>
              </div>
            )}
          </div>
          {/* <!-- Données techniques - fin --> */}
        </div>
      </div>
    </section>
  );
}
