import React, { useEffect, useState } from "react";
import Matchings from "./Matchings";
import Likes from "../Likes";
import { useUser } from "../../../contexts/userService";
import FavArtists from "../FavArtists";
import LockIcon from "@mui/icons-material/Lock";
import axios from "axios";
import { URLDayl, URLDaylImage } from "../../../services/constantes";
import {Skeleton, Stack} from "@mui/material";
import Likes2 from "../Likes2";

function FavoritesTab({ friend }) {
  const [activeTab, setActiveTab] = useState(sessionStorage.getItem("activeTabUserFav") ? parseInt(sessionStorage.getItem("activeTabUserFav")) : 0);
  const auth = useUser();

  const [restricted, setRestricted] = useState(!friend ? false : undefined);


  useEffect(() => {
    setActiveTab(0)
    return sessionStorage.removeItem("activeTabUserFav")
  },[auth, friend])

  const renderContent = () => {
    switch (restricted){
      case false:
        return <div className="profileTab">{menuItems[activeTab].item}</div>
      case true:
        return <div style={style.overlay}>
          <div style={style.content}>
            <LockIcon style={{ fontSize: "100px", marginBottom: "20px" }} />
            <p
                style={{
                  marginBottom: "0px",
                  fontSize: "17px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                  textAlign: "center",
                  borderRadius: "10px",
                  padding: "10px",
                  backgroundColor:"white"
                }}
            >
              RESTRICTED <br /> You must be in this person's network to be
              able to view their account
            </p>
          </div>
        </div>
      case undefined:
        return <Stack spacing={1}>
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="rectangular" width={210} height={60} />
          <Skeleton variant="rounded" width={210} height={60} />
        </Stack>

    }
  }

  const menuItems = [
    {
      tabName: "Artworks",
      icon: null,
      item: (
        <Likes2
          user={auth.data}
          setUser={() => {}}
          friend={friend ? friend.id : undefined}
        />
      ),
    },
    {
      tabName: "Artists",
      icon: null,
      item: <FavArtists user={friend ? friend : auth.data} />,
    },
    { tabName: "Matchings", icon: null, item: <Matchings friend={friend ? friend.id : undefined} /> },
  ];

  const style = {
    overlay: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backdropFilter: "blur(10px) brightness(1)", // Apply the blur effect
      zIndex: 1000,
      borderRadius: "20px",
    },
    content: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "0px",
      zIndex: 1,
      padding: "20px",
      height: "100%",
      justifyContent: "start",
    },
  };

  //test

  useEffect(async () => {
    if (friend)
      await axios
          .get(URLDayl + `friends/listOfFollowers?userId=${friend.id}`)
          .then((friendList) => {
            setRestricted(
                !friendList.data.some((el) => el.friendId === auth.data.id)
            );
          });
  }, [friend]);


  return (
    <div className="profileContainer">
      <div className="shadowBox" style={{minHeight:"300px"}}>

        <div className="mainMenu" style={{ marginBottom: 50, marginTop: 35 }}>
          {menuItems.map((menuItem, index) => (
            <React.Fragment key={index}>
              <div
                className={`profileMenuItem ${
                  activeTab === index ? "active" : ""
                }`}
                onClick={() => {
                  setActiveTab(index)
                  sessionStorage.setItem("activeTabUserFav", index)
                }}
              >
                {menuItem.icon && <img src={menuItem.icon} alt="Logo" />}
                <p>{menuItem.tabName}</p>
              </div>
              {index !== menuItems.length - 1 && (
                <div className="menuSeparator" />
              )}
            </React.Fragment>
          ))}
        </div>

        {
          renderContent()
        }


      </div>
    </div>
  );
}

export default FavoritesTab;
