import newcomment from "../../../webapp/img/icons/newcomment.svg";
import React, { useEffect, useRef, useState } from "react";
import { useUser } from "../../../contexts/userService";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { IconButton, Menu } from "@mui/material";
import axios from "axios";
import { URLDayl } from "../../../services/constantes";
import { MoreHoriz } from "@mui/icons-material";
import { toast } from "react-toastify";
import MenuItem from "@mui/material/MenuItem";

export default function SocialNetworkPostFooter({
  publication,
  setCommentsBool,
}) {
  const [isPostLiked, setIsPostLiked] = useState(false);
  const [isCommentInputVisible, setCommentInputVisible] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [menuItems, setMenuItems] = useState([]);

  const commentInputRef = useRef(null);
  const dropdownRef = useRef(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const auth = useUser();

  const handleClickOnItem = (option) => {
    switch (option) {
      case "Delete": {
        axios
          .post(URLDayl + "publication/remove", {
            userId: auth.data.id,
            publicationId: publication.id,
          })
          .then((res) => {
            toast.success("Post deleted", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
        break;
      }
      case "Publish": {
        axios
          .get(URLDayl + "publication/updatePostPublic", {
            params: { publicationId: publication.id },
          })
          .then((res) => {
            toast.success("Posted", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      }
    }
    handleClose();
  };

  const handleOnClickLike = () => {
    if (isPostLiked) {
      axios
        .delete(URLDayl + "publication/likePubli/removeLike", {
          data: {
            userId: auth.data.id,
            publicationId: publication.id,
          },
        })
        .then((res) => {});
    } else {
      axios.post(URLDayl + "publication/likePubli/addLike", {
        publicationOrCommentId: publication.id,
        userId: auth.data.id,
        likeType: "string",
      });
    }
    setIsPostLiked(!isPostLiked);
  };

  const handleOnPostComment = (e) => {};

  const handleSecondImageClick = () => {
    const temp = isCommentInputVisible;
    setCommentInputVisible(!isCommentInputVisible);
    setCommentsBool(!temp);
    commentInputRef.current.focus();
  };

  const handleThirdImageClick = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event, ref, setState) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setState(false);
    }
  };

  const handleCommentSubmit = () => {
    if (commentText !== "") {
      axios.post(URLDayl + `publication/commentPubli/addComment`, {
        publicationId: publication.id,
        userId: auth.data.id,
        content: commentText,
        rank: 0,
        parentComment: 0,
      });
      setCommentText("");
      setCommentInputVisible(false);
      commentInputRef.current.blur();
    }
  };

  useEffect(() => {
    setIsPostLiked(
      publication.listLike.some((el) => el.userId === auth.data.id)
    );

    const tempMenuItems = [];
    if (auth.data.id === publication.userId) tempMenuItems.push("Delete");
    if (
      auth.data.id === publication.userId &&
      publication.publicationStatus === "PRIVATE"
    )
      tempMenuItems.push("Publish");

    setMenuItems(tempMenuItems);
  }, [publication]);

  const [publicationData, setPublicationData] = useState([]);
  useEffect(() => {
    axios
      .get(
        `${URLDayl}publication/commentPubli/ByPublicationId?id=${publication.id}`
      )
      .then((response) => {
        setPublicationData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [publication.id]);

  return (
    <div>
      <div className="interactions">
        <div
          style={{
            display: "flex",
            gap: "00px",
            alignItems: "center",
          }}
        >
          <IconButton
            aria-label="Like"
            onClick={() => {
              handleOnClickLike();
            }}
          >
            {isPostLiked ? (
              <FavoriteIcon style={{ color: "red", fontSize: 30 }} />
            ) : (
              <FavoriteBorderIcon style={{ color: "black", fontSize: 30 }} />
            )}
          </IconButton>
          <div style={{ fontSize: "16px", marginRight: "20px" }}>
            {publication.listLike.length}
          </div>
          <button
            onClick={handleSecondImageClick}
            style={{
              border: "none",
              padding: 0,
              cursor: "pointer",
            }}
          >
            <img src={newcomment} alt="profile pic" />
          </button>
          <div style={{ fontSize: "16px", marginLeft: "8px" }}>
            {publicationData.length}
          </div>
        </div>

        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreHoriz />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          {menuItems.map((option) => {
            return (
              (option !== "Delete" ||
                auth.data.id === publication.userId ||
                (option !== "Publish" &&
                  publication.publicationStatus === "PRIVATE")) && (
                <MenuItem
                  key={option}
                  onClick={() => handleClickOnItem(option)}
                >
                  {option}
                </MenuItem>
              )
            );
          })}
        </Menu>

        {/*<button*/}
        {/*    onClick={handleThirdImageClick}*/}
        {/*    style={{*/}
        {/*        border: "none",*/}
        {/*        padding: 0,*/}
        {/*        cursor: "pointer",*/}
        {/*    }}*/}
        {/*>*/}
        {/*    <img src={newshare} alt="profile pic"/>*/}
        {/*</button>*/}

        {isDropdownOpen && (
          <div
            ref={dropdownRef}
            className="popover"
            style={{
              zIndex: "100",
              position: "absolute",
              top: "100%",
              left: "95%",
              transform: "translateX(-50%)",
              background: "white",
              border: "1px solid black",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              color: "black",
              borderRadius: "7px",
            }}
          >
            <button className="popover-button">Edit</button>
            <button className="popover-button">Delete</button>
          </div>
        )}
      </div>
      {isCommentInputVisible && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <textarea
            ref={commentInputRef}
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
            placeholder="Ajouter un commentaire..."
            style={{
              height: "45px",
              margin: "20px 55px 5px",
              borderLeft: "2px solid black",
              borderRight: "none",
              borderTop: "none",
              borderBottom: "none",
              borderRadius: "0px",
              resize: "none",
            }}
          ></textarea>
          <button
            style={{ width: "60px", marginLeft: "55px" }}
            onClick={handleCommentSubmit}
            className="commentSubmit"
          >
            Submit
          </button>
        </div>
      )}
    </div>
  );
}
