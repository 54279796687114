import React, { useState } from "react";
import axios from "axios";
import SearchBar from "../DaylContentPage/ContentSearchBar";
import { useUser } from "../../contexts/userService";

const URLDayl = process.env.REACT_APP_API_URL || "https://api.example.com/";

export const ContenuDaylCompteExpert = ({}) => {
  const [movement, setMovement] = useState(null);
  const user = useUser();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editedContent, setEditedContent] = useState({
    dateStart: 0,
    dateEnd: 0,
    summary: "",
    content: "",
  });

  const handleMovementSelect = async (movementId) => {
    setLoading(true);
    try {
      const response = await axios.get(
        URLDayl + "editorial-contents/" + movementId
      );
      setMovement(response.data);
      setEditedContent({
        dateStart: response.data.dateStart,
        dateEnd: response.data.dateEnd,
        summary: response.data.summary,
        content: response.data.content,
      });
      setLoading(false);
    } catch (error) {
      console.error("Error fetching movement:", error);
      setError("Failed to load movement details. Please try again later.");
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedContent((prev) => ({
      ...prev,
      [name]:
        name === "dateStart" || name === "dateEnd" ? parseInt(value) : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.put(
        URLDayl + "editorial-contents/" + movement.id,
        editedContent,
        { headers: { Authorization: `Bearer ${user.data.jwt}` } }
      );
      setLoading(false);
      setError(null);
      alert("Movement updated successfully!");
    } catch (error) {
      console.error("Error updating movement:", error);
      setError("Failed to update movement. Please try again later.");
      setLoading(false);
    }
  };
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 text-center">
        Modifier une page Contenu
      </h1>
      <p className="text-lg mb-8 text-center text-gray-600">
        Entrez le nom du contenu que vous souhaitez modifier.
      </p>
      <div className="flex justify-center mb-12">
        <SearchBar onMovementSelect={handleMovementSelect} />
      </div>

      {loading && <p className="text-center text-lg">Loading...</p>}
      {error && <p className="text-center text-lg text-red-500">{error}</p>}

      {movement && (
        <form
          onSubmit={handleSubmit}
          className="space-y-6 max-w-4xl mx-auto font-raleway"
        >
          <div className="grid grid-cols-2 gap-6 ">
            <div>
              <label
                htmlFor="dateStart"
                className="block text-lg font-medium font-raleway text-gray-700 mb-2"
              >
                Date de début:
              </label>
              <input
                type="number"
                id="dateStart"
                name="dateStart"
                value={editedContent.dateStart}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-900 focus:border-blue-900"
              />
            </div>
            <div>
              <label
                htmlFor="dateEnd"
                className="block text-lg font-medium font-raleway text-gray-700 mb-2"
              >
                Date de fin:
              </label>
              <input
                type="number"
                id="dateEnd"
                name="dateEnd"
                value={editedContent.dateEnd}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-900 focus:border-blue-900"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="summary"
              className="block text-lg font-medium font-raleway text-gray-700 mb-2"
            >
              Résumé:
            </label>
            <textarea
              id="summary"
              name="summary"
              value={editedContent.summary}
              onChange={handleInputChange}
              rows="4"
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-900 focus:border-blue-900"
            />
          </div>
          <div className="w-full">
            <label
              htmlFor="content"
              className="block text-lg font-medium font-raleway text-gray-700 mb-2"
            >
              Contenu:
            </label>
            <textarea
              id="content"
              name="content"
              value={editedContent.content}
              onChange={handleInputChange}
              className="w-full h-[50vh] px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-900 focus:border-blue-900"
            />
          </div>

          <div className="flex justify-center">
            <button
              type="submit"
              disabled={loading}
              className="px-6 py-3 bg-blue-700 text-white font-medium rounded-md shadow-sm hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400 disabled:opacity-50"
            >
              {loading ? "Mise à jour..." : "Mettre à jour"}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default ContenuDaylCompteExpert;
