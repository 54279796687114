import React, { useState, useRef, useEffect } from "react";
import { useUser } from "../../../contexts/userService"; 
import { URLDaylIAgent } from "../../../services/constantes";
import { useNavigate } from "react-router";
import axios from "axios";
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';

import RefreshIcon from '@mui/icons-material/Refresh';
import BarChartIcon from '@mui/icons-material/BarChart';
import Button from '@mui/material/Button';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import "../../../webapp/css/gptAgent.css"
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import FileOpen from '@mui/icons-material/FileOpen';
import { Box } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import './styles.css'
import { Divider } from "antd";
import SettingsIcon from '@mui/icons-material/Settings';
import { BoltRounded } from "@mui/icons-material";


const TypedText = ({ text }) => {
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    let index = 0;

    const intervalId = setInterval(() => {
      setDisplayedText((prevText) => prevText + text[index]);

      index += 1;
      if (index === text.length) {
        clearInterval(intervalId);
      }
    }, 10);

    return () => clearInterval(intervalId);
  }, [text]);

  return <span>{displayedText}</span>;
};


/**
 * This component display the User Page
 * @param {object} user - user object from api
 * @param {object} setUser - user setter
 * @param {boolean} isLogged - test if the user is logged
 * @param {boolean} setIsLogged - isLogged setter
 *  @param {number} isActive - tab selection
 *  @param {number} setIsActive - isActive setter
 */

export default function GptConfiguration(props) {
  // const { state } = useLocation();
  // const [user, setUser] = useState(state);

  const auth = useUser();
  const navigate = useNavigate();


  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
  }

  useEffect(async () => {
    // await timeout(5000);
    while (auth.data === false){
      await timeout(10);
    }
    if (auth.data) return;{
      navigate("../../view/LogIn.js")
    }
  },[])



  const [selectedFile, setSelectedFile] = useState(null);
  const [filesList, setFilesList] = useState([]);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    try {
      setLoading(true)
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('community', mode); // Include the community value


      const response = await axios.post( URLDaylIAgent + '/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log("response.data");
      console.log(response.data);
      fetchFilesList()
      setLoading(false)
    } catch (error) {
      console.error('Error uploading file:', error);
      setLoading(false)
    }
  };

  const fetchFilesList = async () => {

    setLoading(false)
    try {
      axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
        "jwt"
      )}`; 
      const response = await axios.get(URLDaylIAgent + '/upload');
      setFilesList(response.data.files);
    } catch (error) {
      console.error('Error fetching files list:', error);
    }
  };


  useEffect(() => {
    fetchFilesList();
  }, []); // Empty dependency array to run the effect only once when the component mounts

 

 
  
  const [settingsAnchor, setSettingsAnchor] = useState(null);

  const handleSettingsClick = (event) => {
    setSettingsAnchor(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setSettingsAnchor(null);
  };

  const [isActiveUi, setIsActiveUi] = useState(true); // set to true for the first element, false for the second
  const [inputText, setInputText] = useState('');
  const [attachedImage, setAttachedImage] = useState(null);





  const [open, setOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [userMessage, setUserMessage] = useState('');
  const [loading, setLoading] = useState(false); // Added loading state
  const messagesEndRef = useRef(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

 
 

  const [Modele, setModele] = useState("Choisis la base de données")
  const [label, setlabel] = useState("Choisis la base de données")
  const [mode, setmode] = useState("Choisis la base de données")
  
  const RagStart = async () => {
    setLoading(true)
    let params = {
      mode:mode
    }
    axios.post( URLDaylIAgent + Modele, params)
    .then(response => {
      setLoading(false)
      console.log(response)
      alert("Votre traitement a été effectué avec succès.")
    })
    .catch(err => {
      setLoading(false)
      alert("Merci de choisis la base de données à enrichir")
    }) 
  };




  const [versionAnchor, setVersionAnchor] = useState(null);

  const handleVersionClick = (event) => {
    setVersionAnchor(event.currentTarget);
  };

  const handleVersionClose = (version, label, mode) => {
    setVersionAnchor(null);
    setModele(version)
    setlabel(label ? label : "Choisis la base de données")
    setmode(mode)
  };



  return (  

    
    <div className="app-container">
    <div className="sidebar">
      <div className="sidebar-header">
        <h2>Dayl</h2>
      </div>
      <Divider />
      <hr />
      <div className="sidebar-section">
          <div className="elementsSides" style={{
                textAlign:"center",
                textDecoration:"underline"}}  >
          <SettingsIcon style={{
                color: "#1f1fe7",
                fontSize: "18px",
                marginRight: "10px"
          }} /> -- Ma base de données pour les communautés: --
          </div>
          {Object.keys(filesList)?.map((subdirectory, subdirectoryIndex) => (
            <div key={subdirectoryIndex}>
              <div className="subdirectoryTitle">{subdirectory}</div>
              {filesList[subdirectory]?.map((file, fileIndex) => (
                <div className="elementsSides" style={{
                  fontSize:"11px"
                }} key={fileIndex}>
                  <FileOpen
                    style={{
                      color: "#1f1fe7",
                      fontSize: "18px",
                      marginRight: "10px"
                    }}
                  /> {file}
                </div>
              ))}
              <Divider />
            </div>
          ))}

      </div>
      <Divider />
      <div className="sidebar-footer">
        <div>
          <Avatar alt="Profile" src="https://static.vecteezy.com/ti/photos-gratuite/p1/24492193-profil-portrait-de-robot-bot-ai-avec-connecte-des-lunettes-blanc-contexte-synthetique-humanoide-robot-artificiel-intelligence-portrait-de-gynoide-futuriste-cyborg-banniere-avec-espace-pour-texte-copie-espace-photo.jpeg" />
        </div> 
        <div> 
          <label style={{
            position:"relative",
            marginLeft:"10px"
          }} > Agent Test  </label>
          <SettingsIcon className="settings"  onClick={handleSettingsClick} />
        </div>
        <Menu
          anchorEl={settingsAnchor}
          open={Boolean(settingsAnchor)}
          onClose={handleSettingsClose}
        >
        <MenuItem onClick={() => {
          props.setmode(1)
          handleSettingsClose()
        }}> <SettingsIcon /> Chat </MenuItem>
          <MenuItem onClick={handleSettingsClose}> <RefreshIcon />  Historique </MenuItem>
          <MenuItem onClick={handleSettingsClose}> <BarChartIcon />  Usage </MenuItem>
        </Menu>
      </div>
    </div>
    
      <div className="main-content">
          
          <div className="textMainContent" >
            Chat Configuration
          </div>

          <div className="bodyMainContent" >
              <img src={"https://horizon-ui.com/chatgpt-ai-template/_next/static/media/bg-image.4b3208f1.png"}  />
              
              
              <div className="top-select">
                <Button onClick={handleVersionClick}>
                   {label}
                </Button>
                <Menu
                    anchorEl={versionAnchor}
                    open={Boolean(versionAnchor)}
                    onClose={() => handleVersionClose(null)}
                > 

                <MenuItem onClick={() => handleVersionClose("/bert_emedder", "Communauté publique", "PUBLIC")}>
                    <BoltRounded
                    style={{
                        color: "#1f1fe7",
                        fontSize: "18px",
                        marginRight: "5px"
                    }}
                    />
                   Communauté publique
                </MenuItem> 
                <MenuItem onClick={() => handleVersionClose("/bert_emedder", "Communauté privée", "PRIVATE")}>
                    <BoltRounded
                    style={{
                        color: "#1f1fe7",
                        fontSize: "18px",
                        marginRight: "5px"
                    }}
                    />
                   Communauté privée
                </MenuItem>
                <MenuItem onClick={() => handleVersionClose("/bert_emedder", "Communauté académique (Étudiant)", "STUDENTS")}>
                    <BoltRounded
                    style={{
                        color: "#1f1fe7",
                        fontSize: "18px",
                        marginRight: "5px"
                    }}
                    />
                   Communauté académique (Étudiant)
                </MenuItem> 
                <MenuItem onClick={() => handleVersionClose("/bert_emedder", "Communauté académique (corps professoral, encadrement administratif, etc.)", "PROFESSORS")}>
                    <BoltRounded
                    style={{
                        color: "#1f1fe7",
                        fontSize: "18px",
                        marginRight: "5px"
                    }}
                    />
                   Communauté académique (corps professoral, encadrement administratif, etc.)
                </MenuItem> 

 
                </Menu>
            </div>
              
            <div className="bodyText"> 

              <Box style={{ fontSize:"11px",   padding: '3px', gap: '0px', textAlign:"center" }}>
                

              <div className="bottom-section"> 
                    {loading ? 
                      <Button onClick={() => { 
                      }}  color="error" variant="outlined" style={{marginRight:10}} >
                        loading...
                      </Button>
                    :
                      <Button onClick={() => {
                        RagStart()
                      }}  color="error" variant="outlined" style={{marginRight:10}} >
                        Traiter tous les fichiers
                      </Button>
                    } 

                    {/* <Button  color="success" variant="outlined">
                      Traiter les nouveaux fichiers
                    </Button> */}
                  </div>
                  
                  <Divider />

                  <div className="main-section">
                    <h2>Chargement des fichiers</h2>
                    <br />
                    
                      <TextField
                        type="file"
                        onChange={handleFileChange}
                        InputProps={{
                          endAdornment: (
                            <>
                            
                            {loading ? 
                              <Button onClick={() => { 
                              }}  color="error" variant="outlined" style={{marginRight:10}} >
                                loading...
                              </Button>
                            :
                            <Button onClick={handleFileUpload} color="info" variant="outlined">
                              Upload <RocketLaunchIcon style={{ color: '#0091ff', marginLeft: 5, fontSize: '18px' }} />
                            </Button>
                            } 
                            </>
                          ),
                        }}
                      />
                    <br />
                    <br />
                   {/*  <Button onClick={handleFileUpload} color="info" variant="outlined">
                      Upload <RocketLaunchIcon style={{color:"#0091ff", marginLeft:5, fontSize:"18px"}} />
                    </Button> */}
                  </div>
                  
              </Box>


            </div>
            


          </div>
      </div>
      </div>


  );
}