import React from "react";
import { useState } from "react";
import Selector from "../component/Colorimetrie/Selector";
import SelectPaintingModal from "../component/general/SelectPaintingModal/SelectPaintingModal";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { MediaContext } from "../contexts/ColorimetrieUpload";

export default function Colorimetrie() {
  const [boolModal, setBoolModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const navigate = useNavigate();
  const { setMedia } = useContext(MediaContext);
  return (
    <div className="darkBgGradient">
      <Selector
        isCustom={true}
        title={"DECOUVREZ NOS ETUDES CHROMATIQUES"}
        paintIds={[6674, 6712, 6653, 6714, 6673]}
      />
      <Selector
        isCustom={false}
        white={true}
        title={"A VOUS DE JOUER - ACCES OUTIL REQUETAGE"}
        paintIds={[
          6674, 6712, 6653, 6714, 6673, 11389, 11362, 11356, 11332, 4126, 3982,
          3971, 8528, 8529, 8535, 8536, 8566, 8082, 8089, 17529, 17871, 17857,
          36661, 60153, 248345, 261027, 256246,
        ]}
      />
    </div>
  );
}
