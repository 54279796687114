import Carousel3D from "../component/general/Carousel3D";
import { URLDayl, URLDaylImage } from "../services/constantes";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import CarouselPainting from "../component/general/CarouselPainting";
import axios from "axios";
import ArtistCarousel from "../component/ArtistDiscoverPage/ArtistCarousel";

const PaintingItem = () => (
  <div className="carousel-slide" key={0}>
    <div
      className="carousel-card"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {/* <Fav user={auth.data} isLogged={auth.data !== undefined} favPaint={matchPaint}/>*/}
      <div
        className="bt-wrapper"
        style={{
          backgroundImage: `url(${
            URLDaylImage +
            "images/paintingPic/" +
            Math.floor(Math.random() * (6000 + 1)) +
            0
          })`,
        }}
      >
        <div className="overlay">
          <div className="carousel-title">
            Name of painting
            <div className="date">{84}</div>
          </div>
          <div className="carousel-action">
            <Link
              className="bt-action voir"
              to={`../../view/OpusPage.js/${45}`}
              state={846}
            >
              voir
            </Link>
            <Link
              className="bt-action match"
              to={`../../view/MatchingPage.js/${465}`}
            >
              match
            </Link>
          </div>
        </div>
      </div>
      {true && (
        <div className="carousel-artist-name">
          <Link to={`../../view/ArtistDetailPage.js/${5}`} state={8}>
            NAme of artist
          </Link>
        </div>
      )}
    </div>
  </div>
);

export default function PageStatistiques() {
  const [daylLikes, setDaylLikes] = useState([]);

  let cards = [
    {
      key: 0,
      content: <PaintingItem />,
    },
    {
      key: 1,
      content: <PaintingItem />,
    },
    {
      key: 2,
      content: <PaintingItem />,
    },
    {
      key: 3,
      content: <PaintingItem />,
    },
    {
      key: 4,
      content: <PaintingItem />,
    },
    {
      key: 5,
      content: <PaintingItem />,
    },
    {
      key: 6,
      content: <PaintingItem />,
    },
  ];

  const [mostMatched, setMostMatched] = useState([]);
  const [contemporaryArtist, setContemporaryArtist] = useState([]);
  useEffect(() => {
    axios
      .get(
        URLDayl +
          `matchingSearch/moreMatchedPaintingForLast30Days?numberOfMonthsBefore=${1}`
      )
      .then((res) => {
        setMostMatched(res.data.map((el) => el.painting));
      });
    axios
      .get(
        URLDayl +
          "artistSearch/findArtistsBetweenDates?startDate=1950&quantity=10"
      )
      .then((res) => {
        setContemporaryArtist(res.data);
      });
  }, []);

  useEffect(() => {
    axios.get(URLDayl + "paintingSearch/getPaintingByDaylMark").then((res2) => {
      setDaylLikes(res2.data);
    });
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          borderRadius: 20,
          height: 200,
          gap: 20,
          flex: 1,
          margin: 100,
          flexDirection: "column",
          alignItems: "center",
        }}
        className={"darkBgGradient-2"}
      >
        <h3 style={{ color: "white", fontSize: 28 }}>
          Découvrez les oeuvres les plus likés
        </h3>
        <div>
          <input
            type="file"
            style={{ display: "none" }}
            // ref={fileInputRef}
            // onChange={changeHandler}
          />
        </div>
      </div>
      <Carousel3D
        cards={cards}
        height="200px"
        width="90%"
        margin="0 auto"
        offset={5}
      />
      <div className={"darkBgGradient"} style={{ padding: 30, marginTop: 100 }}>
        <h3
          style={{
            marginLeft: 100,
            fontSize: 28,
            color: "white",
            marginTop: 50,
            marginBottom: 50,
          }}
        >
          Les coups de coeur Dayl
        </h3>
        <CarouselPainting paintings={daylLikes} artistName={true} />
      </div>
      <div className={"darkBgGradient"} style={{ padding: 30, marginTop: 100 }}>
        <h3
          style={{
            marginLeft: 100,
            fontSize: 28,
            color: "white",
            marginTop: 50,
            marginBottom: 50,
          }}
        >
          Les plus matchées
        </h3>
        <CarouselPainting paintings={mostMatched} />
      </div>

      <div className={"darkBgGradient"} style={{ padding: 30, marginTop: 100 }}>
        <ArtistCarousel
          artists={contemporaryArtist}
          title={"Nos artistes émergents"}
          color={"white"}
        />
      </div>
    </div>
  );
}
