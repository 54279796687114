import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router";

const BoxCloud = ({ boxes, keyword = false, color = "black" }) => {
  const [showPopover, setShowPopover] = useState(false);
  const popoverRef = useRef(null);

  const handleShowPopover = () => {
    setShowPopover(!showPopover);
  };

  const navigate = useNavigate();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setShowPopover(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [popoverRef]);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div className="box-cloud">
        {boxes.slice(0, 3).map((box, index) => (
          <div
            key={index}
            className={"artistTag"}
            onClick={() =>
              navigate(
                keyword
                  ? `../../view/MatchingKeyword.js`
                  : `../../view/MovementPage.js`,
                { state: box }
              )
            }
          >
            {box}
          </div>
        ))}
      </div>
      {boxes.length > 3 && (
        <button
          className="block mt-4 mb-0 ml-0 mr-0 px-2.5 pb-0 border-b-2 border-gray-400 cursor-pointer w-28"
          onClick={handleShowPopover}
          style={{ color: color }}
        >
          Show All
        </button>
      )}

      {showPopover && (
        <div
          className="absolute z-50 top-full left-1/2 -translate-x-1/2 w-50 p-2.5 bg-gray-100 border border-gray-300 rounded shadow-md"
          ref={popoverRef}
        >
          {boxes.map((box, index) => (
            <div
              key={index}
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate(
                  keyword
                    ? `../../view/MatchingKeyword.js`
                    : `../../view/paintingSearchByStyle.js/${box}`,
                  { state: box }
                )
              }
            >
              {box}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BoxCloud;
