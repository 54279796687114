import React, {useEffect, useState} from "react";
import newtempprofile from "../../../webapp/img/icons/newtempprofile.svg";
import newcomment from "../../../webapp/img/icons/newcomment.svg";
import newshare from "../../../webapp/img/icons/newshare.svg";
import newheart from "../../../webapp/img/icons/newheart.svg";
import SocialNetworkPostList from "./SocialNetworkPostList";
import axios from "axios";
import {URLDayl} from "../../../services/constantes";
import {useUser} from "../../../contexts/userService";
function MyComments() {

  const [posts, setPosts] = useState([])

  const auth = useUser()

  useEffect(() => {
    axios.get(URLDayl + "publication/byUserId", {params: {userId: auth.data.id, status: "PRIVATE"}}).then((res)=> setPosts(res.data))
  },[])

  return (
    <SocialNetworkPostList posts={posts}/>
  );
}

export default MyComments;
