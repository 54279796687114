import { Link } from "react-router-dom";
import { URLDaylImage } from "../../services/constantes";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

function CustomNextArrow(props) {
  const { className, style, onClick, color = "black" } = props;
  return (
    <ChevronRightIcon
      className={`${className} carousel-painting-arrows-custom-next`}
      style={{ ...style, color: color, fontSize: 50 }}
      onClick={onClick}
    />
  );
}

function CustomPrevArrow(props) {
  const { className, style, onClick, color = "black" } = props;
  return (
    <ChevronLeftIcon
      className={`${className} carousel-painting-arrows-custom-prev`}
      style={{ ...style, color: color, fontSize: 50 }}
      onClick={onClick}
    />
  );
}

export default function SingleSlideCarousel({
  paintings,
  artistName,
  width = "25vw",
  height = "30vw",
  arrowColor = "white",
}) {
  const settings = {
    infinite: true,
    speed: 500,
    nextArrow: <CustomNextArrow color={arrowColor} />,
    prevArrow: <CustomPrevArrow color={arrowColor} />,
    slidesToShow: 2,
    slidesToScroll: 2,
  };

  return (
    <div className="w-10/12 m-auto">
      <Slider {...settings}>
        {paintings.length > 0 &&
          paintings.map((matchPaint, index) => (
            <div key={index}>
              <div
                className="carousel-card"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "auto",
                    height: height,
                  }}
                >
                  <img
                    src={`${URLDaylImage}images/paintingPic/${matchPaint.id}`}
                    alt={matchPaint.name}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                  <div
                    className="overlay"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="carousel-title">
                      {matchPaint.name}
                      <div className="date">{matchPaint.year}</div>
                    </div>
                    <div className="carousel-action">
                      <Link
                        className="bt-action voir"
                        to={`../../view/OpusPage.js/${matchPaint.id}`}
                        state={matchPaint.id}
                      >
                        voir
                      </Link>
                      <Link
                        className="bt-action match"
                        to={`../../view/MatchingPage.js/${matchPaint.id}`}
                      >
                        match
                      </Link>
                    </div>
                  </div>
                </div>
                {artistName && (
                  <div className="carousel-artist-name">
                    <Link
                      to={`../../view/ArtistDetailPage.js/${matchPaint.artistId}`}
                      state={matchPaint.artistId}
                    >
                      {matchPaint.artisteName}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          ))}
      </Slider>
    </div>
  );
}
