import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { URLDayl } from "../services/constantes";
import Pagination from "@mui/material/Pagination";
import SearchBar from "../component/DaylContentPage/ContentSearchBar";
import MovementDetail from "../component/DaylContentPage/MovementDetail";
import MovementPreviewGrid from "../component/DaylContentPage/MovementPreviewGrid";

const DaylContent = () => {
  const { movementId } = useParams();
  const navigate = useNavigate();
  const [movements, setMovements] = useState([]);
  const [selectedMovementId, setSelectedMovementId] = useState(
    movementId || null
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchMovements();
  }, [pageNum]);

  useEffect(() => {
    if (movementId) {
      setSelectedMovementId(movementId);
    }
  }, [movementId]);

  const fetchMovements = async () => {
    setLoading(true);
    try {
      const response = await axios.get(URLDayl + "editorial-contents", {
        params: { page: pageNum - 1, size: 15 },
      });
      setMovements(response.data.content);
      setTotalPages(response.data.totalPages);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching movements:", error);
      setError("Failed to load movements. Please try again later.");
      setLoading(false);
    }
  };

  const handleMovementSelect = (movementId) => {
    setSelectedMovementId(movementId);
    navigate(`/view/DaylContent/${movementId}`);
  };

  const handleChangePage = (event, value) => {
    setPageNum(value);
    setSelectedMovementId(null);
    navigate("/view/DaylContent");
  };

  const handleBackToGrid = () => {
    setSelectedMovementId(null);
    navigate("/view/DaylContent");
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen text-red-500 font-bold text-xl">
        {error}
      </div>
    );
  }

  return (
    <div className="bg-gray-50 min-h-screen p-8">
      <h1 className="text-4xl font-cinzel text-center mb-8 text-gray-900">
        Dayl Content
      </h1>
      <SearchBar onMovementSelect={handleMovementSelect} />
      <div className="max-w-8xl mx-auto bg-white rounded-lg shadow-lg p-8 mb-12">
        {selectedMovementId ? (
          <MovementDetail
            movementId={selectedMovementId}
            onBack={handleBackToGrid}
            onChildSelect={handleMovementSelect}
          />
        ) : (
          <MovementPreviewGrid
            movements={movements}
            onMovementSelect={handleMovementSelect}
          />
        )}
      </div>
      {!selectedMovementId && (
        <Pagination
          count={totalPages}
          page={pageNum}
          onChange={handleChangePage}
          className="flex justify-center"
        />
      )}
    </div>
  );
};

export default DaylContent;
