import Carousel from "react-multi-carousel";
import Fav from "../general/Fav";
import { Link } from "react-router-dom";
import { URLDayl, URLDaylImage } from "../../services/constantes";

import CarouselPainting from "../general/CarouselPainting";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router";

export default function MatchedCarousel({ paintId }) {
  const [matchList, setMatchList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(
        URLDayl + `matchingSearch/findMatchForSource/${"STYLE"}/${paintId}/true`
      )
      .then((response2) => {
        // axios.get(URLDayl + `matchingSearch/findMatchForSource/${matchingType.value}/${response.data.aw_idtf_intern}/true`).then((response2) => {

        const boolArray = Object.values(response2.data.payload).map((el) => {
          return { ...el, boolVis: true };
        });
        setMatchList(boolArray);
      });
  }, [paintId]);
  return (
    <div className="MostMatched">
      {/* <!-- ******************************************************
			SECTION SECTION LES PLUS MATCHÉES - début
			****************************************************** --> */}
      <section className=" center-col cont-sty-1 mb-c ">
        {/* <!-- informations et actions - début --> */}
        <div className="cont-l-w">
        <h2 className=" dot mb-a explorerTitleH">
          <a
            className="underlineTrans"
            onClick={() =>
              navigate(`../../view/MatchingPage.js/${paintId}`, {

              })
            }
            style={{
              color: "black",
            }}
          >
            oeuvre similaires
          </a>
          </h2>
        </div>
        {/* <!-- informations et actions - fin --> */}

        {/* <!-- carouse                                    <Link className="discover" to={`../../view/ListAmateurPage.js/${list.id}`} state={list.id}>{list.listName}</Link>
l - début --> */}
        {matchList && matchList.length > 0 && (
          <CarouselPainting paintings={matchList} artistName={true} />
        )}
        {/* <!-- carousel - fin --> */}
      </section>
      {/* <!-- SECTION LES PLUS MATCHÉES - fin --> */}
    </div>
  );
}
