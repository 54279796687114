import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FriendRequest from "../SocialMedia/FriendRequest";
import { enumTypeModalNetwork } from "../CompteAmateurPage/ModalNetwork";
import { useEffect, useState } from "react";
import { useUser } from "../../contexts/userService";
import axios from "axios";
import { URLDayl } from "../../services/constantes";
import { Modal } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ModalNetwork2({
  user,
  setBoolNetWork,
  boolNetWork,
  typeModalNetwork,
  refreshKey,
  setRefreshKey,
}) {
  const [value, setValue] = React.useState(typeModalNetwork);
  useEffect(() => {
    setValue(typeModalNetwork);
  }, [boolNetWork]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [isActive, setIsActive] = useState(typeModalNetwork);

  const [data, setData] = useState([]);
  const auth = useUser();
  const [stats, setStats] = useState();

  useEffect(() => {
    if (auth.data) {
      switch (isActive) {
        case enumTypeModalNetwork.Followers:
          axios
            .get(URLDayl + `friends/listOfFollowers`, {
              params: { userId: user.id },
            })
            .then((res) => {
              setData(res.data);
            });
          break;
        case enumTypeModalNetwork.Following:
          axios
            .get(URLDayl + `friends/listOfFollowing`, {
              params: { userId: user.id },
            })
            .then((res) => {
              setData(res.data);
            });
          break;
        case enumTypeModalNetwork.Requests:
          axios
            .get(URLDayl + `friends/listRequests`, {
              headers: {
                Authorization: `Bearer ${
                  auth.data && localStorage.getItem("jwt")
                }`,
              },
            })
            .then((res) => {
              setData(res.data);
            });
      }

      axios
        .get(URLDayl + `friends/statistics/${auth.data && auth.data.id}`)
        .then((res) => {
          setStats(res.data);
        });
    }
  }, [typeModalNetwork, isActive, refreshKey]);
  const isOwnProfile = user.id === auth.data.id;
  return auth.data ? (
    <Modal
      style={{ marginTop: 50, padding: 50, marginBottom: 40 }}
      open={boolNetWork}
      onClose={() => setBoolNetWork(!boolNetWork)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          width: "100%",
          backgroundColor: "background.paper",
          borderRadius: 2,
          height: "90%",
          marginTop: 5,
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider", padding: 5 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            centered
          >
            {isOwnProfile && (
              <Tab
                label="Demandes d'ajout"
                {...a11yProps(0)}
                onClick={() => {
                  setIsActive(enumTypeModalNetwork.Requests);
                }}
              />
            )}
            <Tab
              label="Abonnés"
              {...a11yProps(isOwnProfile ? 1 : 0)}
              onClick={() => {
                setIsActive(enumTypeModalNetwork.Followers);
              }}
            />
            <Tab
              label="Abonnements"
              {...a11yProps(isOwnProfile ? 2 : 1)}
              onClick={() => {
                setIsActive(enumTypeModalNetwork.Following);
              }}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={isOwnProfile ? 0 : -1}>
          <h4 className="mb-b">Demandes d'ajout</h4>
          <div style={{ display: "flex", gap: 10, flexDirection: "column" }}>
            {data &&
              data.length > 0 &&
              data.map((el) => {
                return (
                  <FriendRequest
                    user={user}
                    friendId={el.userId}
                    key={el.userId}
                    typeFriendModal={enumTypeModalNetwork.Requests}
                    refreshKey={refreshKey}
                    setRefreshKey={setRefreshKey}
                  />
                );
              })}
          </div>
          {/* <!-- DÉTAILS CONTACT DU RESEAU - fin --> */}
        </TabPanel>
        <TabPanel value={value} index={isOwnProfile ? 1 : 0}>
          <h4 className="mb-b">Abonnés ({data && data.length})</h4>
          <div style={{ display: "flex", gap: 10, flexDirection: "column" }}>
            {data &&
              data.length > 0 &&
              data.map((el) => {
                return (
                  <FriendRequest
                    user={user}
                    friendId={el.friendId}
                    key={el.friendId}
                    typeFriendModal={enumTypeModalNetwork.Followers}
                    refreshKey={refreshKey}
                    setRefreshKey={setRefreshKey}
                  />
                );
              })}
          </div>
        </TabPanel>
        <TabPanel value={value} index={isOwnProfile ? 2 : 1}>
          <h4 className="mb-b">Abonnemment ({data && data.length})</h4>
          <div style={{ display: "flex", gap: 10, flexDirection: "column" }}>
            {data &&
              data.length > 0 &&
              data.map((el) => {
                return (
                  <FriendRequest
                    user={user}
                    friendId={el.friendId}
                    key={el.friendId}
                    typeFriendModal={enumTypeModalNetwork.Following}
                    refreshKey={refreshKey}
                    setRefreshKey={setRefreshKey}
                  />
                );
              })}
          </div>
        </TabPanel>
      </Box>
    </Modal>
  ) : (
    <div></div>
  );
}
