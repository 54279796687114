import AutoCompleteCustom from "../PaintingSearch/AutoCompleteCustom";
import { useEffect, useState } from "react";
import axios from "axios";
import { URLDayl } from "../../services/constantes";

export default function SearchBarPaintings({
  onSubmit,
  searchDTO,
  setSearchDTO,
}) {
  const [listGenres, setListGenres] = useState([]);
  const [listStyles, setListStyles] = useState([]);
  const [listNationality, setListNationality] = useState([]);

  const [artworkTextOptions, setArtworkTextOptions] = useState([]);
  const [artistTextOptions, setArtistTextOptions] = useState([]);

  useEffect(() => {
    axios.get(URLDayl + `paintingFront/listGenres`).then((res) => {
      var temp = res.data.map((el) => {
        return {
          name: el.name,
        };
      });

      setListGenres(temp.sort((a, b) => -b.name.localeCompare(a.name)));
    });
    axios.get(URLDayl + `movement/getMovements`).then((res) => {
      setListStyles(
        res.data
          .map((el) => {
            return { name: el.name };
          })
          .sort((a, b) => -b.name.localeCompare(a.name))
      );
    });

    axios
      .get(URLDayl + `paintingFront/listOriginsAndNationalities`)
      .then((res) => {
        setListNationality(
          res.data.sort().map((el) => {
            return { name: el };
          })
        );
      });
  }, []);

  const onInputChangeArtwork = async (e, searchedtext) => {
    setArtworkTextOptions([]);

    if (searchedtext) {
      await axios
        .get(
          URLDayl + `paintingSearch/findPaintingByPrefix?prefix=${searchedtext}`
        )
        .then((res) => {
          setArtworkTextOptions(
            res.data.map((el) => {
              return {
                name: el.name,
                type: "Painting",
                id: el.id,
              };
            })
          );
        });
    }
  };

  const onInputChangeStyle = async (e, searchedtext) => {
    if (searchedtext === "" || searchedtext === null) {
      axios.get(URLDayl + `movement/getMovements`).then((res) => {
        setListStyles(
          res.data
            .map((el) => {
              return { name: el.name };
            })
            .sort((a, b) => -b.name.localeCompare(a.name))
        );
      });
    } else {
      setListStyles([]);

      if (searchedtext) {
        await axios
          .get(
            URLDayl +
              `paintingSearch/findStyleByPrefix?occurrence=${searchedtext}`
          )
          .then((res) => {
            setListStyles(
              res.data.map((el) => {
                return {
                  name: el.styleName,
                };
              })
            );
          });
      }
    }
  };

  const onInputChangeArtist = async (e, searchedtext) => {
    setArtistTextOptions([]);

    if (searchedtext) {
      await axios
        .get(URLDayl + `artistSearch/findByPrefix?occurence=${searchedtext}`)
        .then((res) => {
          setArtistTextOptions(
            res.data.map((el) => {
              return {
                name: el.artist_name_tot,
                type: "Artist",
                id: el.artistIdtfIntern,
              };
            })
          );
        });
    }
  };

  const onSelectArtwork = (e, item) => {
    if (item !== null)
      setSearchDTO((previous) => ({ ...previous, aw_name: item.name }));
    else {
      setSearchDTO((previous) => ({ ...previous, aw_name: null }));
    }
  };

  const onSelectNationality = (e, item) => {
    if (item !== null)
      setSearchDTO((previous) => ({ ...previous, nationality: item.name }));
    else {
      setSearchDTO((previous) => ({ ...previous, nationality: null }));
    }
  };

  const onSelectArtist = (e, item) => {
    console.log(item);

    if (item != null)
      setSearchDTO((previous) => ({ ...previous, artistIdtf: item.id }));
    else {
      setSearchDTO((previous) => ({ ...previous, artistIdtf: null }));
    }
  };

  const onSelectGenre = (e, item) => {
    if (item !== null)
      setSearchDTO((previous) => ({ ...previous, genre: item.name }));
    else {
      setSearchDTO((previous) => ({ ...previous, genre: null }));
    }
  };

  const onSelectStyle = (e, item) => {
    if (item != null)
      setSearchDTO((previous) => ({ ...previous, style: item.name }));
    else {
      setSearchDTO((previous) => ({ ...previous, style: null }));
    }
  };

  return (
    <section
      className="center-col"
      style={{ paddingBottom: 30, marginBottom: 50 }}
    >
      <form>
        <div id="freeSearchMega" className="freeSearchMega">
          {/* <!-- Choisir un style d'oeuvre - début --> */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 20,
              marginTop: "20px",
              justifyContent: "center",
            }}
          >
            <input
              className="searchFilterDarkBg inputSmall"
              placeholder={"Period start"}
              onChange={(e) => {
                setSearchDTO((previous) => ({
                  ...previous,
                  periodStart: e.target.value,
                }));
              }}
            ></input>
            <input
              className="searchFilterDarkBg inputSmall"
              placeholder={"Period end"}
              onChange={(e) => {
                setSearchDTO((previous) => ({
                  ...previous,
                  periodEnd: e.target.value,
                }));
              }}
            ></input>
          </div>
          <div>
            <AutoCompleteCustom
              defaultValue={searchDTO.style ? { name: searchDTO.style } : null}
              onChange={onInputChangeStyle}
              placeholder={"Style"}
              onSelect={onSelectStyle}
              options={listStyles}
              className="whiteAutoComplete "
            />
          </div>
          {/* <!-- Choisir un style d'oeuvre - fin --> */}

          {/* <!-- Choisir un genre d'oeuvre - début --> */}
          <div>
            <AutoCompleteCustom
              defaultValue={searchDTO.genre ? { name: searchDTO.genre } : null}
              onChange={() => {}}
              placeholder={"Genre"}
              onSelect={onSelectGenre}
              options={listGenres}
              className="whiteAutoComplete "
            />
          </div>

          <input
            style={{ marginTop: "20px" }}
            type="text"
            className="searchFilterDarkBg"
            placeholder="Mot-clé"
            onChange={(e) => {
              setSearchDTO((previous) => ({
                ...previous,
                keyword: e.target.value,
              }));
            }}
          />

          {/* <!-- Bouton de lancement de recherche - début --> */}
          <div data-tooltip="Lancer la recherche" style={{ marginTop: "20px" }}>
            <input className="squareSub" value="" onClick={() => onSubmit()} />
          </div>
          <div
            data-tooltip="Réinitialiser tous les filtres de recherche"
            style={{ marginTop: "20px" }}
          >
            <input onClick={(e) => {}} className="squareReset" value="" />
          </div>
        </div>
        <div
          id={"freeSearchMega"}
          className={"freeSearchMega"}
          style={{ justifyContent: "center" }}
        >
          <div>
            <AutoCompleteCustom
              defaultValue={
                searchDTO.artistIdtf ? { name: searchDTO.artistIdtf } : null
              }
              onChange={onInputChangeArtist}
              placeholder={"Artist"}
              onSelect={onSelectArtist}
              options={artistTextOptions}
              className="whiteAutoComplete larger"
              customWidth={280}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <AutoCompleteCustom
              defaultValue={
                searchDTO.aw_name ? { name: searchDTO.aw_name } : null
              }
              onChange={onInputChangeArtwork}
              placeholder={"Artwork name"}
              onSelect={onSelectArtwork}
              options={artworkTextOptions}
              className="whiteAutoComplete "
              customWidth={280}
            />
          </div>
          <div>
            <AutoCompleteCustom
              defaultValue={
                searchDTO.nationality ? { name: searchDTO.nationality } : null
              }
              onChange={() => {}}
              placeholder={"Nationality"}
              onSelect={onSelectNationality}
              options={listNationality}
              className="whiteAutoComplete "
            />
          </div>

          <div>
            <select
              className="searchFilterDarkBg"
              style={{ marginTop: "20px" }}
              id="style"
              name="style"
              onChange={(e) => {
                setSearchDTO((previous) => ({
                  ...previous,
                  sexe: e.target.value,
                }));
              }}
            >
              <option value="" selected>
                Sexe
              </option>
              <option value="female">Female</option>
              <option value="male">Male</option>
            </select>
          </div>
          <div>
            <select
                className="searchFilterDarkBg"
                style={{ marginTop: "20px" }}
                id="style"
                name="style"
                onChange={(e) => {
                  setSearchDTO((previous) => ({
                    ...previous,
                    hd: e.target.value,
                  }));
                }}
            >
              <option value="" selected>
                HD
              </option>
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </div>
        </div>
      </form>
    </section>
  );
}
