import { withStyles } from "@mui/styles";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import React, { useState, useEffect, useRef } from "react";
import { URLDaylImage } from "../../services/constantes";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useUser } from "../../contexts/userService";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";

const styles = {};

const CustomProfileMenu = withStyles(styles)(Menu);

export default function ProfileMenuIcon() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [imageError, setImageError] = useState(false);

  const open = Boolean(anchorEl);
  const menuRef = useRef(null);
  const auth = useUser();
  const navigate = useNavigate();
  const [imageSrc, setImageSrc] = useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOutsideClick = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const Disconnect = (e) => {
    e.preventDefault();
    navigate("../view/Login.js");
    handleClose(e);
    localStorage.removeItem("id");
    localStorage.removeItem("jwt");
    auth.setData(undefined);

    toast.success("Vous vous êtes déconnecté avec succès!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await axios.get(
          URLDaylImage + `images/userIconImage`,
          {
            responseType: "blob",
            headers: {
              Authorization: `Bearer ${auth.data.jwt}`,
            },
          }
        );
        const imageBlob = response.data;
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setImageSrc(imageObjectURL);
      } catch (error) {
        setImageError(true);
      }
    };

    fetchImage();
  }, []);

  return (
    <React.Fragment>
      <div
        onMouseEnter={handleClick}
        onClick={() => {
          navigate("../../view/CompteAmateurPage.js");
        }}
      >
        {imageError ? (
          <AccountCircleIcon style={{ color: "black", fontSize: 40 }} />
        ) : (
          <img
            style={{
              borderRadius: 50,
              height: 50,
              width: 50,
              objectFit: "cover",
            }}
            src={imageSrc}
            alt="profile"
          />
        )}
      </div>

      <CustomProfileMenu
        ref={menuRef}
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          right: 50,
          top: 50,
          zindex: 9999,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClose={handleClose}
          onClick={(e) => {
            navigate("/view/compteAmateurPage.js");
            handleClose(e);
          }}
        >
          <Avatar /> My account
        </MenuItem>
        {auth.data && auth.data.userType === "expert" && (
          <MenuItem
            onClose={handleClose}
            onClick={(e) => {
              navigate("/view/CompteExpertPage.js/1");
              handleClose(e);
            }}
          >
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            Compte expert
          </MenuItem>
        )}
        <MenuItem onClose={handleClose} onClick={Disconnect}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </CustomProfileMenu>
    </React.Fragment>
  );
}
