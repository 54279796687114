import React from "react";
import CustomGaleryCarousel from "./CustomGaleryCarousel/CustomGaleryCarousel";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

/**
 * Artist Biography in ArtistPage
 * @param {string} bg - random image from this artist
 * @param {object} artist - artist informations from api
 * @param {JSX.Element} text - artist informations text
 */

export default function BiographieElement({
  text,
  direction,
  title,
  imageList,
  setIndex,
  titleSize = "text-xl",
  canZoom = "true",
}) {
  return direction === "left" ? (
    <>
      <div
        className={"swiperCont"}
        id={"swiperContElementsBiographies"}
        style={{ justifyContent: "center" }}
      >
        <div className={"swiperCarousel"}>
          <div>
            <CustomGaleryCarousel imageList={imageList} canZoom={canZoom} />
          </div>
        </div>
        <div>
          <div className={`${titleSize} mb-5 text-center italic font-bold`}>
            {title}
          </div>
          <div
            className={"swiperText"}
            style={{
              alignSelf: "center",
              maxWidth: "48vw",
              width: "95%",
              margin: "auto",
            }}
          >
            <Swiper modules={[Navigation]} navigation={true}>
              {text.map((el, index, array) => {
                const isSingleElement = array.length === 1;
                if (isSingleElement)
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "0 60px",
                        whiteSpace: "pre-wrap",
                        fontSize: "22px",
                        fontFamily: "Raleway",
                      }}
                    >
                      {text}
                    </div>
                  );
                else
                  return (
                    <SwiperSlide key={index}>
                      <p
                        style={{
                          padding: "0 60px",
                          whiteSpace: "pre-wrap",
                          fontSize: "22px",
                          fontFamily: "Raleway",
                        }}
                      >
                        {el}
                      </p>
                    </SwiperSlide>
                  );
              })}
            </Swiper>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div
        className={"swiperCont"}
        id={"swiperContElementsBiographies"}
        style={{ justifyContent: "center" }}
      >
        <div>
          <div className={`${titleSize} mb-5 text-center italic font-bold`}>
            {title}
          </div>
          <div
            className={"swiperText"}
            style={{
              alignSelf: "center",
              maxWidth: "45vw",
              width: "95%",
              margin: "auto",
            }}
          >
            <Swiper modules={[Navigation]} navigation={true}>
              {text.map((el, index, array) => {
                const isSingleElement = array.length === 1;
                if (isSingleElement)
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "0 60px",
                        whiteSpace: "pre-wrap",
                        fontSize: "22px",
                        fontFamily: "Raleway",
                      }}
                    >
                      {text}
                    </div>
                  );
                else
                  return (
                    <SwiperSlide key={index}>
                      <p
                        style={{
                          padding: "0 60px",
                          whiteSpace: "pre-wrap",
                          fontSize: "22px",
                          fontFamily: "Raleway",
                        }}
                      >
                        {el}
                      </p>
                    </SwiperSlide>
                  );
              })}
            </Swiper>
          </div>
        </div>
        <div className={"swiperCarousel"}>
          <div>
            <CustomGaleryCarousel
              imageList={imageList}
              setIndex={setIndex}
              canZoom={canZoom}
            />
          </div>
        </div>
      </div>
    </>
  );
}
