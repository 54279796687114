import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import axios from "axios";
import { URLDayl, URLDaylImage } from "../../services/constantes";

export default function PaintingSearchAsyncSelect({ setActive }) {
  const [options, setOptions] = useState([]);
  const navigate = useNavigate();
  const [movements, setMovements] = useState([]);

  useEffect(() => {
    // Fetch movements
    axios.get(URLDayl + "paintingFront/listStyles").then((res) => {
      setMovements(res.data.map((el) => el.name));
    });
  }, []);

  const onChange = async (e, searchedtext) => {
    if (!searchedtext) {
      setOptions([]);
      return;
    }

    try {
      const [paintings, artists, hashtags, users] = await Promise.all([
        axios.get(
          URLDayl + `paintingSearch/findPaintingByPrefix?prefix=${searchedtext}`
        ),
        axios.get(
          URLDayl +
            `artistSearch/findByPrefixOptimised?occurence=${searchedtext}&numberOfArtists=5`
        ),
        axios.get(
          URLDayl +
            `publication/searchPrefixByHashtag?prefix=%23${searchedtext}`
        ),
        axios.post(URLDayl + `users/searchUsersByText`, {
          freeText: searchedtext,
        }),
      ]);

      const filteredMovements = movements
        .filter((str) =>
          str.toLowerCase().startsWith(searchedtext.toLowerCase())
        )
        .slice(0, 5);

      const newOptions = [
        ...paintings.data
          .slice(0, 5)
          .map((el) => ({ title: el.name, type: "Painting", id: el.id })),
        ...artists.data.map((el) => ({
          title: el.artist_name_tot,
          type: "Artist",
          id: el.id,
        })),
        ...hashtags.data.map((el) => ({ title: el, type: "Hashtag", id: el })),
        ...users.data.map((el) => ({
          title: `${el.firstName} ${el.lastName}`,
          type: "Users",
          id: el.id,
        })),
        ...filteredMovements.map((el) => ({
          title: el,
          type: "Style",
          id: el,
        })),
      ];

      setOptions(newOptions);
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  const onSelect = (e, item) => {
    if (item) {
      if (item.type === "Painting")
        navigate(`../../view/OpusPage.js/${item.id}`, { state: item.id });
      else if (item.type === "Artist")
        navigate(`../../view/ArtistDetailPage.js/${item.id}`, {
          state: item.id,
        });
      else if (item.type === "Style")
        navigate(`../../view/paintingSearchByStyle.js/${item.id}`);
      else if (item.type === "Users")
        navigate(`../../view/ProfileAmateur.js/${item.id}`);
      else navigate(`../../view/SearchByHashtag.js/${item.id.substring(1)}`);
      setActive(false);
    }
  };

  return (
    <Autocomplete
      style={{
        position: "fixed",
        color: "black",
        top: "10%",
        left: "25%",
        zIndex: 99999999,
        width: "50%",
        borderColor: "white",
        backgroundColor: "white",
        borderRadius: 10,
      }}
      openOnFocus={false}
      selectOnFocus={true}
      color={"black"}
      onChange={(e, value) => onSelect(e, value)}
      onInputChange={(e, value) => onChange(e, value)}
      options={options.sort((a, b) => -b.type.localeCompare(a.type))}
      groupBy={(option) => option.type}
      getOptionLabel={(option) => option.title}
      sx={{ width: 300, color: "black" }}
      renderInput={(params) => (
        <TextField
          autoFocus={true}
          {...params}
          label=""
          placeholder="Search"
          className={"paintingAutoSelect"}
          style={{ color: "black" }}
        />
      )}
      renderOption={(params, option) => (
        <div
          {...params}
          style={{
            display: "flex",
            color: "black",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <span>{option.title}</span>{" "}
          <img
            style={{ height: 80, width: 80, borderRadius: 10 }}
            src={
              URLDaylImage +
              `images/${option.type === "Artist" ? "artistPic" : "paintingPic"}/${option.id}`
            }
            alt="artist"
          />
        </div>
      )}
    />
  );
}
