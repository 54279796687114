import { Link } from "react-router-dom";
import { URLDaylImage } from "../../services/constantes";
import { useUser } from "../../contexts/userService";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

function CustomNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ChevronRightIcon
      className={`${className} carousel-painting-arrows-custom-next`}
      style={{ ...style, color: "black", fontSize: 60 }}
      onClick={onClick}
    />
  );
}

function CustomPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ChevronLeftIcon
      className={`${className} carousel-painting-arrows-custom-prev`}
      style={{ ...style, color: "black", fontSize: 60 }}
      onClick={onClick}
    />
  );
}

export default function CarouselPainting({
  paintings,
  artistName,
  slidesToShowOverride = null,
  width = "85%",
  size = "250px",
  isHidden = false,
}) {
  const auth = useUser();

  var settings = {
    infinite: true,
    speed: 500,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 750, // width to change options
        settings: {
          slidesToShow: slidesToShowOverride || Math.min(1, paintings.length),
          slidesToScroll: slidesToShowOverride || Math.min(1, paintings.length),
        },
      },
      {
        breakpoint: 1000, // width to change options
        settings: {
          slidesToShow: slidesToShowOverride || Math.min(2, paintings.length),
          slidesToScroll: slidesToShowOverride || Math.min(2, paintings.length),
        },
      },
      {
        breakpoint: 1250, // width to change options
        settings: {
          slidesToShow: slidesToShowOverride || Math.min(3, paintings.length),
          slidesToScroll: slidesToShowOverride || Math.min(3, paintings.length),
        },
      },
      {
        breakpoint: 1500, // width to change options
        settings: {
          slidesToShow: slidesToShowOverride || Math.min(4, paintings.length),
          slidesToScroll: slidesToShowOverride || Math.min(4, paintings.length),
        },
      },
      {
        breakpoint: 1750, // width to change options
        settings: {
          slidesToShow: slidesToShowOverride || Math.min(5, paintings.length),
          slidesToScroll: slidesToShowOverride || Math.min(5, paintings.length),
        },
      },
      {
        breakpoint: 9999, // a high value to cover widths above the last breakpoint
        settings: {
          slidesToShow: slidesToShowOverride || Math.min(6, paintings.length),
          slidesToScroll: slidesToShowOverride || Math.min(6, paintings.length),
        },
      },
    ],
  };

  return (
    <div style={{ width: width, margin: "auto" }}>
      <Slider {...settings}>
        {paintings.length > 0 &&
          paintings.map((matchPaint, index) => (
            <div className="carousel-slide" key={index}>
              <div
                className="carousel-card"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {/* <Fav user={auth.data} isLogged={auth.data !== undefined} favPaint={matchPaint}/>*/}
                <div
                  className={`bt-wrapper ${isHidden ? "blur-sm" : ""}`}
                  style={{
                    backgroundImage: `url(${
                      URLDaylImage + "images/paintingPic/mini/" + matchPaint.id
                    })`,
                    width: size,
                    height: size,
                    minWidth: "250px",
                    minHeight: "250px",
                  }}
                >
                  <div className="overlay">
                    <div className="carousel-title">
                      {matchPaint.name}
                      <div className="date">{matchPaint.year}</div>
                    </div>
                    <div className="carousel-action">
                      <Link
                        className="bt-action voir"
                        to={`../../view/OpusPage.js/${matchPaint.id}`}
                        state={matchPaint.id}
                      >
                        voir
                      </Link>
                      <Link
                        className="bt-action match"
                        to={`../../view/MatchingPage.js/${matchPaint.id}`}
                      >
                        match
                      </Link>
                    </div>
                  </div>
                </div>
                {artistName && (
                  <div className="carousel-artist-name">
                    <Link
                      to={`../../view/ArtistDetailPage.js/${matchPaint.artistId}`}
                      state={matchPaint.artistId}
                    >
                      {matchPaint.artisteName}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          ))}
      </Slider>
    </div>
  );
}
