import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { URLDayl } from "../../services/constantes";
import axios from "axios";
import AsyncSelect from "react-select/async";
import { ArtistStatementCompteExpert } from "./ArtistStatement/ArtistStatementCompteExpert";
import { ContenuWikipedia } from "./ContenuWikipedia";
import { toast } from "react-toastify";
import { ElementsBiographies } from "./ElementsBiographies";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { AvisExperts } from "./AvisExperts/AvisExperts";
import { useCompteExpert } from "../../contexts/CompteExpertService";
import numberOneSvg from "../../webapp/img/icons/number-one.svg";
import numberTwoSvg from "../../webapp/img/icons/number-two.svg";
import AutoCompleteCustom from "../PaintingSearch/AutoCompleteCustom";
import {Button} from "@mui/material";
import ChoosePaintings from "./ChoosePaintings";
import SelectPaintingModal from "../general/SelectPaintingModal/SelectPaintingModal";

export const enumContenu = {
  ArtistStatement: 0,
  ElementBiographies: 1,
  ContenuWikipedia: 2,
  AvisExperts: 3,
};

export const PeintresCompteExpert = ({ user }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();
  const [artist, setArtist] = useState();
  const [searchPhrase, setSearchPhrase] = useState();
  const [keywords, setKeywords] = useState([]);
  const [keywordsToRemove, setKeywordsToRemove] = useState([]);
  const [keywordTemp, setKeywordTemp] = useState();
  const [originalKeywords, setOriginalKeywords] = useState([]);
  const [openModal, setOpenModal] = useState(false)
  const [selectedPaintings, setSelectedPaintings] = useState([])

  const compteExpert = useCompteExpert();
  const [options, setOptions] = useState([]);

  const [contenuEdition, setContenuEdition] = useState("0");

  const change = () => {
    if (artist) {
      switch (contenuEdition) {
        case "artisteStatement":
          return <ArtistStatementCompteExpert user={user} artist={artist} />;

        case "elementBiographie":
          return (
            <>
              <ElementsBiographies user={user} artist={artist} />
            </>
          );
        case "contenuWikipedia":
          return <ContenuWikipedia user={user} artist={artist} />;
        case "avisExperts":
          return <AvisExperts user={user} artist={artist} />;
        default:
          return <></>;
      }
    }
  };

  useEffect(() => {
    setContenuEdition("");
  }, [compteExpert.language]);

  let addKeywords = async () => {
    for (var i = 0; i < keywords.length; i++) {
      try {
        if (!originalKeywords.includes(keywords[i].name)) {
          await axios
            .post(URLDayl + "artist/update/keyword", {
              itemId: artist.id,
              operation: "add",
              keyword: keywords[i].name,
            })
            .then((response) => {})
            .catch((err) => {
              toast.error(
                "Artist could not be modified, please try again later.",
                {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                }
              );
            });
        }
      } catch (error) {
        console.log("keyword ", i);
      }
      console.log("axios request done");
    }
  };

  let removeKeywords = async () => {
    for (var i = 0; i < keywordsToRemove.length; i++) {
      try {
        await axios
          .post(URLDayl + "artist/update/keyword", {
            itemId: artist.id,
            operation: "remove",
            keyword: keywordsToRemove[i].name,
          })
          .then((response) => {})
          .catch((err) => {
            toast.error(
              "Artist could not be modified, please try again later.",
              {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          });
      } catch (error) {
        console.log("keyword ", i);
      }
      console.log("axios request done");
    }
  };

  const submitChangeArtist = async () => {
    await axios
      .post(URLDayl + "artist/update", artist)
      .then((response) => {
        addKeywords();

        removeKeywords();
        axios.get(URLDayl + `artist/${artist.id}`).then((response2) => {
          setArtist(response2.data);
          toast.success("Artist modified", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      })
      .catch((err) => {
        toast.error("Could not change artist", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  // const loadOptions = (inputValue) => {
  //     return fetch(
  //         URLDayl + `artistSearch/findByPrefix?occurence=Picasso`,
  //     ).then((res) => {
  //         res.json()
  //     });
  // };
  //
  // // const handleChange = value => {
  // //     setSelectedValue(value);
  // //     console.log(selectedValue)
  // //
  // //     //send the selected artist to the api
  // //     axios.get(URLDayl + `artist/${value.id}`,
  // //     ).then((responseArtist) => {
  // //         setArtist(responseArtist.data)
  // //     })
  // //
  // // }
  //
  // const handleChange = value => {
  //     setSelectedValue(value);
  //
  //     console.log(value)
  //
  //     axios.get(URLDayl + `artist/${value}`).then((response3) => {
  //         setArtist(response3.data)
  //
  //
  //     })
  //
  //
  // }

  // const onClickSubmit = () => {
  //     if (artistDetails.origin === artist.artist_origin)
  //         setArtistDetail({origin: null, dob: artistDetails.dob, dod: artistDetails.dod, motsClefs: null})
  //     if (artistDetails.dob === artist.artist_birthyear)
  //         setArtistDetail({origin: artistDetails.origin, dob: null, dod: artistDetails.dod, motsClefs: null})
  //     if (artistDetails.dod === artist.artist_deathyear)
  //         setArtistDetail({origin: artistDetails.origin, dob: artistDetails.dob, dod: null, motsClefs: null})
  //
  //     console.log(artist.id)
  //
  //     axios.post(URLDayl + "artist/update",
  //         {
  //             id: artist.id,
  //             artist_birthyear: null,
  //             artist_deathyear: null,
  //             artist_origin: null,
  //             artist_desc_life_1: null,
  //             myOwnDescription: null
  //         }, {headers: {"Access-Control-Allow-Origin": "*", "Content-Type": "application/json"}}
  //     ).then((response) => {
  //         console.log(response.status)
  //     })
  //
  // }

  // handle input change event for artist search

  const [inputValue, setNewValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);


  useEffect(() => {}, [artist]);

  // handle input change event
  const handleSelect = (e, value) => {
    if (value) {
      axios.get(URLDayl + `artist/${value.id}`).then((response) => {
        setArtist(response.data);
      });
    }
  };

  useEffect(() => {
    if (artist)
    {
      axios.get(URLDayl + "artist/topPainting", {params: {artistId: artist.id}}).then((res) => {
        setSelectedPaintings(res.data.map((el) => el.paintingId))
      })
    }
  },[artist])

  const onSubmitTopPaintings = () => {
    axios.post(URLDayl + "artist/topPaintingMultiple/save", {
      "paintId": selectedPaintings,
      "artistId": artist.id
    }).then((res) => {
      toast.success("Updated top paintings", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }).catch((err) => {

    })
  }

  const handleInputChange = async (e, value) => {
    if (value.length > 3) {
      const res = await fetch(
        URLDayl + `artistSearch/findByPrefix?occurence=${value}`
      );
      const data = await res.json();
      const renamedData = data.map((item) => ({
        ...item,
        name: item.artist_name_tot,
      }));

      setOptions(renamedData);
    } else {
      setOptions([]);
    }
  };

  return (
    <>
      <div className="editMain center-tex">
        <h1>Modifier une page Peintre</h1>
        <p className="how">
          Entrez le nom de l'artiste dont vous souhaitez modifier les
          informations puis sélectionnez la langue d'édition afin de faire
          apparaître le menu d'édition.
        </p>
      </div>

      <div className="editMain mt-b pb-a">
        <div className="inline">
          <form className="subOpt" style={{ marginTop: "30px" }}>
            <img src={numberOneSvg} />
            <p>Nom de l'artiste</p>

            {/*<input type="text" id="artistName" name="artistName"/>*/}

            <AutoCompleteCustom
              className={"thisisMyClass"}
              options={options}
              defaultValue={selectedValue}
              onSelect={handleSelect}
              onChange={handleInputChange}
              placeholder="Nom de l'artiste"
            />
          </form>

          <div className="subOpt" style={{ marginTop: "30px" }}>
            <img src={numberTwoSvg} />
            <p>Langue d'édition</p>
            <ul className="langEdit">
              <li
                className={
                  compteExpert.language === "FRENCH" ? "fr active" : "fr"
                }
                onClick={() => compteExpert.setLanguage("FRENCH")}
              >
                Français
              </li>
              <li
                className={
                  compteExpert.language === "ENGLISH" ? "en active" : "en"
                }
                onClick={() => compteExpert.setLanguage("ENGLISH")}
              >
                Anglais
              </li>
              <li
                className={
                  compteExpert.language === "SPANISH" ? "es active" : "es"
                }
                onClick={() => compteExpert.setLanguage("SPANISH")}
              >
                Espagnol
              </li>
            </ul>

            {/*                        <select name="année" value={compteExpert.language}
                                onChange={(e) => compteExpert.setLanguage(e.target.value)}>
                            <option value="">Langue</option>
                            <option value="FRENCH">Français</option>
                            <option value="ENGLISH">Anglais</option>
                            <option value="SPANISH">Espagnol</option>
                        </select>*/}
          </div>
        </div>
      </div>

      {artist && compteExpert.language !== "" && (
        <section style={{ width: "100%" }}>
          <form className="editMain darkBgGradient">
            <div className="editMain edit center-tex">
              <h2>Informations générales pour cet.te artiste</h2>

              <div className="editMain inline">
                <div>
                  <h3>Nom complet</h3>
                  <input
                    type="text"
                    size="55"
                    value={artist ? artist.artist_name_tot : ""}
                    onChange={(value) => {
                      setArtist((previous) => ({
                        ...previous,
                        artist_name_tot: value.target.value,
                      }));
                    }}
                    placeholder="Nationalité"
                  />
                </div>
                <div>
                  <h3>Nationalité</h3>
                  <input
                    type="text"
                    size="55"
                    value={artist ? artist.artist_nationality : ""}
                    onChange={(value) => {
                      setArtist((previous) => ({
                        ...previous,
                        artist_nationality: value.target.value,
                      }));
                    }}
                    placeholder="Nationalité"
                  />
                </div>

                {/* <!-- INPUT TEXT DATE DE NAISSANCE DE L'ARTISTE --> */}
                <div>
                  <h3>Date de naissance</h3>
                  <input
                    type="text"
                    size="4"
                    value={artist ? artist.artist_birthyear : ""}
                    onChange={(value) => {
                      setArtist((previous) => ({
                        ...previous,
                        artist_birthyear: parseInt(value.target.value),
                      }));
                    }}
                    placeholder="Date de naissance"
                  />
                </div>
                {/* <!-- INPUT TEXT DATE DE DÉCÈS DE L'ARTISTE --> */}
                <div>
                  <h3>Date de décès</h3>
                  <input
                    type="text"
                    size="4"
                    value={artist ? artist.artist_deathyear : ""}
                    onChange={(value) => {
                      setArtist((previous) => ({
                        ...previous,
                        artist_deathyear: parseInt(value.target.value),
                      }));
                    }}
                    placeholder="Date de décès"
                  />
                </div>
                {/* <!-- INPUT TEXT SEXE L'ARTISTE --> */}
                <div>
                  <h3>Sexe</h3>
                  <select
                    className="searchFilterDarkBg mb-a"
                    id="mostMatchedByMonth"
                    name="mostMatchedByMonth"
                    value={artist ? artist.artist_sex : ""}
                    onChange={(e) => {
                      setArtist((previous) => ({
                        ...previous,
                        artist_sex: e.target.value,
                      }));
                    }}
                  >
                    <option value="female">Female</option>
                    <option value="male" selected>
                      Male
                    </option>
                  </select>
                </div>
                <div>
                  <h3>hide</h3>
                  <select
                    className="searchFilterDarkBg mb-a"
                    id="mostMatchedByMonth"
                    name="mostMatchedByMonth"
                    value={artist ? artist.hideArtist : ""}
                    onChange={(e) => {
                      setArtist((previous) => ({
                        ...previous,
                        hideArtist: e.target.value,
                      }));
                    }}
                  >
                    <option value="true">true</option>
                    <option value="false" selected>
                      false
                    </option>
                  </select>
                </div>

              </div>
              <Button
                  variant="outlined"
                  style={{ color: 'white', border: 'solid' }}
                  onClick={setOpenModal}
              >
                Choose top paintings for artist
              </Button>
              {<SelectPaintingModal selected={selectedPaintings} setSelected={setSelectedPaintings} onSubmit={onSubmitTopPaintings} open={openModal}
                                    multiple setOpen={setOpenModal} selectedFile={false} setFile={() => {
              }} defaultSearchDTO={{artistIdtf: artist.artist_idtf_intern}}/>}

              {/*<div className="inline">*/}
              {/*    <div>*/}
              {/*        <h3>Birthplace</h3>*/}
              {/*        <input type="text" size="55"*/}
              {/*               value={artist ? artist.artist_birthplace_x : ''}*/}

              {/*               onChange={(value) => {*/}
              {/*                   setArtist((previous) => ({*/}
              {/*                       ...previous,*/}
              {/*                       artist_birthplace_x: value.target.value*/}
              {/*                   }))*/}
              {/*               }}*/}
              {/*               placeholder="Lieu de naissance"/>*/}
              {/*    </div>*/}

              {/*    /!* <!-- INPUT TEXT DATE DE NAISSANCE DE L'ARTISTE --> *!/*/}
              {/*    <div>*/}
              {/*        <h3>Deathplace</h3>*/}
              {/*        <input type="text" size="4" value={artist ? artist.artist_deathplace_x : ''}*/}
              {/*               onChange={(value) => {*/}
              {/*                   setArtist((previous) => ({*/}
              {/*                       ...previous,*/}
              {/*                       artist_deathplace_x: parseInt(value.target.value)*/}
              {/*                   }))*/}
              {/*               }}*/}
              {/*               placeholder="Lieu de déces"/>*/}
              {/*    </div>*/}
              {/*</div>*/}

              {/*<h3 className="pt-b">Mots-clés (Tags)</h3>*/}
              {/*<p className="howTo">Saisissez les tags un à un puis cliquez sur l'icone + pour l'ajouter à*/}
              {/*    cet.te artiste</p>*/}
              {/*<div style={{*/}
              {/*    display: "flex",*/}
              {/*    flexDirection: "row",*/}
              {/*    width: "350px",*/}
              {/*    flex: 1,*/}
              {/*    alignContent: "center",*/}
              {/*    alignItems: "center"*/}
              {/*}}>*/}
              {/*    <input type="text" style={{width: keywordTemp ? "95%" : "100%"}}*/}
              {/*           placeholder="Ex : expressionnisme, surréalisme …"*/}
              {/*           onChange={(e) => setKeywordTemp(e.target.value)}/>*/}
              {/*    <AiOutlinePlusCircle*/}
              {/*        size={100}*/}
              {/*        color={"white"}*/}
              {/*        onClick={() => {*/}
              {/*            setKeywords(key => [...key, {*/}
              {/*                name: keywordTemp,*/}
              {/*                id: keywords ? key[0] + keywords.length : 1*/}
              {/*            }])*/}
              {/*            setKeywordTemp(undefined)*/}
              {/*            // if (keywordTemp) {*/}
              {/*            //     setKeywords((previous) => [...previous, {*/}
              {/*            //         name: keywordTemp,*/}
              {/*            //         id: previous[0].id + previous.length*/}
              {/*            //     }*/}
              {/*            //     ])*/}
              {/*            //     setKeywordTemp(undefined)*/}
              {/*            // }}}*/}
              {/*        }}*/}
              {/*        style={{flex: 1, justifyContent: "center", alignSelf: "center"}}/>*/}
              {/*</div>*/}
              <div className="hashList toLeft mt-a mb-c">
                {keywords.map((item) => {
                  return (
                    <a
                      key={item.name}
                      style={{
                        backgroundColor: keywordsToRemove.includes(item)
                          ? "grey"
                          : null,
                      }}
                      onClick={() =>
                        keywordsToRemove.includes(item)
                          ? setKeywordsToRemove(
                              keywordsToRemove.filter((el) => el !== item)
                            )
                          : setKeywordsToRemove((key) => [...key, item])
                      }
                      href="#"
                    >
                      {item.name}
                    </a>
                  );
                })}
              </div>
            </div>
          </form>


        </section>

      )}

      {artist && compteExpert.language !== "" && (
        <>

          <div
            className="editMain redac lightBgGradient-2 center-tex pb-b"
            style={{ display: "block" }}
          >
            <h2 className="mt-a">Contenu rédactionnel sur l'artiste</h2>

            <select
              name="année"
              className="mt-a mb-b"
              onChange={(event) => setContenuEdition(event.target.value)}
              value={contenuEdition}
            >
              <option value="0">
                Choisissez un contenu rédactionnel à modifier
              </option>
              <option value="avisExperts">Avis d'expert</option>
              <option value="elementBiographie">Element biographie</option>
              <option value="2">Analyse chromatique</option>
              <option value="artisteStatement">Artiste statement</option>
              <option value="5">Actualités</option>
              <option value="contenuWikipedia">Contenu wikipedia</option>
            </select>

            {change()}
          </div>

          <div className="editMain darkBgGradient pt-b pb-c">
            <input
              type="submit"
              className="saveDraftBtn mt-a"
              value="Enregistrer les modifications"
              onClick={() => {
                submitChangeArtist();
              }}
            />
          </div>
        </>
      )}
    </>
  );
};
