import ImageGrid from "../component/general/ImageGrid/ImageGrid";
import { useUser } from "../contexts/userService";
import { useEffect, useState } from "react";
import axios from "axios";
import { URLDayl } from "../services/constantes";
import CompactSearchBarPaintings from "../component/general/CompactSearchBarPaintings";
import SingleSlideCarousel from "../component/general/SingleSlideCarousel";

export default function HDPaintings() {
  const auth = useUser();
  const [paintings, setPaintings] = useState([]);
  const [paintingsHD, setPaintingsHD] = useState();
  const [maxNumPainting, setMaxNumPainting] = useState(40);
  const [totalNumPainting, setTotalNumPainting] = useState(40);
  const [refreshKey, setRefreshKey] = useState(0);
  const [searchDTO, setSearchDTO] = useState(
    JSON.parse(sessionStorage.getItem("searchBarPaintingHD") || "{}")
  );

  const onSubmit = async () => {
    sessionStorage.setItem("searchBarPaintingHD", JSON.stringify(searchDTO));
    try {
      const tempDTO = {
        ...searchDTO,
        hd: true,
      };
      const { data } = await axios.post(
        `${URLDayl}paintingSearch/findPaintingBySearchDtoNewEdition`,
        tempDTO
      );

      setPaintings(data.payload);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    axios.get(URLDayl + `hd-paintings?page=0&size=20`).then((res) => {
      setPaintingsHD(res.data.content);
    });
  }, []);

  useEffect(() => {
    const temp = JSON.parse(sessionStorage.getItem("searchBarPaintingHD"));
    setSearchDTO(temp);
    if (!temp) setSearchDTO({});
    if (temp === null || temp === undefined) {
      axios
        .get(URLDayl + `paintingSearch/getHDPaintings?num=${maxNumPainting}`)
        .then((res) => {
          setTotalNumPainting(res.data.numberMax);
          setPaintings(res.data.payload);
        });
    } else {
      onSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxNumPainting]);

  return (
    <div>
      <h1 className="text-4xl font-cinzel text-center mb-12 text-gray-900 mt-10">
        HD Paintings
      </h1>
      {paintingsHD && (
        <SingleSlideCarousel
          paintings={paintingsHD}
          artistName={false}
          arrowColor="black"
          height="25vw"
        />
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          borderRadius: 20,
          gap: 20,
          flex: 1,
          marginTop: 50,
          flexDirection: "column",
          alignItems: "center",
        }}
        className={""}
      >
        <CompactSearchBarPaintings
          searchDTO={searchDTO}
          setSearchDTO={setSearchDTO}
          onSubmit={onSubmit}
        />
      </div>
      <ImageGrid
        user={auth.data}
        isLogged={auth.data !== undefined}
        paintings={paintings}
        showTools={true}
        totalMaxNum={totalNumPainting}
        selectionMode={false}
        maxNumPainting={maxNumPainting}
        setMaxNumPainting={setMaxNumPainting}
        refreshKey={refreshKey}
        setRefreshKey={setRefreshKey}
      />
    </div>
  );
}
