import { useEffect, useState } from "react";
import SockJS from "sockjs-client";
import Stomp from 'stompjs';
import { Button } from "@mui/material";
import { useUser } from "../contexts/userService";
import TextField from "@mui/material/TextField";

export default function ChatPage() {
    const [stompClient, setStompClient] = useState(null);
    const [connected, setConnected] = useState(false);
    const [friendId, setFriendId] = useState(0)
    const auth = useUser();

    const [messages, setMessages] = useState([])


    useEffect(() => {
        const socket = new SockJS("http://localhost:8081/chat");
        const temp = Stomp.over(socket);
        setStompClient(temp);

        if (auth.data.jwt) {
            temp.connect({"Bearer": auth.data.jwt, "recipient": friendId},
                function(frame) {
                    console.log('Connected: ' + frame);
                    setConnected(true);

                    temp.subscribe('/users/queue/notification', function(message) {

                        setMessages((previous) => [...previous, JSON.parse(message.body)])
                        console.log(messages)
                        // Further processing or updating the state can be done here
                    }, {"Bearer": auth.data.jwt, "recipient": friendId});
                },
                function(error) {
                    console.log("ERROR");
                    console.log(error);
                }
            );
        }

        return () => {
            if (temp && connected) {
                temp.disconnect();
                console.log('Disconnected');
                setConnected(false);
            }
        };
    }, [auth.data.jwt]);

    const sendMessage = () => {
        if (stompClient && connected) {
            stompClient.send(
                '/app/directMessage',
                {},
                JSON.stringify({
                    recipient: friendId,
                    content: "this is a message",
                    emoji: ":-))",
                    priority: "HIGH"
                })
            );
        }
    };

    return (
        <div style={{ justifyContent: "center", alignSelf: "center", marginTop: 500 }}>
            <h1>Chat Application</h1>
            {/*<Chat />*/}
            <TextField value={friendId} onChange={(e) => {setFriendId(e.target.value)}}>

            </TextField>
            <Button
                variant="outlined"
                color="primary"
                style={{ border: 'solid' }}
                onClick={sendMessage}
            >
                Accepter
            </Button>

            {messages.map((el, index) => <p key={index}>{el.content}</p>)}
        </div>
    );
}
