import React, { useEffect, useState } from "react";
import axios from "axios";
import { URLDayl } from "../../services/constantes";
import ImageGrid, { enumTypeGrid } from "../general/ImageGrid/ImageGrid";
import { Button } from "@mui/material";
import { useUser } from "../../contexts/userService";

export default function ListPaintArtist({
  setUser,
  paintId,
  isLogged,
  isHidden,
}) {
  const [listPaintArtist, setListPaintArtist] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [maxNumberPainting, setMaxNumberPainting] = useState(30);
  const [totalNumberPainting, setTotalNumberPainting] = useState(0);
  // const [sort, setSort] = useState(localStorage.getItem("artistDate") ? JSON.parse(localStorage.getItem("artistDate")).artist === paintId ? JSON.parse(localStorage.getItem("artistDate")).sort : "" : "");
  const [sort, setSort] = useState("");
  const [year, setYear] = useState();

  const auth = useUser();
  useEffect(() => {
    if (year !== undefined) {
      localStorage.setItem(
        "artistDate",
        JSON.stringify({
          artist: paintId,
          start: year.startYear,
          end: year.endYear,
          sort: sort,
        })
      );
    }
  }, [refreshKey, sort, year, paintId]);

  useEffect(() => {
    setYear(undefined);
  }, [paintId]);

  //get list paint artist//
  useEffect(() => {
    async function fetchData() {
      let start = "";
      let end = "";

      if (year) {
        start = year.startYear;
        end = year.endYear;
      } else {
        const date = localStorage.getItem("artistDate");
        if (date) {
          const a = JSON.parse(date);
          if (a.artist === paintId) {
            await setYear({ startYear: a.start, endYear: a.end });
            start = a.start;
            end = a.end;
          } else {
            localStorage.removeItem("artistDate");
          }
        }
      }

      try {
        const response = await axios.get(
          `${URLDayl}paintingSearch/findPaintingByArtistIdtfn?artistId=${paintId}&number=${maxNumberPainting}&minNumPainting=0&sort=${sort}&startYear=${start}&endYear=${end}`
        );

        setTotalNumberPainting(response.data.numberMax);

        const boolArray = response.data.payload.map((el) => ({
          ...el,
          bool: false,
          boolList: false,
        }));

        setListPaintArtist(boolArray);
        sessionStorage.setItem("viewMoreX", maxNumberPainting.toString());
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    }

    fetchData();
  }, [paintId, maxNumberPainting, sort, refreshKey, year]);
  return (
    <div style={{ margin: "auto" }}>
      <ImageGrid
        user={auth.data}
        isLogged={isLogged}
        paintings={listPaintArtist}
        showTools={true}
        selectionMode={false}
        maxNumPainting={maxNumberPainting}
        setMaxNumPainting={setMaxNumberPainting}
        totalMaxNum={totalNumberPainting}
        orderDate={sort}
        setOrderDate={setSort}
        typeGrid={enumTypeGrid.ArtistPaintingsGrid}
        year={year || { startYear: "", endYear: "" }}
        setYear={setYear}
        refreshKey={refreshKey}
        setRefreshKey={setRefreshKey}
        isHidden={isHidden}
      />
    </div>
  );
}
